import { Layout } from 'components/layout/layout'
import { Description } from 'components/description/description'
import { Snake } from './snake/snake'

export const November30 = () => {
  return (
    <>
      <Layout
        title='Місяць кешбеків розпочнеться вже завтра.'
        subTitle='Субота, 30 листопада'
        description={(
          <Description>Чекаю на тебе!</Description>
        )}
        snake={<Snake />}
        typePhone='snowflakes'
      />
    </>
  )
}
