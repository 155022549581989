import { styled, Box } from '@mui/material'

export const Container = styled(Box)(() => ({
  position: 'relative',
  marginLeft: '-50px',
  marginTop: '-48px'
}))

export const SaluteContainer = styled(Box)(() => ({
  position: 'absolute',
  top: '40px',
  right: '-40px'
}))
