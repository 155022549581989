import { Box } from '@mui/material'
import { Layout } from 'components/layout/layout'
import { Description } from 'components/description/description'
import { Snake } from './snake/snake'

export const December25Complete = () => {
  return (
    <Layout
      title='Дякую, я вже трішки більший!'
      subTitle='Середа, 25 грудня'
      description={(
        <Description>
          Твоя нагорода вже в додатку!<br />
          Завтра буде нова, повертайся!
        </Description>
      )}
      snake={(
        <Box mt='68px'>
          <Snake />
        </Box>
      )}
    />
  )
}
