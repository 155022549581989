import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Layout } from 'components/layout/layout'
import { Drawer } from 'components/drawer/drawer'
import { Description } from 'components/description/description'
import { useActivateMutation } from 'store/sessionApi'
import logoDrawer from 'assets/images/december18/logoDrawer.png'
import { Snake } from './snake/snake'
import { LogoDrawerStyled } from './styles'

export const December18 = () => {
  const [isDrawer, setIsDrawer] = useState(false)
  const [activate, { isLoading }] = useActivateMutation()
  const navigate = useNavigate()

  const goNext = async () => {
    const res = await activate({ cell: 19 })

    if (res) {
      navigate('/december-18/complete')
    }
  }

  return (
    <>
      <Layout
        title='Коляд, коляд, колядниця Змієтопчику гомілка сниться.'
        subTitle='Середа, 18 грудня'
        description={<Description>Це я до Різдва готуюсь, от придумав власну колядку.</Description>}
        snake={<Snake onComplete={() => setIsDrawer(true)} />}
        typePhone='music'
      />

      <Drawer
        isOpen={isDrawer}
        title={<>А ти придумай, що готувати на вечерю. Бо сьогодні кешбек <span>20%</span> на гомілки.</>}
        buttonLabel='Погодуй мене і отримай кешбек'
        onSubmit={goNext}
        isDisabled={isLoading}
      >
        <LogoDrawerStyled logo={logoDrawer} />
      </Drawer>
    </>
  )
}
