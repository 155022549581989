import { styled, Box } from '@mui/material'

export const Container = styled(Box)(() => ({
  width: '100vw',
  height: '100vh',
  position: 'absolute',
  top: 0,
  left: 0,
  zIndex: 1,
  overflow: 'hidden',
  opacity: 0.5
}))
