import { useEffect, useRef, useState } from 'react'
import { gsap } from 'gsap'
import OpenBoxMusic from 'assets/music/openBox.mp3'
import SaluteImage from 'assets/images/salute.gif'
import { HeadBlock } from './headBlock/headBlock'
import { Block1 } from './block1/block1'
import { Block2 } from './block2/block2'
import { TailBlock1 } from './tailBlock/tailBlocks'
import { Container, SaluteContainer } from './styles'

export const Snake = ({ onComplete }) => {
  const [showSalute, setShowSalute] = useState(false)
  const blockRefs = useRef([])
  const tailBlockRefs = useRef([])
  const audioRef = useRef(null)

  useEffect(() => {
    audioRef.current = new Audio(OpenBoxMusic)

    gsap.fromTo(
      blockRefs.current[0],
      { x: '150vw', y: 100 },
      { x: 100, y: 100, duration: 0.4, ease: 'linear', onComplete: startBlock2Animation }
    )

    gsap.set(blockRefs.current[1], { x: '150vw', y: 100, transformOrigin: '100% 100%' })
    gsap.set(tailBlockRefs.current[0], { y: '150vh', x: 200 })
  }, [])

  const startBlock2Animation = () => {
    gsap.to(blockRefs.current[1], { x: 200, y: 100, duration: 0.4, ease: 'linear', onComplete: startTailBlockAnimation } )
  }

  const startTailBlockAnimation = () => {
    gsap.to(tailBlockRefs.current[0], { x: 160, y: 200, duration: 0.4, ease: 'linear' } )
  }

  const handleBlock2Click = () => {
    const rotationDuration = 3
    audioRef.current.play()

    gsap.to(blockRefs.current[1], { rotation: '+=90', duration: rotationDuration, ease: 'elastic.out(1, 0.3)' })

    setTimeout(() => {
      setShowSalute(true)

      setTimeout(() => {
        setShowSalute(false)
        onComplete()
      }, 2000)
    }, rotationDuration * 1000 * 0.1)
  }

  return (
    <Container>
      <svg width="301" height="305" viewBox="0 0 351 305" overflow='visible'>
        <HeadBlock />

        <g ref={(el) => (blockRefs.current[0] = el)}>
          <Block1 />
        </g>

        <g ref={(el) => (blockRefs.current[1] = el)} style={{ cursor: 'pointer' }} onClick={handleBlock2Click}>
          <Block2 />
        </g>

        <g ref={(el) => (tailBlockRefs.current[0] = el)}>
          <TailBlock1 />
        </g>
      </svg>

      {showSalute && (
        <SaluteContainer>
          <img src={SaluteImage} alt="salute" width={180} height={318} />
        </SaluteContainer>
      )}
    </Container>
  )
}
