export const HeadBlock = () => {
  return (
    <g>
      <path d="M151.789 74.4253C151.789 74.4253 181.171 44.2738 178.462 21.2458L184.808 33.1251L193.535 27.2633C193.535 27.2633 177.105 71.3404 151.789 74.4253Z" fill="#FF530A"/>
      <path d="M167.202 55.71C166.435 46.0361 163.435 36.7275 157.986 28.3964C136.668 -4.21412 86.0096 -9.51786 44.8378 16.5476C11.8776 37.4141 0.551754 71.5637 0.500195 101.773C0.390202 165.759 46.8795 200 102.488 200V144.446C109.132 141.906 115.694 138.642 122.029 134.63C148.359 117.961 164.438 92.5013 167.027 67.7821C167.453 63.7231 167.518 59.6878 167.198 55.71H167.202Z" fill="#EFB825"/>
      <path d="M68.0326 66.2295C68.1598 66.3039 68.287 66.3682 68.4176 66.4392C68.5791 66.5238 68.7441 66.6117 68.9057 66.6929C69.0569 66.7707 69.2116 66.8451 69.3594 66.9195C81.7268 72.9031 96.5552 68.7833 104 57.665C104.533 56.8702 105.028 56.0381 105.482 55.1688C112.35 42.0447 107.108 25.9272 93.7745 19.1724C80.4378 12.4142 64.0591 17.5725 57.1914 30.6965C56.7377 31.5658 56.3355 32.4486 55.9849 33.3416C51.1727 45.6166 56.2015 59.6979 68.0292 66.2295H68.0326Z" fill="#FFE6DE"/>
      <path d="M68.4829 67.4235C68.5963 67.4911 68.7166 67.552 68.837 67.6163C68.9848 67.6941 69.1326 67.7719 69.2838 67.8463C69.4213 67.9173 69.5622 67.985 69.6997 68.0526C80.9843 73.512 94.5135 69.754 101.306 59.6066C104.702 50.254 98.9132 38.6961 87.5495 32.9391C76.1859 27.1787 63.2582 29.2555 57.4973 37.4141C53.1045 48.6135 57.6933 61.4602 68.4863 67.4201L68.4829 67.4235Z" fill="black"/>
      <path d="M61.7252 117.626C71.31 117.626 79.08 109.98 79.08 100.548C79.08 91.1161 71.31 83.47 61.7252 83.47C52.1403 83.47 44.3703 91.1161 44.3703 100.548C44.3703 109.98 52.1403 117.626 61.7252 117.626Z" fill="#FF530A"/>
      <path d="M85.109 83.9909C89.8491 88.2765 92.0352 94.8047 90.8837 101.025C90.6121 102.496 88.9176 103.589 87.4395 103.207C85.879 102.804 84.9337 101.387 85.2225 99.8176C85.2775 99.5233 85.3256 99.2256 85.3668 98.928C85.3875 98.7791 85.4012 98.6303 85.4184 98.4815C85.3943 98.7149 85.3943 98.8468 85.4046 98.5356C85.4253 97.8963 85.4287 97.2638 85.3771 96.6279C85.3531 96.3302 85.3222 96.0292 85.2809 95.7349C85.2706 95.6706 85.2018 95.1768 85.2568 95.5421C85.3153 95.9074 85.2293 95.4203 85.2156 95.3527C85.085 94.7269 84.9165 94.1113 84.7103 93.5058C84.6141 93.2217 84.5075 92.941 84.3941 92.6602C84.3391 92.5215 84.2772 92.3828 84.2188 92.2442C84.0641 91.8755 84.26 92.163 84.2635 92.322C84.2635 92.1055 83.9507 91.7131 83.8441 91.5237C83.6963 91.2632 83.5416 91.0028 83.3766 90.7491C83.0295 90.2079 82.6411 89.7039 82.2389 89.2033C81.9777 88.8752 82.5964 89.5686 82.2939 89.2743C82.1873 89.1695 82.0877 89.0545 81.9811 88.9462C81.7439 88.7027 81.4964 88.4693 81.2421 88.2393C80.1284 87.2313 79.9119 85.3236 81.0152 84.2142C82.1014 83.1216 83.9129 82.9119 85.1056 83.9909H85.109Z" fill="black"/>
      <path d="M87.137 96.726C94.4928 98.5999 102.182 98.9212 109.706 97.964C123.724 96.1882 137.669 90.7796 149.586 83.3686C156.295 79.198 162.445 73.8773 167.027 67.5352C167.418 63.4728 167.453 59.4307 167.109 55.4462C161.785 69.4869 148.073 78.8394 134.438 84.8704C120.083 91.2193 103.942 95.1261 88.3642 91.1551C84.7447 90.2316 83.5279 95.806 87.1336 96.726H87.137Z" fill="black"/>
      <path d="M149.753 38.655C149.294 37.5929 148.811 36.4776 148.194 35.5085C148.734 36.3599 148.116 35.3976 148.016 35.2336C147.888 35.0237 147.753 34.8075 147.618 34.6048C147.299 34.1141 146.975 33.6374 146.633 33.1689C145.954 32.2313 145.221 31.3385 144.447 30.4896C144.282 30.3096 144.112 30.13 143.941 29.9503C143.682 29.6811 143.716 29.713 144.036 30.0463C143.921 29.9312 143.805 29.8162 143.689 29.7011C143.276 29.3058 142.857 28.9246 142.426 28.551C141.106 27.4049 140.574 25.206 141.685 23.6933C142.714 22.282 144.77 21.7158 146.178 22.9447C150.206 26.44 153.442 30.8447 155.634 35.896C156.349 37.5489 155.607 39.8087 154.006 40.4664C152.357 41.1481 150.522 40.4341 149.754 38.6618L149.753 38.655Z" fill="black"/>
    </g>
  )
}
