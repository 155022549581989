import { useSelector, useDispatch } from 'react-redux'
import { togglePlayPause } from 'store/playbackSlice'
import { ReactComponent as VoiceActiveImage } from 'assets/images/voiceActive.svg'
import { ReactComponent as VoiceImage } from 'assets/images/voice.svg'
import { Container } from './styles'

export const Voice = () => {
  const { isPlaying } = useSelector(({ playback }) => playback)
  const dispatch = useDispatch()

  const onChangeIsActive = () => dispatch(togglePlayPause())

  return (
    <Container onClick={onChangeIsActive}>
      {isPlaying ? <VoiceActiveImage/> : <VoiceImage />}
    </Container>
  )
}
