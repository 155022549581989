import { useEffect } from 'react'
import { gsap } from 'gsap'

export const Block2 = () => {
  useEffect(() => {
    gsap.fromTo(
      '#box',
      { scale: 1 },
      {
        scale: 0.8,
        duration: 0.5,
        repeat: -1,
        yoyo: true,
        ease: 'power1.inOut'
      }
    )
  }, [])

  return (
    <g>
      <path d="M1.52588e-05 0C55.2276 0 100 44.7724 100 100H1.52588e-05V0Z" fill="#FF530A"/>
      <path d="M20.7434 75.2L16.5834 79.648H25.3834V84H9.95938V80.064L17.3194 72.352C18.2154 71.4133 18.7167 70.88 18.8234 70.752C19.1007 70.432 19.4207 69.9307 19.7834 69.248C20.146 68.5867 20.3274 67.9787 20.3274 67.424C20.3274 66.528 20.018 65.8027 19.3994 65.248C18.7807 64.672 18.002 64.384 17.0634 64.384C15.3994 64.384 13.6287 65.2587 11.7514 67.008L9.15938 63.616C11.6127 61.1627 14.3754 59.936 17.4474 59.936C19.7087 59.936 21.554 60.6187 22.9834 61.984C24.434 63.328 25.1594 65.056 25.1594 67.168C25.1594 69.4507 23.6874 72.128 20.7434 75.2ZM26.8259 66.176L34.1539 60.352H37.6739V84H33.0339V66.624L29.2579 69.536L26.8259 66.176ZM44.2969 84.512C43.5502 84.512 42.9209 84.2667 42.4089 83.776C41.8969 83.264 41.6409 82.6347 41.6409 81.888C41.6409 81.1627 41.8969 80.5547 42.4089 80.064C42.9209 79.5733 43.5502 79.328 44.2969 79.328C45.0009 79.328 45.5982 79.5733 46.0889 80.064C46.6009 80.5547 46.8569 81.1627 46.8569 81.888C46.8569 82.6347 46.6009 83.264 46.0889 83.776C45.5982 84.2667 45.0009 84.512 44.2969 84.512ZM46.6071 66.176L53.9351 60.352H57.4551V84H52.8151V66.624L49.0391 69.536L46.6071 66.176ZM72.4621 75.2L68.3021 79.648H77.1021V84H61.6781V80.064L69.0381 72.352C69.9341 71.4133 70.4355 70.88 70.5421 70.752C70.8195 70.432 71.1395 69.9307 71.5021 69.248C71.8648 68.5867 72.0461 67.9787 72.0461 67.424C72.0461 66.528 71.7368 65.8027 71.1181 65.248C70.4995 64.672 69.7208 64.384 68.7821 64.384C67.1181 64.384 65.3475 65.2587 63.4701 67.008L60.8781 63.616C63.3315 61.1627 66.0941 59.936 69.1661 59.936C71.4275 59.936 73.2728 60.6187 74.7021 61.984C76.1528 63.328 76.8781 65.056 76.8781 67.168C76.8781 69.4507 75.4061 72.128 72.4621 75.2Z" fill="#FFE6DE"/>
      <path id="box" d="M19 30.0006V38.0006H15C14.2044 38.0006 13.4413 37.6845 12.8787 37.1219C12.3161 36.5593 12 35.7962 12 35.0006V31.0006C12 30.7354 12.1054 30.481 12.2929 30.2935C12.4804 30.1059 12.7348 30.0006 13 30.0006H19ZM27 30.0006C27.2652 30.0006 27.5196 30.1059 27.7071 30.2935C27.8946 30.481 28 30.7354 28 31.0006V35.0006C28 35.7962 27.6839 36.5593 27.1213 37.1219C26.5587 37.6845 25.7956 38.0006 25 38.0006H21V30.0006H27ZM24.5 18.0006C25.0905 18.0005 25.6715 18.1498 26.1888 18.4346C26.706 18.7194 27.1429 19.1304 27.4586 19.6295C27.7743 20.1285 27.9586 20.6993 27.9943 21.2887C28.03 21.8782 27.9161 22.4671 27.663 23.0006H28C28.5304 23.0006 29.0391 23.2113 29.4142 23.5864C29.7893 23.9614 30 24.4702 30 25.0006V26.0006C30 26.531 29.7893 27.0397 29.4142 27.4148C29.0391 27.7899 28.5304 28.0006 28 28.0006H21V23.0006H19V28.0006H12C11.4696 28.0006 10.9609 27.7899 10.5858 27.4148C10.2107 27.0397 10 26.531 10 26.0006V25.0006C10 24.4702 10.2107 23.9614 10.5858 23.5864C10.9609 23.2113 11.4696 23.0006 12 23.0006H12.337C12.1144 22.5319 11.9993 22.0194 12 21.5006C12 19.5676 13.567 18.0006 15.483 18.0006C17.238 17.9706 18.795 19.0926 19.864 20.9346L20 21.1776C21.033 19.2636 22.56 18.0636 24.291 18.0026L24.5 18.0006ZM15.5 20.0006C15.1022 20.0006 14.7206 20.1586 14.4393 20.4399C14.158 20.7212 14 21.1028 14 21.5006C14 21.8984 14.158 22.2799 14.4393 22.5612C14.7206 22.8426 15.1022 23.0006 15.5 23.0006H18.643C17.902 21.0956 16.694 19.9806 15.5 20.0006ZM24.483 20.0006C23.303 19.9806 22.098 21.0966 21.357 23.0006H24.5C24.8978 22.9983 25.2785 22.8381 25.5582 22.5552C25.8379 22.2723 25.9938 21.8899 25.9915 21.4921C25.9892 21.0943 25.829 20.7136 25.5462 20.4339C25.2633 20.1542 24.8808 19.9983 24.483 20.0006Z" fill="#FFE6DE"/>
    </g>
  )
}
