export const BUTTONS = [
  { label: 'Каву на кокосовому', key: 1 },
  { label: 'Вівсянку з родзинками', key: 2 },
  { label: 'Млинці з м’ясом ', key: 3 }
]

export const ANSWERS = {
  3: {
    title: 'От молодець!',
    description: <>Дарую тобі кешбек <span>20%</span> на кулінарію у «М’ясомаркет».</>
  },
  1: {
    title: 'Бачу, тобі потрібно підкріпись!',
    description: <>Рушай у «М’ясомаркет», бо сьогодні кешбек <span>20%</span> на кулінарію.</>
  },
  2: {
    title: 'Бачу, тобі потрібно підкріпись!',
    description: <>Рушай у «М’ясомаркет», бо сьогодні кешбек <span>20%</span> на кулінарію.</>
  }
}
