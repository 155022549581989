import { styled, Box, Typography } from '@mui/material'

export const SnakeContainer = styled(Box)(() => ({
  position: 'relative',
  display: 'inline-block'
}))

export const LightContainer = styled(Box)(() => ({
  position: 'absolute',
  top: '65px',
  right: 0,
  cursor: 'pointer'
}))

export const Title = styled(Typography)(({ theme }) => ({
  fontFamily: 'Axiforma',
  fontWeight: '700',
  fontSize: '24px',
  lineHeight: '31.2px',
  textAlign: 'left',
  color: theme.colors.darkTeal,
  marginBottom: '24px'
}))

export const Row = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'flex-start',
  textAlign: 'left',
  marginTop: '16px',

  svg: {
    minWidth: '24px',
    minHeight: '24px',
    maxWidth: '24px',
    maxHeight: '24px',
    marginRight: '16px'
  }
}))

export const Circle = styled(Box)(({ theme }) => ({
  minWidth: '8px',
  minHeight: '8px',
  maxWidth: '8px',
  maxHeight: '8px',
  border: `2px solid ${theme.colors.orange}`,
  borderRadius: '100%',
  marginLeft: '8px',
  marginRight: '16px',
  marginTop: '5px'
}))

export const Label = styled(Typography)(() => ({
  fontFamily: 'Axiforma',
  fontWeight: '500',
  fontSize: '16px',
  lineHeight: '24px',
  color: '#003429CC'
}))
