import { useNavigate } from 'react-router-dom'
import { Layout } from 'components/layout/layout'
import { Description } from 'components/description/description'
import { ReactComponent as SnakeImage } from 'assets/images/home/snake.svg'

export const Home = () => {
  const navigate = useNavigate()

  const goNext = () => navigate('/conditions')

  return (
    <Layout
      title='Вітаю! Мене звати Змієтопчик.'
      description={(
        <Description>
          І я дуже люблю топтати смачненьке. А ще маю <span>багато підвищених кешбеків</span>, які даруватиму тобі протягом грудня.
        </Description>
      )}
      snake={<SnakeImage />}
      buttonLabel='Розпочати'
      onSubmit={goNext}
      typePhone='snowflakes'
    />
  )
}
