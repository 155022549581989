export const HeadBlock = () => {
  return (
    <g>
      <path d="M151.789 74.4255C151.789 74.4255 181.171 44.274 178.462 21.2461L184.808 33.1254L193.535 27.2635C193.535 27.2635 177.105 71.3407 151.789 74.4255Z" fill="#FF530A"/>
      <path d="M167.202 55.71C166.435 46.0361 163.435 36.7275 157.986 28.3964C136.668 -4.21412 86.0096 -9.51786 44.8378 16.5476C11.8776 37.4141 0.551754 71.5637 0.500195 101.773C0.390202 165.759 46.8795 200 102.488 200V144.446C109.132 141.906 115.694 138.642 122.029 134.63C148.359 117.961 164.438 92.5013 167.027 67.7821C167.453 63.7231 167.518 59.6878 167.198 55.71H167.202Z" fill="#EFB825"/>
      <path d="M68.0326 66.229C68.1598 66.3034 68.287 66.3677 68.4176 66.4387C68.5791 66.5233 68.7441 66.6112 68.9057 66.6924C69.0569 66.7702 69.2116 66.8446 69.3594 66.919C81.7268 72.9026 96.5552 68.7828 104 57.6646C104.533 56.8697 105.028 56.0376 105.482 55.1683C112.35 42.0442 107.108 25.9267 93.7745 19.1719C80.4378 12.4137 64.0591 17.572 57.1914 30.696C56.7377 31.5653 56.3355 32.4481 55.9849 33.3411C51.1727 45.6161 56.2015 59.6974 68.0292 66.229H68.0326Z" fill="#FFE6DE"/>
      <path d="M68.4828 67.4237C68.5963 67.4914 68.7166 67.5523 68.8369 67.6165C68.9847 67.6943 69.1325 67.7721 69.2837 67.8466C69.4212 67.9176 69.5622 67.9852 69.6996 68.0529C80.9843 73.5122 94.5134 69.7543 101.306 59.6068C104.702 50.2543 98.9132 38.6963 87.5495 32.9393C76.1858 27.1789 63.2582 29.2558 57.4973 37.4143C53.1044 48.6137 57.6932 61.4604 68.4863 67.4204L68.4828 67.4237Z" fill="black"/>
      <path d="M61.7252 117.626C71.3101 117.626 79.0801 109.98 79.0801 100.548C79.0801 91.1159 71.3101 83.4697 61.7252 83.4697C52.1404 83.4697 44.3704 91.1159 44.3704 100.548C44.3704 109.98 52.1404 117.626 61.7252 117.626Z" fill="#FF530A"/>
      <path d="M85.1091 83.991C89.8491 88.2766 92.0352 94.8048 90.8837 101.025C90.6122 102.497 88.9176 103.589 87.4396 103.207C85.8791 102.804 84.9338 101.387 85.2225 99.8177C85.2775 99.5234 85.3256 99.2257 85.3669 98.9281C85.3875 98.7793 85.4013 98.6304 85.4185 98.4816C85.3944 98.715 85.3944 98.8469 85.4047 98.5357C85.4253 97.8964 85.4288 97.2639 85.3772 96.628C85.3531 96.3303 85.3222 96.0293 85.281 95.735C85.2707 95.6707 85.2019 95.1769 85.2569 95.5422C85.3153 95.9075 85.2294 95.4204 85.2157 95.3528C85.085 94.727 84.9166 94.1114 84.7104 93.506C84.6141 93.2218 84.5076 92.9411 84.3941 92.6603C84.3392 92.5217 84.2773 92.383 84.2188 92.2443C84.0642 91.8756 84.2601 92.1631 84.2635 92.3221C84.2635 92.1056 83.9507 91.7132 83.8442 91.5238C83.6964 91.2634 83.5417 91.0029 83.3767 90.7492C83.0295 90.208 82.6411 89.704 82.239 89.2034C81.9777 88.8753 82.5964 89.5687 82.294 89.2745C82.1874 89.1696 82.0877 89.0546 81.9812 88.9464C81.744 88.7028 81.4965 88.4694 81.2421 88.2394C80.1285 87.2314 79.9119 85.3237 81.0153 84.2143C82.1015 83.1217 83.9129 82.912 85.1057 83.991H85.1091Z" fill="black"/>
      <path d="M87.137 96.7261C94.4928 98.6 102.182 98.9214 109.706 97.9641C123.724 96.1883 137.669 90.7797 149.586 83.3687C156.295 79.1981 162.445 73.8774 167.027 67.5353C167.418 63.4729 167.453 59.4309 167.109 55.4463C161.785 69.487 148.073 78.8396 134.438 84.8705C120.083 91.2194 103.942 95.1262 88.3642 91.1552C84.7447 90.2318 83.5279 95.8061 87.1336 96.7261H87.137Z" fill="black"/>
      <path d="M149.754 38.6549C149.294 37.5928 148.812 36.4775 148.194 35.5084C148.734 36.3598 148.116 35.3975 148.016 35.2335C147.888 35.0236 147.753 34.8073 147.618 34.6047C147.3 34.114 146.975 33.6373 146.633 33.1688C145.954 32.2312 145.221 31.3384 144.447 30.4895C144.282 30.3095 144.112 30.1298 143.941 29.9502C143.682 29.681 143.716 29.7128 144.036 30.0462C143.921 29.9311 143.805 29.816 143.69 29.701C143.276 29.3057 142.857 28.9245 142.426 28.5509C141.106 27.4048 140.574 25.2059 141.685 23.6932C142.714 22.2819 144.77 21.7157 146.178 22.9446C150.206 26.4399 153.442 30.8446 155.634 35.8959C156.35 37.5488 155.607 39.8086 154.007 40.4663C152.357 41.148 150.522 40.434 149.754 38.6617L149.754 38.6549Z" fill="black"/>
    </g>
  )
}
