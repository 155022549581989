export const HeadBlock1 = () => {
  return (
    <g>
      <path d="M199.254 143.655C198.794 142.593 198.312 141.478 197.694 140.508C198.234 141.36 197.616 140.398 197.516 140.234C197.388 140.024 197.253 139.807 197.118 139.605C196.8 139.114 196.475 138.637 196.133 138.169C195.454 137.231 194.721 136.338 193.947 135.49C193.782 135.309 193.612 135.13 193.441 134.95C193.182 134.681 193.216 134.713 193.536 135.046C193.421 134.931 193.305 134.816 193.19 134.701C192.776 134.306 192.357 133.924 191.926 133.551C190.606 132.405 190.074 130.206 191.185 128.693C192.214 127.282 194.27 126.716 195.678 127.945C199.706 131.44 202.942 135.845 205.134 140.896C205.85 142.549 205.107 144.809 203.507 145.466C201.857 146.148 200.022 145.434 199.254 143.662L199.254 143.655Z" fill="black"/>
      <path d="M201.289 179.426C201.289 179.426 230.671 149.274 227.962 126.246L234.308 138.125L243.035 132.264C243.035 132.264 226.605 176.341 201.289 179.426Z" fill="#FF530A"/>
      <path d="M216.702 160.71C215.935 151.036 212.935 141.727 207.486 133.396C186.168 100.786 135.51 95.4821 94.3378 121.548C61.3776 142.414 50.0518 176.564 50.0002 206.773C49.8902 270.759 96.3795 305 151.988 305V249.446C158.632 246.906 165.194 243.642 171.529 239.63C197.859 222.961 213.938 197.501 216.527 172.782C216.953 168.723 217.018 164.688 216.698 160.71H216.702Z" fill="#EFB825"/>
      <path d="M117.533 171.228C117.66 171.302 117.787 171.367 117.918 171.438C118.079 171.522 118.244 171.61 118.406 171.691C118.557 171.769 118.712 171.844 118.859 171.918C131.227 177.902 146.055 173.782 153.5 162.664C154.033 161.869 154.528 161.037 154.982 160.167C161.85 147.043 156.608 130.926 143.274 124.171C129.938 117.413 113.559 122.571 106.691 135.695C106.238 136.564 105.836 137.447 105.485 138.34C100.673 150.615 105.701 164.696 117.529 171.228H117.533Z" fill="#FFE6DE"/>
      <path d="M117.983 172.425C118.096 172.492 118.217 172.553 118.337 172.618C118.485 172.695 118.632 172.773 118.784 172.848C118.921 172.919 119.062 172.986 119.2 173.054C130.484 178.513 144.013 174.755 150.806 164.608C154.202 155.255 148.413 143.697 137.049 137.94C125.686 132.18 112.758 134.257 106.997 142.415C102.604 153.615 107.193 166.461 117.986 172.421L117.983 172.425Z" fill="black"/>
      <path d="M111.225 222.625C120.81 222.625 128.58 214.979 128.58 205.547C128.58 196.115 120.81 188.469 111.225 188.469C101.64 188.469 93.8704 196.115 93.8704 205.547C93.8704 214.979 101.64 222.625 111.225 222.625Z" fill="#FF530A"/>
      <path d="M134.609 188.992C139.349 193.278 141.535 199.806 140.384 206.026C140.112 207.498 138.418 208.59 136.94 208.208C135.379 207.805 134.434 206.388 134.723 204.819C134.778 204.524 134.826 204.227 134.867 203.929C134.888 203.78 134.901 203.631 134.918 203.483C134.894 203.716 134.894 203.848 134.905 203.537C134.925 202.897 134.929 202.265 134.877 201.629C134.853 201.331 134.822 201.03 134.781 200.736C134.771 200.672 134.702 200.178 134.757 200.543C134.815 200.908 134.729 200.421 134.716 200.354C134.585 199.728 134.417 199.112 134.21 198.507C134.114 198.223 134.008 197.942 133.894 197.661C133.839 197.523 133.777 197.384 133.719 197.245C133.564 196.877 133.76 197.164 133.764 197.323C133.764 197.107 133.451 196.714 133.344 196.525C133.196 196.264 133.042 196.004 132.877 195.75C132.53 195.209 132.141 194.705 131.739 194.204C131.478 193.876 132.096 194.57 131.794 194.275C131.687 194.171 131.588 194.056 131.481 193.947C131.244 193.704 130.997 193.47 130.742 193.24C129.628 192.232 129.412 190.325 130.515 189.215C131.601 188.123 133.413 187.913 134.606 188.992H134.609Z" fill="black"/>
      <path d="M136.637 201.725C143.993 203.599 151.682 203.92 159.206 202.963C173.223 201.187 187.168 195.779 199.085 188.368C205.795 184.197 211.944 178.876 216.526 172.534C216.918 168.472 216.952 164.43 216.609 160.445C211.284 174.486 197.573 183.839 183.937 189.87C169.583 196.218 153.442 200.125 137.864 196.154C134.244 195.231 133.028 200.805 136.633 201.725H136.637Z" fill="black"/>
      <path d="M69.1066 47.5466C69.1066 47.5466 50.6773 46.0097 39.6193 50.3311C28.566 54.6584 71.4857 58.671 69.1066 47.5466Z" fill="#FF530A"/>
      <path d="M-6.45066 102.879C-6.45066 102.879 -27.3789 75.0939 20.0385 54.0533C20.0385 54.0533 36.2756 47.8944 56.2484 51.1233C76.2212 54.3522 59.059 44.7799 75.2489 48.3121C91.4389 51.8443 124.558 81.2344 124.558 81.2344C124.558 81.2344 101.179 136.744 52.0606 147.921C52.0606 147.921 43.3009 139.479 42.5955 131.545C41.8902 123.61 39.3893 123.237 35.6272 120.743C31.8652 118.249 34.2195 113.144 35.5296 110.604C36.8396 108.064 35.8393 105.962 31.9502 104.797C28.0621 103.621 29.5607 96.3045 29.5607 96.3045C29.5607 96.3045 27.2815 100.623 22.6706 94.4444C18.0644 88.2713 23.0809 88.751 18.0644 88.2713C13.0479 87.7917 21.9154 92.6401 9.36111 103.972C-3.19313 115.304 -6.45066 102.879 -6.45066 102.879Z" fill="#FF530A"/>
      <path opacity="0.53" d="M124.553 81.237C124.553 81.237 101.173 136.746 52.0552 147.923C52.0552 147.923 43.2954 139.482 42.5901 131.547C41.8848 123.613 39.3839 123.24 35.6219 120.745C31.8598 118.251 34.2141 113.146 35.5242 110.606C36.8342 108.066 35.8339 105.965 31.9448 104.799C28.0567 103.623 29.5553 96.307 29.5553 96.307C29.5553 96.307 27.2761 100.626 22.6652 94.447C18.059 88.2739 23.0755 88.7535 18.059 88.2739C13.0425 87.7942 21.91 92.6426 9.35572 103.975C-3.19852 115.307 -6.45607 102.881 -6.45607 102.881C-3.50278 104.692 0.579561 103.388 3.69909 101.889C6.26254 100.654 8.84078 99.1528 10.358 96.7452C12.071 94.0292 12.172 90.6175 13.3989 87.6567C14.6258 84.6958 18.091 82.0509 20.8781 83.6366C23.0752 84.887 23.5282 88.0568 25.6863 89.3786C28.2396 90.9419 31.6275 89.1047 34.5111 89.9059C36.6001 90.4918 38.076 92.3865 38.9425 94.3731C39.809 96.3597 40.2242 98.523 41.052 100.522C41.7154 102.108 42.6403 103.597 43.1208 105.246C43.8277 107.668 43.5675 110.388 44.8343 112.563C46.4596 115.363 50.3102 116.707 51.0166 119.863C51.5175 122.083 50.3074 124.756 51.7715 126.494C52.8789 127.812 54.8791 127.756 56.5888 127.598C57.1894 127.543 57.8015 127.478 58.324 127.174C60.4312 125.944 59.0185 122.714 59.4357 120.314C59.8863 117.789 62.5526 116.263 65.0748 115.797C67.6018 115.336 70.2444 115.572 72.6963 114.831C76.031 113.83 78.5846 111.184 80.829 108.519C87.1821 100.964 92.6791 92.1 101.483 87.6328C107.276 84.701 113.906 84.005 120.345 83.1834C121.604 83.025 124.482 82.4797 124.553 81.237Z" fill="#FF530A"/>
      <path d="M7.67444 66.7056C16.0171 61.7867 24.919 57.8062 34.1494 54.8759C37.5231 53.8042 41.104 52.8649 44.5757 53.5722C47.9422 54.2588 50.8088 56.4152 54.0083 57.6703C55.9515 58.4353 58.0133 58.8576 60.0596 59.2732C62.2479 59.7183 64.7361 60.1118 66.5401 58.7988C67.3762 58.187 67.9507 57.282 68.7916 56.676C70.7683 55.2401 73.528 55.8794 75.8274 56.732C82.1913 59.1048 88.0961 62.6886 93.1303 67.2455C95.7754 69.6435 98.3135 72.5997 98.5627 76.1629C98.6017 76.7082 98.5749 77.2687 98.3715 77.7695C97.6344 79.533 95.2377 79.6417 93.5208 80.4911C89.9748 82.2435 89.596 86.9907 88.5034 90.7903C86.1433 98.984 78.7713 104.687 71.2994 108.799C68.6217 110.276 65.1763 111.592 62.6801 109.825C61.0528 108.672 60.4536 106.583 59.8912 104.668C57.2625 95.6487 53.1202 86.8118 46.2698 80.3866C39.4193 73.9614 29.482 70.3192 20.3963 72.6894C16.9151 73.5954 13.3903 75.3498 9.88831 74.5108C8.72965 74.2338 7.64095 73.6739 6.45365 73.5282C2.70043 73.0728 0.795168 76.3978 -1.89525 78.1193C-4.74817 73.8567 4.94104 68.3158 7.67444 66.7056Z" fill="#FF530A"/>
      <path d="M36.065 57.3889C40.5343 56.0198 45.677 54.7311 49.6266 57.227C52.7713 59.2223 54.6986 63.4071 58.3997 63.8468C61.5868 64.2212 64.2159 61.5714 67.0704 60.0961C69.8465 58.6562 73.1617 58.3082 76.1728 59.1217C82.0425 60.7179 85.895 66.1915 89.049 71.3999C90.1099 73.1477 91.1771 74.998 91.3029 77.0478C91.3329 77.4636 91.3066 77.9062 91.0797 78.2599C90.4186 79.2853 88.8699 78.7135 87.66 78.6354C85.4481 78.4936 83.6109 80.4898 82.9215 82.5958C82.2369 84.7076 82.352 86.9816 81.9977 89.1732C81.6491 91.36 80.633 93.6868 78.5922 94.5588C76.6967 95.3697 74.4372 94.6603 72.8523 93.345C71.2673 92.0298 70.2135 90.2076 69.1546 88.4386C64.8912 81.3169 59.8577 74.3414 52.7868 69.9973C46.8397 66.3505 39.636 64.7983 32.7205 65.6708C29.2193 66.1136 25.8109 67.1552 22.318 67.6792C20.6204 67.9352 18.6983 68.0142 17.4006 66.8927C13.485 63.5366 20.8412 62.036 22.5817 61.4999C27.0877 60.1396 31.5734 58.7667 36.065 57.3889Z" fill="#FF530A"/>
      <g filter="url(#filter0_d_3025_1735)">
        <path d="M41.8256 134.551C39.155 138.028 39.6011 143.514 42.8002 146.512C38.0814 149.144 35.796 155.436 37.7317 160.485C38.471 162.4 39.8545 164.195 41.8188 164.796C43.7773 165.401 46.2721 164.38 46.7521 162.388C46.0203 164.881 47.7571 167.637 50.1349 168.68C52.5128 169.722 55.3138 169.368 57.7415 168.431C62.0778 166.77 65.6811 163.297 67.5048 159.031C67.1546 161.571 69.4404 163.914 71.9592 164.38C74.4779 164.846 77.0547 163.864 79.2454 162.545C82.5858 160.532 85.6048 157.168 85.3813 153.281C92.5689 155.62 100.91 154.031 106.743 149.215C109.043 147.317 110.885 143.535 108.577 141.652C110.678 141.102 112.789 140.553 114.891 140.003C118.388 139.094 122.203 137.952 124.166 134.922C126.124 131.886 124.339 126.605 120.74 126.893C123.794 126.585 126.902 126.104 129.624 124.67C132.335 123.235 134.624 120.67 134.985 117.621C135.346 114.572 133.291 111.217 130.255 110.777C133.163 110.873 136.071 110.968 138.984 111.07C142.748 111.194 146.984 111.143 149.626 108.462C151.164 106.909 151.871 104.671 151.925 102.483C152.085 95.3121 144.428 89.0832 137.435 90.7045C140.28 88.885 141.655 85.0482 140.61 81.838C139.566 78.6277 136.203 76.3328 132.827 76.5355C130.89 69.8132 122.913 65.5219 116.237 67.597C109.554 69.6769 105.427 77.7338 107.651 84.3656C102.616 84.4204 97.7219 87.4811 95.4834 91.9915C93.2449 96.502 93.7517 102.251 96.7487 106.297C92.7277 107.296 88.6725 108.989 86.064 112.209C83.4555 115.429 82.7747 120.475 85.4306 123.657C81.0939 121.676 75.9949 120.658 71.5806 122.461C67.1663 124.265 64.0615 129.647 66.0044 134.005C65.8899 131.613 64.0504 129.538 61.8373 128.624C60.0003 127.869 57.7729 127.721 55.8321 128.109C53.6232 128.552 53.0411 130.041 51.4701 130.711C48.1246 132.161 44.5448 131.014 41.8256 134.551Z" fill="#FFE6DE"/>
        <path d="M59.2736 132.385C55.9593 132.948 53.703 136.915 54.915 140.056C53.7108 138.573 51.9417 137.556 50.0495 137.268C49.0826 137.117 48.0645 137.164 47.1647 137.545C44.9352 138.485 43.9859 141.402 44.792 143.683C45.5982 145.965 47.7898 147.552 50.1271 148.178C47.1405 148.734 44.2214 150.38 42.7802 153.058C41.339 155.736 41.7489 159.47 44.1568 161.319C46.5647 163.169 50.7722 162.183 51.6403 159.273C51.6162 160.703 52.688 162.001 54.0097 162.535C55.3315 163.069 56.8449 162.946 58.2108 162.513C60.1905 161.887 62.012 160.56 62.9148 158.689C63.8177 156.818 63.6686 154.396 62.2887 152.843C62.9423 155.71 61.5112 159.123 58.7181 160.068C55.9308 161.008 52.3939 158.514 52.886 155.612C51.5226 157.364 49.733 159.311 47.5305 159.014C45.6506 158.765 44.3223 156.729 44.5457 154.841C44.7681 152.964 46.2804 151.392 48.0688 150.748C49.8513 150.109 51.8504 150.289 53.6494 150.88C52.1958 149.818 50.7085 148.716 49.7606 147.188C48.8068 145.665 48.5014 143.588 49.4973 142.09C50.3744 140.769 52.1103 140.168 53.68 140.409C55.2496 140.651 56.637 141.62 57.6463 142.842C56.0594 140.707 56.7695 137.262 59.0589 135.92C61.4336 134.528 64.1364 135.762 65.7456 137.664C66.1603 134.561 62.1738 131.89 59.2736 132.385Z" fill="#FFE6DE"/>
        <path d="M83.8395 148.709C87.1805 151.12 91.6161 151.786 95.6414 150.91C98.2104 150.346 100.707 149.137 102.401 147.122C104.096 145.107 104.849 142.213 103.926 139.755C106.931 139.565 109.972 139.228 112.765 138.117C115.558 137.006 118.122 135.036 119.396 132.31C120.67 129.585 120.423 126.059 118.353 123.872C116.283 121.684 112.353 121.394 110.303 123.595C111.865 120.211 109.76 115.762 106.241 114.536C102.717 113.314 98.5047 115.244 96.8188 118.574C98.5081 115.544 103.305 115.295 105.774 117.741C108.238 120.18 108.375 124.355 106.696 127.385C109.364 125.672 112.881 123.944 115.486 125.759C117.673 127.282 117.775 130.702 116.308 132.921C114.841 135.141 112.263 136.358 109.72 137.155C107.155 137.951 104.193 138.37 101.909 136.956C103.655 140.908 101.865 145.939 98.2403 148.295C94.6151 150.651 89.6246 150.405 85.9621 148.103C82.6843 146.041 80.9652 142.82 79.6708 139.307C76.6361 140.872 82.1989 147.528 83.8395 148.709Z" fill="#FFE6DE"/>
        <path d="M98.4673 97.4096C98.4594 94.7436 99.7781 92.2259 101.511 90.1983C102.99 88.4682 104.881 86.9758 107.111 86.5348C109.341 86.0938 111.93 86.9526 113.004 88.9537C112.51 86.327 112.864 83.4704 114.337 81.2356C115.811 79.0009 118.496 77.5094 121.155 77.8011C123.818 78.0987 126.215 80.3979 126.298 83.071C126.856 81.2689 129.421 81.0798 131.144 81.8506C133.386 82.8586 135.155 85.0011 135.403 87.4488C135.652 89.8965 134.192 92.4919 131.859 93.2717C134.624 92.3991 137.93 93.8307 139.187 96.4445C140.444 99.0583 139.501 102.529 137.099 104.149C134.697 105.77 131.12 105.326 129.169 103.177C131.21 103.597 133.449 103.795 135.273 102.779C137.097 101.763 138.105 99.1189 136.779 97.512C134.919 95.2588 131.135 97.0903 128.397 96.051C130.234 94.7305 131.9 93.0128 132.573 90.8517C133.247 88.6905 132.674 86.0403 130.795 84.772C128.921 83.5095 125.829 84.3293 125.27 86.5229C126.482 83.8877 124.319 80.4847 121.45 80.0441C118.58 79.6035 115.69 81.7243 114.778 84.4846C113.86 87.2391 114.681 90.369 116.405 92.7056C114.367 91.3041 112.081 90.0881 109.607 89.9374C106.44 89.7418 103.233 91.4784 101.661 94.2346C99.9285 97.2705 101.645 99.7888 101.028 102.658C99.3302 101.621 98.4699 99.3457 98.4673 97.4096Z" fill="#FFE6DE"/>
        <path d="M71.3245 143.366C71.5441 142.079 72.5581 140.84 73.8586 140.755C72.7676 140.05 72.4554 138.605 72.3377 137.312C72.0797 134.402 72.4458 131.134 74.6018 129.168C76.1521 127.761 78.4809 127.297 80.4604 127.964C82.4388 128.641 83.9965 130.41 84.4335 132.457C86.1916 129.606 90.8564 129.221 93.0601 131.748C95.2638 134.275 94.2478 138.844 91.1802 140.191C92.6029 138.998 93.1012 136.814 92.3242 135.126C91.5472 133.437 89.555 132.4 87.7313 132.74C85.9076 133.081 84.4159 134.772 84.3241 136.629C83.9477 134.845 83.0877 133.127 81.6984 131.954C80.309 130.781 78.3581 130.208 76.6073 130.684C75.9685 130.859 75.3542 131.171 74.8947 131.657C74.4294 132.149 74.1432 132.787 73.9479 133.428C73.0182 136.476 74.0408 140.027 76.4466 142.124C75.5898 141.382 73.1771 145.302 73.1182 145.919C72.9881 147.279 74.0543 148.523 74.2138 149.772C72.235 148.762 70.9557 145.54 71.3245 143.366Z" fill="#FFE6DE"/>
      </g>
      <path d="M-13.5983 98.666C-14.7353 100.799 -14.0762 103.72 -12.1322 105.15C-14.7102 105.976 -15.336 109.381 -14.8835 112.052C-14.527 114.156 -13.5989 116.395 -11.6552 117.267C-9.71151 118.139 -6.84882 116.579 -7.28761 114.494C-8.01536 115.711 -7.24795 117.388 -6.03151 118.127C-4.81508 118.865 -3.31901 118.922 -1.89531 118.951C0.171168 118.988 2.39836 118.97 4.07913 117.769C5.7599 116.567 6.36578 113.708 4.69048 112.497C6.75784 114.207 9.36315 115.394 12.0527 115.308C14.7316 115.221 17.4415 113.689 18.4777 111.209C19.5139 108.728 18.4659 105.443 15.9924 104.391C19.4684 104.997 23.1885 101.985 23.0803 98.4621C22.972 94.9392 18.7444 92.154 15.5553 93.6509C18.2704 90.9986 18.65 86.2996 16.3956 83.2365C14.0029 79.9939 9.46814 79.0187 5.46858 79.5694C2.05614 80.0368 -1.34072 81.5192 -3.44139 84.2517C-5.53623 86.9794 -6.01351 91.0737 -4.00729 93.8718C-5.09539 92.352 -7.46053 95.8315 -7.92067 96.0449C-10.0607 97.0576 -12.1034 95.8755 -13.5983 98.666Z" fill="#FFE6DE"/>
      <path d="M-11.4037 100.896C-12.5408 103.029 -11.8816 105.951 -9.93764 107.381C-12.5156 108.207 -13.1415 111.611 -12.689 114.282C-12.3324 116.386 -11.4043 118.625 -9.4606 119.498C-7.51692 120.37 -4.65424 118.81 -5.09302 116.724C-5.82077 117.942 -5.05336 119.619 -3.83693 120.357C-2.62049 121.095 -1.12442 121.153 0.299279 121.182C2.36575 121.218 4.59294 121.201 6.27371 119.999C7.95448 118.798 8.56035 115.938 6.88504 114.727C8.95241 116.437 11.5577 117.625 14.2473 117.539C16.9368 117.453 19.636 115.92 20.6722 113.439C21.7084 110.959 20.6605 107.673 18.187 106.622C21.663 107.227 25.3831 104.215 25.2749 100.693C25.1666 97.1697 20.939 94.3844 17.7499 95.8813C20.465 93.229 20.8445 88.5301 18.5902 85.467C16.1974 82.2244 11.6627 81.2492 7.66316 81.7998C4.25072 82.2672 0.853843 83.7497 -1.24682 86.4822C-3.34166 89.2099 -3.81895 93.3042 -1.81273 96.1023C-2.90082 94.5825 -5.26595 98.0619 -5.72609 98.2753C-7.8709 99.2822 -9.91367 98.1002 -11.4037 100.896Z" fill="#FFE6DE"/>
      <path d="M10.7141 90.6116C11.731 91.642 12.2647 93.0712 12.46 94.5056C12.7142 96.3907 12.3847 98.4346 11.1474 99.8768C13.4928 98.5673 16.4723 98.4769 18.8912 99.652C19.7775 100.085 20.6606 100.776 20.8088 101.75C20.929 102.512 20.5658 103.282 20.0749 103.873C18.7148 105.534 16.2498 106.189 14.2418 105.428C15.6185 106.622 16.6453 108.221 17.1475 109.98C17.4452 111.017 17.5354 112.205 16.9252 113.09C16.2965 114.001 15.0741 114.335 13.9766 114.203C12.8849 114.066 11.8792 113.536 10.9044 113.019C10.2458 112.672 9.57279 112.307 9.08536 111.74C8.59793 111.173 8.34365 110.355 8.63392 109.675C9.63817 110.726 10.7398 111.823 12.1652 112.115C13.5906 112.407 15.3553 111.45 15.307 109.992C15.2852 109.266 14.831 108.632 14.3638 108.078C13.3697 106.921 12.1951 105.913 10.8997 105.103C12.5034 105.101 14.1089 104.911 15.6727 104.541C16.2765 104.395 16.8999 104.213 17.3687 103.796C17.8375 103.38 18.0979 102.676 17.8241 102.119C17.5282 101.511 16.7704 101.305 16.1008 101.241C13.1386 100.925 10.1891 101.823 7.34795 102.71C8.29232 101.47 9.25518 100.205 9.82359 98.7474C11.5258 94.4055 8.45703 94.1389 6.79931 91.2294C5.19622 88.4216 9.05644 88.9355 10.7141 90.6116Z" fill="#FFE6DE"/>
      <defs>
        <filter id="filter0_d_3025_1735" x="33.2211" y="65.1595" width="122.528" height="109.887" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dy="1.91081"/>
          <feGaussianBlur stdDeviation="1.91081"/>
          <feComposite in2="hardAlpha" operator="out"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3025_1735"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3025_1735" result="shape"/>
        </filter>
      </defs>
    </g>
  )
}

export const HeadBlock2 = () => {
  return (
    <g>
      <path d="M199.254 143.655C198.794 142.593 198.312 141.478 197.694 140.508C198.234 141.36 197.616 140.398 197.516 140.234C197.388 140.024 197.253 139.807 197.118 139.605C196.8 139.114 196.475 138.637 196.133 138.169C195.454 137.231 194.721 136.338 193.947 135.49C193.782 135.309 193.612 135.13 193.441 134.95C193.182 134.681 193.216 134.713 193.536 135.046C193.421 134.931 193.305 134.816 193.19 134.701C192.776 134.306 192.357 133.924 191.926 133.551C190.606 132.405 190.074 130.206 191.185 128.693C192.214 127.282 194.27 126.716 195.678 127.945C199.706 131.44 202.942 135.845 205.134 140.896C205.85 142.549 205.107 144.809 203.507 145.466C201.857 146.148 200.022 145.434 199.254 143.662L199.254 143.655Z" fill="black"/>
      <path d="M201.289 179.426C201.289 179.426 230.671 149.274 227.962 126.246L234.308 138.125L243.035 132.264C243.035 132.264 226.605 176.341 201.289 179.426Z" fill="#FF530A"/>
      <path d="M216.702 160.71C215.935 151.036 212.935 141.727 207.486 133.396C186.168 100.786 135.51 95.4821 94.3378 121.548C61.3776 142.414 50.0518 176.564 50.0002 206.773C49.8902 270.759 96.3795 305 151.988 305V249.446C158.632 246.906 165.194 243.642 171.529 239.63C197.859 222.961 213.938 197.501 216.527 172.782C216.953 168.723 217.018 164.688 216.698 160.71H216.702Z" fill="#EFB825"/>
      <path d="M117.533 171.228C117.66 171.302 117.787 171.367 117.918 171.438C118.079 171.522 118.244 171.61 118.406 171.691C118.557 171.769 118.712 171.844 118.859 171.918C131.227 177.902 146.055 173.782 153.5 162.664C154.033 161.869 154.528 161.037 154.982 160.167C161.85 147.043 156.608 130.926 143.274 124.171C129.938 117.413 113.559 122.571 106.691 135.695C106.238 136.564 105.836 137.447 105.485 138.34C100.673 150.615 105.701 164.696 117.529 171.228H117.533Z" fill="#FFE6DE"/>
      <path d="M117.983 172.425C118.096 172.492 118.217 172.553 118.337 172.618C118.485 172.695 118.632 172.773 118.784 172.848C118.921 172.919 119.062 172.986 119.2 173.054C130.484 178.513 144.013 174.755 150.806 164.608C154.202 155.255 148.413 143.697 137.049 137.94C125.686 132.18 112.758 134.257 106.997 142.415C102.604 153.615 107.193 166.461 117.986 172.421L117.983 172.425Z" fill="black"/>
      <path d="M111.225 222.625C120.81 222.625 128.58 214.979 128.58 205.547C128.58 196.115 120.81 188.469 111.225 188.469C101.64 188.469 93.8701 196.115 93.8701 205.547C93.8701 214.979 101.64 222.625 111.225 222.625Z" fill="#FF530A"/>
      <path d="M134.609 188.992C139.349 193.278 141.535 199.806 140.384 206.026C140.112 207.498 138.418 208.59 136.94 208.208C135.379 207.805 134.434 206.388 134.723 204.819C134.778 204.524 134.826 204.227 134.867 203.929C134.888 203.78 134.901 203.631 134.918 203.483C134.894 203.716 134.894 203.848 134.905 203.537C134.925 202.897 134.929 202.265 134.877 201.629C134.853 201.331 134.822 201.03 134.781 200.736C134.771 200.672 134.702 200.178 134.757 200.543C134.815 200.908 134.729 200.421 134.716 200.354C134.585 199.728 134.417 199.112 134.21 198.507C134.114 198.223 134.008 197.942 133.894 197.661C133.839 197.523 133.777 197.384 133.719 197.245C133.564 196.877 133.76 197.164 133.764 197.323C133.764 197.107 133.451 196.714 133.344 196.525C133.196 196.264 133.042 196.004 132.877 195.75C132.53 195.209 132.141 194.705 131.739 194.204C131.478 193.876 132.096 194.57 131.794 194.275C131.687 194.171 131.588 194.056 131.481 193.947C131.244 193.704 130.997 193.47 130.742 193.24C129.628 192.232 129.412 190.325 130.515 189.215C131.601 188.123 133.413 187.913 134.606 188.992H134.609Z" fill="black"/>
      <path d="M136.637 201.725C143.993 203.599 151.682 203.92 159.206 202.963C173.223 201.187 187.168 195.779 199.085 188.368C205.795 184.197 211.944 178.876 216.526 172.534C216.918 168.472 216.952 164.43 216.609 160.445C211.284 174.486 197.573 183.839 183.937 189.87C169.583 196.218 153.442 200.125 137.864 196.154C134.244 195.231 133.028 200.805 136.633 201.725H136.637Z" fill="black"/>
      <path d="M69.1068 47.5466C69.1068 47.5466 50.6776 46.0097 39.6195 50.3311C28.5663 54.6584 71.486 58.671 69.1068 47.5466Z" fill="#FF530A"/>
      <path d="M-6.45066 102.877C-6.45066 102.877 -27.3789 75.092 20.0385 54.0514C20.0385 54.0514 36.2756 47.8924 56.2484 51.1213C76.2212 54.3503 59.059 44.7779 75.2489 48.3101C91.4389 51.8423 124.558 81.2325 124.558 81.2325C124.558 81.2325 101.179 136.742 52.0606 147.919C52.0606 147.919 43.3009 139.477 42.5955 131.543C41.8902 123.608 39.3893 123.235 35.6272 120.741C31.8652 118.247 34.2195 113.142 35.5296 110.602C36.8396 108.062 35.8393 105.96 31.9502 104.795C28.0621 103.619 29.5607 96.3025 29.5607 96.3025C29.5607 96.3025 27.2815 100.621 22.6706 94.4425C18.0644 88.2694 23.0809 88.749 18.0644 88.2694C13.0479 87.7897 21.9154 92.6381 9.36111 103.97C-3.19313 115.302 -6.45066 102.877 -6.45066 102.877Z" fill="#FF530A"/>
      <path opacity="0.53" d="M124.553 81.237C124.553 81.237 101.173 136.746 52.0552 147.923C52.0552 147.923 43.2954 139.482 42.5901 131.547C41.8848 123.613 39.3839 123.24 35.6219 120.745C31.8598 118.251 34.2141 113.146 35.5242 110.606C36.8342 108.066 35.8339 105.965 31.9448 104.799C28.0567 103.623 29.5553 96.307 29.5553 96.307C29.5553 96.307 27.2761 100.626 22.6652 94.447C18.059 88.2739 23.0755 88.7535 18.059 88.2739C13.0425 87.7942 21.91 92.6426 9.35572 103.975C-3.19852 115.307 -6.45607 102.881 -6.45607 102.881C-3.50278 104.692 0.579561 103.388 3.69909 101.889C6.26254 100.654 8.84078 99.1528 10.358 96.7452C12.071 94.0292 12.172 90.6175 13.3989 87.6567C14.6258 84.6958 18.091 82.0509 20.8781 83.6366C23.0752 84.887 23.5282 88.0568 25.6863 89.3786C28.2396 90.9419 31.6275 89.1047 34.5111 89.9059C36.6001 90.4918 38.076 92.3865 38.9425 94.3731C39.809 96.3597 40.2242 98.523 41.052 100.522C41.7154 102.108 42.6403 103.597 43.1208 105.246C43.8277 107.668 43.5675 110.388 44.8343 112.563C46.4596 115.363 50.3102 116.707 51.0166 119.863C51.5175 122.083 50.3074 124.756 51.7715 126.494C52.8789 127.812 54.8791 127.756 56.5888 127.598C57.1894 127.543 57.8015 127.478 58.324 127.174C60.4312 125.944 59.0185 122.714 59.4357 120.314C59.8863 117.789 62.5526 116.263 65.0748 115.797C67.6018 115.336 70.2444 115.572 72.6963 114.831C76.031 113.83 78.5846 111.184 80.829 108.519C87.1821 100.964 92.6791 92.1 101.483 87.6328C107.276 84.701 113.906 84.005 120.345 83.1834C121.604 83.025 124.482 82.4797 124.553 81.237Z" fill="#FF530A"/>
      <path d="M7.67468 66.7037C16.0174 61.7848 24.9192 57.8042 34.1497 54.8739C37.5234 53.8022 41.1043 52.863 44.576 53.5703C47.9424 54.2568 50.809 56.4132 54.0085 57.6683C55.9517 58.4333 58.0135 58.8557 60.0598 59.2712C62.2481 59.7164 64.7363 60.1098 66.5404 58.7969C67.3765 58.185 67.951 57.2801 68.7919 56.674C70.7686 55.2382 73.5282 55.8774 75.8277 56.7301C82.1915 59.1029 88.0963 62.6866 93.1305 67.2436C95.7756 69.6416 98.3137 72.5978 98.5629 76.1609C98.6019 76.7063 98.5751 77.2668 98.3717 77.7675C97.6347 79.5311 95.2379 79.6398 93.5211 80.4891C89.9751 82.2415 89.5962 86.9888 88.5036 90.7883C86.1435 98.982 78.7715 104.686 71.2997 108.797C68.622 110.274 65.1766 111.59 62.6803 109.823C61.0531 108.67 60.4538 106.581 59.8915 104.666C57.2628 95.6467 53.1205 86.8099 46.27 80.3846C39.4196 73.9594 29.4823 70.3172 20.3965 72.6875C16.9153 73.5934 13.3906 75.3478 9.88855 74.5089C8.7299 74.2318 7.64119 73.6719 6.45389 73.5262C2.70068 73.0708 0.795412 76.3958 -1.89501 78.1174C-4.74792 73.8548 4.94128 68.3139 7.67468 66.7037Z" fill="#FF530A"/>
      <path d="M36.065 57.387C40.5343 56.0178 45.677 54.7291 49.6266 57.225C52.7713 59.2203 54.6986 63.4051 58.3997 63.8448C61.5868 64.2193 64.2159 61.5694 67.0704 60.0942C69.8465 58.6543 73.1617 58.3063 76.1728 59.1197C82.0425 60.716 85.895 66.1896 89.049 71.398C90.1099 73.1458 91.1771 74.996 91.3029 77.0459C91.3329 77.4616 91.3066 77.9042 91.0797 78.2579C90.4186 79.2833 88.8699 78.7116 87.66 78.6335C85.4481 78.4917 83.6109 80.4879 82.9215 82.5938C82.2369 84.7056 82.352 86.9797 81.9977 89.1713C81.6491 91.3581 80.633 93.6848 78.5922 94.5569C76.6967 95.3677 74.4372 94.6583 72.8523 93.3431C71.2673 92.0278 70.2135 90.2057 69.1546 88.4366C64.8912 81.3149 59.8577 74.3395 52.7868 69.9953C46.8397 66.3485 39.636 64.7964 32.7205 65.6689C29.2193 66.1117 25.8109 67.1532 22.318 67.6773C20.6204 67.9333 18.6983 68.0122 17.4006 66.8907C13.485 63.5347 20.8412 62.034 22.5817 61.4979C27.0877 60.1376 31.5734 58.7647 36.065 57.387Z" fill="#FF530A"/>
      <g filter="url(#filter0_d_3025_2168)">
        <path d="M41.8256 134.549C39.155 138.026 39.6011 143.512 42.8002 146.51C38.0814 149.142 35.796 155.434 37.7317 160.483C38.471 162.398 39.8545 164.193 41.8188 164.794C43.7773 165.399 46.2721 164.378 46.7521 162.386C46.0203 164.879 47.7571 167.635 50.1349 168.678C52.5128 169.72 55.3138 169.366 57.7415 168.429C62.0778 166.768 65.6811 163.295 67.5048 159.029C67.1546 161.569 69.4404 163.912 71.9592 164.378C74.4779 164.844 77.0547 163.862 79.2454 162.543C82.5858 160.53 85.6048 157.166 85.3813 153.279C92.5689 155.618 100.91 154.029 106.743 149.213C109.043 147.315 110.885 143.533 108.577 141.65C110.678 141.1 112.789 140.551 114.891 140.002C118.388 139.092 122.203 137.95 124.166 134.92C126.124 131.884 124.339 126.603 120.74 126.891C123.794 126.583 126.902 126.102 129.624 124.668C132.335 123.233 134.624 120.668 134.985 117.619C135.346 114.57 133.291 111.215 130.255 110.775C133.163 110.871 136.071 110.966 138.984 111.068C142.748 111.192 146.984 111.141 149.626 108.46C151.164 106.907 151.871 104.669 151.925 102.481C152.085 95.3102 144.428 89.0813 137.435 90.7025C140.28 88.8831 141.655 85.0462 140.61 81.836C139.566 78.6258 136.203 76.3308 132.827 76.5335C130.89 69.8113 122.913 65.5199 116.237 67.595C109.554 69.675 105.427 77.7319 107.651 84.3636C102.616 84.4184 97.7219 87.4791 95.4834 91.9896C93.2449 96.5 93.7517 102.249 96.7487 106.295C92.7277 107.294 88.6725 108.987 86.064 112.207C83.4555 115.427 82.7747 120.473 85.4306 123.655C81.0939 121.674 75.9949 120.656 71.5806 122.459C67.1663 124.263 64.0615 129.645 66.0044 134.003C65.8899 131.611 64.0504 129.536 61.8373 128.622C60.0003 127.867 57.7729 127.719 55.8321 128.107C53.6232 128.55 53.0411 130.039 51.4701 130.709C48.1246 132.159 44.5448 131.012 41.8256 134.549Z" fill="#FFE6DE"/>
        <path d="M59.2736 132.383C55.9593 132.946 53.703 136.913 54.915 140.054C53.7108 138.571 51.9417 137.554 50.0495 137.266C49.0826 137.115 48.0645 137.162 47.1647 137.543C44.9352 138.483 43.9859 141.4 44.792 143.681C45.5982 145.963 47.7898 147.55 50.1271 148.176C47.1405 148.732 44.2214 150.378 42.7802 153.056C41.339 155.734 41.7489 159.468 44.1568 161.317C46.5647 163.167 50.7722 162.181 51.6403 159.271C51.6162 160.701 52.688 161.999 54.0097 162.533C55.3315 163.067 56.8449 162.944 58.2108 162.511C60.1905 161.885 62.012 160.558 62.9148 158.687C63.8177 156.816 63.6686 154.394 62.2887 152.841C62.9423 155.708 61.5112 159.121 58.7181 160.066C55.9308 161.006 52.3939 158.512 52.886 155.61C51.5226 157.362 49.733 159.309 47.5305 159.012C45.6506 158.763 44.3223 156.727 44.5457 154.839C44.7681 152.962 46.2804 151.39 48.0688 150.746C49.8513 150.107 51.8504 150.287 53.6494 150.878C52.1958 149.816 50.7085 148.714 49.7606 147.186C48.8068 145.663 48.5014 143.586 49.4973 142.088C50.3744 140.767 52.1103 140.166 53.68 140.407C55.2496 140.649 56.637 141.618 57.6463 142.841C56.0594 140.705 56.7695 137.26 59.0589 135.918C61.4336 134.526 64.1364 135.76 65.7456 137.662C66.1603 134.56 62.1738 131.889 59.2736 132.383Z" fill="#FFE6DE"/>
        <path d="M83.8395 148.708C87.1805 151.118 91.6161 151.784 95.6414 150.908C98.2104 150.344 100.707 149.135 102.401 147.12C104.096 145.105 104.849 142.211 103.926 139.753C106.931 139.563 109.972 139.226 112.765 138.115C115.558 137.004 118.122 135.034 119.396 132.308C120.67 129.583 120.423 126.057 118.353 123.87C116.283 121.682 112.353 121.392 110.303 123.593C111.865 120.209 109.76 115.76 106.241 114.534C102.717 113.312 98.5047 115.242 96.8188 118.572C98.5081 115.542 103.305 115.293 105.774 117.739C108.238 120.178 108.375 124.353 106.696 127.383C109.364 125.67 112.881 123.942 115.486 125.757C117.673 127.28 117.775 130.7 116.308 132.919C114.841 135.139 112.263 136.356 109.72 137.153C107.155 137.949 104.193 138.368 101.909 136.954C103.655 140.907 101.865 145.937 98.2403 148.293C94.6151 150.649 89.6246 150.403 85.9621 148.101C82.6843 146.039 80.9652 142.818 79.6708 139.305C76.6361 140.87 82.1989 147.526 83.8395 148.708Z" fill="#FFE6DE"/>
        <path d="M98.4673 97.4076C98.4594 94.7416 99.7781 92.2239 101.511 90.1963C102.99 88.4662 104.881 86.9739 107.111 86.5328C109.341 86.0918 111.93 86.9507 113.004 88.9518C112.51 86.3251 112.864 83.4684 114.337 81.2337C115.811 78.999 118.496 77.5074 121.155 77.7992C123.818 78.0968 126.215 80.396 126.298 83.0691C126.856 81.267 129.421 81.0779 131.144 81.8486C133.386 82.8566 135.155 84.9992 135.403 87.4469C135.652 89.8946 134.192 92.4899 131.859 93.2697C134.624 92.3972 137.93 93.8287 139.187 96.4425C140.444 99.0564 139.501 102.527 137.099 104.147C134.697 105.768 131.12 105.324 129.169 103.175C131.21 103.595 133.449 103.793 135.273 102.777C137.097 101.761 138.105 99.117 136.779 97.51C134.919 95.2569 131.135 97.0884 128.397 96.049C130.234 94.7285 131.9 93.0108 132.573 90.8497C133.247 88.6886 132.674 86.0383 130.795 84.77C128.921 83.5076 125.829 84.3274 125.27 86.5209C126.482 83.8858 124.319 80.4828 121.45 80.0422C118.58 79.6015 115.69 81.7224 114.778 84.4826C113.86 87.2371 114.681 90.367 116.405 92.7036C114.367 91.3022 112.081 90.0861 109.607 89.9354C106.44 89.7399 103.233 91.4764 101.661 94.2327C99.9285 97.2685 101.645 99.7868 101.028 102.656C99.3302 101.619 98.4699 99.3438 98.4673 97.4076Z" fill="#FFE6DE"/>
        <path d="M71.3242 143.364C71.5439 142.077 72.5578 140.838 73.8583 140.753C72.7674 140.048 72.4552 138.603 72.3375 137.31C72.0794 134.4 72.4455 131.132 74.6016 129.166C76.1519 127.759 78.4807 127.295 80.4601 127.962C82.4386 128.639 83.9962 130.408 84.4333 132.455C86.1913 129.604 90.8561 129.219 93.0598 131.746C95.2635 134.273 94.2476 138.842 91.18 140.19C92.6026 138.996 93.101 136.812 92.3239 135.124C91.5469 133.435 89.5547 132.398 87.731 132.738C85.9074 133.079 84.4157 134.77 84.3239 136.627C83.9475 134.843 83.0875 133.125 81.6981 131.952C80.3088 130.779 78.3579 130.206 76.607 130.682C75.9683 130.857 75.354 131.169 74.8945 131.656C74.4292 132.147 74.143 132.785 73.9476 133.426C73.018 136.474 74.0405 140.025 76.4464 142.122C75.5895 141.38 73.1769 145.3 73.118 145.917C72.9879 147.277 74.0541 148.521 74.2135 149.77C72.2347 148.76 70.9555 145.538 71.3242 143.364Z" fill="#FFE6DE"/>
      </g>
      <path d="M-13.5976 106.729C-13.7093 109.144 -11.8604 111.5 -9.49082 111.957C-11.4649 113.81 -10.5692 117.153 -9.01433 119.371C-7.78937 121.119 -5.99014 122.743 -3.86021 122.697C-1.73028 122.651 0.185846 120.013 -1.10527 118.318C-1.24016 119.73 0.172562 120.915 1.58816 121.06C3.00375 121.205 4.37964 120.615 5.67798 120.03C7.56026 119.176 9.56438 118.204 10.567 116.398C11.5695 114.592 10.8898 111.749 8.85678 111.374C11.4579 112.031 14.3207 111.986 16.7131 110.754C19.0955 109.526 20.8857 106.979 20.7573 104.294C20.6288 101.609 18.2723 99.0909 15.5868 99.2025C18.9865 98.258 21.0541 93.941 19.4446 90.8055C17.835 87.6699 12.8212 86.9683 10.5831 89.689C11.8972 86.1282 10.2236 81.721 6.87291 79.9217C3.32021 78.0196 -1.19425 79.0847 -4.57051 81.2985C-7.4522 83.185 -9.88425 85.9817 -10.6091 89.3513C-11.3307 92.714 -10.0048 96.617 -6.99193 98.2834C-8.62693 97.3776 -9.27009 101.535 -9.59413 101.926C-11.0925 103.759 -13.4448 103.568 -13.5976 106.729Z" fill="#FFE6DE"/>
      <path d="M-10.6584 107.802C-10.7702 110.216 -8.92116 112.572 -6.55162 113.029C-8.52569 114.882 -7.63002 118.225 -6.07513 120.444C-4.85017 122.191 -3.05093 123.815 -0.921004 123.769C1.20893 123.723 3.12505 121.085 1.83394 119.39C1.69905 120.802 3.11177 121.987 4.52737 122.132C5.94297 122.277 7.31885 121.687 8.61719 121.102C10.4995 120.248 12.5036 119.277 13.5062 117.47C14.5088 115.664 13.829 112.821 11.796 112.446C14.3971 113.103 17.26 113.058 19.6523 111.826C22.0447 110.594 23.8249 108.051 23.6965 105.366C23.568 102.681 21.2115 100.163 18.526 100.275C21.9257 99.3303 23.9933 95.0133 22.3838 91.8777C20.7742 88.7422 15.7604 88.0406 13.5223 90.7612C14.8364 87.2004 13.1628 82.7933 9.81211 80.9939C6.2594 79.0919 1.74496 80.157 -1.63131 82.3707C-4.51299 84.2573 -6.94505 87.054 -7.66985 90.4235C-8.39146 93.7863 -7.06557 97.6893 -4.05273 99.3557C-5.68772 98.4499 -6.33088 102.608 -6.65492 102.998C-8.16012 104.828 -10.5125 104.637 -10.6584 107.802Z" fill="#FFE6DE"/>
      <path d="M4.90565 89.0207C6.26632 89.5149 7.36168 90.5768 8.15366 91.7886C9.19217 93.3823 9.77163 95.3698 9.273 97.2034C10.8295 95.0142 13.4819 93.6539 16.171 93.6773C17.1575 93.6883 18.2515 93.9329 18.8033 94.7492C19.239 95.3861 19.2412 96.2372 19.0515 96.9819C18.5359 99.0659 16.5902 100.715 14.4502 100.89C16.2058 101.377 17.8198 102.381 19.0281 103.754C19.7418 104.563 20.3332 105.598 20.1621 106.659C19.9849 107.751 19.024 108.577 17.9761 108.929C16.9315 109.274 15.7954 109.227 14.6931 109.178C13.9493 109.147 13.1849 109.107 12.5014 108.804C11.8179 108.501 11.2372 107.871 11.2075 107.133C12.5655 107.65 14.0315 108.169 15.4441 107.821C16.8568 107.473 18.0399 105.851 17.3707 104.555C17.0395 103.908 16.3574 103.531 15.6976 103.231C14.3032 102.613 12.8098 102.206 11.2921 102.03C12.7396 101.34 14.1083 100.48 15.3618 99.4741C15.8445 99.083 16.3296 98.6514 16.5744 98.0741C16.8192 97.4969 16.7521 96.7488 16.2657 96.3632C15.7378 95.9415 14.9646 96.0801 14.3324 96.3096C11.5214 97.2959 9.24259 99.3725 7.0568 101.393C7.37784 99.8677 7.70482 98.3122 7.5926 96.7514C7.26687 92.0992 4.38062 93.1753 1.63473 91.2586C-1.01816 89.4104 2.68909 88.2181 4.90565 89.0207Z" fill="#FFE6DE"/>
      <defs>
        <filter id="filter0_d_3025_2168" x="33.2211" y="65.1576" width="122.528" height="109.887" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dy="1.91081"/>
          <feGaussianBlur stdDeviation="1.91081"/>
          <feComposite in2="hardAlpha" operator="out"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3025_2168"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3025_2168" result="shape"/>
        </filter>
      </defs>
    </g>
  )
}

export const HeadBlock3 = () => {
  return (
    <g>
      <path d="M199.254 143.655C198.794 142.593 198.312 141.478 197.694 140.508C198.234 141.36 197.616 140.398 197.516 140.234C197.388 140.024 197.253 139.807 197.118 139.605C196.8 139.114 196.475 138.637 196.133 138.169C195.454 137.231 194.721 136.338 193.947 135.49C193.782 135.309 193.612 135.13 193.441 134.95C193.182 134.681 193.216 134.713 193.536 135.046C193.421 134.931 193.305 134.816 193.19 134.701C192.776 134.306 192.357 133.924 191.926 133.551C190.606 132.405 190.074 130.206 191.185 128.693C192.214 127.282 194.27 126.716 195.678 127.945C199.706 131.44 202.942 135.845 205.134 140.896C205.85 142.549 205.107 144.809 203.507 145.466C201.857 146.148 200.022 145.434 199.254 143.662L199.254 143.655Z" fill="black"/>
      <path d="M201.289 179.426C201.289 179.426 230.671 149.274 227.962 126.246L234.308 138.125L243.035 132.264C243.035 132.264 226.605 176.341 201.289 179.426Z" fill="#FF530A"/>
      <path d="M216.702 160.71C215.935 151.036 212.935 141.727 207.486 133.396C186.168 100.786 135.51 95.4821 94.3378 121.548C61.3776 142.414 50.0518 176.564 50.0002 206.773C49.8902 270.759 96.3795 305 151.988 305V249.446C158.632 246.906 165.194 243.642 171.529 239.63C197.859 222.961 213.938 197.501 216.527 172.782C216.953 168.723 217.018 164.688 216.698 160.71H216.702Z" fill="#EFB825"/>
      <path d="M117.533 171.228C117.66 171.302 117.787 171.367 117.918 171.438C118.079 171.522 118.244 171.61 118.406 171.691C118.557 171.769 118.712 171.844 118.859 171.918C131.227 177.902 146.055 173.782 153.5 162.664C154.033 161.869 154.528 161.037 154.982 160.167C161.85 147.043 156.608 130.926 143.274 124.171C129.938 117.413 113.559 122.571 106.691 135.695C106.238 136.564 105.836 137.447 105.485 138.34C100.673 150.615 105.701 164.696 117.529 171.228H117.533Z" fill="#FFE6DE"/>
      <path d="M117.983 172.425C118.096 172.492 118.217 172.553 118.337 172.618C118.485 172.695 118.632 172.773 118.784 172.848C118.921 172.919 119.062 172.986 119.2 173.054C130.484 178.513 144.013 174.755 150.806 164.608C154.202 155.255 148.413 143.697 137.049 137.94C125.686 132.18 112.758 134.257 106.997 142.415C102.604 153.615 107.193 166.461 117.986 172.421L117.983 172.425Z" fill="black"/>
      <path d="M111.225 222.625C120.81 222.625 128.58 214.979 128.58 205.547C128.58 196.115 120.81 188.469 111.225 188.469C101.64 188.469 93.8701 196.115 93.8701 205.547C93.8701 214.979 101.64 222.625 111.225 222.625Z" fill="#FF530A"/>
      <path d="M134.609 188.992C139.349 193.278 141.535 199.806 140.384 206.026C140.112 207.498 138.418 208.59 136.94 208.208C135.379 207.805 134.434 206.388 134.723 204.819C134.778 204.524 134.826 204.227 134.867 203.929C134.888 203.78 134.901 203.631 134.918 203.483C134.894 203.716 134.894 203.848 134.905 203.537C134.925 202.897 134.929 202.265 134.877 201.629C134.853 201.331 134.822 201.03 134.781 200.736C134.771 200.672 134.702 200.178 134.757 200.543C134.815 200.908 134.729 200.421 134.716 200.354C134.585 199.728 134.417 199.112 134.21 198.507C134.114 198.223 134.008 197.942 133.894 197.661C133.839 197.523 133.777 197.384 133.719 197.245C133.564 196.877 133.76 197.164 133.764 197.323C133.764 197.107 133.451 196.714 133.344 196.525C133.196 196.264 133.042 196.004 132.877 195.75C132.53 195.209 132.141 194.705 131.739 194.204C131.478 193.876 132.096 194.57 131.794 194.275C131.687 194.171 131.588 194.056 131.481 193.947C131.244 193.704 130.997 193.47 130.742 193.24C129.628 192.232 129.412 190.325 130.515 189.215C131.601 188.123 133.413 187.913 134.606 188.992H134.609Z" fill="black"/>
      <path d="M136.637 201.725C143.993 203.599 151.682 203.92 159.206 202.963C173.223 201.187 187.168 195.779 199.085 188.368C205.795 184.197 211.944 178.876 216.526 172.534C216.918 168.472 216.952 164.43 216.609 160.445C211.284 174.486 197.573 183.839 183.937 189.87C169.583 196.218 153.442 200.125 137.864 196.154C134.244 195.231 133.028 200.805 136.633 201.725H136.637Z" fill="black"/>
      <path d="M69.1068 47.5466C69.1068 47.5466 50.6776 46.0097 39.6195 50.3311C28.5663 54.6584 71.486 58.671 69.1068 47.5466Z" fill="#FF530A"/>
      <path d="M-6.4509 102.877C-6.4509 102.877 -27.3792 75.092 20.0382 54.0514C20.0382 54.0514 36.2753 47.8924 56.2481 51.1213C76.2209 54.3503 59.0587 44.7779 75.2487 48.3101C91.4386 51.8423 124.558 81.2325 124.558 81.2325C124.558 81.2325 101.178 136.742 52.0604 147.919C52.0604 147.919 43.3006 139.477 42.5953 131.543C41.8899 123.608 39.3891 123.235 35.627 120.741C31.8649 118.247 34.2193 113.142 35.5293 110.602C36.8394 108.062 35.839 105.96 31.95 104.795C28.0619 103.619 29.5605 96.3025 29.5605 96.3025C29.5605 96.3025 27.2813 100.621 22.6703 94.4425C18.0642 88.2694 23.0807 88.749 18.0642 88.2694C13.0477 87.7897 21.9151 92.6381 9.36087 103.97C-3.19338 115.302 -6.4509 102.877 -6.4509 102.877Z" fill="#FF530A"/>
      <path opacity="0.53" d="M124.553 81.237C124.553 81.237 101.173 136.746 52.0552 147.923C52.0552 147.923 43.2954 139.482 42.5901 131.547C41.8848 123.613 39.3839 123.24 35.6219 120.745C31.8598 118.251 34.2141 113.146 35.5242 110.606C36.8342 108.066 35.8339 105.965 31.9448 104.799C28.0567 103.623 29.5553 96.307 29.5553 96.307C29.5553 96.307 27.2761 100.626 22.6652 94.447C18.059 88.2739 23.0755 88.7535 18.059 88.2739C13.0425 87.7942 21.91 92.6426 9.35572 103.975C-3.19852 115.307 -6.45607 102.881 -6.45607 102.881C-3.50278 104.692 0.579561 103.388 3.69909 101.889C6.26254 100.654 8.84078 99.1528 10.358 96.7452C12.071 94.0292 12.172 90.6175 13.3989 87.6567C14.6258 84.6958 18.091 82.0509 20.8781 83.6366C23.0752 84.887 23.5282 88.0568 25.6863 89.3786C28.2396 90.9419 31.6275 89.1047 34.5111 89.9059C36.6001 90.4918 38.076 92.3865 38.9425 94.3731C39.809 96.3597 40.2242 98.523 41.052 100.522C41.7154 102.108 42.6403 103.597 43.1208 105.246C43.8277 107.668 43.5675 110.388 44.8343 112.563C46.4596 115.363 50.3102 116.707 51.0166 119.863C51.5175 122.083 50.3074 124.756 51.7715 126.494C52.8789 127.812 54.8791 127.756 56.5888 127.598C57.1894 127.543 57.8015 127.478 58.324 127.174C60.4312 125.944 59.0185 122.714 59.4357 120.314C59.8863 117.789 62.5526 116.263 65.0748 115.797C67.6018 115.336 70.2444 115.572 72.6963 114.831C76.031 113.83 78.5846 111.184 80.829 108.519C87.1821 100.964 92.6791 92.1 101.483 87.6328C107.276 84.701 113.906 84.005 120.345 83.1834C121.604 83.025 124.482 82.4797 124.553 81.237Z" fill="#FF530A"/>
      <path d="M7.67444 66.7037C16.0171 61.7848 24.919 57.8042 34.1494 54.8739C37.5231 53.8022 41.104 52.863 44.5757 53.5703C47.9422 54.2568 50.8088 56.4132 54.0083 57.6683C55.9515 58.4333 58.0133 58.8557 60.0596 59.2712C62.2479 59.7164 64.7361 60.1098 66.5401 58.7969C67.3762 58.185 67.9507 57.2801 68.7916 56.674C70.7683 55.2382 73.528 55.8774 75.8274 56.7301C82.1913 59.1029 88.0961 62.6866 93.1303 67.2436C95.7754 69.6416 98.3135 72.5978 98.5627 76.1609C98.6017 76.7063 98.5749 77.2668 98.3715 77.7675C97.6344 79.5311 95.2377 79.6398 93.5208 80.4891C89.9748 82.2415 89.596 86.9888 88.5034 90.7883C86.1433 98.982 78.7713 104.686 71.2994 108.797C68.6217 110.274 65.1763 111.59 62.6801 109.823C61.0528 108.67 60.4536 106.581 59.8912 104.666C57.2625 95.6467 53.1202 86.8099 46.2698 80.3846C39.4193 73.9594 29.482 70.3172 20.3963 72.6875C16.9151 73.5934 13.3903 75.3478 9.88831 74.5089C8.72965 74.2318 7.64095 73.6719 6.45365 73.5262C2.70043 73.0708 0.795168 76.3958 -1.89525 78.1174C-4.74817 73.8548 4.94104 68.3139 7.67444 66.7037Z" fill="#FF530A"/>
      <path d="M36.0648 57.387C40.5341 56.0178 45.6767 54.7291 49.6263 57.225C52.7711 59.2203 54.6984 63.4051 58.3995 63.8448C61.5866 64.2193 64.2156 61.5694 67.0702 60.0942C69.8463 58.6543 73.1615 58.3063 76.1726 59.1197C82.0422 60.716 85.8948 66.1896 89.0487 71.398C90.1097 73.1458 91.1769 74.996 91.3027 77.0459C91.3326 77.4616 91.3064 77.9042 91.0795 78.2579C90.4184 79.2833 88.8696 78.7116 87.6598 78.6335C85.4478 78.4917 83.6106 80.4879 82.9213 82.5938C82.2367 84.7056 82.3518 86.9797 81.9974 89.1713C81.6489 91.3581 80.6327 93.6848 78.592 94.5569C76.6965 95.3677 74.4369 94.6583 72.852 93.3431C71.2671 92.0278 70.2133 90.2057 69.1544 88.4366C64.891 81.3149 59.8574 74.3395 52.7865 69.9953C46.8395 66.3485 39.6357 64.7964 32.7203 65.6689C29.219 66.1117 25.8107 67.1532 22.3177 67.6773C20.6201 67.9333 18.6981 68.0122 17.4003 66.8907C13.4847 63.5347 20.841 62.034 22.5815 61.4979C27.0875 60.1376 31.5732 58.7647 36.0648 57.387Z" fill="#FF530A"/>
      <g filter="url(#filter0_d_3025_2313)">
        <path d="M41.8254 134.549C39.1548 138.026 39.6009 143.512 42.8 146.51C38.0812 149.142 35.7957 155.434 37.7315 160.483C38.4707 162.398 39.8543 164.193 41.8186 164.794C43.777 165.399 46.2718 164.378 46.7519 162.386C46.0201 164.879 47.7568 167.635 50.1347 168.678C52.5125 169.72 55.3136 169.366 57.7413 168.429C62.0775 166.768 65.6809 163.295 67.5045 159.029C67.1544 161.569 69.4401 163.912 71.9589 164.378C74.4777 164.844 77.0545 163.862 79.2452 162.543C82.5856 160.53 85.6046 157.166 85.3811 153.279C92.5686 155.618 100.909 154.029 106.743 149.213C109.043 147.315 110.885 143.533 108.576 141.65C110.677 141.1 112.789 140.551 114.89 140.002C118.388 139.092 122.203 137.95 124.166 134.92C126.124 131.884 124.339 126.603 120.74 126.891C123.794 126.583 126.902 126.102 129.624 124.668C132.335 123.233 134.623 120.668 134.985 117.619C135.346 114.57 133.291 111.215 130.255 110.775C133.163 110.871 136.071 110.966 138.984 111.068C142.748 111.192 146.984 111.141 149.626 108.46C151.164 106.907 151.871 104.669 151.925 102.481C152.085 95.3102 144.427 89.0813 137.435 90.7025C140.279 88.8831 141.655 85.0462 140.61 81.836C139.566 78.6258 136.203 76.3308 132.826 76.5335C130.89 69.8113 122.913 65.5199 116.236 67.595C109.554 69.675 105.427 77.7319 107.651 84.3636C102.615 84.4184 97.7217 87.4791 95.4832 91.9896C93.2447 96.5 93.7514 102.249 96.7484 106.295C92.7274 107.294 88.6723 108.987 86.0638 112.207C83.4553 115.427 82.7745 120.473 85.4304 123.655C81.0937 121.674 75.9947 120.656 71.5804 122.459C67.1661 124.263 64.0613 129.645 66.0042 134.003C65.8897 131.611 64.0502 129.536 61.837 128.622C60.0001 127.867 57.7727 127.719 55.8318 128.107C53.623 128.55 53.0409 130.039 51.4699 130.709C48.1244 132.159 44.5445 131.012 41.8254 134.549Z" fill="#FFE6DE"/>
        <path d="M59.2731 132.383C55.9588 132.946 53.7025 136.913 54.9145 140.054C53.7103 138.571 51.9413 137.554 50.049 137.266C49.0821 137.115 48.064 137.162 47.1642 137.543C44.9347 138.483 43.9854 141.4 44.7915 143.681C45.5977 145.963 47.7893 147.55 50.1266 148.176C47.1401 148.732 44.2209 150.378 42.7797 153.056C41.3386 155.734 41.7484 159.468 44.1563 161.317C46.5642 163.167 50.7717 162.181 51.6398 159.271C51.6157 160.701 52.6875 161.999 54.0093 162.533C55.331 163.067 56.8444 162.944 58.2103 162.511C60.19 161.885 62.0115 160.558 62.9144 158.687C63.8172 156.816 63.6681 154.394 62.2882 152.841C62.9418 155.708 61.5107 159.121 58.7176 160.066C55.9303 161.006 52.3935 158.512 52.8855 155.61C51.5221 157.362 49.7325 159.309 47.53 159.012C45.6501 158.763 44.3218 156.727 44.5452 154.839C44.7676 152.962 46.2799 151.39 48.0683 150.746C49.8508 150.107 51.8499 150.287 53.649 150.878C52.1953 149.816 50.708 148.714 49.7601 147.186C48.8064 145.663 48.5009 143.586 49.4968 142.088C50.3739 140.767 52.1098 140.166 53.6795 140.407C55.2491 140.649 56.6365 141.618 57.6458 142.841C56.0589 140.705 56.769 137.26 59.0584 135.918C61.4331 134.526 64.1359 135.76 65.7451 137.662C66.1598 134.56 62.1733 131.889 59.2731 132.383Z" fill="#FFE6DE"/>
        <path d="M83.8395 148.708C87.1805 151.118 91.6161 151.784 95.6414 150.908C98.2104 150.344 100.707 149.135 102.401 147.12C104.096 145.105 104.849 142.211 103.926 139.753C106.931 139.563 109.972 139.226 112.765 138.115C115.558 137.004 118.122 135.034 119.396 132.308C120.67 129.583 120.423 126.057 118.353 123.87C116.283 121.682 112.353 121.392 110.303 123.593C111.865 120.209 109.76 115.76 106.241 114.534C102.717 113.312 98.5047 115.242 96.8188 118.572C98.5081 115.542 103.305 115.293 105.774 117.739C108.238 120.178 108.375 124.353 106.696 127.383C109.364 125.67 112.881 123.942 115.486 125.757C117.673 127.28 117.775 130.7 116.308 132.919C114.841 135.139 112.263 136.356 109.72 137.153C107.155 137.949 104.193 138.368 101.909 136.954C103.655 140.907 101.865 145.937 98.2403 148.293C94.6151 150.649 89.6246 150.403 85.9621 148.101C82.6843 146.039 80.9652 142.818 79.6708 139.305C76.6361 140.87 82.1989 147.526 83.8395 148.708Z" fill="#FFE6DE"/>
        <path d="M98.4668 97.4076C98.459 94.7416 99.7776 92.2239 101.511 90.1963C102.99 88.4662 104.881 86.9739 107.111 86.5328C109.341 86.0918 111.929 86.9507 113.004 88.9518C112.509 86.3251 112.863 83.4684 114.337 81.2337C115.811 78.999 118.495 77.5074 121.154 77.7992C123.818 78.0968 126.215 80.396 126.297 83.0691C126.856 81.267 129.421 81.0779 131.144 81.8486C133.385 82.8566 135.154 84.9992 135.403 87.4469C135.651 89.8946 134.191 92.4899 131.859 93.2697C134.624 92.3972 137.93 93.8287 139.187 96.4425C140.444 99.0564 139.5 102.527 137.098 104.147C134.697 105.768 131.119 105.324 129.169 103.175C131.209 103.595 133.448 103.793 135.272 102.777C137.096 101.761 138.105 99.117 136.779 97.51C134.919 95.2569 131.134 97.0884 128.397 96.049C130.234 94.7285 131.9 93.0108 132.573 90.8497C133.246 88.6886 132.674 86.0383 130.795 84.77C128.92 83.5076 125.828 84.3274 125.27 86.5209C126.482 83.8858 124.319 80.4828 121.449 80.0422C118.579 79.6015 115.69 81.7224 114.777 84.4826C113.859 87.2371 114.681 90.367 116.404 92.7036C114.366 91.3022 112.08 90.0861 109.606 89.9354C106.44 89.7399 103.233 91.4764 101.661 94.2327C99.928 97.2685 101.645 99.7868 101.027 102.656C99.3297 101.619 98.4694 99.3438 98.4668 97.4076Z" fill="#FFE6DE"/>
        <path d="M71.3242 143.364C71.5439 142.077 72.5578 140.838 73.8583 140.753C72.7674 140.048 72.4552 138.603 72.3375 137.31C72.0794 134.4 72.4455 131.132 74.6016 129.166C76.1519 127.759 78.4807 127.295 80.4601 127.962C82.4386 128.639 83.9962 130.408 84.4333 132.455C86.1913 129.604 90.8561 129.219 93.0598 131.746C95.2635 134.273 94.2476 138.842 91.18 140.19C92.6026 138.996 93.101 136.812 92.3239 135.124C91.5469 133.435 89.5547 132.398 87.731 132.738C85.9074 133.079 84.4157 134.77 84.3239 136.627C83.9475 134.843 83.0875 133.125 81.6981 131.952C80.3088 130.779 78.3579 130.206 76.607 130.682C75.9683 130.857 75.354 131.169 74.8945 131.656C74.4292 132.147 74.143 132.785 73.9476 133.426C73.018 136.474 74.0405 140.025 76.4464 142.122C75.5895 141.38 73.1769 145.3 73.118 145.917C72.9879 147.277 74.0541 148.521 74.2135 149.77C72.2347 148.76 70.9555 145.538 71.3242 143.364Z" fill="#FFE6DE"/>
      </g>
      <path d="M-9.96133 114.234C-8.99223 116.448 -6.29113 117.741 -3.96406 117.102C-4.91359 119.637 -2.62976 122.238 -0.253217 123.539C1.61898 124.563 3.95147 125.222 5.84066 124.238C7.72985 123.253 8.2796 120.039 6.3713 119.091C6.87568 120.417 8.66722 120.854 10.0006 120.357C11.334 119.86 12.3061 118.721 13.2112 117.622C14.5208 116.023 15.8873 114.264 15.9862 112.201C16.0851 110.137 14.2166 107.889 12.2277 108.453C14.851 107.891 17.3977 106.582 18.9971 104.418C20.5891 102.262 21.0663 99.1858 19.762 96.8353C18.4577 94.4849 15.2297 93.2709 12.8714 94.5603C15.5013 92.2079 15.4431 87.4216 12.6114 85.3232C9.77961 83.2247 4.97364 84.8162 4.17183 88.2467C3.7731 84.4721 0.320763 81.2619 -3.48032 81.1326C-7.50799 81.0006 -11.0839 83.955 -13.1306 87.435C-14.8787 90.4027 -15.8207 93.9872 -14.9782 97.3293C-14.1359 100.664 -11.2186 103.576 -7.77935 103.736C-9.64642 103.648 -8.3817 107.66 -8.49941 108.154C-9.031 110.461 -11.2247 111.331 -9.96133 114.234Z" fill="#FFE6DE"/>
      <path d="M-6.85099 113.894C-5.88188 116.108 -3.18079 117.402 -0.853711 116.762C-1.80324 119.297 0.480593 121.899 2.85714 123.199C4.72933 124.223 7.06181 124.883 8.951 123.898C10.8402 122.913 11.3899 119.699 9.48165 118.751C9.98602 120.077 11.7776 120.514 13.111 120.017C14.4443 119.52 15.4165 118.382 16.3215 117.282C17.6311 115.683 18.9976 113.924 19.0965 111.861C19.1954 109.797 17.327 107.55 15.3381 108.114C17.9613 107.551 20.508 106.242 22.1074 104.078C23.7068 101.914 24.1767 98.846 22.8724 96.4955C21.5681 94.145 18.3401 92.9311 15.9817 94.2205C18.6116 91.868 18.5535 87.0818 15.7217 84.9833C12.89 82.8848 8.08398 84.4763 7.28217 87.9068C6.88345 84.1322 3.43111 80.9221 -0.36997 80.7928C-4.39764 80.6608 -7.97351 83.6151 -10.0202 87.0952C-11.7684 90.0628 -12.7103 93.6474 -11.8679 96.9895C-11.0256 100.324 -8.10828 103.236 -4.669 103.396C-6.53607 103.308 -5.27136 107.321 -5.38907 107.814C-5.9282 110.121 -8.12191 110.992 -6.85099 113.894Z" fill="#FFE6DE"/>
      <path d="M-1.21435 90.1618C0.224494 90.0023 1.67688 90.4693 2.92364 91.2051C4.56055 92.174 5.96028 93.6993 6.32529 95.5641C6.75125 92.912 8.52692 90.5176 10.9482 89.3477C11.8376 88.9207 12.9268 88.6555 13.7831 89.143C14.4558 89.5211 14.8347 90.2831 14.9944 91.0348C15.4551 93.1317 14.4409 95.4718 12.5996 96.5763C14.3894 96.2356 16.2813 96.4212 17.9727 97.1172C18.9707 97.526 19.9592 98.1918 20.276 99.2195C20.6009 100.277 20.105 101.443 19.3214 102.223C18.5377 102.995 17.498 103.455 16.4882 103.9C15.8076 104.202 15.1043 104.504 14.3574 104.535C13.6105 104.566 12.8109 104.259 12.4571 103.61C13.904 103.473 15.448 103.288 16.5604 102.351C17.6729 101.413 18.0152 99.4345 16.8412 98.5688C16.2579 98.1359 15.4794 98.0999 14.7549 98.1231C13.2309 98.1862 11.712 98.4833 10.2733 98.9977C11.2655 97.7379 12.1117 96.3603 12.79 94.9034C13.0496 94.339 13.2934 93.7372 13.2572 93.1112C13.221 92.4852 12.8296 91.8443 12.2227 91.7139C11.5626 91.5696 10.9308 92.0363 10.4656 92.5221C8.38216 94.6513 7.25866 97.5224 6.19358 100.302C5.80605 98.7922 5.41032 97.2527 4.61848 95.9031C2.26608 91.8762 0.154887 94.1193 -3.15587 93.6169C-6.35292 93.1348 -3.55713 90.4239 -1.21435 90.1618Z" fill="#FFE6DE"/>
      <defs>
        <filter id="filter0_d_3025_2313" x="33.2209" y="65.1576" width="122.528" height="109.887" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dy="1.91081"/>
          <feGaussianBlur stdDeviation="1.91081"/>
          <feComposite in2="hardAlpha" operator="out"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3025_2313"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3025_2313" result="shape"/>
        </filter>
      </defs>
    </g>
  )
}

export const HeadBlock4 = () => {
  return (
    <g>
      <path d="M199.254 143.655C198.794 142.593 198.312 141.478 197.694 140.508C198.234 141.36 197.616 140.398 197.516 140.234C197.388 140.024 197.253 139.807 197.118 139.605C196.8 139.114 196.475 138.637 196.133 138.169C195.454 137.231 194.721 136.338 193.947 135.49C193.782 135.309 193.612 135.13 193.441 134.95C193.182 134.681 193.216 134.713 193.536 135.046C193.421 134.931 193.305 134.816 193.19 134.701C192.776 134.306 192.357 133.924 191.926 133.551C190.606 132.405 190.074 130.206 191.185 128.693C192.214 127.282 194.27 126.716 195.678 127.945C199.706 131.44 202.942 135.845 205.134 140.896C205.85 142.549 205.107 144.809 203.507 145.466C201.857 146.148 200.022 145.434 199.254 143.662L199.254 143.655Z" fill="black"/>
      <path d="M201.289 179.426C201.289 179.426 230.671 149.274 227.962 126.246L234.308 138.125L243.035 132.264C243.035 132.264 226.605 176.341 201.289 179.426Z" fill="#FF530A"/>
      <path d="M216.702 160.71C215.935 151.036 212.935 141.727 207.486 133.396C186.168 100.786 135.51 95.4821 94.3378 121.548C61.3776 142.414 50.0518 176.564 50.0002 206.773C49.8902 270.759 96.3795 305 151.988 305V249.446C158.632 246.906 165.194 243.642 171.529 239.63C197.859 222.961 213.938 197.501 216.527 172.782C216.953 168.723 217.018 164.688 216.698 160.71H216.702Z" fill="#EFB825"/>
      <path d="M117.533 171.228C117.66 171.302 117.787 171.367 117.918 171.438C118.079 171.522 118.244 171.61 118.406 171.691C118.557 171.769 118.712 171.844 118.859 171.918C131.227 177.902 146.055 173.782 153.5 162.664C154.033 161.869 154.528 161.037 154.982 160.167C161.85 147.043 156.608 130.926 143.274 124.171C129.938 117.413 113.559 122.571 106.691 135.695C106.238 136.564 105.836 137.447 105.485 138.34C100.673 150.615 105.701 164.696 117.529 171.228H117.533Z" fill="#FFE6DE"/>
      <path d="M117.983 172.425C118.096 172.492 118.217 172.553 118.337 172.618C118.485 172.695 118.632 172.773 118.784 172.848C118.921 172.919 119.062 172.986 119.2 173.054C130.484 178.513 144.013 174.755 150.806 164.608C154.202 155.255 148.413 143.697 137.049 137.94C125.686 132.18 112.758 134.257 106.997 142.415C102.604 153.615 107.193 166.461 117.986 172.421L117.983 172.425Z" fill="black"/>
      <path d="M111.225 222.625C120.81 222.625 128.58 214.979 128.58 205.547C128.58 196.115 120.81 188.469 111.225 188.469C101.64 188.469 93.8701 196.115 93.8701 205.547C93.8701 214.979 101.64 222.625 111.225 222.625Z" fill="#FF530A"/>
      <path d="M134.609 188.992C139.349 193.278 141.535 199.806 140.384 206.026C140.112 207.498 138.418 208.59 136.94 208.208C135.379 207.805 134.434 206.388 134.723 204.819C134.778 204.524 134.826 204.227 134.867 203.929C134.888 203.78 134.901 203.631 134.918 203.483C134.894 203.716 134.894 203.848 134.905 203.537C134.925 202.897 134.929 202.265 134.877 201.629C134.853 201.331 134.822 201.03 134.781 200.736C134.771 200.672 134.702 200.178 134.757 200.543C134.815 200.908 134.729 200.421 134.716 200.354C134.585 199.728 134.417 199.112 134.21 198.507C134.114 198.223 134.008 197.942 133.894 197.661C133.839 197.523 133.777 197.384 133.719 197.245C133.564 196.877 133.76 197.164 133.764 197.323C133.764 197.107 133.451 196.714 133.344 196.525C133.196 196.264 133.042 196.004 132.877 195.75C132.53 195.209 132.141 194.705 131.739 194.204C131.478 193.876 132.096 194.57 131.794 194.275C131.687 194.171 131.588 194.056 131.481 193.947C131.244 193.704 130.997 193.47 130.742 193.24C129.628 192.232 129.412 190.325 130.515 189.215C131.601 188.123 133.413 187.913 134.606 188.992H134.609Z" fill="black"/>
      <path d="M136.637 201.725C143.993 203.599 151.682 203.92 159.206 202.963C173.223 201.187 187.168 195.779 199.085 188.368C205.795 184.197 211.944 178.876 216.526 172.534C216.918 168.472 216.952 164.43 216.609 160.445C211.284 174.486 197.573 183.839 183.937 189.87C169.583 196.218 153.442 200.125 137.864 196.154C134.244 195.231 133.028 200.805 136.633 201.725H136.637Z" fill="black"/>
      <path d="M69.1063 47.5466C69.1063 47.5466 50.6771 46.0097 39.619 50.3311C28.5658 54.6584 71.4855 58.671 69.1063 47.5466Z" fill="#FF530A"/>
      <path d="M-6.4509 102.877C-6.4509 102.877 -27.3792 75.092 20.0382 54.0514C20.0382 54.0514 36.2753 47.8924 56.2481 51.1213C76.2209 54.3503 59.0587 44.7779 75.2487 48.3101C91.4386 51.8423 124.558 81.2325 124.558 81.2325C124.558 81.2325 101.178 136.742 52.0604 147.919C52.0604 147.919 43.3006 139.477 42.5953 131.543C41.8899 123.608 39.3891 123.235 35.627 120.741C31.8649 118.247 34.2193 113.142 35.5293 110.602C36.8394 108.062 35.839 105.96 31.95 104.795C28.0619 103.619 29.5605 96.3025 29.5605 96.3025C29.5605 96.3025 27.2813 100.621 22.6703 94.4425C18.0642 88.2694 23.0807 88.749 18.0642 88.2694C13.0477 87.7897 21.9151 92.6381 9.36087 103.97C-3.19338 115.302 -6.4509 102.877 -6.4509 102.877Z" fill="#FF530A"/>
      <path opacity="0.53" d="M124.553 81.237C124.553 81.237 101.173 136.746 52.0552 147.923C52.0552 147.923 43.2954 139.482 42.5901 131.547C41.8848 123.613 39.3839 123.24 35.6219 120.745C31.8598 118.251 34.2141 113.146 35.5242 110.606C36.8342 108.066 35.8339 105.965 31.9448 104.799C28.0567 103.623 29.5553 96.307 29.5553 96.307C29.5553 96.307 27.2761 100.626 22.6652 94.447C18.059 88.2739 23.0755 88.7535 18.059 88.2739C13.0425 87.7942 21.91 92.6426 9.35572 103.975C-3.19852 115.307 -6.45607 102.881 -6.45607 102.881C-3.50278 104.692 0.579561 103.388 3.69909 101.889C6.26254 100.654 8.84078 99.1528 10.358 96.7452C12.071 94.0292 12.172 90.6175 13.3989 87.6567C14.6258 84.6958 18.091 82.0509 20.8781 83.6366C23.0752 84.887 23.5282 88.0568 25.6863 89.3786C28.2396 90.9419 31.6275 89.1047 34.5111 89.9059C36.6001 90.4918 38.076 92.3865 38.9425 94.3731C39.809 96.3597 40.2242 98.523 41.052 100.522C41.7154 102.108 42.6403 103.597 43.1208 105.246C43.8277 107.668 43.5675 110.388 44.8343 112.563C46.4596 115.363 50.3102 116.707 51.0166 119.863C51.5175 122.083 50.3074 124.756 51.7715 126.494C52.8789 127.812 54.8791 127.756 56.5888 127.598C57.1894 127.543 57.8015 127.478 58.324 127.174C60.4312 125.944 59.0185 122.714 59.4357 120.314C59.8863 117.789 62.5526 116.263 65.0748 115.797C67.6018 115.336 70.2444 115.572 72.6963 114.831C76.031 113.83 78.5846 111.184 80.829 108.519C87.1821 100.964 92.6791 92.1 101.483 87.6328C107.276 84.701 113.906 84.005 120.345 83.1834C121.604 83.025 124.482 82.4797 124.553 81.237Z" fill="#FF530A"/>
      <path d="M7.67444 66.7037C16.0171 61.7848 24.919 57.8042 34.1494 54.8739C37.5231 53.8022 41.104 52.863 44.5757 53.5703C47.9422 54.2568 50.8088 56.4132 54.0083 57.6683C55.9515 58.4333 58.0133 58.8557 60.0596 59.2712C62.2479 59.7164 64.7361 60.1098 66.5401 58.7969C67.3762 58.185 67.9507 57.2801 68.7916 56.674C70.7683 55.2382 73.528 55.8774 75.8274 56.7301C82.1913 59.1029 88.0961 62.6866 93.1303 67.2436C95.7754 69.6416 98.3135 72.5978 98.5627 76.1609C98.6017 76.7063 98.5749 77.2668 98.3715 77.7675C97.6344 79.5311 95.2377 79.6398 93.5208 80.4891C89.9748 82.2415 89.596 86.9888 88.5034 90.7883C86.1433 98.982 78.7713 104.686 71.2994 108.797C68.6217 110.274 65.1763 111.59 62.6801 109.823C61.0528 108.67 60.4536 106.581 59.8912 104.666C57.2625 95.6467 53.1202 86.8099 46.2698 80.3846C39.4193 73.9594 29.482 70.3172 20.3963 72.6875C16.9151 73.5934 13.3903 75.3478 9.88831 74.5089C8.72965 74.2318 7.64095 73.6719 6.45365 73.5262C2.70043 73.0708 0.795168 76.3958 -1.89525 78.1174C-4.74817 73.8548 4.94104 68.3139 7.67444 66.7037Z" fill="#FF530A"/>
      <path d="M36.0648 57.387C40.5341 56.0178 45.6767 54.7291 49.6263 57.225C52.7711 59.2203 54.6984 63.4051 58.3995 63.8448C61.5866 64.2193 64.2156 61.5694 67.0702 60.0942C69.8463 58.6543 73.1615 58.3063 76.1726 59.1197C82.0422 60.716 85.8948 66.1896 89.0487 71.398C90.1097 73.1458 91.1769 74.996 91.3027 77.0459C91.3326 77.4616 91.3064 77.9042 91.0795 78.2579C90.4184 79.2833 88.8696 78.7116 87.6598 78.6335C85.4478 78.4917 83.6106 80.4879 82.9213 82.5938C82.2367 84.7056 82.3518 86.9797 81.9974 89.1713C81.6489 91.3581 80.6327 93.6848 78.592 94.5569C76.6965 95.3677 74.4369 94.6583 72.852 93.3431C71.2671 92.0278 70.2133 90.2057 69.1544 88.4366C64.891 81.3149 59.8574 74.3395 52.7865 69.9953C46.8395 66.3485 39.6357 64.7964 32.7203 65.6689C29.219 66.1117 25.8107 67.1532 22.3177 67.6773C20.6201 67.9333 18.6981 68.0122 17.4003 66.8907C13.4847 63.5347 20.841 62.034 22.5815 61.4979C27.0875 60.1376 31.5732 58.7647 36.0648 57.387Z" fill="#FF530A"/>
      <g filter="url(#filter0_d_3025_2461)">
        <path d="M41.8254 134.549C39.1548 138.026 39.6009 143.512 42.8 146.51C38.0812 149.142 35.7957 155.434 37.7315 160.483C38.4707 162.398 39.8543 164.193 41.8186 164.794C43.777 165.399 46.2718 164.378 46.7519 162.386C46.0201 164.879 47.7568 167.635 50.1347 168.678C52.5125 169.72 55.3136 169.366 57.7413 168.429C62.0775 166.768 65.6809 163.295 67.5045 159.029C67.1544 161.569 69.4401 163.912 71.9589 164.378C74.4777 164.844 77.0545 163.862 79.2452 162.543C82.5856 160.53 85.6046 157.166 85.3811 153.279C92.5686 155.618 100.909 154.029 106.743 149.213C109.043 147.315 110.885 143.533 108.576 141.65C110.677 141.1 112.789 140.551 114.89 140.002C118.388 139.092 122.203 137.95 124.166 134.92C126.124 131.884 124.339 126.603 120.74 126.891C123.794 126.583 126.902 126.102 129.624 124.668C132.335 123.233 134.623 120.668 134.985 117.619C135.346 114.57 133.291 111.215 130.255 110.775C133.163 110.871 136.071 110.966 138.984 111.068C142.748 111.192 146.984 111.141 149.626 108.46C151.164 106.907 151.871 104.669 151.925 102.481C152.085 95.3102 144.427 89.0813 137.435 90.7025C140.279 88.8831 141.655 85.0462 140.61 81.836C139.566 78.6258 136.203 76.3308 132.826 76.5335C130.89 69.8113 122.913 65.5199 116.236 67.595C109.554 69.675 105.427 77.7319 107.651 84.3636C102.615 84.4184 97.7217 87.4791 95.4832 91.9896C93.2447 96.5 93.7514 102.249 96.7484 106.295C92.7274 107.294 88.6723 108.987 86.0638 112.207C83.4553 115.427 82.7745 120.473 85.4304 123.655C81.0937 121.674 75.9947 120.656 71.5804 122.459C67.1661 124.263 64.0613 129.645 66.0042 134.003C65.8897 131.611 64.0502 129.536 61.837 128.622C60.0001 127.867 57.7727 127.719 55.8318 128.107C53.623 128.55 53.0409 130.039 51.4699 130.709C48.1244 132.159 44.5445 131.012 41.8254 134.549Z" fill="#FFE6DE"/>
        <path d="M59.2731 132.383C55.9588 132.946 53.7025 136.913 54.9145 140.054C53.7103 138.571 51.9413 137.554 50.049 137.266C49.0821 137.115 48.064 137.162 47.1642 137.543C44.9347 138.483 43.9854 141.4 44.7915 143.681C45.5977 145.963 47.7893 147.55 50.1266 148.176C47.1401 148.732 44.2209 150.378 42.7797 153.056C41.3386 155.734 41.7484 159.468 44.1563 161.317C46.5642 163.167 50.7717 162.181 51.6398 159.271C51.6157 160.701 52.6875 161.999 54.0093 162.533C55.331 163.067 56.8444 162.944 58.2103 162.511C60.19 161.885 62.0115 160.558 62.9144 158.687C63.8172 156.816 63.6681 154.394 62.2882 152.841C62.9418 155.708 61.5107 159.121 58.7176 160.066C55.9303 161.006 52.3935 158.512 52.8855 155.61C51.5221 157.362 49.7325 159.309 47.53 159.012C45.6501 158.763 44.3218 156.727 44.5452 154.839C44.7676 152.962 46.2799 151.39 48.0683 150.746C49.8508 150.107 51.8499 150.287 53.649 150.878C52.1953 149.816 50.708 148.714 49.7601 147.186C48.8064 145.663 48.5009 143.586 49.4968 142.088C50.3739 140.767 52.1098 140.166 53.6795 140.407C55.2491 140.649 56.6365 141.618 57.6458 142.841C56.0589 140.705 56.769 137.26 59.0584 135.918C61.4331 134.526 64.1359 135.76 65.7451 137.662C66.1598 134.56 62.1733 131.889 59.2731 132.383Z" fill="#FFE6DE"/>
        <path d="M83.8395 148.708C87.1805 151.118 91.6161 151.784 95.6414 150.908C98.2104 150.344 100.707 149.135 102.401 147.12C104.096 145.105 104.849 142.211 103.926 139.753C106.931 139.563 109.972 139.226 112.765 138.115C115.558 137.004 118.122 135.034 119.396 132.308C120.67 129.583 120.423 126.057 118.353 123.87C116.283 121.682 112.353 121.392 110.303 123.593C111.865 120.209 109.76 115.76 106.241 114.534C102.717 113.312 98.5047 115.242 96.8188 118.572C98.5081 115.542 103.305 115.293 105.774 117.739C108.238 120.178 108.375 124.353 106.696 127.383C109.364 125.67 112.881 123.942 115.486 125.757C117.673 127.28 117.775 130.7 116.308 132.919C114.841 135.139 112.263 136.356 109.72 137.153C107.155 137.949 104.193 138.368 101.909 136.954C103.655 140.907 101.865 145.937 98.2403 148.293C94.6151 150.649 89.6246 150.403 85.9621 148.101C82.6843 146.039 80.9652 142.818 79.6708 139.305C76.6361 140.87 82.1989 147.526 83.8395 148.708Z" fill="#FFE6DE"/>
        <path d="M98.4668 97.4076C98.459 94.7416 99.7776 92.2239 101.511 90.1963C102.99 88.4662 104.881 86.9739 107.111 86.5328C109.341 86.0918 111.929 86.9507 113.004 88.9518C112.509 86.3251 112.863 83.4684 114.337 81.2337C115.811 78.999 118.495 77.5074 121.154 77.7992C123.818 78.0968 126.215 80.396 126.297 83.0691C126.856 81.267 129.421 81.0779 131.144 81.8486C133.385 82.8566 135.154 84.9992 135.403 87.4469C135.651 89.8946 134.191 92.4899 131.859 93.2697C134.624 92.3972 137.93 93.8287 139.187 96.4425C140.444 99.0564 139.5 102.527 137.098 104.147C134.697 105.768 131.119 105.324 129.169 103.175C131.209 103.595 133.448 103.793 135.272 102.777C137.096 101.761 138.105 99.117 136.779 97.51C134.919 95.2569 131.134 97.0884 128.397 96.049C130.234 94.7285 131.9 93.0108 132.573 90.8497C133.246 88.6886 132.674 86.0383 130.795 84.77C128.92 83.5076 125.828 84.3274 125.27 86.5209C126.482 83.8858 124.319 80.4828 121.449 80.0422C118.579 79.6015 115.69 81.7224 114.777 84.4826C113.859 87.2371 114.681 90.367 116.404 92.7036C114.366 91.3022 112.08 90.0861 109.606 89.9354C106.44 89.7399 103.233 91.4764 101.661 94.2327C99.928 97.2685 101.645 99.7868 101.027 102.656C99.3297 101.619 98.4694 99.3438 98.4668 97.4076Z" fill="#FFE6DE"/>
        <path d="M71.3242 143.364C71.5439 142.077 72.5578 140.838 73.8583 140.753C72.7674 140.048 72.4552 138.603 72.3375 137.31C72.0794 134.4 72.4455 131.132 74.6016 129.166C76.1519 127.759 78.4807 127.295 80.4601 127.962C82.4386 128.639 83.9962 130.408 84.4333 132.455C86.1913 129.604 90.8561 129.219 93.0598 131.746C95.2635 134.273 94.2476 138.842 91.18 140.19C92.6026 138.996 93.101 136.812 92.3239 135.124C91.5469 133.435 89.5547 132.398 87.731 132.738C85.9074 133.079 84.4157 134.77 84.3239 136.627C83.9475 134.843 83.0875 133.125 81.6981 131.952C80.3088 130.779 78.3579 130.206 76.607 130.682C75.9683 130.857 75.354 131.169 74.8945 131.656C74.4292 132.147 74.143 132.785 73.9476 133.426C73.018 136.474 74.0405 140.025 76.4464 142.122C75.5895 141.38 73.1769 145.3 73.118 145.917C72.9879 147.277 74.0541 148.521 74.2135 149.77C72.2347 148.76 70.9555 145.538 71.3242 143.364Z" fill="#FFE6DE"/>
      </g>
      <path d="M-13.5978 106.729C-13.7096 109.144 -11.8606 111.5 -9.49106 111.957C-11.4651 113.81 -10.5695 117.153 -9.01457 119.371C-7.78962 121.119 -5.99038 122.743 -3.86045 122.697C-1.73052 122.651 0.185602 120.013 -1.10552 118.318C-1.24041 119.73 0.172318 120.915 1.58791 121.06C3.00351 121.205 4.37939 120.615 5.67773 120.03C7.56001 119.176 9.56414 118.204 10.5667 116.398C11.5693 114.592 10.8895 111.749 8.85653 111.374C11.4577 112.031 14.3205 111.986 16.7129 110.754C19.0953 109.526 20.8855 106.979 20.757 104.294C20.6286 101.609 18.272 99.0909 15.5866 99.2025C18.9863 98.258 21.0539 93.941 19.4443 90.8055C17.8348 87.6699 12.821 86.9683 10.5828 89.689C11.897 86.1282 10.2234 81.721 6.87267 79.9217C3.31996 78.0196 -1.19449 79.0847 -4.57076 81.2985C-7.45244 83.185 -9.88449 85.9817 -10.6093 89.3513C-11.3309 92.714 -10.005 96.617 -6.99217 98.2834C-8.62717 97.3776 -9.27033 101.535 -9.59437 101.926C-11.0927 103.759 -13.4451 103.568 -13.5978 106.729Z" fill="#FFE6DE"/>
      <path d="M-10.6589 107.802C-10.7706 110.216 -8.92165 112.572 -6.55211 113.029C-8.52617 114.882 -7.6305 118.225 -6.07561 120.444C-4.85066 122.191 -3.05142 123.815 -0.921492 123.769C1.20844 123.723 3.12457 121.085 1.83345 119.39C1.69856 120.802 3.11129 121.987 4.52688 122.132C5.94248 122.277 7.31836 121.687 8.6167 121.102C10.499 120.248 12.5031 119.277 13.5057 117.47C14.5083 115.664 13.8285 112.821 11.7955 112.446C14.3966 113.103 17.2595 113.058 19.6519 111.826C22.0443 110.594 23.8244 108.051 23.696 105.366C23.5675 102.681 21.211 100.163 18.5255 100.275C21.9252 99.3303 23.9928 95.0133 22.3833 91.8777C20.7737 88.7422 15.76 88.0406 13.5218 90.7612C14.836 87.2004 13.1623 82.7933 9.81162 80.9939C6.25891 79.0919 1.74447 80.157 -1.63179 82.3707C-4.51348 84.2573 -6.94554 87.054 -7.67034 90.4235C-8.39195 93.7863 -7.06606 97.6893 -4.05322 99.3557C-5.68821 98.4499 -6.33137 102.608 -6.65541 102.998C-8.16061 104.828 -10.513 104.637 -10.6589 107.802Z" fill="#FFE6DE"/>
      <path d="M4.90565 89.0207C6.26632 89.5149 7.36168 90.5768 8.15366 91.7886C9.19217 93.3823 9.77163 95.3698 9.273 97.2034C10.8295 95.0142 13.4819 93.6539 16.171 93.6773C17.1575 93.6883 18.2515 93.9329 18.8033 94.7492C19.239 95.3861 19.2412 96.2372 19.0515 96.9819C18.5359 99.0659 16.5902 100.715 14.4502 100.89C16.2058 101.377 17.8198 102.381 19.0281 103.754C19.7418 104.563 20.3332 105.598 20.1621 106.659C19.9849 107.751 19.024 108.577 17.9761 108.929C16.9315 109.274 15.7954 109.227 14.6931 109.178C13.9493 109.147 13.1849 109.107 12.5014 108.804C11.8179 108.501 11.2372 107.871 11.2075 107.133C12.5655 107.65 14.0315 108.169 15.4441 107.821C16.8568 107.473 18.0399 105.851 17.3707 104.555C17.0395 103.908 16.3574 103.531 15.6976 103.231C14.3032 102.613 12.8098 102.206 11.2921 102.03C12.7396 101.34 14.1083 100.48 15.3618 99.4741C15.8445 99.083 16.3296 98.6514 16.5744 98.0741C16.8192 97.4969 16.7521 96.7488 16.2657 96.3632C15.7378 95.9415 14.9646 96.0801 14.3324 96.3096C11.5214 97.2959 9.24259 99.3725 7.0568 101.393C7.37784 99.8677 7.70482 98.3122 7.5926 96.7514C7.26687 92.0992 4.38062 93.1753 1.63473 91.2586C-1.01816 89.4104 2.68909 88.2181 4.90565 89.0207Z" fill="#FFE6DE"/>
      <defs>
        <filter id="filter0_d_3025_2461" x="33.2209" y="65.1576" width="122.528" height="109.887" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dy="1.91081"/>
          <feGaussianBlur stdDeviation="1.91081"/>
          <feComposite in2="hardAlpha" operator="out"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3025_2461"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3025_2461" result="shape"/>
        </filter>
      </defs>
    </g>
  )
}

export const HeadBlock5 = () => {
  return (
    <g>
      <path d="M199.254 143.655C198.794 142.593 198.312 141.478 197.694 140.508C198.234 141.36 197.616 140.398 197.516 140.234C197.388 140.024 197.253 139.807 197.118 139.605C196.8 139.114 196.475 138.637 196.133 138.169C195.454 137.231 194.721 136.338 193.947 135.49C193.782 135.309 193.612 135.13 193.441 134.95C193.182 134.681 193.216 134.713 193.536 135.046C193.421 134.931 193.305 134.816 193.19 134.701C192.776 134.306 192.357 133.924 191.926 133.551C190.606 132.405 190.074 130.206 191.185 128.693C192.214 127.282 194.27 126.716 195.678 127.945C199.706 131.44 202.942 135.845 205.134 140.896C205.85 142.549 205.107 144.809 203.507 145.466C201.857 146.148 200.022 145.434 199.254 143.662L199.254 143.655Z" fill="black"/>
      <path d="M201.289 179.426C201.289 179.426 230.671 149.274 227.962 126.246L234.308 138.125L243.035 132.264C243.035 132.264 226.605 176.341 201.289 179.426Z" fill="#FF530A"/>
      <path d="M216.702 160.71C215.935 151.036 212.935 141.727 207.486 133.396C186.168 100.786 135.51 95.4821 94.3378 121.548C61.3776 142.414 50.0518 176.564 50.0002 206.773C49.8902 270.759 96.3795 305 151.988 305V249.446C158.632 246.906 165.194 243.642 171.529 239.63C197.859 222.961 213.938 197.501 216.527 172.782C216.953 168.723 217.018 164.688 216.698 160.71H216.702Z" fill="#EFB825"/>
      <path d="M117.533 171.228C117.66 171.302 117.787 171.367 117.918 171.438C118.079 171.522 118.244 171.61 118.406 171.691C118.557 171.769 118.712 171.844 118.859 171.918C131.227 177.902 146.055 173.782 153.5 162.664C154.033 161.869 154.528 161.037 154.982 160.167C161.85 147.043 156.608 130.926 143.274 124.171C129.938 117.413 113.559 122.571 106.691 135.695C106.238 136.564 105.836 137.447 105.485 138.34C100.673 150.615 105.701 164.696 117.529 171.228H117.533Z" fill="#FFE6DE"/>
      <path d="M117.983 172.425C118.096 172.492 118.217 172.553 118.337 172.618C118.485 172.695 118.632 172.773 118.784 172.848C118.921 172.919 119.062 172.986 119.2 173.054C130.484 178.513 144.013 174.755 150.806 164.608C154.202 155.255 148.413 143.697 137.049 137.94C125.686 132.18 112.758 134.257 106.997 142.415C102.604 153.615 107.193 166.461 117.986 172.421L117.983 172.425Z" fill="black"/>
      <path d="M111.225 222.625C120.81 222.625 128.58 214.979 128.58 205.547C128.58 196.115 120.81 188.469 111.225 188.469C101.64 188.469 93.8701 196.115 93.8701 205.547C93.8701 214.979 101.64 222.625 111.225 222.625Z" fill="#FF530A"/>
      <path d="M134.609 188.992C139.349 193.278 141.535 199.806 140.384 206.026C140.112 207.498 138.418 208.59 136.94 208.208C135.379 207.805 134.434 206.388 134.723 204.819C134.778 204.524 134.826 204.227 134.867 203.929C134.888 203.78 134.901 203.631 134.918 203.483C134.894 203.716 134.894 203.848 134.905 203.537C134.925 202.897 134.929 202.265 134.877 201.629C134.853 201.331 134.822 201.03 134.781 200.736C134.771 200.672 134.702 200.178 134.757 200.543C134.815 200.908 134.729 200.421 134.716 200.354C134.585 199.728 134.417 199.112 134.21 198.507C134.114 198.223 134.008 197.942 133.894 197.661C133.839 197.523 133.777 197.384 133.719 197.245C133.564 196.877 133.76 197.164 133.764 197.323C133.764 197.107 133.451 196.714 133.344 196.525C133.196 196.264 133.042 196.004 132.877 195.75C132.53 195.209 132.141 194.705 131.739 194.204C131.478 193.876 132.096 194.57 131.794 194.275C131.687 194.171 131.588 194.056 131.481 193.947C131.244 193.704 130.997 193.47 130.742 193.24C129.628 192.232 129.412 190.325 130.515 189.215C131.601 188.123 133.413 187.913 134.606 188.992H134.609Z" fill="black"/>
      <path d="M136.637 201.725C143.993 203.599 151.682 203.92 159.206 202.963C173.223 201.187 187.168 195.779 199.085 188.368C205.795 184.197 211.944 178.876 216.526 172.534C216.918 168.472 216.952 164.43 216.609 160.445C211.284 174.486 197.573 183.839 183.937 189.87C169.583 196.218 153.442 200.125 137.864 196.154C134.244 195.231 133.028 200.805 136.633 201.725H136.637Z" fill="black"/>
      <path d="M69.1068 47.5466C69.1068 47.5466 50.6776 46.0097 39.6195 50.3311C28.5663 54.6584 71.486 58.671 69.1068 47.5466Z" fill="#FF530A"/>
      <path d="M-6.4509 102.879C-6.4509 102.879 -27.3792 75.0939 20.0382 54.0533C20.0382 54.0533 36.2753 47.8944 56.2481 51.1233C76.2209 54.3522 59.0587 44.7799 75.2487 48.3121C91.4386 51.8443 124.558 81.2344 124.558 81.2344C124.558 81.2344 101.178 136.744 52.0604 147.921C52.0604 147.921 43.3006 139.479 42.5953 131.545C41.8899 123.61 39.3891 123.237 35.627 120.743C31.8649 118.249 34.2193 113.144 35.5293 110.604C36.8394 108.064 35.839 105.962 31.95 104.797C28.0619 103.621 29.5605 96.3045 29.5605 96.3045C29.5605 96.3045 27.2813 100.623 22.6703 94.4444C18.0642 88.2713 23.0807 88.751 18.0642 88.2713C13.0477 87.7917 21.9151 92.6401 9.36087 103.972C-3.19338 115.304 -6.4509 102.879 -6.4509 102.879Z" fill="#FF530A"/>
      <path opacity="0.53" d="M124.553 81.237C124.553 81.237 101.173 136.746 52.0552 147.923C52.0552 147.923 43.2954 139.482 42.5901 131.547C41.8848 123.613 39.3839 123.24 35.6219 120.745C31.8598 118.251 34.2141 113.146 35.5242 110.606C36.8342 108.066 35.8339 105.965 31.9448 104.799C28.0567 103.623 29.5553 96.307 29.5553 96.307C29.5553 96.307 27.2761 100.626 22.6652 94.447C18.059 88.2739 23.0755 88.7535 18.059 88.2739C13.0425 87.7942 21.91 92.6426 9.35572 103.975C-3.19852 115.307 -6.45607 102.881 -6.45607 102.881C-3.50278 104.692 0.579561 103.388 3.69909 101.889C6.26254 100.654 8.84078 99.1528 10.358 96.7452C12.071 94.0292 12.172 90.6175 13.3989 87.6567C14.6258 84.6958 18.091 82.0509 20.8781 83.6366C23.0752 84.887 23.5282 88.0568 25.6863 89.3786C28.2396 90.9419 31.6275 89.1047 34.5111 89.9059C36.6001 90.4918 38.076 92.3865 38.9425 94.3731C39.809 96.3597 40.2242 98.523 41.052 100.522C41.7154 102.108 42.6403 103.597 43.1208 105.246C43.8277 107.668 43.5675 110.388 44.8343 112.563C46.4596 115.363 50.3102 116.707 51.0166 119.863C51.5175 122.083 50.3074 124.756 51.7715 126.494C52.8789 127.812 54.8791 127.756 56.5888 127.598C57.1894 127.543 57.8015 127.478 58.324 127.174C60.4312 125.944 59.0185 122.714 59.4357 120.314C59.8863 117.789 62.5526 116.263 65.0748 115.797C67.6018 115.336 70.2444 115.572 72.6963 114.831C76.031 113.83 78.5846 111.184 80.829 108.519C87.1821 100.964 92.6791 92.1 101.483 87.6328C107.276 84.701 113.906 84.005 120.345 83.1834C121.604 83.025 124.482 82.4797 124.553 81.237Z" fill="#FF530A"/>
      <path d="M7.67444 66.7056C16.0171 61.7867 24.919 57.8062 34.1494 54.8759C37.5231 53.8042 41.104 52.8649 44.5757 53.5722C47.9422 54.2588 50.8088 56.4152 54.0083 57.6703C55.9515 58.4353 58.0133 58.8576 60.0596 59.2732C62.2479 59.7183 64.7361 60.1118 66.5401 58.7988C67.3762 58.187 67.9507 57.282 68.7916 56.676C70.7683 55.2401 73.528 55.8794 75.8274 56.732C82.1913 59.1048 88.0961 62.6886 93.1303 67.2455C95.7754 69.6435 98.3135 72.5997 98.5627 76.1629C98.6017 76.7082 98.5749 77.2687 98.3715 77.7695C97.6344 79.533 95.2377 79.6417 93.5208 80.4911C89.9748 82.2435 89.596 86.9907 88.5034 90.7903C86.1433 98.984 78.7713 104.687 71.2994 108.799C68.6217 110.276 65.1763 111.592 62.6801 109.825C61.0528 108.672 60.4536 106.583 59.8912 104.668C57.2625 95.6487 53.1202 86.8118 46.2698 80.3866C39.4193 73.9614 29.482 70.3192 20.3963 72.6894C16.9151 73.5954 13.3903 75.3498 9.88831 74.5108C8.72965 74.2338 7.64095 73.6739 6.45365 73.5282C2.70043 73.0728 0.795168 76.3978 -1.89525 78.1193C-4.74817 73.8567 4.94104 68.3158 7.67444 66.7056Z" fill="#FF530A"/>
      <path d="M36.0648 57.3889C40.5341 56.0198 45.6767 54.7311 49.6263 57.227C52.7711 59.2223 54.6984 63.4071 58.3995 63.8468C61.5866 64.2212 64.2156 61.5714 67.0702 60.0961C69.8463 58.6562 73.1615 58.3082 76.1726 59.1217C82.0422 60.7179 85.8948 66.1915 89.0487 71.3999C90.1097 73.1477 91.1769 74.998 91.3027 77.0478C91.3326 77.4636 91.3064 77.9062 91.0795 78.2599C90.4184 79.2853 88.8696 78.7135 87.6598 78.6354C85.4478 78.4936 83.6106 80.4898 82.9213 82.5958C82.2367 84.7076 82.3518 86.9816 81.9974 89.1732C81.6489 91.36 80.6327 93.6868 78.592 94.5588C76.6965 95.3697 74.4369 94.6603 72.852 93.345C71.2671 92.0298 70.2133 90.2076 69.1544 88.4386C64.891 81.3169 59.8574 74.3414 52.7865 69.9973C46.8395 66.3505 39.6357 64.7983 32.7203 65.6708C29.219 66.1136 25.8107 67.1552 22.3177 67.6792C20.6201 67.9352 18.6981 68.0142 17.4003 66.8927C13.4847 63.5366 20.841 62.036 22.5815 61.4999C27.0875 60.1396 31.5732 58.7667 36.0648 57.3889Z" fill="#FF530A"/>
      <g filter="url(#filter0_d_3025_2606)">
        <path d="M41.8254 134.551C39.1548 138.028 39.6009 143.514 42.8 146.512C38.0812 149.144 35.7957 155.436 37.7315 160.485C38.4707 162.4 39.8543 164.195 41.8186 164.796C43.777 165.401 46.2718 164.38 46.7519 162.388C46.0201 164.881 47.7568 167.637 50.1347 168.68C52.5125 169.722 55.3136 169.368 57.7413 168.431C62.0775 166.77 65.6809 163.297 67.5045 159.031C67.1544 161.571 69.4401 163.914 71.9589 164.38C74.4777 164.846 77.0545 163.864 79.2452 162.545C82.5856 160.532 85.6046 157.168 85.3811 153.281C92.5686 155.62 100.909 154.031 106.743 149.215C109.043 147.317 110.885 143.535 108.576 141.652C110.677 141.102 112.789 140.553 114.89 140.003C118.388 139.094 122.203 137.952 124.166 134.922C126.124 131.886 124.339 126.605 120.74 126.893C123.794 126.585 126.902 126.104 129.624 124.67C132.335 123.235 134.623 120.67 134.985 117.621C135.346 114.572 133.291 111.217 130.255 110.777C133.163 110.873 136.071 110.968 138.984 111.07C142.748 111.194 146.984 111.143 149.626 108.462C151.164 106.909 151.871 104.671 151.925 102.483C152.085 95.3121 144.427 89.0832 137.435 90.7045C140.279 88.885 141.655 85.0482 140.61 81.838C139.566 78.6277 136.203 76.3328 132.826 76.5355C130.89 69.8132 122.913 65.5219 116.236 67.597C109.554 69.6769 105.427 77.7338 107.651 84.3656C102.615 84.4204 97.7217 87.4811 95.4832 91.9915C93.2447 96.502 93.7514 102.251 96.7484 106.297C92.7274 107.296 88.6723 108.989 86.0638 112.209C83.4553 115.429 82.7745 120.475 85.4304 123.657C81.0937 121.676 75.9947 120.658 71.5804 122.461C67.1661 124.265 64.0613 129.647 66.0042 134.005C65.8897 131.613 64.0502 129.538 61.837 128.624C60.0001 127.869 57.7727 127.721 55.8318 128.109C53.623 128.552 53.0409 130.041 51.4699 130.711C48.1244 132.161 44.5445 131.014 41.8254 134.551Z" fill="#FFE6DE"/>
        <path d="M59.2731 132.385C55.9588 132.948 53.7025 136.915 54.9145 140.056C53.7103 138.573 51.9413 137.556 50.049 137.268C49.0821 137.117 48.064 137.164 47.1642 137.545C44.9347 138.485 43.9854 141.402 44.7915 143.683C45.5977 145.965 47.7893 147.552 50.1266 148.178C47.1401 148.734 44.2209 150.38 42.7797 153.058C41.3386 155.736 41.7484 159.47 44.1563 161.319C46.5642 163.169 50.7717 162.183 51.6398 159.273C51.6157 160.703 52.6875 162.001 54.0093 162.535C55.331 163.069 56.8444 162.946 58.2103 162.513C60.19 161.887 62.0115 160.56 62.9144 158.689C63.8172 156.818 63.6681 154.396 62.2882 152.843C62.9418 155.71 61.5107 159.123 58.7176 160.068C55.9303 161.008 52.3935 158.514 52.8855 155.612C51.5221 157.364 49.7325 159.311 47.53 159.014C45.6501 158.765 44.3218 156.729 44.5452 154.841C44.7676 152.964 46.2799 151.392 48.0683 150.748C49.8508 150.109 51.8499 150.289 53.649 150.88C52.1953 149.818 50.708 148.716 49.7601 147.188C48.8064 145.665 48.5009 143.588 49.4968 142.09C50.3739 140.769 52.1098 140.168 53.6795 140.409C55.2491 140.651 56.6365 141.62 57.6458 142.842C56.0589 140.707 56.769 137.262 59.0584 135.92C61.4331 134.528 64.1359 135.762 65.7451 137.664C66.1598 134.561 62.1733 131.89 59.2731 132.385Z" fill="#FFE6DE"/>
        <path d="M83.8395 148.709C87.1805 151.12 91.6161 151.786 95.6414 150.91C98.2104 150.346 100.707 149.137 102.401 147.122C104.096 145.107 104.849 142.213 103.926 139.755C106.931 139.565 109.972 139.228 112.765 138.117C115.558 137.006 118.122 135.036 119.396 132.31C120.67 129.585 120.423 126.059 118.353 123.872C116.283 121.684 112.353 121.394 110.303 123.595C111.865 120.211 109.76 115.762 106.241 114.536C102.717 113.314 98.5047 115.244 96.8188 118.574C98.5081 115.544 103.305 115.295 105.774 117.741C108.238 120.18 108.375 124.355 106.696 127.385C109.364 125.672 112.881 123.944 115.486 125.759C117.673 127.282 117.775 130.702 116.308 132.921C114.841 135.141 112.263 136.358 109.72 137.155C107.155 137.951 104.193 138.37 101.909 136.956C103.655 140.908 101.865 145.939 98.2403 148.295C94.6151 150.651 89.6246 150.405 85.9621 148.103C82.6843 146.041 80.9652 142.82 79.6708 139.307C76.6361 140.872 82.1989 147.528 83.8395 148.709Z" fill="#FFE6DE"/>
        <path d="M98.4668 97.4096C98.459 94.7436 99.7776 92.2259 101.511 90.1983C102.99 88.4682 104.881 86.9758 107.111 86.5348C109.341 86.0938 111.929 86.9526 113.004 88.9537C112.509 86.327 112.863 83.4704 114.337 81.2356C115.811 79.0009 118.495 77.5094 121.154 77.8011C123.818 78.0987 126.215 80.3979 126.297 83.071C126.856 81.2689 129.421 81.0798 131.144 81.8506C133.385 82.8586 135.154 85.0011 135.403 87.4488C135.651 89.8965 134.191 92.4919 131.859 93.2717C134.624 92.3991 137.93 93.8307 139.187 96.4445C140.444 99.0583 139.5 102.529 137.098 104.149C134.697 105.77 131.119 105.326 129.169 103.177C131.209 103.597 133.448 103.795 135.272 102.779C137.096 101.763 138.105 99.1189 136.779 97.512C134.919 95.2588 131.134 97.0903 128.397 96.051C130.234 94.7305 131.9 93.0128 132.573 90.8517C133.246 88.6905 132.674 86.0403 130.795 84.772C128.92 83.5095 125.828 84.3293 125.27 86.5229C126.482 83.8877 124.319 80.4847 121.449 80.0441C118.579 79.6035 115.69 81.7243 114.777 84.4846C113.859 87.2391 114.681 90.369 116.404 92.7056C114.366 91.3041 112.08 90.0881 109.606 89.9374C106.44 89.7418 103.233 91.4784 101.661 94.2346C99.928 97.2705 101.645 99.7888 101.027 102.658C99.3297 101.621 98.4694 99.3457 98.4668 97.4096Z" fill="#FFE6DE"/>
        <path d="M71.3242 143.366C71.5439 142.079 72.5578 140.84 73.8583 140.755C72.7674 140.05 72.4552 138.605 72.3375 137.312C72.0794 134.402 72.4455 131.134 74.6016 129.168C76.1519 127.761 78.4807 127.297 80.4601 127.964C82.4386 128.641 83.9962 130.41 84.4333 132.457C86.1913 129.606 90.8561 129.221 93.0598 131.748C95.2635 134.275 94.2476 138.844 91.18 140.191C92.6026 138.998 93.101 136.814 92.3239 135.126C91.5469 133.437 89.5547 132.4 87.731 132.74C85.9074 133.081 84.4157 134.772 84.3239 136.629C83.9475 134.845 83.0875 133.127 81.6981 131.954C80.3088 130.781 78.3579 130.208 76.607 130.684C75.9683 130.859 75.354 131.171 74.8945 131.657C74.4292 132.149 74.143 132.787 73.9476 133.428C73.018 136.476 74.0405 140.027 76.4464 142.124C75.5895 141.382 73.1769 145.302 73.118 145.919C72.9879 147.279 74.0541 148.523 74.2135 149.772C72.2347 148.762 70.9555 145.54 71.3242 143.366Z" fill="#FFE6DE"/>
      </g>
      <path d="M-13.5985 98.666C-14.7356 100.799 -14.0764 103.72 -12.1325 105.15C-14.7104 105.976 -15.3363 109.381 -14.8838 112.052C-14.5272 114.156 -13.5991 116.395 -11.6554 117.267C-9.71175 118.139 -6.84907 116.579 -7.28785 114.494C-8.0156 115.711 -7.24819 117.388 -6.03176 118.127C-4.81532 118.865 -3.31925 118.922 -1.89555 118.951C0.170924 118.988 2.39812 118.97 4.07888 117.769C5.75965 116.567 6.36554 113.708 4.69023 112.497C6.7576 114.207 9.3629 115.394 12.0525 115.308C14.7314 115.221 17.4412 113.689 18.4774 111.209C19.5136 108.728 18.4657 105.443 15.9921 104.391C19.4682 104.997 23.1883 101.985 23.08 98.4621C22.9718 94.9392 18.7442 92.154 15.5551 93.6509C18.2701 90.9986 18.6497 86.2996 16.3954 83.2365C14.0026 79.9939 9.4679 79.0187 5.46833 79.5694C2.0559 80.0368 -1.34097 81.5192 -3.44163 84.2517C-5.53647 86.9794 -6.01376 91.0737 -4.00754 93.8718C-5.09563 92.352 -7.46077 95.8315 -7.92092 96.0449C-10.0609 97.0576 -12.1037 95.8755 -13.5985 98.666Z" fill="#FFE6DE"/>
      <path d="M-11.4037 100.896C-12.5408 103.029 -11.8816 105.951 -9.93764 107.381C-12.5156 108.207 -13.1415 111.611 -12.689 114.282C-12.3324 116.386 -11.4043 118.625 -9.4606 119.498C-7.51692 120.37 -4.65424 118.81 -5.09302 116.724C-5.82077 117.942 -5.05336 119.619 -3.83693 120.357C-2.62049 121.095 -1.12442 121.153 0.299279 121.182C2.36575 121.218 4.59294 121.201 6.27371 119.999C7.95448 118.798 8.56035 115.938 6.88504 114.727C8.95241 116.437 11.5577 117.625 14.2473 117.539C16.9368 117.453 19.636 115.92 20.6722 113.439C21.7084 110.959 20.6605 107.673 18.187 106.622C21.663 107.227 25.3831 104.215 25.2749 100.693C25.1666 97.1697 20.939 94.3844 17.7499 95.8813C20.465 93.229 20.8445 88.5301 18.5902 85.467C16.1974 82.2244 11.6627 81.2492 7.66316 81.7998C4.25072 82.2672 0.853843 83.7497 -1.24682 86.4822C-3.34166 89.2099 -3.81895 93.3042 -1.81273 96.1023C-2.90082 94.5825 -5.26595 98.0619 -5.72609 98.2753C-7.8709 99.2822 -9.91367 98.1002 -11.4037 100.896Z" fill="#FFE6DE"/>
      <path d="M10.7136 90.6116C11.7305 91.642 12.2642 93.0712 12.4595 94.5056C12.7137 96.3907 12.3842 98.4346 11.1469 99.8768C13.4923 98.5673 16.4719 98.4769 18.8907 99.652C19.777 100.085 20.6601 100.776 20.8083 101.75C20.9285 102.512 20.5653 103.282 20.0744 103.873C18.7143 105.534 16.2493 106.189 14.2413 105.428C15.618 106.622 16.6448 108.221 17.147 109.98C17.4447 111.017 17.5349 112.205 16.9247 113.09C16.296 114.001 15.0736 114.335 13.9761 114.203C12.8844 114.066 11.8787 113.536 10.9039 113.019C10.2453 112.672 9.5723 112.307 9.08487 111.74C8.59744 111.173 8.34317 110.355 8.63343 109.675C9.63768 110.726 10.7393 111.823 12.1647 112.115C13.5901 112.407 15.3548 111.45 15.3065 109.992C15.2847 109.266 14.8305 108.632 14.3633 108.078C13.3692 106.921 12.1946 105.913 10.8993 105.103C12.5029 105.101 14.1084 104.911 15.6722 104.541C16.276 104.395 16.8994 104.213 17.3682 103.796C17.837 103.38 18.0975 102.676 17.8236 102.119C17.5278 101.511 16.7699 101.305 16.1003 101.241C13.1381 100.925 10.1886 101.823 7.34746 102.71C8.29184 101.47 9.25469 100.205 9.82311 98.7474C11.5253 94.4055 8.45654 94.1389 6.79882 91.2294C5.19573 88.4216 9.05596 88.9355 10.7136 90.6116Z" fill="#FFE6DE"/>
      <defs>
        <filter id="filter0_d_3025_2606" x="33.2209" y="65.1595" width="122.528" height="109.887" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dy="1.91081"/>
          <feGaussianBlur stdDeviation="1.91081"/>
          <feComposite in2="hardAlpha" operator="out"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3025_2606"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3025_2606" result="shape"/>
        </filter>
      </defs>
    </g>
  )
}

export const HeadBlock6 = () => {
  return (
    <g>
      <path d="M199.254 143.655C198.794 142.593 198.312 141.478 197.694 140.508C198.234 141.36 197.616 140.398 197.516 140.234C197.388 140.024 197.253 139.807 197.118 139.605C196.8 139.114 196.475 138.637 196.133 138.169C195.454 137.231 194.721 136.338 193.947 135.49C193.782 135.309 193.612 135.13 193.441 134.95C193.182 134.681 193.216 134.713 193.536 135.046C193.421 134.931 193.305 134.816 193.19 134.701C192.776 134.306 192.357 133.924 191.926 133.551C190.606 132.405 190.074 130.206 191.185 128.693C192.214 127.282 194.27 126.716 195.678 127.945C199.706 131.44 202.942 135.845 205.134 140.896C205.85 142.549 205.107 144.809 203.507 145.466C201.857 146.148 200.022 145.434 199.254 143.662L199.254 143.655Z" fill="black"/>
      <path d="M201.289 179.426C201.289 179.426 230.671 149.274 227.962 126.246L234.308 138.125L243.035 132.264C243.035 132.264 226.605 176.341 201.289 179.426Z" fill="#FF530A"/>
      <path d="M216.702 160.71C215.935 151.036 212.935 141.727 207.486 133.396C186.168 100.786 135.51 95.4821 94.3378 121.548C61.3776 142.414 50.0518 176.564 50.0002 206.773C49.8902 270.759 96.3795 305 151.988 305V249.446C158.632 246.906 165.194 243.642 171.529 239.63C197.859 222.961 213.938 197.501 216.527 172.782C216.953 168.723 217.018 164.688 216.698 160.71H216.702Z" fill="#EFB825"/>
      <path d="M117.533 171.228C117.66 171.302 117.787 171.367 117.918 171.438C118.079 171.522 118.244 171.61 118.406 171.691C118.557 171.769 118.712 171.844 118.859 171.918C131.227 177.902 146.055 173.782 153.5 162.664C154.033 161.869 154.528 161.037 154.982 160.167C161.85 147.043 156.608 130.926 143.274 124.171C129.938 117.413 113.559 122.571 106.691 135.695C106.238 136.564 105.836 137.447 105.485 138.34C100.673 150.615 105.701 164.696 117.529 171.228H117.533Z" fill="#FFE6DE"/>
      <path d="M117.983 172.425C118.096 172.492 118.217 172.553 118.337 172.618C118.485 172.695 118.632 172.773 118.784 172.848C118.921 172.919 119.062 172.986 119.2 173.054C130.484 178.513 144.013 174.755 150.806 164.608C154.202 155.255 148.413 143.697 137.049 137.94C125.686 132.18 112.758 134.257 106.997 142.415C102.604 153.615 107.193 166.461 117.986 172.421L117.983 172.425Z" fill="black"/>
      <path d="M111.225 222.625C120.81 222.625 128.58 214.979 128.58 205.547C128.58 196.115 120.81 188.469 111.225 188.469C101.64 188.469 93.8701 196.115 93.8701 205.547C93.8701 214.979 101.64 222.625 111.225 222.625Z" fill="#FF530A"/>
      <path d="M134.609 188.992C139.349 193.278 141.535 199.806 140.384 206.026C140.112 207.498 138.418 208.59 136.94 208.208C135.379 207.805 134.434 206.388 134.723 204.819C134.778 204.524 134.826 204.227 134.867 203.929C134.888 203.78 134.901 203.631 134.918 203.483C134.894 203.716 134.894 203.848 134.905 203.537C134.925 202.897 134.929 202.265 134.877 201.629C134.853 201.331 134.822 201.03 134.781 200.736C134.771 200.672 134.702 200.178 134.757 200.543C134.815 200.908 134.729 200.421 134.716 200.354C134.585 199.728 134.417 199.112 134.21 198.507C134.114 198.223 134.008 197.942 133.894 197.661C133.839 197.523 133.777 197.384 133.719 197.245C133.564 196.877 133.76 197.164 133.764 197.323C133.764 197.107 133.451 196.714 133.344 196.525C133.196 196.264 133.042 196.004 132.877 195.75C132.53 195.209 132.141 194.705 131.739 194.204C131.478 193.876 132.096 194.57 131.794 194.275C131.687 194.171 131.588 194.056 131.481 193.947C131.244 193.704 130.997 193.47 130.742 193.24C129.628 192.232 129.412 190.325 130.515 189.215C131.601 188.123 133.413 187.913 134.606 188.992H134.609Z" fill="black"/>
      <path d="M136.637 201.725C143.993 203.599 151.682 203.92 159.206 202.963C173.223 201.187 187.168 195.779 199.085 188.368C205.795 184.197 211.944 178.876 216.526 172.534C216.918 168.472 216.952 164.43 216.609 160.445C211.284 174.486 197.573 183.839 183.937 189.87C169.583 196.218 153.442 200.125 137.864 196.154C134.244 195.231 133.028 200.805 136.633 201.725H136.637Z" fill="black"/>
      <path d="M69.1068 47.5466C69.1068 47.5466 50.6776 46.0097 39.6195 50.3311C28.5663 54.6584 71.486 58.671 69.1068 47.5466Z" fill="#FF530A"/>
      <path d="M-6.4509 102.879C-6.4509 102.879 -27.3792 75.0939 20.0382 54.0533C20.0382 54.0533 36.2753 47.8944 56.2481 51.1233C76.2209 54.3522 59.0587 44.7799 75.2487 48.3121C91.4386 51.8443 124.558 81.2344 124.558 81.2344C124.558 81.2344 101.178 136.744 52.0604 147.921C52.0604 147.921 43.3006 139.479 42.5953 131.545C41.8899 123.61 39.3891 123.237 35.627 120.743C31.8649 118.249 34.2193 113.144 35.5293 110.604C36.8394 108.064 35.839 105.962 31.95 104.797C28.0619 103.621 29.5605 96.3045 29.5605 96.3045C29.5605 96.3045 27.2813 100.623 22.6703 94.4444C18.0642 88.2713 23.0807 88.751 18.0642 88.2713C13.0477 87.7917 21.9151 92.6401 9.36087 103.972C-3.19338 115.304 -6.4509 102.879 -6.4509 102.879Z" fill="#FF530A"/>
      <path opacity="0.53" d="M124.553 81.237C124.553 81.237 101.173 136.746 52.0552 147.923C52.0552 147.923 43.2954 139.482 42.5901 131.547C41.8848 123.613 39.3839 123.24 35.6219 120.745C31.8598 118.251 34.2141 113.146 35.5242 110.606C36.8342 108.066 35.8339 105.965 31.9448 104.799C28.0567 103.623 29.5553 96.307 29.5553 96.307C29.5553 96.307 27.2761 100.626 22.6652 94.447C18.059 88.2739 23.0755 88.7535 18.059 88.2739C13.0425 87.7942 21.91 92.6426 9.35572 103.975C-3.19852 115.307 -6.45607 102.881 -6.45607 102.881C-3.50278 104.692 0.579561 103.388 3.69909 101.889C6.26254 100.654 8.84078 99.1528 10.358 96.7452C12.071 94.0292 12.172 90.6175 13.3989 87.6567C14.6258 84.6958 18.091 82.0509 20.8781 83.6366C23.0752 84.887 23.5282 88.0568 25.6863 89.3786C28.2396 90.9419 31.6275 89.1047 34.5111 89.9059C36.6001 90.4918 38.076 92.3865 38.9425 94.3731C39.809 96.3597 40.2242 98.523 41.052 100.522C41.7154 102.108 42.6403 103.597 43.1208 105.246C43.8277 107.668 43.5675 110.388 44.8343 112.563C46.4596 115.363 50.3102 116.707 51.0166 119.863C51.5175 122.083 50.3074 124.756 51.7715 126.494C52.8789 127.812 54.8791 127.756 56.5888 127.598C57.1894 127.543 57.8015 127.478 58.324 127.174C60.4312 125.944 59.0185 122.714 59.4357 120.314C59.8863 117.789 62.5526 116.263 65.0748 115.797C67.6018 115.336 70.2444 115.572 72.6963 114.831C76.031 113.83 78.5846 111.184 80.829 108.519C87.1821 100.964 92.6791 92.1 101.483 87.6328C107.276 84.701 113.906 84.005 120.345 83.1834C121.604 83.025 124.482 82.4797 124.553 81.237Z" fill="#FF530A"/>
      <path d="M7.67444 66.7056C16.0171 61.7867 24.919 57.8062 34.1494 54.8759C37.5231 53.8042 41.104 52.8649 44.5757 53.5722C47.9422 54.2588 50.8088 56.4152 54.0083 57.6703C55.9515 58.4353 58.0133 58.8576 60.0596 59.2732C62.2479 59.7183 64.7361 60.1118 66.5401 58.7988C67.3762 58.187 67.9507 57.282 68.7916 56.676C70.7683 55.2401 73.528 55.8794 75.8274 56.732C82.1913 59.1048 88.0961 62.6886 93.1303 67.2455C95.7754 69.6435 98.3135 72.5997 98.5627 76.1629C98.6017 76.7082 98.5749 77.2687 98.3715 77.7695C97.6344 79.533 95.2377 79.6417 93.5208 80.4911C89.9748 82.2435 89.596 86.9907 88.5034 90.7903C86.1433 98.984 78.7713 104.687 71.2994 108.799C68.6217 110.276 65.1763 111.592 62.6801 109.825C61.0528 108.672 60.4536 106.583 59.8912 104.668C57.2625 95.6487 53.1202 86.8118 46.2698 80.3866C39.4193 73.9614 29.482 70.3192 20.3963 72.6894C16.9151 73.5954 13.3903 75.3498 9.88831 74.5108C8.72965 74.2338 7.64095 73.6739 6.45365 73.5282C2.70043 73.0728 0.795168 76.3978 -1.89525 78.1193C-4.74817 73.8567 4.94104 68.3158 7.67444 66.7056Z" fill="#FF530A"/>
      <path d="M36.0648 57.3889C40.5341 56.0198 45.6767 54.7311 49.6263 57.227C52.7711 59.2223 54.6984 63.4071 58.3995 63.8468C61.5866 64.2212 64.2156 61.5714 67.0702 60.0961C69.8463 58.6562 73.1615 58.3082 76.1726 59.1217C82.0422 60.7179 85.8948 66.1915 89.0487 71.3999C90.1097 73.1477 91.1769 74.998 91.3027 77.0478C91.3326 77.4636 91.3064 77.9062 91.0795 78.2599C90.4184 79.2853 88.8696 78.7135 87.6598 78.6354C85.4478 78.4936 83.6106 80.4898 82.9213 82.5958C82.2367 84.7076 82.3518 86.9816 81.9974 89.1732C81.6489 91.36 80.6327 93.6868 78.592 94.5588C76.6965 95.3697 74.4369 94.6603 72.852 93.345C71.2671 92.0298 70.2133 90.2076 69.1544 88.4386C64.891 81.3169 59.8574 74.3414 52.7865 69.9973C46.8395 66.3505 39.6357 64.7983 32.7203 65.6708C29.219 66.1136 25.8107 67.1552 22.3177 67.6792C20.6201 67.9352 18.6981 68.0142 17.4003 66.8927C13.4847 63.5366 20.841 62.036 22.5815 61.4999C27.0875 60.1396 31.5732 58.7667 36.0648 57.3889Z" fill="#FF530A"/>
      <g filter="url(#filter0_d_3053_108)">
        <path d="M41.8254 134.551C39.1548 138.028 39.6009 143.514 42.8 146.512C38.0812 149.144 35.7957 155.436 37.7315 160.485C38.4707 162.4 39.8543 164.195 41.8186 164.796C43.777 165.401 46.2718 164.38 46.7519 162.388C46.0201 164.881 47.7568 167.637 50.1347 168.68C52.5125 169.722 55.3136 169.368 57.7413 168.431C62.0775 166.77 65.6809 163.297 67.5045 159.031C67.1544 161.571 69.4401 163.914 71.9589 164.38C74.4777 164.846 77.0545 163.864 79.2452 162.545C82.5856 160.532 85.6046 157.168 85.3811 153.281C92.5686 155.62 100.909 154.031 106.743 149.215C109.043 147.317 110.885 143.535 108.576 141.652C110.677 141.102 112.789 140.553 114.89 140.003C118.388 139.094 122.203 137.952 124.166 134.922C126.124 131.886 124.339 126.605 120.74 126.893C123.794 126.585 126.902 126.104 129.624 124.67C132.335 123.235 134.623 120.67 134.985 117.621C135.346 114.572 133.291 111.217 130.255 110.777C133.163 110.873 136.071 110.968 138.984 111.07C142.748 111.194 146.984 111.143 149.626 108.462C151.164 106.909 151.871 104.671 151.925 102.483C152.085 95.3121 144.427 89.0832 137.435 90.7045C140.279 88.885 141.655 85.0482 140.61 81.838C139.566 78.6277 136.203 76.3328 132.826 76.5355C130.89 69.8132 122.913 65.5219 116.236 67.597C109.554 69.6769 105.427 77.7338 107.651 84.3656C102.615 84.4204 97.7217 87.4811 95.4832 91.9915C93.2447 96.502 93.7514 102.251 96.7484 106.297C92.7274 107.296 88.6723 108.989 86.0638 112.209C83.4553 115.429 82.7745 120.475 85.4304 123.657C81.0937 121.676 75.9947 120.658 71.5804 122.461C67.1661 124.265 64.0613 129.647 66.0042 134.005C65.8897 131.613 64.0502 129.538 61.837 128.624C60.0001 127.869 57.7727 127.721 55.8318 128.109C53.623 128.552 53.0409 130.041 51.4699 130.711C48.1244 132.161 44.5445 131.014 41.8254 134.551Z" fill="#FFE6DE"/>
        <path d="M59.2731 132.385C55.9588 132.948 53.7025 136.915 54.9145 140.056C53.7103 138.573 51.9413 137.556 50.049 137.268C49.0821 137.117 48.064 137.164 47.1642 137.545C44.9347 138.485 43.9854 141.402 44.7915 143.683C45.5977 145.965 47.7893 147.552 50.1266 148.178C47.1401 148.734 44.2209 150.38 42.7797 153.058C41.3386 155.736 41.7484 159.47 44.1563 161.319C46.5642 163.169 50.7717 162.183 51.6398 159.273C51.6157 160.703 52.6875 162.001 54.0093 162.535C55.331 163.069 56.8444 162.946 58.2103 162.513C60.19 161.887 62.0115 160.56 62.9144 158.689C63.8172 156.818 63.6681 154.396 62.2882 152.843C62.9418 155.71 61.5107 159.123 58.7176 160.068C55.9303 161.008 52.3935 158.514 52.8855 155.612C51.5221 157.364 49.7325 159.311 47.53 159.014C45.6501 158.765 44.3218 156.729 44.5452 154.841C44.7676 152.964 46.2799 151.392 48.0683 150.748C49.8508 150.109 51.8499 150.289 53.649 150.88C52.1953 149.818 50.708 148.716 49.7601 147.188C48.8064 145.665 48.5009 143.588 49.4968 142.09C50.3739 140.769 52.1098 140.168 53.6795 140.409C55.2491 140.651 56.6365 141.62 57.6458 142.842C56.0589 140.707 56.769 137.262 59.0584 135.92C61.4331 134.528 64.1359 135.762 65.7451 137.664C66.1598 134.561 62.1733 131.89 59.2731 132.385Z" fill="#FFE6DE"/>
        <path d="M83.8395 148.709C87.1805 151.12 91.6161 151.786 95.6414 150.91C98.2104 150.346 100.707 149.137 102.401 147.122C104.096 145.107 104.849 142.213 103.926 139.755C106.931 139.565 109.972 139.228 112.765 138.117C115.558 137.006 118.122 135.036 119.396 132.31C120.67 129.585 120.423 126.059 118.353 123.872C116.283 121.684 112.353 121.394 110.303 123.595C111.865 120.211 109.76 115.762 106.241 114.536C102.717 113.314 98.5047 115.244 96.8188 118.574C98.5081 115.544 103.305 115.295 105.774 117.741C108.238 120.18 108.375 124.355 106.696 127.385C109.364 125.672 112.881 123.944 115.486 125.759C117.673 127.282 117.775 130.702 116.308 132.921C114.841 135.141 112.263 136.358 109.72 137.155C107.155 137.951 104.193 138.37 101.909 136.956C103.655 140.908 101.865 145.939 98.2403 148.295C94.6151 150.651 89.6246 150.405 85.9621 148.103C82.6843 146.041 80.9652 142.82 79.6708 139.307C76.6361 140.872 82.1989 147.528 83.8395 148.709Z" fill="#FFE6DE"/>
        <path d="M98.4668 97.4096C98.459 94.7436 99.7776 92.2259 101.511 90.1983C102.99 88.4682 104.881 86.9758 107.111 86.5348C109.341 86.0938 111.929 86.9526 113.004 88.9537C112.509 86.327 112.863 83.4704 114.337 81.2356C115.811 79.0009 118.495 77.5094 121.154 77.8011C123.818 78.0987 126.215 80.3979 126.297 83.071C126.856 81.2689 129.421 81.0798 131.144 81.8506C133.385 82.8586 135.154 85.0011 135.403 87.4488C135.651 89.8965 134.191 92.4919 131.859 93.2717C134.624 92.3991 137.93 93.8307 139.187 96.4445C140.444 99.0583 139.5 102.529 137.098 104.149C134.697 105.77 131.119 105.326 129.169 103.177C131.209 103.597 133.448 103.795 135.272 102.779C137.096 101.763 138.105 99.1189 136.779 97.512C134.919 95.2588 131.134 97.0903 128.397 96.051C130.234 94.7305 131.9 93.0128 132.573 90.8517C133.246 88.6905 132.674 86.0403 130.795 84.772C128.92 83.5095 125.828 84.3293 125.27 86.5229C126.482 83.8877 124.319 80.4847 121.449 80.0441C118.579 79.6035 115.69 81.7243 114.777 84.4846C113.859 87.2391 114.681 90.369 116.404 92.7056C114.366 91.3041 112.08 90.0881 109.606 89.9374C106.44 89.7418 103.233 91.4784 101.661 94.2346C99.928 97.2705 101.645 99.7888 101.027 102.658C99.3297 101.621 98.4694 99.3457 98.4668 97.4096Z" fill="#FFE6DE"/>
        <path d="M71.3242 143.366C71.5439 142.079 72.5578 140.84 73.8583 140.755C72.7674 140.05 72.4552 138.605 72.3375 137.312C72.0794 134.402 72.4455 131.134 74.6016 129.168C76.1519 127.761 78.4807 127.297 80.4601 127.964C82.4386 128.641 83.9962 130.41 84.4333 132.457C86.1913 129.606 90.8561 129.221 93.0598 131.748C95.2635 134.275 94.2476 138.844 91.18 140.191C92.6026 138.998 93.101 136.814 92.3239 135.126C91.5469 133.437 89.5547 132.4 87.731 132.74C85.9074 133.081 84.4157 134.772 84.3239 136.629C83.9475 134.845 83.0875 133.127 81.6981 131.954C80.3088 130.781 78.3579 130.208 76.607 130.684C75.9683 130.859 75.354 131.171 74.8945 131.657C74.4292 132.149 74.143 132.787 73.9476 133.428C73.018 136.476 74.0405 140.027 76.4464 142.124C75.5895 141.382 73.1769 145.302 73.118 145.919C72.9879 147.279 74.0541 148.523 74.2135 149.772C72.2347 148.762 70.9555 145.54 71.3242 143.366Z" fill="#FFE6DE"/>
      </g>
      <path d="M-13.5985 98.666C-14.7356 100.799 -14.0764 103.72 -12.1325 105.15C-14.7104 105.976 -15.3363 109.381 -14.8838 112.052C-14.5272 114.156 -13.5991 116.395 -11.6554 117.267C-9.71175 118.139 -6.84907 116.579 -7.28785 114.494C-8.0156 115.711 -7.24819 117.388 -6.03176 118.127C-4.81532 118.865 -3.31925 118.922 -1.89555 118.951C0.170924 118.988 2.39812 118.97 4.07888 117.769C5.75965 116.567 6.36554 113.708 4.69023 112.497C6.7576 114.207 9.3629 115.394 12.0525 115.308C14.7314 115.221 17.4412 113.689 18.4774 111.209C19.5136 108.728 18.4657 105.443 15.9921 104.391C19.4682 104.997 23.1883 101.985 23.08 98.4621C22.9718 94.9392 18.7442 92.154 15.5551 93.6509C18.2701 90.9986 18.6497 86.2996 16.3954 83.2365C14.0026 79.9939 9.4679 79.0187 5.46833 79.5694C2.0559 80.0368 -1.34097 81.5192 -3.44163 84.2517C-5.53647 86.9794 -6.01376 91.0737 -4.00754 93.8718C-5.09563 92.352 -7.46077 95.8315 -7.92092 96.0449C-10.0609 97.0576 -12.1037 95.8755 -13.5985 98.666Z" fill="#FFE6DE"/>
      <path d="M-11.4037 100.896C-12.5408 103.029 -11.8816 105.951 -9.93764 107.381C-12.5156 108.207 -13.1415 111.611 -12.689 114.282C-12.3324 116.386 -11.4043 118.625 -9.4606 119.498C-7.51692 120.37 -4.65424 118.81 -5.09302 116.724C-5.82077 117.942 -5.05336 119.619 -3.83693 120.357C-2.62049 121.095 -1.12442 121.153 0.299279 121.182C2.36575 121.218 4.59294 121.201 6.27371 119.999C7.95448 118.798 8.56035 115.938 6.88504 114.727C8.95241 116.437 11.5577 117.625 14.2473 117.539C16.9368 117.453 19.636 115.92 20.6722 113.439C21.7084 110.959 20.6605 107.673 18.187 106.622C21.663 107.227 25.3831 104.215 25.2749 100.693C25.1666 97.1697 20.939 94.3844 17.7499 95.8813C20.465 93.229 20.8445 88.5301 18.5902 85.467C16.1974 82.2244 11.6627 81.2492 7.66316 81.7998C4.25072 82.2672 0.853843 83.7497 -1.24682 86.4822C-3.34166 89.2099 -3.81895 93.3042 -1.81273 96.1023C-2.90082 94.5825 -5.26595 98.0619 -5.72609 98.2753C-7.8709 99.2822 -9.91367 98.1002 -11.4037 100.896Z" fill="#FFE6DE"/>
      <path d="M10.7136 90.6116C11.7305 91.642 12.2642 93.0712 12.4595 94.5056C12.7137 96.3907 12.3842 98.4346 11.1469 99.8768C13.4923 98.5673 16.4719 98.4769 18.8907 99.652C19.777 100.085 20.6601 100.776 20.8083 101.75C20.9285 102.512 20.5653 103.282 20.0744 103.873C18.7143 105.534 16.2493 106.189 14.2413 105.428C15.618 106.622 16.6448 108.221 17.147 109.98C17.4447 111.017 17.5349 112.205 16.9247 113.09C16.296 114.001 15.0736 114.335 13.9761 114.203C12.8844 114.066 11.8787 113.536 10.9039 113.019C10.2453 112.672 9.5723 112.307 9.08487 111.74C8.59744 111.173 8.34317 110.355 8.63343 109.675C9.63768 110.726 10.7393 111.823 12.1647 112.115C13.5901 112.407 15.3548 111.45 15.3065 109.992C15.2847 109.266 14.8305 108.632 14.3633 108.078C13.3692 106.921 12.1946 105.913 10.8993 105.103C12.5029 105.101 14.1084 104.911 15.6722 104.541C16.276 104.395 16.8994 104.213 17.3682 103.796C17.837 103.38 18.0975 102.676 17.8236 102.119C17.5278 101.511 16.7699 101.305 16.1003 101.241C13.1381 100.925 10.1886 101.823 7.34746 102.71C8.29184 101.47 9.25469 100.205 9.82311 98.7474C11.5253 94.4055 8.45654 94.1389 6.79882 91.2294C5.19573 88.4216 9.05596 88.9355 10.7136 90.6116Z" fill="#FFE6DE"/>
      <defs>
        <filter id="filter0_d_3053_108" x="33.2209" y="65.1595" width="122.528" height="109.887" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dy="1.91081"/>
          <feGaussianBlur stdDeviation="1.91081"/>
          <feComposite in2="hardAlpha" operator="out"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3053_108"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3053_108" result="shape"/>
        </filter>
      </defs>
    </g>
  )
}

export const HeadBlock7 = () => {
  return (
    <g>
      <path d="M199.254 143.655C198.794 142.593 198.312 141.478 197.694 140.508C198.234 141.36 197.616 140.398 197.516 140.234C197.388 140.024 197.253 139.807 197.118 139.605C196.8 139.114 196.475 138.637 196.133 138.169C195.454 137.231 194.721 136.338 193.947 135.49C193.782 135.309 193.612 135.13 193.441 134.95C193.182 134.681 193.216 134.713 193.536 135.046C193.421 134.931 193.305 134.816 193.19 134.701C192.776 134.306 192.357 133.924 191.926 133.551C190.606 132.405 190.074 130.206 191.185 128.693C192.214 127.282 194.27 126.716 195.678 127.945C199.706 131.44 202.942 135.845 205.134 140.896C205.85 142.549 205.107 144.809 203.507 145.466C201.857 146.148 200.022 145.434 199.254 143.662L199.254 143.655Z" fill="black"/>
      <path d="M201.289 179.426C201.289 179.426 230.671 149.274 227.962 126.246L234.308 138.125L243.035 132.264C243.035 132.264 226.605 176.341 201.289 179.426Z" fill="#FF530A"/>
      <path d="M216.702 160.71C215.935 151.036 212.935 141.727 207.486 133.396C186.168 100.786 135.51 95.4821 94.3378 121.548C61.3776 142.414 50.0518 176.564 50.0002 206.773C49.8902 270.759 96.3795 305 151.988 305V249.446C158.632 246.906 165.194 243.642 171.529 239.63C197.859 222.961 213.938 197.501 216.527 172.782C216.953 168.723 217.018 164.688 216.698 160.71H216.702Z" fill="#EFB825"/>
      <path d="M117.533 171.228C117.66 171.302 117.787 171.367 117.918 171.438C118.079 171.522 118.244 171.61 118.406 171.691C118.557 171.769 118.712 171.844 118.859 171.918C131.227 177.902 146.055 173.782 153.5 162.664C154.033 161.869 154.528 161.037 154.982 160.167C161.85 147.043 156.608 130.926 143.274 124.171C129.938 117.413 113.559 122.571 106.691 135.695C106.238 136.564 105.836 137.447 105.485 138.34C100.673 150.615 105.701 164.696 117.529 171.228H117.533Z" fill="#FFE6DE"/>
      <path d="M117.983 172.425C118.096 172.492 118.217 172.553 118.337 172.618C118.485 172.695 118.632 172.773 118.784 172.848C118.921 172.919 119.062 172.986 119.2 173.054C130.484 178.513 144.013 174.755 150.806 164.608C154.202 155.255 148.413 143.697 137.049 137.94C125.686 132.18 112.758 134.257 106.997 142.415C102.604 153.615 107.193 166.461 117.986 172.421L117.983 172.425Z" fill="black"/>
      <path d="M111.225 222.625C120.81 222.625 128.58 214.979 128.58 205.547C128.58 196.115 120.81 188.469 111.225 188.469C101.64 188.469 93.8701 196.115 93.8701 205.547C93.8701 214.979 101.64 222.625 111.225 222.625Z" fill="#FF530A"/>
      <path d="M134.609 188.992C139.349 193.278 141.535 199.806 140.384 206.026C140.112 207.498 138.418 208.59 136.94 208.208C135.379 207.805 134.434 206.388 134.723 204.819C134.778 204.524 134.826 204.227 134.867 203.929C134.888 203.78 134.901 203.631 134.918 203.483C134.894 203.716 134.894 203.848 134.905 203.537C134.925 202.897 134.929 202.265 134.877 201.629C134.853 201.331 134.822 201.03 134.781 200.736C134.771 200.672 134.702 200.178 134.757 200.543C134.815 200.908 134.729 200.421 134.716 200.354C134.585 199.728 134.417 199.112 134.21 198.507C134.114 198.223 134.008 197.942 133.894 197.661C133.839 197.523 133.777 197.384 133.719 197.245C133.564 196.877 133.76 197.164 133.764 197.323C133.764 197.107 133.451 196.714 133.344 196.525C133.196 196.264 133.042 196.004 132.877 195.75C132.53 195.209 132.141 194.705 131.739 194.204C131.478 193.876 132.096 194.57 131.794 194.275C131.687 194.171 131.588 194.056 131.481 193.947C131.244 193.704 130.997 193.47 130.742 193.24C129.628 192.232 129.412 190.325 130.515 189.215C131.601 188.123 133.413 187.913 134.606 188.992H134.609Z" fill="black"/>
      <path d="M136.637 201.725C143.993 203.599 151.682 203.92 159.206 202.963C173.223 201.187 187.168 195.779 199.085 188.368C205.795 184.197 211.944 178.876 216.526 172.534C216.918 168.472 216.952 164.43 216.609 160.445C211.284 174.486 197.573 183.839 183.937 189.87C169.583 196.218 153.442 200.125 137.864 196.154C134.244 195.231 133.028 200.805 136.633 201.725H136.637Z" fill="black"/>
      <path d="M69.1063 47.5466C69.1063 47.5466 50.6771 46.0097 39.619 50.3311C28.5658 54.6584 71.4855 58.671 69.1063 47.5466Z" fill="#FF530A"/>
      <path d="M-6.4509 102.877C-6.4509 102.877 -27.3792 75.092 20.0382 54.0514C20.0382 54.0514 36.2753 47.8924 56.2481 51.1213C76.2209 54.3503 59.0587 44.7779 75.2487 48.3101C91.4386 51.8423 124.558 81.2325 124.558 81.2325C124.558 81.2325 101.178 136.742 52.0604 147.919C52.0604 147.919 43.3006 139.477 42.5953 131.543C41.8899 123.608 39.3891 123.235 35.627 120.741C31.8649 118.247 34.2193 113.142 35.5293 110.602C36.8394 108.062 35.839 105.96 31.95 104.795C28.0619 103.619 29.5605 96.3025 29.5605 96.3025C29.5605 96.3025 27.2813 100.621 22.6703 94.4425C18.0642 88.2694 23.0807 88.749 18.0642 88.2694C13.0477 87.7897 21.9151 92.6381 9.36087 103.97C-3.19338 115.302 -6.4509 102.877 -6.4509 102.877Z" fill="#FF530A"/>
      <path opacity="0.53" d="M124.553 81.237C124.553 81.237 101.173 136.746 52.0552 147.923C52.0552 147.923 43.2954 139.482 42.5901 131.547C41.8848 123.613 39.3839 123.24 35.6219 120.745C31.8598 118.251 34.2141 113.146 35.5242 110.606C36.8342 108.066 35.8339 105.965 31.9448 104.799C28.0567 103.623 29.5553 96.307 29.5553 96.307C29.5553 96.307 27.2761 100.626 22.6652 94.447C18.059 88.2739 23.0755 88.7535 18.059 88.2739C13.0425 87.7942 21.91 92.6426 9.35572 103.975C-3.19852 115.307 -6.45607 102.881 -6.45607 102.881C-3.50278 104.692 0.579561 103.388 3.69909 101.889C6.26254 100.654 8.84078 99.1528 10.358 96.7452C12.071 94.0292 12.172 90.6175 13.3989 87.6567C14.6258 84.6958 18.091 82.0509 20.8781 83.6366C23.0752 84.887 23.5282 88.0568 25.6863 89.3786C28.2396 90.9419 31.6275 89.1047 34.5111 89.9059C36.6001 90.4918 38.076 92.3865 38.9425 94.3731C39.809 96.3597 40.2242 98.523 41.052 100.522C41.7154 102.108 42.6403 103.597 43.1208 105.246C43.8277 107.668 43.5675 110.388 44.8343 112.563C46.4596 115.363 50.3102 116.707 51.0166 119.863C51.5175 122.083 50.3074 124.756 51.7715 126.494C52.8789 127.812 54.8791 127.756 56.5888 127.598C57.1894 127.543 57.8015 127.478 58.324 127.174C60.4312 125.944 59.0185 122.714 59.4357 120.314C59.8863 117.789 62.5526 116.263 65.0748 115.797C67.6018 115.336 70.2444 115.572 72.6963 114.831C76.031 113.83 78.5846 111.184 80.829 108.519C87.1821 100.964 92.6791 92.1 101.483 87.6328C107.276 84.701 113.906 84.005 120.345 83.1834C121.604 83.025 124.482 82.4797 124.553 81.237Z" fill="#FF530A"/>
      <path d="M7.67444 66.7037C16.0171 61.7848 24.919 57.8042 34.1494 54.8739C37.5231 53.8022 41.104 52.863 44.5757 53.5703C47.9422 54.2568 50.8088 56.4132 54.0083 57.6683C55.9515 58.4333 58.0133 58.8557 60.0596 59.2712C62.2479 59.7164 64.7361 60.1098 66.5401 58.7969C67.3762 58.185 67.9507 57.2801 68.7916 56.674C70.7683 55.2382 73.528 55.8774 75.8274 56.7301C82.1913 59.1029 88.0961 62.6866 93.1303 67.2436C95.7754 69.6416 98.3135 72.5978 98.5627 76.1609C98.6017 76.7063 98.5749 77.2668 98.3715 77.7675C97.6344 79.5311 95.2377 79.6398 93.5208 80.4891C89.9748 82.2415 89.596 86.9888 88.5034 90.7883C86.1433 98.982 78.7713 104.686 71.2994 108.797C68.6217 110.274 65.1763 111.59 62.6801 109.823C61.0528 108.67 60.4536 106.581 59.8912 104.666C57.2625 95.6467 53.1202 86.8099 46.2698 80.3846C39.4193 73.9594 29.482 70.3172 20.3963 72.6875C16.9151 73.5934 13.3903 75.3478 9.88831 74.5089C8.72965 74.2318 7.64095 73.6719 6.45365 73.5262C2.70043 73.0708 0.795168 76.3958 -1.89525 78.1174C-4.74817 73.8548 4.94104 68.3139 7.67444 66.7037Z" fill="#FF530A"/>
      <path d="M36.0648 57.387C40.5341 56.0178 45.6767 54.7291 49.6263 57.225C52.7711 59.2203 54.6984 63.4051 58.3995 63.8448C61.5866 64.2193 64.2156 61.5694 67.0702 60.0942C69.8463 58.6543 73.1615 58.3063 76.1726 59.1197C82.0422 60.716 85.8948 66.1896 89.0487 71.398C90.1097 73.1458 91.1769 74.996 91.3027 77.0459C91.3326 77.4616 91.3064 77.9042 91.0795 78.2579C90.4184 79.2833 88.8696 78.7116 87.6598 78.6335C85.4478 78.4917 83.6106 80.4879 82.9213 82.5938C82.2367 84.7056 82.3518 86.9797 81.9974 89.1713C81.6489 91.3581 80.6327 93.6848 78.592 94.5569C76.6965 95.3677 74.4369 94.6583 72.852 93.3431C71.2671 92.0278 70.2133 90.2057 69.1544 88.4366C64.891 81.3149 59.8574 74.3395 52.7865 69.9953C46.8395 66.3485 39.6357 64.7964 32.7203 65.6689C29.219 66.1117 25.8107 67.1532 22.3177 67.6773C20.6201 67.9333 18.6981 68.0122 17.4003 66.8907C13.4847 63.5347 20.841 62.034 22.5815 61.4979C27.0875 60.1376 31.5732 58.7647 36.0648 57.387Z" fill="#FF530A"/>
      <g filter="url(#filter0_d_3053_398)">
        <path d="M41.8254 134.549C39.1548 138.026 39.6009 143.512 42.8 146.51C38.0812 149.142 35.7957 155.434 37.7315 160.483C38.4707 162.398 39.8543 164.193 41.8186 164.794C43.777 165.399 46.2718 164.378 46.7519 162.386C46.0201 164.879 47.7568 167.635 50.1347 168.678C52.5125 169.72 55.3136 169.366 57.7413 168.429C62.0775 166.768 65.6809 163.295 67.5045 159.029C67.1544 161.569 69.4401 163.912 71.9589 164.378C74.4777 164.844 77.0545 163.862 79.2452 162.543C82.5856 160.53 85.6046 157.166 85.3811 153.279C92.5686 155.618 100.909 154.029 106.743 149.213C109.043 147.315 110.885 143.533 108.576 141.65C110.677 141.1 112.789 140.551 114.89 140.002C118.388 139.092 122.203 137.95 124.166 134.92C126.124 131.884 124.339 126.603 120.74 126.891C123.794 126.583 126.902 126.102 129.624 124.668C132.335 123.233 134.623 120.668 134.985 117.619C135.346 114.57 133.291 111.215 130.255 110.775C133.163 110.871 136.071 110.966 138.984 111.068C142.748 111.192 146.984 111.141 149.626 108.46C151.164 106.907 151.871 104.669 151.925 102.481C152.085 95.3102 144.427 89.0813 137.435 90.7025C140.279 88.8831 141.655 85.0462 140.61 81.836C139.566 78.6258 136.203 76.3308 132.826 76.5335C130.89 69.8113 122.913 65.5199 116.236 67.595C109.554 69.675 105.427 77.7319 107.651 84.3636C102.615 84.4184 97.7217 87.4791 95.4832 91.9896C93.2447 96.5 93.7514 102.249 96.7484 106.295C92.7274 107.294 88.6723 108.987 86.0638 112.207C83.4553 115.427 82.7745 120.473 85.4304 123.655C81.0937 121.674 75.9947 120.656 71.5804 122.459C67.1661 124.263 64.0613 129.645 66.0042 134.003C65.8897 131.611 64.0502 129.536 61.837 128.622C60.0001 127.867 57.7727 127.719 55.8318 128.107C53.623 128.55 53.0409 130.039 51.4699 130.709C48.1244 132.159 44.5445 131.012 41.8254 134.549Z" fill="#FFE6DE"/>
        <path d="M59.2731 132.383C55.9588 132.946 53.7025 136.913 54.9145 140.054C53.7103 138.571 51.9413 137.554 50.049 137.266C49.0821 137.115 48.064 137.162 47.1642 137.543C44.9347 138.483 43.9854 141.4 44.7915 143.681C45.5977 145.963 47.7893 147.55 50.1266 148.176C47.1401 148.732 44.2209 150.378 42.7797 153.056C41.3386 155.734 41.7484 159.468 44.1563 161.317C46.5642 163.167 50.7717 162.181 51.6398 159.271C51.6157 160.701 52.6875 161.999 54.0093 162.533C55.331 163.067 56.8444 162.944 58.2103 162.511C60.19 161.885 62.0115 160.558 62.9144 158.687C63.8172 156.816 63.6681 154.394 62.2882 152.841C62.9418 155.708 61.5107 159.121 58.7176 160.066C55.9303 161.006 52.3935 158.512 52.8855 155.61C51.5221 157.362 49.7325 159.309 47.53 159.012C45.6501 158.763 44.3218 156.727 44.5452 154.839C44.7676 152.962 46.2799 151.39 48.0683 150.746C49.8508 150.107 51.8499 150.287 53.649 150.878C52.1953 149.816 50.708 148.714 49.7601 147.186C48.8064 145.663 48.5009 143.586 49.4968 142.088C50.3739 140.767 52.1098 140.166 53.6795 140.407C55.2491 140.649 56.6365 141.618 57.6458 142.841C56.0589 140.705 56.769 137.26 59.0584 135.918C61.4331 134.526 64.1359 135.76 65.7451 137.662C66.1598 134.56 62.1733 131.889 59.2731 132.383Z" fill="#FFE6DE"/>
        <path d="M83.8395 148.708C87.1805 151.118 91.6161 151.784 95.6414 150.908C98.2104 150.344 100.707 149.135 102.401 147.12C104.096 145.105 104.849 142.211 103.926 139.753C106.931 139.563 109.972 139.226 112.765 138.115C115.558 137.004 118.122 135.034 119.396 132.308C120.67 129.583 120.423 126.057 118.353 123.87C116.283 121.682 112.353 121.392 110.303 123.593C111.865 120.209 109.76 115.76 106.241 114.534C102.717 113.312 98.5047 115.242 96.8188 118.572C98.5081 115.542 103.305 115.293 105.774 117.739C108.238 120.178 108.375 124.353 106.696 127.383C109.364 125.67 112.881 123.942 115.486 125.757C117.673 127.28 117.775 130.7 116.308 132.919C114.841 135.139 112.263 136.356 109.72 137.153C107.155 137.949 104.193 138.368 101.909 136.954C103.655 140.907 101.865 145.937 98.2403 148.293C94.6151 150.649 89.6246 150.403 85.9621 148.101C82.6843 146.039 80.9652 142.818 79.6708 139.305C76.6361 140.87 82.1989 147.526 83.8395 148.708Z" fill="#FFE6DE"/>
        <path d="M98.4668 97.4076C98.459 94.7416 99.7776 92.2239 101.511 90.1963C102.99 88.4662 104.881 86.9739 107.111 86.5328C109.341 86.0918 111.929 86.9507 113.004 88.9518C112.509 86.3251 112.863 83.4684 114.337 81.2337C115.811 78.999 118.495 77.5074 121.154 77.7992C123.818 78.0968 126.215 80.396 126.297 83.0691C126.856 81.267 129.421 81.0779 131.144 81.8486C133.385 82.8566 135.154 84.9992 135.403 87.4469C135.651 89.8946 134.191 92.4899 131.859 93.2697C134.624 92.3972 137.93 93.8287 139.187 96.4425C140.444 99.0564 139.5 102.527 137.098 104.147C134.697 105.768 131.119 105.324 129.169 103.175C131.209 103.595 133.448 103.793 135.272 102.777C137.096 101.761 138.105 99.117 136.779 97.51C134.919 95.2569 131.134 97.0884 128.397 96.049C130.234 94.7285 131.9 93.0108 132.573 90.8497C133.246 88.6886 132.674 86.0383 130.795 84.77C128.92 83.5076 125.828 84.3274 125.27 86.5209C126.482 83.8858 124.319 80.4828 121.449 80.0422C118.579 79.6015 115.69 81.7224 114.777 84.4826C113.859 87.2371 114.681 90.367 116.404 92.7036C114.366 91.3022 112.08 90.0861 109.606 89.9354C106.44 89.7399 103.233 91.4764 101.661 94.2327C99.928 97.2685 101.645 99.7868 101.027 102.656C99.3297 101.619 98.4694 99.3438 98.4668 97.4076Z" fill="#FFE6DE"/>
        <path d="M71.3242 143.364C71.5439 142.077 72.5578 140.838 73.8583 140.753C72.7674 140.048 72.4552 138.603 72.3375 137.31C72.0794 134.4 72.4455 131.132 74.6016 129.166C76.1519 127.759 78.4807 127.295 80.4601 127.962C82.4386 128.639 83.9962 130.408 84.4333 132.455C86.1913 129.604 90.8561 129.219 93.0598 131.746C95.2635 134.273 94.2476 138.842 91.18 140.19C92.6026 138.996 93.101 136.812 92.3239 135.124C91.5469 133.435 89.5547 132.398 87.731 132.738C85.9074 133.079 84.4157 134.77 84.3239 136.627C83.9475 134.843 83.0875 133.125 81.6981 131.952C80.3088 130.779 78.3579 130.206 76.607 130.682C75.9683 130.857 75.354 131.169 74.8945 131.656C74.4292 132.147 74.143 132.785 73.9476 133.426C73.018 136.474 74.0405 140.025 76.4464 142.122C75.5895 141.38 73.1769 145.3 73.118 145.917C72.9879 147.277 74.0541 148.521 74.2135 149.77C72.2347 148.76 70.9555 145.538 71.3242 143.364Z" fill="#FFE6DE"/>
      </g>
      <path d="M-13.5978 106.729C-13.7096 109.144 -11.8606 111.5 -9.49106 111.957C-11.4651 113.81 -10.5695 117.153 -9.01457 119.371C-7.78962 121.119 -5.99038 122.743 -3.86045 122.697C-1.73052 122.651 0.185602 120.013 -1.10552 118.318C-1.24041 119.73 0.172318 120.915 1.58791 121.06C3.00351 121.205 4.37939 120.615 5.67773 120.03C7.56001 119.176 9.56414 118.204 10.5667 116.398C11.5693 114.592 10.8895 111.749 8.85653 111.374C11.4577 112.031 14.3205 111.986 16.7129 110.754C19.0953 109.526 20.8855 106.979 20.757 104.294C20.6286 101.609 18.272 99.0909 15.5866 99.2025C18.9863 98.258 21.0539 93.941 19.4443 90.8055C17.8348 87.6699 12.821 86.9683 10.5828 89.689C11.897 86.1282 10.2234 81.721 6.87267 79.9217C3.31996 78.0196 -1.19449 79.0847 -4.57076 81.2985C-7.45244 83.185 -9.88449 85.9817 -10.6093 89.3513C-11.3309 92.714 -10.005 96.617 -6.99217 98.2834C-8.62717 97.3776 -9.27033 101.535 -9.59437 101.926C-11.0927 103.759 -13.4451 103.568 -13.5978 106.729Z" fill="#FFE6DE"/>
      <path d="M-10.6589 107.802C-10.7706 110.216 -8.92165 112.572 -6.55211 113.029C-8.52617 114.882 -7.6305 118.225 -6.07561 120.444C-4.85066 122.191 -3.05142 123.815 -0.921492 123.769C1.20844 123.723 3.12457 121.085 1.83345 119.39C1.69856 120.802 3.11129 121.987 4.52688 122.132C5.94248 122.277 7.31836 121.687 8.6167 121.102C10.499 120.248 12.5031 119.277 13.5057 117.47C14.5083 115.664 13.8285 112.821 11.7955 112.446C14.3966 113.103 17.2595 113.058 19.6519 111.826C22.0443 110.594 23.8244 108.051 23.696 105.366C23.5675 102.681 21.211 100.163 18.5255 100.275C21.9252 99.3303 23.9928 95.0133 22.3833 91.8777C20.7737 88.7422 15.76 88.0406 13.5218 90.7612C14.836 87.2004 13.1623 82.7933 9.81162 80.9939C6.25891 79.0919 1.74447 80.157 -1.63179 82.3707C-4.51348 84.2573 -6.94554 87.054 -7.67034 90.4235C-8.39195 93.7863 -7.06606 97.6893 -4.05322 99.3557C-5.68821 98.4499 -6.33137 102.608 -6.65541 102.998C-8.16061 104.828 -10.513 104.637 -10.6589 107.802Z" fill="#FFE6DE"/>
      <path d="M4.90565 89.0207C6.26632 89.5149 7.36168 90.5768 8.15366 91.7886C9.19217 93.3823 9.77163 95.3698 9.273 97.2034C10.8295 95.0142 13.4819 93.6539 16.171 93.6773C17.1575 93.6883 18.2515 93.9329 18.8033 94.7492C19.239 95.3861 19.2412 96.2372 19.0515 96.9819C18.5359 99.0659 16.5902 100.715 14.4502 100.89C16.2058 101.377 17.8198 102.381 19.0281 103.754C19.7418 104.563 20.3332 105.598 20.1621 106.659C19.9849 107.751 19.024 108.577 17.9761 108.929C16.9315 109.274 15.7954 109.227 14.6931 109.178C13.9493 109.147 13.1849 109.107 12.5014 108.804C11.8179 108.501 11.2372 107.871 11.2075 107.133C12.5655 107.65 14.0315 108.169 15.4441 107.821C16.8568 107.473 18.0399 105.851 17.3707 104.555C17.0395 103.908 16.3574 103.531 15.6976 103.231C14.3032 102.613 12.8098 102.206 11.2921 102.03C12.7396 101.34 14.1083 100.48 15.3618 99.4741C15.8445 99.083 16.3296 98.6514 16.5744 98.0741C16.8192 97.4969 16.7521 96.7488 16.2657 96.3632C15.7378 95.9415 14.9646 96.0801 14.3324 96.3096C11.5214 97.2959 9.24259 99.3725 7.0568 101.393C7.37784 99.8677 7.70482 98.3122 7.5926 96.7514C7.26687 92.0992 4.38062 93.1753 1.63473 91.2586C-1.01816 89.4104 2.68909 88.2181 4.90565 89.0207Z" fill="#FFE6DE"/>
      <defs>
        <filter id="filter0_d_3053_398" x="33.2209" y="65.1576" width="122.528" height="109.887" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dy="1.91081"/>
          <feGaussianBlur stdDeviation="1.91081"/>
          <feComposite in2="hardAlpha" operator="out"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3053_398"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3053_398" result="shape"/>
        </filter>
      </defs>
    </g>
  )
}

export const HeadBlock8 = () => {
  return (
    <g>
      <path d="M199.254 143.655C198.794 142.593 198.312 141.478 197.694 140.508C198.234 141.36 197.616 140.398 197.516 140.234C197.388 140.024 197.253 139.807 197.118 139.605C196.8 139.114 196.475 138.637 196.133 138.169C195.454 137.231 194.721 136.338 193.947 135.49C193.782 135.309 193.612 135.13 193.441 134.95C193.182 134.681 193.216 134.713 193.536 135.046C193.421 134.931 193.305 134.816 193.19 134.701C192.776 134.306 192.357 133.924 191.926 133.551C190.606 132.405 190.074 130.206 191.185 128.693C192.214 127.282 194.27 126.716 195.678 127.945C199.706 131.44 202.942 135.845 205.134 140.896C205.85 142.549 205.107 144.809 203.507 145.466C201.857 146.148 200.022 145.434 199.254 143.662L199.254 143.655Z" fill="black"/>
      <path d="M201.289 179.426C201.289 179.426 230.671 149.274 227.962 126.246L234.308 138.125L243.035 132.264C243.035 132.264 226.605 176.341 201.289 179.426Z" fill="#FF530A"/>
      <path d="M216.702 160.71C215.935 151.036 212.935 141.727 207.486 133.396C186.168 100.786 135.51 95.4821 94.3378 121.548C61.3776 142.414 50.0518 176.564 50.0002 206.773C49.8902 270.759 96.3795 305 151.988 305V249.446C158.632 246.906 165.194 243.642 171.529 239.63C197.859 222.961 213.938 197.501 216.527 172.782C216.953 168.723 217.018 164.688 216.698 160.71H216.702Z" fill="#EFB825"/>
      <path d="M117.533 171.228C117.66 171.302 117.787 171.367 117.918 171.438C118.079 171.522 118.244 171.61 118.406 171.691C118.557 171.769 118.712 171.844 118.859 171.918C131.227 177.902 146.055 173.782 153.5 162.664C154.033 161.869 154.528 161.037 154.982 160.167C161.85 147.043 156.608 130.926 143.274 124.171C129.938 117.413 113.559 122.571 106.691 135.695C106.238 136.564 105.836 137.447 105.485 138.34C100.673 150.615 105.701 164.696 117.529 171.228H117.533Z" fill="#FFE6DE"/>
      <path d="M117.983 172.425C118.096 172.492 118.217 172.553 118.337 172.618C118.485 172.695 118.632 172.773 118.784 172.848C118.921 172.919 119.062 172.986 119.2 173.054C130.484 178.513 144.013 174.755 150.806 164.608C154.202 155.255 148.413 143.697 137.049 137.94C125.686 132.18 112.758 134.257 106.997 142.415C102.604 153.615 107.193 166.461 117.986 172.421L117.983 172.425Z" fill="black"/>
      <path d="M111.225 222.625C120.81 222.625 128.58 214.979 128.58 205.547C128.58 196.115 120.81 188.469 111.225 188.469C101.64 188.469 93.8701 196.115 93.8701 205.547C93.8701 214.979 101.64 222.625 111.225 222.625Z" fill="#FF530A"/>
      <path d="M134.609 188.992C139.349 193.278 141.535 199.806 140.384 206.026C140.112 207.498 138.418 208.59 136.94 208.208C135.379 207.805 134.434 206.388 134.723 204.819C134.778 204.524 134.826 204.227 134.867 203.929C134.888 203.78 134.901 203.631 134.918 203.483C134.894 203.716 134.894 203.848 134.905 203.537C134.925 202.897 134.929 202.265 134.877 201.629C134.853 201.331 134.822 201.03 134.781 200.736C134.771 200.672 134.702 200.178 134.757 200.543C134.815 200.908 134.729 200.421 134.716 200.354C134.585 199.728 134.417 199.112 134.21 198.507C134.114 198.223 134.008 197.942 133.894 197.661C133.839 197.523 133.777 197.384 133.719 197.245C133.564 196.877 133.76 197.164 133.764 197.323C133.764 197.107 133.451 196.714 133.344 196.525C133.196 196.264 133.042 196.004 132.877 195.75C132.53 195.209 132.141 194.705 131.739 194.204C131.478 193.876 132.096 194.57 131.794 194.275C131.687 194.171 131.588 194.056 131.481 193.947C131.244 193.704 130.997 193.47 130.742 193.24C129.628 192.232 129.412 190.325 130.515 189.215C131.601 188.123 133.413 187.913 134.606 188.992H134.609Z" fill="black"/>
      <path d="M136.637 201.725C143.993 203.599 151.682 203.92 159.206 202.963C173.223 201.187 187.168 195.779 199.085 188.368C205.795 184.197 211.944 178.876 216.526 172.534C216.918 168.472 216.952 164.43 216.609 160.445C211.284 174.486 197.573 183.839 183.937 189.87C169.583 196.218 153.442 200.125 137.864 196.154C134.244 195.231 133.028 200.805 136.633 201.725H136.637Z" fill="black"/>
      <path d="M69.1068 47.5466C69.1068 47.5466 50.6776 46.0097 39.6195 50.3311C28.5663 54.6584 71.486 58.671 69.1068 47.5466Z" fill="#FF530A"/>
      <path d="M-6.4509 102.877C-6.4509 102.877 -27.3792 75.092 20.0382 54.0514C20.0382 54.0514 36.2753 47.8924 56.2481 51.1213C76.2209 54.3503 59.0587 44.7779 75.2487 48.3101C91.4386 51.8423 124.558 81.2325 124.558 81.2325C124.558 81.2325 101.178 136.742 52.0604 147.919C52.0604 147.919 43.3006 139.477 42.5953 131.543C41.8899 123.608 39.3891 123.235 35.627 120.741C31.8649 118.247 34.2193 113.142 35.5293 110.602C36.8394 108.062 35.839 105.96 31.95 104.795C28.0619 103.619 29.5605 96.3025 29.5605 96.3025C29.5605 96.3025 27.2813 100.621 22.6703 94.4425C18.0642 88.2694 23.0807 88.749 18.0642 88.2694C13.0477 87.7897 21.9151 92.6381 9.36087 103.97C-3.19338 115.302 -6.4509 102.877 -6.4509 102.877Z" fill="#FF530A"/>
      <path opacity="0.53" d="M124.553 81.237C124.553 81.237 101.173 136.746 52.0552 147.923C52.0552 147.923 43.2954 139.482 42.5901 131.547C41.8848 123.613 39.3839 123.24 35.6219 120.745C31.8598 118.251 34.2141 113.146 35.5242 110.606C36.8342 108.066 35.8339 105.965 31.9448 104.799C28.0567 103.623 29.5553 96.307 29.5553 96.307C29.5553 96.307 27.2761 100.626 22.6652 94.447C18.059 88.2739 23.0755 88.7535 18.059 88.2739C13.0425 87.7942 21.91 92.6426 9.35572 103.975C-3.19852 115.307 -6.45607 102.881 -6.45607 102.881C-3.50278 104.692 0.579561 103.388 3.69909 101.889C6.26254 100.654 8.84078 99.1528 10.358 96.7452C12.071 94.0292 12.172 90.6175 13.3989 87.6567C14.6258 84.6958 18.091 82.0509 20.8781 83.6366C23.0752 84.887 23.5282 88.0568 25.6863 89.3786C28.2396 90.9419 31.6275 89.1047 34.5111 89.9059C36.6001 90.4918 38.076 92.3865 38.9425 94.3731C39.809 96.3597 40.2242 98.523 41.052 100.522C41.7154 102.108 42.6403 103.597 43.1208 105.246C43.8277 107.668 43.5675 110.388 44.8343 112.563C46.4596 115.363 50.3102 116.707 51.0166 119.863C51.5175 122.083 50.3074 124.756 51.7715 126.494C52.8789 127.812 54.8791 127.756 56.5888 127.598C57.1894 127.543 57.8015 127.478 58.324 127.174C60.4312 125.944 59.0185 122.714 59.4357 120.314C59.8863 117.789 62.5526 116.263 65.0748 115.797C67.6018 115.336 70.2444 115.572 72.6963 114.831C76.031 113.83 78.5846 111.184 80.829 108.519C87.1821 100.964 92.6791 92.1 101.483 87.6328C107.276 84.701 113.906 84.005 120.345 83.1834C121.604 83.025 124.482 82.4797 124.553 81.237Z" fill="#FF530A"/>
      <path d="M7.67444 66.7037C16.0171 61.7848 24.919 57.8042 34.1494 54.8739C37.5231 53.8022 41.104 52.863 44.5757 53.5703C47.9422 54.2568 50.8088 56.4132 54.0083 57.6683C55.9515 58.4333 58.0133 58.8557 60.0596 59.2712C62.2479 59.7164 64.7361 60.1098 66.5401 58.7969C67.3762 58.185 67.9507 57.2801 68.7916 56.674C70.7683 55.2382 73.528 55.8774 75.8274 56.7301C82.1913 59.1029 88.0961 62.6866 93.1303 67.2436C95.7754 69.6416 98.3135 72.5978 98.5627 76.1609C98.6017 76.7063 98.5749 77.2668 98.3715 77.7675C97.6344 79.5311 95.2377 79.6398 93.5208 80.4891C89.9748 82.2415 89.596 86.9888 88.5034 90.7883C86.1433 98.982 78.7713 104.686 71.2994 108.797C68.6217 110.274 65.1763 111.59 62.6801 109.823C61.0528 108.67 60.4536 106.581 59.8912 104.666C57.2625 95.6467 53.1202 86.8099 46.2698 80.3846C39.4193 73.9594 29.482 70.3172 20.3963 72.6875C16.9151 73.5934 13.3903 75.3478 9.88831 74.5089C8.72965 74.2318 7.64095 73.6719 6.45365 73.5262C2.70043 73.0708 0.795168 76.3958 -1.89525 78.1174C-4.74817 73.8548 4.94104 68.3139 7.67444 66.7037Z" fill="#FF530A"/>
      <path d="M36.0648 57.387C40.5341 56.0178 45.6767 54.7291 49.6263 57.225C52.7711 59.2203 54.6984 63.4051 58.3995 63.8448C61.5866 64.2193 64.2156 61.5694 67.0702 60.0942C69.8463 58.6543 73.1615 58.3063 76.1726 59.1197C82.0422 60.716 85.8948 66.1896 89.0487 71.398C90.1097 73.1458 91.1769 74.996 91.3027 77.0459C91.3326 77.4616 91.3064 77.9042 91.0795 78.2579C90.4184 79.2833 88.8696 78.7116 87.6598 78.6335C85.4478 78.4917 83.6106 80.4879 82.9213 82.5938C82.2367 84.7056 82.3518 86.9797 81.9974 89.1713C81.6489 91.3581 80.6327 93.6848 78.592 94.5569C76.6965 95.3677 74.4369 94.6583 72.852 93.3431C71.2671 92.0278 70.2133 90.2057 69.1544 88.4366C64.891 81.3149 59.8574 74.3395 52.7865 69.9953C46.8395 66.3485 39.6357 64.7964 32.7203 65.6689C29.219 66.1117 25.8107 67.1532 22.3177 67.6773C20.6201 67.9333 18.6981 68.0122 17.4003 66.8907C13.4847 63.5347 20.841 62.034 22.5815 61.4979C27.0875 60.1376 31.5732 58.7647 36.0648 57.387Z" fill="#FF530A"/>
      <g filter="url(#filter0_d_3053_688)">
        <path d="M41.8254 134.549C39.1548 138.026 39.6009 143.512 42.8 146.51C38.0812 149.142 35.7957 155.434 37.7315 160.483C38.4707 162.398 39.8543 164.193 41.8186 164.794C43.777 165.399 46.2718 164.378 46.7519 162.386C46.0201 164.879 47.7568 167.635 50.1347 168.678C52.5125 169.72 55.3136 169.366 57.7413 168.429C62.0775 166.768 65.6809 163.295 67.5045 159.029C67.1544 161.569 69.4401 163.912 71.9589 164.378C74.4777 164.844 77.0545 163.862 79.2452 162.543C82.5856 160.53 85.6046 157.166 85.3811 153.279C92.5686 155.618 100.909 154.029 106.743 149.213C109.043 147.315 110.885 143.533 108.576 141.65C110.677 141.1 112.789 140.551 114.89 140.002C118.388 139.092 122.203 137.95 124.166 134.92C126.124 131.884 124.339 126.603 120.74 126.891C123.794 126.583 126.902 126.102 129.624 124.668C132.335 123.233 134.623 120.668 134.985 117.619C135.346 114.57 133.291 111.215 130.255 110.775C133.163 110.871 136.071 110.966 138.984 111.068C142.748 111.192 146.984 111.141 149.626 108.46C151.164 106.907 151.871 104.669 151.925 102.481C152.085 95.3102 144.427 89.0813 137.435 90.7025C140.279 88.8831 141.655 85.0462 140.61 81.836C139.566 78.6258 136.203 76.3308 132.826 76.5335C130.89 69.8113 122.913 65.5199 116.236 67.595C109.554 69.675 105.427 77.7319 107.651 84.3636C102.615 84.4184 97.7217 87.4791 95.4832 91.9896C93.2447 96.5 93.7514 102.249 96.7484 106.295C92.7274 107.294 88.6723 108.987 86.0638 112.207C83.4553 115.427 82.7745 120.473 85.4304 123.655C81.0937 121.674 75.9947 120.656 71.5804 122.459C67.1661 124.263 64.0613 129.645 66.0042 134.003C65.8897 131.611 64.0502 129.536 61.837 128.622C60.0001 127.867 57.7727 127.719 55.8318 128.107C53.623 128.55 53.0409 130.039 51.4699 130.709C48.1244 132.159 44.5445 131.012 41.8254 134.549Z" fill="#FFE6DE"/>
        <path d="M59.2731 132.383C55.9588 132.946 53.7025 136.913 54.9145 140.054C53.7103 138.571 51.9413 137.554 50.049 137.266C49.0821 137.115 48.064 137.162 47.1642 137.543C44.9347 138.483 43.9854 141.4 44.7915 143.681C45.5977 145.963 47.7893 147.55 50.1266 148.176C47.1401 148.732 44.2209 150.378 42.7797 153.056C41.3386 155.734 41.7484 159.468 44.1563 161.317C46.5642 163.167 50.7717 162.181 51.6398 159.271C51.6157 160.701 52.6875 161.999 54.0093 162.533C55.331 163.067 56.8444 162.944 58.2103 162.511C60.19 161.885 62.0115 160.558 62.9144 158.687C63.8172 156.816 63.6681 154.394 62.2882 152.841C62.9418 155.708 61.5107 159.121 58.7176 160.066C55.9303 161.006 52.3935 158.512 52.8855 155.61C51.5221 157.362 49.7325 159.309 47.53 159.012C45.6501 158.763 44.3218 156.727 44.5452 154.839C44.7676 152.962 46.2799 151.39 48.0683 150.746C49.8508 150.107 51.8499 150.287 53.649 150.878C52.1953 149.816 50.708 148.714 49.7601 147.186C48.8064 145.663 48.5009 143.586 49.4968 142.088C50.3739 140.767 52.1098 140.166 53.6795 140.407C55.2491 140.649 56.6365 141.618 57.6458 142.841C56.0589 140.705 56.769 137.26 59.0584 135.918C61.4331 134.526 64.1359 135.76 65.7451 137.662C66.1598 134.56 62.1733 131.889 59.2731 132.383Z" fill="#FFE6DE"/>
        <path d="M83.8395 148.708C87.1805 151.118 91.6161 151.784 95.6414 150.908C98.2104 150.344 100.707 149.135 102.401 147.12C104.096 145.105 104.849 142.211 103.926 139.753C106.931 139.563 109.972 139.226 112.765 138.115C115.558 137.004 118.122 135.034 119.396 132.308C120.67 129.583 120.423 126.057 118.353 123.87C116.283 121.682 112.353 121.392 110.303 123.593C111.865 120.209 109.76 115.76 106.241 114.534C102.717 113.312 98.5047 115.242 96.8188 118.572C98.5081 115.542 103.305 115.293 105.774 117.739C108.238 120.178 108.375 124.353 106.696 127.383C109.364 125.67 112.881 123.942 115.486 125.757C117.673 127.28 117.775 130.7 116.308 132.919C114.841 135.139 112.263 136.356 109.72 137.153C107.155 137.949 104.193 138.368 101.909 136.954C103.655 140.907 101.865 145.937 98.2403 148.293C94.6151 150.649 89.6246 150.403 85.9621 148.101C82.6843 146.039 80.9652 142.818 79.6708 139.305C76.6361 140.87 82.1989 147.526 83.8395 148.708Z" fill="#FFE6DE"/>
        <path d="M98.4668 97.4076C98.459 94.7416 99.7776 92.2239 101.511 90.1963C102.99 88.4662 104.881 86.9739 107.111 86.5328C109.341 86.0918 111.929 86.9507 113.004 88.9518C112.509 86.3251 112.863 83.4684 114.337 81.2337C115.811 78.999 118.495 77.5074 121.154 77.7992C123.818 78.0968 126.215 80.396 126.297 83.0691C126.856 81.267 129.421 81.0779 131.144 81.8486C133.385 82.8566 135.154 84.9992 135.403 87.4469C135.651 89.8946 134.191 92.4899 131.859 93.2697C134.624 92.3972 137.93 93.8287 139.187 96.4425C140.444 99.0564 139.5 102.527 137.098 104.147C134.697 105.768 131.119 105.324 129.169 103.175C131.209 103.595 133.448 103.793 135.272 102.777C137.096 101.761 138.105 99.117 136.779 97.51C134.919 95.2569 131.134 97.0884 128.397 96.049C130.234 94.7285 131.9 93.0108 132.573 90.8497C133.246 88.6886 132.674 86.0383 130.795 84.77C128.92 83.5076 125.828 84.3274 125.27 86.5209C126.482 83.8858 124.319 80.4828 121.449 80.0422C118.579 79.6015 115.69 81.7224 114.777 84.4826C113.859 87.2371 114.681 90.367 116.404 92.7036C114.366 91.3022 112.08 90.0861 109.606 89.9354C106.44 89.7399 103.233 91.4764 101.661 94.2327C99.928 97.2685 101.645 99.7868 101.027 102.656C99.3297 101.619 98.4694 99.3438 98.4668 97.4076Z" fill="#FFE6DE"/>
        <path d="M71.3242 143.364C71.5439 142.077 72.5578 140.838 73.8583 140.753C72.7674 140.048 72.4552 138.603 72.3375 137.31C72.0794 134.4 72.4455 131.132 74.6016 129.166C76.1519 127.759 78.4807 127.295 80.4601 127.962C82.4386 128.639 83.9962 130.408 84.4333 132.455C86.1913 129.604 90.8561 129.219 93.0598 131.746C95.2635 134.273 94.2476 138.842 91.18 140.19C92.6026 138.996 93.101 136.812 92.3239 135.124C91.5469 133.435 89.5547 132.398 87.731 132.738C85.9074 133.079 84.4157 134.77 84.3239 136.627C83.9475 134.843 83.0875 133.125 81.6981 131.952C80.3088 130.779 78.3579 130.206 76.607 130.682C75.9683 130.857 75.354 131.169 74.8945 131.656C74.4292 132.147 74.143 132.785 73.9476 133.426C73.018 136.474 74.0405 140.025 76.4464 142.122C75.5895 141.38 73.1769 145.3 73.118 145.917C72.9879 147.277 74.0541 148.521 74.2135 149.77C72.2347 148.76 70.9555 145.538 71.3242 143.364Z" fill="#FFE6DE"/>
      </g>
      <path d="M-9.96133 114.234C-8.99223 116.448 -6.29113 117.741 -3.96406 117.102C-4.91359 119.637 -2.62976 122.238 -0.253217 123.539C1.61898 124.563 3.95147 125.222 5.84066 124.238C7.72985 123.253 8.2796 120.039 6.3713 119.091C6.87568 120.417 8.66722 120.854 10.0006 120.357C11.334 119.86 12.3061 118.721 13.2112 117.622C14.5208 116.023 15.8873 114.264 15.9862 112.201C16.0851 110.137 14.2166 107.889 12.2277 108.453C14.851 107.891 17.3977 106.582 18.9971 104.418C20.5891 102.262 21.0663 99.1858 19.762 96.8353C18.4577 94.4849 15.2297 93.2709 12.8714 94.5603C15.5013 92.2079 15.4431 87.4216 12.6114 85.3232C9.77961 83.2247 4.97364 84.8162 4.17183 88.2467C3.7731 84.4721 0.320763 81.2619 -3.48032 81.1326C-7.50799 81.0006 -11.0839 83.955 -13.1306 87.435C-14.8787 90.4027 -15.8207 93.9872 -14.9782 97.3293C-14.1359 100.664 -11.2186 103.576 -7.77935 103.736C-9.64642 103.648 -8.3817 107.66 -8.49941 108.154C-9.031 110.461 -11.2247 111.331 -9.96133 114.234Z" fill="#FFE6DE"/>
      <path d="M-6.85099 113.894C-5.88188 116.108 -3.18079 117.402 -0.853711 116.762C-1.80324 119.297 0.480593 121.899 2.85714 123.199C4.72933 124.223 7.06181 124.883 8.951 123.898C10.8402 122.913 11.3899 119.699 9.48165 118.751C9.98602 120.077 11.7776 120.514 13.111 120.017C14.4443 119.52 15.4165 118.382 16.3215 117.282C17.6311 115.683 18.9976 113.924 19.0965 111.861C19.1954 109.797 17.327 107.55 15.3381 108.114C17.9613 107.551 20.508 106.242 22.1074 104.078C23.7068 101.914 24.1767 98.846 22.8724 96.4955C21.5681 94.145 18.3401 92.9311 15.9817 94.2205C18.6116 91.868 18.5535 87.0818 15.7217 84.9833C12.89 82.8848 8.08398 84.4763 7.28217 87.9068C6.88345 84.1322 3.43111 80.9221 -0.36997 80.7928C-4.39764 80.6608 -7.97351 83.6151 -10.0202 87.0952C-11.7684 90.0628 -12.7103 93.6474 -11.8679 96.9895C-11.0256 100.324 -8.10828 103.236 -4.669 103.396C-6.53607 103.308 -5.27136 107.321 -5.38907 107.814C-5.9282 110.121 -8.12191 110.992 -6.85099 113.894Z" fill="#FFE6DE"/>
      <path d="M-1.21435 90.1618C0.224494 90.0023 1.67688 90.4693 2.92364 91.2051C4.56055 92.174 5.96028 93.6993 6.32529 95.5641C6.75125 92.912 8.52692 90.5176 10.9482 89.3477C11.8376 88.9207 12.9268 88.6555 13.7831 89.143C14.4558 89.5211 14.8347 90.2831 14.9944 91.0348C15.4551 93.1317 14.4409 95.4718 12.5996 96.5763C14.3894 96.2356 16.2813 96.4212 17.9727 97.1172C18.9707 97.526 19.9592 98.1918 20.276 99.2195C20.6009 100.277 20.105 101.443 19.3214 102.223C18.5377 102.995 17.498 103.455 16.4882 103.9C15.8076 104.202 15.1043 104.504 14.3574 104.535C13.6105 104.566 12.8109 104.259 12.4571 103.61C13.904 103.473 15.448 103.288 16.5604 102.351C17.6729 101.413 18.0152 99.4345 16.8412 98.5688C16.2579 98.1359 15.4794 98.0999 14.7549 98.1231C13.2309 98.1862 11.712 98.4833 10.2733 98.9977C11.2655 97.7379 12.1117 96.3603 12.79 94.9034C13.0496 94.339 13.2934 93.7372 13.2572 93.1112C13.221 92.4852 12.8296 91.8443 12.2227 91.7139C11.5626 91.5696 10.9308 92.0363 10.4656 92.5221C8.38216 94.6513 7.25866 97.5224 6.19358 100.302C5.80605 98.7922 5.41032 97.2527 4.61848 95.9031C2.26608 91.8762 0.154887 94.1193 -3.15587 93.6169C-6.35292 93.1348 -3.55713 90.4239 -1.21435 90.1618Z" fill="#FFE6DE"/>
      <defs>
        <filter id="filter0_d_3053_688" x="33.2209" y="65.1576" width="122.528" height="109.887" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dy="1.91081"/>
          <feGaussianBlur stdDeviation="1.91081"/>
          <feComposite in2="hardAlpha" operator="out"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3053_688"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3053_688" result="shape"/>
        </filter>
      </defs>
    </g>
  )
}

export const HeadBlock9 = () => {
  return (
    <g>
      <path d="M199.254 143.655C198.794 142.593 198.312 141.478 197.694 140.508C198.234 141.36 197.616 140.398 197.516 140.234C197.388 140.024 197.253 139.807 197.118 139.605C196.8 139.114 196.475 138.637 196.133 138.169C195.454 137.231 194.721 136.338 193.947 135.49C193.782 135.309 193.612 135.13 193.441 134.95C193.182 134.681 193.216 134.713 193.536 135.046C193.421 134.931 193.305 134.816 193.19 134.701C192.776 134.306 192.357 133.924 191.926 133.551C190.606 132.405 190.074 130.206 191.185 128.693C192.214 127.282 194.27 126.716 195.678 127.945C199.706 131.44 202.942 135.845 205.134 140.896C205.85 142.549 205.107 144.809 203.507 145.466C201.857 146.148 200.022 145.434 199.254 143.662L199.254 143.655Z" fill="black"/>
      <path d="M201.289 179.426C201.289 179.426 230.671 149.274 227.962 126.246L234.308 138.125L243.035 132.264C243.035 132.264 226.605 176.341 201.289 179.426Z" fill="#FF530A"/>
      <path d="M216.702 160.71C215.935 151.036 212.935 141.727 207.486 133.396C186.168 100.786 135.51 95.4821 94.3378 121.548C61.3776 142.414 50.0518 176.564 50.0002 206.773C49.8902 270.759 96.3795 305 151.988 305V249.446C158.632 246.906 165.194 243.642 171.529 239.63C197.859 222.961 213.938 197.501 216.527 172.782C216.953 168.723 217.018 164.688 216.698 160.71H216.702Z" fill="#EFB825"/>
      <path d="M117.533 171.228C117.66 171.302 117.787 171.367 117.918 171.438C118.079 171.522 118.244 171.61 118.406 171.691C118.557 171.769 118.712 171.844 118.859 171.918C131.227 177.902 146.055 173.782 153.5 162.664C154.033 161.869 154.528 161.037 154.982 160.167C161.85 147.043 156.608 130.926 143.274 124.171C129.938 117.413 113.559 122.571 106.691 135.695C106.238 136.564 105.836 137.447 105.485 138.34C100.673 150.615 105.701 164.696 117.529 171.228H117.533Z" fill="#FFE6DE"/>
      <path d="M117.983 172.425C118.096 172.492 118.217 172.553 118.337 172.618C118.485 172.695 118.632 172.773 118.784 172.848C118.921 172.919 119.062 172.986 119.2 173.054C130.484 178.513 144.013 174.755 150.806 164.608C154.202 155.255 148.413 143.697 137.049 137.94C125.686 132.18 112.758 134.257 106.997 142.415C102.604 153.615 107.193 166.461 117.986 172.421L117.983 172.425Z" fill="black"/>
      <path d="M111.225 222.625C120.81 222.625 128.58 214.979 128.58 205.547C128.58 196.115 120.81 188.469 111.225 188.469C101.64 188.469 93.8701 196.115 93.8701 205.547C93.8701 214.979 101.64 222.625 111.225 222.625Z" fill="#FF530A"/>
      <path d="M134.609 188.992C139.349 193.278 141.535 199.806 140.384 206.026C140.112 207.498 138.418 208.59 136.94 208.208C135.379 207.805 134.434 206.388 134.723 204.819C134.778 204.524 134.826 204.227 134.867 203.929C134.888 203.78 134.901 203.631 134.918 203.483C134.894 203.716 134.894 203.848 134.905 203.537C134.925 202.897 134.929 202.265 134.877 201.629C134.853 201.331 134.822 201.03 134.781 200.736C134.771 200.672 134.702 200.178 134.757 200.543C134.815 200.908 134.729 200.421 134.716 200.354C134.585 199.728 134.417 199.112 134.21 198.507C134.114 198.223 134.008 197.942 133.894 197.661C133.839 197.523 133.777 197.384 133.719 197.245C133.564 196.877 133.76 197.164 133.764 197.323C133.764 197.107 133.451 196.714 133.344 196.525C133.196 196.264 133.042 196.004 132.877 195.75C132.53 195.209 132.141 194.705 131.739 194.204C131.478 193.876 132.096 194.57 131.794 194.275C131.687 194.171 131.588 194.056 131.481 193.947C131.244 193.704 130.997 193.47 130.742 193.24C129.628 192.232 129.412 190.325 130.515 189.215C131.601 188.123 133.413 187.913 134.606 188.992H134.609Z" fill="black"/>
      <path d="M136.637 201.725C143.993 203.599 151.682 203.92 159.206 202.963C173.223 201.187 187.168 195.779 199.085 188.368C205.795 184.197 211.944 178.876 216.526 172.534C216.918 168.472 216.952 164.43 216.609 160.445C211.284 174.486 197.573 183.839 183.937 189.87C169.583 196.218 153.442 200.125 137.864 196.154C134.244 195.231 133.028 200.805 136.633 201.725H136.637Z" fill="black"/>
      <path d="M69.1063 47.5466C69.1063 47.5466 50.6771 46.0097 39.619 50.3311C28.5658 54.6584 71.4855 58.671 69.1063 47.5466Z" fill="#FF530A"/>
      <path d="M-6.4509 102.877C-6.4509 102.877 -27.3792 75.092 20.0382 54.0514C20.0382 54.0514 36.2753 47.8924 56.2481 51.1213C76.2209 54.3503 59.0587 44.7779 75.2487 48.3101C91.4386 51.8423 124.558 81.2325 124.558 81.2325C124.558 81.2325 101.178 136.742 52.0604 147.919C52.0604 147.919 43.3006 139.477 42.5953 131.543C41.8899 123.608 39.3891 123.235 35.627 120.741C31.8649 118.247 34.2193 113.142 35.5293 110.602C36.8394 108.062 35.839 105.96 31.95 104.795C28.0619 103.619 29.5605 96.3025 29.5605 96.3025C29.5605 96.3025 27.2813 100.621 22.6703 94.4425C18.0642 88.2694 23.0807 88.749 18.0642 88.2694C13.0477 87.7897 21.9151 92.6381 9.36087 103.97C-3.19338 115.302 -6.4509 102.877 -6.4509 102.877Z" fill="#FF530A"/>
      <path opacity="0.53" d="M124.553 81.237C124.553 81.237 101.173 136.746 52.0552 147.923C52.0552 147.923 43.2954 139.482 42.5901 131.547C41.8848 123.613 39.3839 123.24 35.6219 120.745C31.8598 118.251 34.2141 113.146 35.5242 110.606C36.8342 108.066 35.8339 105.965 31.9448 104.799C28.0567 103.623 29.5553 96.307 29.5553 96.307C29.5553 96.307 27.2761 100.626 22.6652 94.447C18.059 88.2739 23.0755 88.7535 18.059 88.2739C13.0425 87.7942 21.91 92.6426 9.35572 103.975C-3.19852 115.307 -6.45607 102.881 -6.45607 102.881C-3.50278 104.692 0.579561 103.388 3.69909 101.889C6.26254 100.654 8.84078 99.1528 10.358 96.7452C12.071 94.0292 12.172 90.6175 13.3989 87.6567C14.6258 84.6958 18.091 82.0509 20.8781 83.6366C23.0752 84.887 23.5282 88.0568 25.6863 89.3786C28.2396 90.9419 31.6275 89.1047 34.5111 89.9059C36.6001 90.4918 38.076 92.3865 38.9425 94.3731C39.809 96.3597 40.2242 98.523 41.052 100.522C41.7154 102.108 42.6403 103.597 43.1208 105.246C43.8277 107.668 43.5675 110.388 44.8343 112.563C46.4596 115.363 50.3102 116.707 51.0166 119.863C51.5175 122.083 50.3074 124.756 51.7715 126.494C52.8789 127.812 54.8791 127.756 56.5888 127.598C57.1894 127.543 57.8015 127.478 58.324 127.174C60.4312 125.944 59.0185 122.714 59.4357 120.314C59.8863 117.789 62.5526 116.263 65.0748 115.797C67.6018 115.336 70.2444 115.572 72.6963 114.831C76.031 113.83 78.5846 111.184 80.829 108.519C87.1821 100.964 92.6791 92.1 101.483 87.6328C107.276 84.701 113.906 84.005 120.345 83.1834C121.604 83.025 124.482 82.4797 124.553 81.237Z" fill="#FF530A"/>
      <path d="M7.67444 66.7037C16.0171 61.7848 24.919 57.8042 34.1494 54.8739C37.5231 53.8022 41.104 52.863 44.5757 53.5703C47.9422 54.2568 50.8088 56.4132 54.0083 57.6683C55.9515 58.4333 58.0133 58.8557 60.0596 59.2712C62.2479 59.7164 64.7361 60.1098 66.5401 58.7969C67.3762 58.185 67.9507 57.2801 68.7916 56.674C70.7683 55.2382 73.528 55.8774 75.8274 56.7301C82.1913 59.1029 88.0961 62.6866 93.1303 67.2436C95.7754 69.6416 98.3135 72.5978 98.5627 76.1609C98.6017 76.7063 98.5749 77.2668 98.3715 77.7675C97.6344 79.5311 95.2377 79.6398 93.5208 80.4891C89.9748 82.2415 89.596 86.9888 88.5034 90.7883C86.1433 98.982 78.7713 104.686 71.2994 108.797C68.6217 110.274 65.1763 111.59 62.6801 109.823C61.0528 108.67 60.4536 106.581 59.8912 104.666C57.2625 95.6467 53.1202 86.8099 46.2698 80.3846C39.4193 73.9594 29.482 70.3172 20.3963 72.6875C16.9151 73.5934 13.3903 75.3478 9.88831 74.5089C8.72965 74.2318 7.64095 73.6719 6.45365 73.5262C2.70043 73.0708 0.795168 76.3958 -1.89525 78.1174C-4.74817 73.8548 4.94104 68.3139 7.67444 66.7037Z" fill="#FF530A"/>
      <path d="M36.0648 57.387C40.5341 56.0178 45.6767 54.7291 49.6263 57.225C52.7711 59.2203 54.6984 63.4051 58.3995 63.8448C61.5866 64.2193 64.2156 61.5694 67.0702 60.0942C69.8463 58.6543 73.1615 58.3063 76.1726 59.1197C82.0422 60.716 85.8948 66.1896 89.0487 71.398C90.1097 73.1458 91.1769 74.996 91.3027 77.0459C91.3326 77.4616 91.3064 77.9042 91.0795 78.2579C90.4184 79.2833 88.8696 78.7116 87.6598 78.6335C85.4478 78.4917 83.6106 80.4879 82.9213 82.5938C82.2367 84.7056 82.3518 86.9797 81.9974 89.1713C81.6489 91.3581 80.6327 93.6848 78.592 94.5569C76.6965 95.3677 74.4369 94.6583 72.852 93.3431C71.2671 92.0278 70.2133 90.2057 69.1544 88.4366C64.891 81.3149 59.8574 74.3395 52.7865 69.9953C46.8395 66.3485 39.6357 64.7964 32.7203 65.6689C29.219 66.1117 25.8107 67.1532 22.3177 67.6773C20.6201 67.9333 18.6981 68.0122 17.4003 66.8907C13.4847 63.5347 20.841 62.034 22.5815 61.4979C27.0875 60.1376 31.5732 58.7647 36.0648 57.387Z" fill="#FF530A"/>
      <g filter="url(#filter0_d_3053_543)">
        <path d="M41.8254 134.549C39.1548 138.026 39.6009 143.512 42.8 146.51C38.0812 149.142 35.7957 155.434 37.7315 160.483C38.4707 162.398 39.8543 164.193 41.8186 164.794C43.777 165.399 46.2718 164.378 46.7519 162.386C46.0201 164.879 47.7568 167.635 50.1347 168.678C52.5125 169.72 55.3136 169.366 57.7413 168.429C62.0775 166.768 65.6809 163.295 67.5045 159.029C67.1544 161.569 69.4401 163.912 71.9589 164.378C74.4777 164.844 77.0545 163.862 79.2452 162.543C82.5856 160.53 85.6046 157.166 85.3811 153.279C92.5686 155.618 100.909 154.029 106.743 149.213C109.043 147.315 110.885 143.533 108.576 141.65C110.677 141.1 112.789 140.551 114.89 140.002C118.388 139.092 122.203 137.95 124.166 134.92C126.124 131.884 124.339 126.603 120.74 126.891C123.794 126.583 126.902 126.102 129.624 124.668C132.335 123.233 134.623 120.668 134.985 117.619C135.346 114.57 133.291 111.215 130.255 110.775C133.163 110.871 136.071 110.966 138.984 111.068C142.748 111.192 146.984 111.141 149.626 108.46C151.164 106.907 151.871 104.669 151.925 102.481C152.085 95.3102 144.427 89.0813 137.435 90.7025C140.279 88.8831 141.655 85.0462 140.61 81.836C139.566 78.6258 136.203 76.3308 132.826 76.5335C130.89 69.8113 122.913 65.5199 116.236 67.595C109.554 69.675 105.427 77.7319 107.651 84.3636C102.615 84.4184 97.7217 87.4791 95.4832 91.9896C93.2447 96.5 93.7514 102.249 96.7484 106.295C92.7274 107.294 88.6723 108.987 86.0638 112.207C83.4553 115.427 82.7745 120.473 85.4304 123.655C81.0937 121.674 75.9947 120.656 71.5804 122.459C67.1661 124.263 64.0613 129.645 66.0042 134.003C65.8897 131.611 64.0502 129.536 61.837 128.622C60.0001 127.867 57.7727 127.719 55.8318 128.107C53.623 128.55 53.0409 130.039 51.4699 130.709C48.1244 132.159 44.5445 131.012 41.8254 134.549Z" fill="#FFE6DE"/>
        <path d="M59.2731 132.383C55.9588 132.946 53.7025 136.913 54.9145 140.054C53.7103 138.571 51.9413 137.554 50.049 137.266C49.0821 137.115 48.064 137.162 47.1642 137.543C44.9347 138.483 43.9854 141.4 44.7915 143.681C45.5977 145.963 47.7893 147.55 50.1266 148.176C47.1401 148.732 44.2209 150.378 42.7797 153.056C41.3386 155.734 41.7484 159.468 44.1563 161.317C46.5642 163.167 50.7717 162.181 51.6398 159.271C51.6157 160.701 52.6875 161.999 54.0093 162.533C55.331 163.067 56.8444 162.944 58.2103 162.511C60.19 161.885 62.0115 160.558 62.9144 158.687C63.8172 156.816 63.6681 154.394 62.2882 152.841C62.9418 155.708 61.5107 159.121 58.7176 160.066C55.9303 161.006 52.3935 158.512 52.8855 155.61C51.5221 157.362 49.7325 159.309 47.53 159.012C45.6501 158.763 44.3218 156.727 44.5452 154.839C44.7676 152.962 46.2799 151.39 48.0683 150.746C49.8508 150.107 51.8499 150.287 53.649 150.878C52.1953 149.816 50.708 148.714 49.7601 147.186C48.8064 145.663 48.5009 143.586 49.4968 142.088C50.3739 140.767 52.1098 140.166 53.6795 140.407C55.2491 140.649 56.6365 141.618 57.6458 142.841C56.0589 140.705 56.769 137.26 59.0584 135.918C61.4331 134.526 64.1359 135.76 65.7451 137.662C66.1598 134.56 62.1733 131.889 59.2731 132.383Z" fill="#FFE6DE"/>
        <path d="M83.8395 148.708C87.1805 151.118 91.6161 151.784 95.6414 150.908C98.2104 150.344 100.707 149.135 102.401 147.12C104.096 145.105 104.849 142.211 103.926 139.753C106.931 139.563 109.972 139.226 112.765 138.115C115.558 137.004 118.122 135.034 119.396 132.308C120.67 129.583 120.423 126.057 118.353 123.87C116.283 121.682 112.353 121.392 110.303 123.593C111.865 120.209 109.76 115.76 106.241 114.534C102.717 113.312 98.5047 115.242 96.8188 118.572C98.5081 115.542 103.305 115.293 105.774 117.739C108.238 120.178 108.375 124.353 106.696 127.383C109.364 125.67 112.881 123.942 115.486 125.757C117.673 127.28 117.775 130.7 116.308 132.919C114.841 135.139 112.263 136.356 109.72 137.153C107.155 137.949 104.193 138.368 101.909 136.954C103.655 140.907 101.865 145.937 98.2403 148.293C94.6151 150.649 89.6246 150.403 85.9621 148.101C82.6843 146.039 80.9652 142.818 79.6708 139.305C76.6361 140.87 82.1989 147.526 83.8395 148.708Z" fill="#FFE6DE"/>
        <path d="M98.4668 97.4076C98.459 94.7416 99.7776 92.2239 101.511 90.1963C102.99 88.4662 104.881 86.9739 107.111 86.5328C109.341 86.0918 111.929 86.9507 113.004 88.9518C112.509 86.3251 112.863 83.4684 114.337 81.2337C115.811 78.999 118.495 77.5074 121.154 77.7992C123.818 78.0968 126.215 80.396 126.297 83.0691C126.856 81.267 129.421 81.0779 131.144 81.8486C133.385 82.8566 135.154 84.9992 135.403 87.4469C135.651 89.8946 134.191 92.4899 131.859 93.2697C134.624 92.3972 137.93 93.8287 139.187 96.4425C140.444 99.0564 139.5 102.527 137.098 104.147C134.697 105.768 131.119 105.324 129.169 103.175C131.209 103.595 133.448 103.793 135.272 102.777C137.096 101.761 138.105 99.117 136.779 97.51C134.919 95.2569 131.134 97.0884 128.397 96.049C130.234 94.7285 131.9 93.0108 132.573 90.8497C133.246 88.6886 132.674 86.0383 130.795 84.77C128.92 83.5076 125.828 84.3274 125.27 86.5209C126.482 83.8858 124.319 80.4828 121.449 80.0422C118.579 79.6015 115.69 81.7224 114.777 84.4826C113.859 87.2371 114.681 90.367 116.404 92.7036C114.366 91.3022 112.08 90.0861 109.606 89.9354C106.44 89.7399 103.233 91.4764 101.661 94.2327C99.928 97.2685 101.645 99.7868 101.027 102.656C99.3297 101.619 98.4694 99.3438 98.4668 97.4076Z" fill="#FFE6DE"/>
        <path d="M71.3242 143.364C71.5439 142.077 72.5578 140.838 73.8583 140.753C72.7674 140.048 72.4552 138.603 72.3375 137.31C72.0794 134.4 72.4455 131.132 74.6016 129.166C76.1519 127.759 78.4807 127.295 80.4601 127.962C82.4386 128.639 83.9962 130.408 84.4333 132.455C86.1913 129.604 90.8561 129.219 93.0598 131.746C95.2635 134.273 94.2476 138.842 91.18 140.19C92.6026 138.996 93.101 136.812 92.3239 135.124C91.5469 133.435 89.5547 132.398 87.731 132.738C85.9074 133.079 84.4157 134.77 84.3239 136.627C83.9475 134.843 83.0875 133.125 81.6981 131.952C80.3088 130.779 78.3579 130.206 76.607 130.682C75.9683 130.857 75.354 131.169 74.8945 131.656C74.4292 132.147 74.143 132.785 73.9476 133.426C73.018 136.474 74.0405 140.025 76.4464 142.122C75.5895 141.38 73.1769 145.3 73.118 145.917C72.9879 147.277 74.0541 148.521 74.2135 149.77C72.2347 148.76 70.9555 145.538 71.3242 143.364Z" fill="#FFE6DE"/>
      </g>
      <path d="M-13.5978 106.729C-13.7096 109.144 -11.8606 111.5 -9.49106 111.957C-11.4651 113.81 -10.5695 117.153 -9.01457 119.371C-7.78962 121.119 -5.99038 122.743 -3.86045 122.697C-1.73052 122.651 0.185602 120.013 -1.10552 118.318C-1.24041 119.73 0.172318 120.915 1.58791 121.06C3.00351 121.205 4.37939 120.615 5.67773 120.03C7.56001 119.176 9.56414 118.204 10.5667 116.398C11.5693 114.592 10.8895 111.749 8.85653 111.374C11.4577 112.031 14.3205 111.986 16.7129 110.754C19.0953 109.526 20.8855 106.979 20.757 104.294C20.6286 101.609 18.272 99.0909 15.5866 99.2025C18.9863 98.258 21.0539 93.941 19.4443 90.8055C17.8348 87.6699 12.821 86.9683 10.5828 89.689C11.897 86.1282 10.2234 81.721 6.87267 79.9217C3.31996 78.0196 -1.19449 79.0847 -4.57076 81.2985C-7.45244 83.185 -9.88449 85.9817 -10.6093 89.3513C-11.3309 92.714 -10.005 96.617 -6.99217 98.2834C-8.62717 97.3776 -9.27033 101.535 -9.59437 101.926C-11.0927 103.759 -13.4451 103.568 -13.5978 106.729Z" fill="#FFE6DE"/>
      <path d="M-10.6589 107.802C-10.7706 110.216 -8.92165 112.572 -6.55211 113.029C-8.52617 114.882 -7.6305 118.225 -6.07561 120.444C-4.85066 122.191 -3.05142 123.815 -0.921492 123.769C1.20844 123.723 3.12457 121.085 1.83345 119.39C1.69856 120.802 3.11129 121.987 4.52688 122.132C5.94248 122.277 7.31836 121.687 8.6167 121.102C10.499 120.248 12.5031 119.277 13.5057 117.47C14.5083 115.664 13.8285 112.821 11.7955 112.446C14.3966 113.103 17.2595 113.058 19.6519 111.826C22.0443 110.594 23.8244 108.051 23.696 105.366C23.5675 102.681 21.211 100.163 18.5255 100.275C21.9252 99.3303 23.9928 95.0133 22.3833 91.8777C20.7737 88.7422 15.76 88.0406 13.5218 90.7612C14.836 87.2004 13.1623 82.7933 9.81162 80.9939C6.25891 79.0919 1.74447 80.157 -1.63179 82.3707C-4.51348 84.2573 -6.94554 87.054 -7.67034 90.4235C-8.39195 93.7863 -7.06606 97.6893 -4.05322 99.3557C-5.68821 98.4499 -6.33137 102.608 -6.65541 102.998C-8.16061 104.828 -10.513 104.637 -10.6589 107.802Z" fill="#FFE6DE"/>
      <path d="M4.90565 89.0207C6.26632 89.5149 7.36168 90.5768 8.15366 91.7886C9.19217 93.3823 9.77163 95.3698 9.273 97.2034C10.8295 95.0142 13.4819 93.6539 16.171 93.6773C17.1575 93.6883 18.2515 93.9329 18.8033 94.7492C19.239 95.3861 19.2412 96.2372 19.0515 96.9819C18.5359 99.0659 16.5902 100.715 14.4502 100.89C16.2058 101.377 17.8198 102.381 19.0281 103.754C19.7418 104.563 20.3332 105.598 20.1621 106.659C19.9849 107.751 19.024 108.577 17.9761 108.929C16.9315 109.274 15.7954 109.227 14.6931 109.178C13.9493 109.147 13.1849 109.107 12.5014 108.804C11.8179 108.501 11.2372 107.871 11.2075 107.133C12.5655 107.65 14.0315 108.169 15.4441 107.821C16.8568 107.473 18.0399 105.851 17.3707 104.555C17.0395 103.908 16.3574 103.531 15.6976 103.231C14.3032 102.613 12.8098 102.206 11.2921 102.03C12.7396 101.34 14.1083 100.48 15.3618 99.4741C15.8445 99.083 16.3296 98.6514 16.5744 98.0741C16.8192 97.4969 16.7521 96.7488 16.2657 96.3632C15.7378 95.9415 14.9646 96.0801 14.3324 96.3096C11.5214 97.2959 9.24259 99.3725 7.0568 101.393C7.37784 99.8677 7.70482 98.3122 7.5926 96.7514C7.26687 92.0992 4.38062 93.1753 1.63473 91.2586C-1.01816 89.4104 2.68909 88.2181 4.90565 89.0207Z" fill="#FFE6DE"/>
      <defs>
        <filter id="filter0_d_3053_543" x="33.2209" y="65.1576" width="122.528" height="109.887" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dy="1.91081"/>
          <feGaussianBlur stdDeviation="1.91081"/>
          <feComposite in2="hardAlpha" operator="out"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3053_543"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3053_543" result="shape"/>
        </filter>
      </defs>
    </g>
  )
}

export const HeadBlock10 = () => {
  return (
    <g>
      <path d="M199.254 143.655C198.794 142.593 198.312 141.478 197.694 140.508C198.234 141.36 197.616 140.398 197.516 140.234C197.388 140.024 197.253 139.807 197.118 139.605C196.8 139.114 196.475 138.637 196.133 138.169C195.454 137.231 194.721 136.338 193.947 135.49C193.782 135.309 193.612 135.13 193.441 134.95C193.182 134.681 193.216 134.713 193.536 135.046C193.421 134.931 193.305 134.816 193.19 134.701C192.776 134.306 192.357 133.924 191.926 133.551C190.606 132.405 190.074 130.206 191.185 128.693C192.214 127.282 194.27 126.716 195.678 127.945C199.706 131.44 202.942 135.845 205.134 140.896C205.85 142.549 205.107 144.809 203.507 145.466C201.857 146.148 200.022 145.434 199.254 143.662L199.254 143.655Z" fill="black"/>
      <path d="M201.289 179.426C201.289 179.426 230.671 149.274 227.962 126.246L234.308 138.125L243.035 132.264C243.035 132.264 226.605 176.341 201.289 179.426Z" fill="#FF530A"/>
      <path d="M216.702 160.71C215.935 151.036 212.935 141.727 207.486 133.396C186.168 100.786 135.51 95.4821 94.3378 121.548C61.3776 142.414 50.0518 176.564 50.0002 206.773C49.8902 270.759 96.3795 305 151.988 305V249.446C158.632 246.906 165.194 243.642 171.529 239.63C197.859 222.961 213.938 197.501 216.527 172.782C216.953 168.723 217.018 164.688 216.698 160.71H216.702Z" fill="#EFB825"/>
      <path d="M117.533 171.228C117.66 171.302 117.787 171.367 117.918 171.438C118.079 171.522 118.244 171.61 118.406 171.691C118.557 171.769 118.712 171.844 118.859 171.918C131.227 177.902 146.055 173.782 153.5 162.664C154.033 161.869 154.528 161.037 154.982 160.167C161.85 147.043 156.608 130.926 143.274 124.171C129.938 117.413 113.559 122.571 106.691 135.695C106.238 136.564 105.836 137.447 105.485 138.34C100.673 150.615 105.701 164.696 117.529 171.228H117.533Z" fill="#FFE6DE"/>
      <path d="M117.983 172.425C118.096 172.492 118.217 172.553 118.337 172.618C118.485 172.695 118.632 172.773 118.784 172.848C118.921 172.919 119.062 172.986 119.2 173.054C130.484 178.513 144.013 174.755 150.806 164.608C154.202 155.255 148.413 143.697 137.049 137.94C125.686 132.18 112.758 134.257 106.997 142.415C102.604 153.615 107.193 166.461 117.986 172.421L117.983 172.425Z" fill="black"/>
      <path d="M111.225 222.625C120.81 222.625 128.58 214.979 128.58 205.547C128.58 196.115 120.81 188.469 111.225 188.469C101.64 188.469 93.8701 196.115 93.8701 205.547C93.8701 214.979 101.64 222.625 111.225 222.625Z" fill="#FF530A"/>
      <path d="M134.609 188.992C139.349 193.278 141.535 199.806 140.384 206.026C140.112 207.498 138.418 208.59 136.94 208.208C135.379 207.805 134.434 206.388 134.723 204.819C134.778 204.524 134.826 204.227 134.867 203.929C134.888 203.78 134.901 203.631 134.918 203.483C134.894 203.716 134.894 203.848 134.905 203.537C134.925 202.897 134.929 202.265 134.877 201.629C134.853 201.331 134.822 201.03 134.781 200.736C134.771 200.672 134.702 200.178 134.757 200.543C134.815 200.908 134.729 200.421 134.716 200.354C134.585 199.728 134.417 199.112 134.21 198.507C134.114 198.223 134.008 197.942 133.894 197.661C133.839 197.523 133.777 197.384 133.719 197.245C133.564 196.877 133.76 197.164 133.764 197.323C133.764 197.107 133.451 196.714 133.344 196.525C133.196 196.264 133.042 196.004 132.877 195.75C132.53 195.209 132.141 194.705 131.739 194.204C131.478 193.876 132.096 194.57 131.794 194.275C131.687 194.171 131.588 194.056 131.481 193.947C131.244 193.704 130.997 193.47 130.742 193.24C129.628 192.232 129.412 190.325 130.515 189.215C131.601 188.123 133.413 187.913 134.606 188.992H134.609Z" fill="black"/>
      <path d="M136.637 201.725C143.993 203.599 151.682 203.92 159.206 202.963C173.223 201.187 187.168 195.779 199.085 188.368C205.795 184.197 211.944 178.876 216.526 172.534C216.918 168.472 216.952 164.43 216.609 160.445C211.284 174.486 197.573 183.839 183.937 189.87C169.583 196.218 153.442 200.125 137.864 196.154C134.244 195.231 133.028 200.805 136.633 201.725H136.637Z" fill="black"/>
      <path d="M69.1068 47.5466C69.1068 47.5466 50.6776 46.0097 39.6195 50.3311C28.5663 54.6584 71.486 58.671 69.1068 47.5466Z" fill="#FF530A"/>
      <path d="M-6.4509 102.879C-6.4509 102.879 -27.3792 75.0939 20.0382 54.0533C20.0382 54.0533 36.2753 47.8944 56.2481 51.1233C76.2209 54.3522 59.0587 44.7799 75.2487 48.3121C91.4386 51.8443 124.558 81.2344 124.558 81.2344C124.558 81.2344 101.178 136.744 52.0604 147.921C52.0604 147.921 43.3006 139.479 42.5953 131.545C41.8899 123.61 39.3891 123.237 35.627 120.743C31.8649 118.249 34.2193 113.144 35.5293 110.604C36.8394 108.064 35.839 105.962 31.95 104.797C28.0619 103.621 29.5605 96.3045 29.5605 96.3045C29.5605 96.3045 27.2813 100.623 22.6703 94.4444C18.0642 88.2713 23.0807 88.751 18.0642 88.2713C13.0477 87.7917 21.9151 92.6401 9.36087 103.972C-3.19338 115.304 -6.4509 102.879 -6.4509 102.879Z" fill="#FF530A"/>
      <path opacity="0.53" d="M124.553 81.237C124.553 81.237 101.173 136.746 52.0552 147.923C52.0552 147.923 43.2954 139.482 42.5901 131.547C41.8848 123.613 39.3839 123.24 35.6219 120.745C31.8598 118.251 34.2141 113.146 35.5242 110.606C36.8342 108.066 35.8339 105.965 31.9448 104.799C28.0567 103.623 29.5553 96.307 29.5553 96.307C29.5553 96.307 27.2761 100.626 22.6652 94.447C18.059 88.2739 23.0755 88.7535 18.059 88.2739C13.0425 87.7942 21.91 92.6426 9.35572 103.975C-3.19852 115.307 -6.45607 102.881 -6.45607 102.881C-3.50278 104.692 0.579561 103.388 3.69909 101.889C6.26254 100.654 8.84078 99.1528 10.358 96.7452C12.071 94.0292 12.172 90.6175 13.3989 87.6567C14.6258 84.6958 18.091 82.0509 20.8781 83.6366C23.0752 84.887 23.5282 88.0568 25.6863 89.3786C28.2396 90.9419 31.6275 89.1047 34.5111 89.9059C36.6001 90.4918 38.076 92.3865 38.9425 94.3731C39.809 96.3597 40.2242 98.523 41.052 100.522C41.7154 102.108 42.6403 103.597 43.1208 105.246C43.8277 107.668 43.5675 110.388 44.8343 112.563C46.4596 115.363 50.3102 116.707 51.0166 119.863C51.5175 122.083 50.3074 124.756 51.7715 126.494C52.8789 127.812 54.8791 127.756 56.5888 127.598C57.1894 127.543 57.8015 127.478 58.324 127.174C60.4312 125.944 59.0185 122.714 59.4357 120.314C59.8863 117.789 62.5526 116.263 65.0748 115.797C67.6018 115.336 70.2444 115.572 72.6963 114.831C76.031 113.83 78.5846 111.184 80.829 108.519C87.1821 100.964 92.6791 92.1 101.483 87.6328C107.276 84.701 113.906 84.005 120.345 83.1834C121.604 83.025 124.482 82.4797 124.553 81.237Z" fill="#FF530A"/>
      <path d="M7.67444 66.7056C16.0171 61.7867 24.919 57.8062 34.1494 54.8759C37.5231 53.8042 41.104 52.8649 44.5757 53.5722C47.9422 54.2588 50.8088 56.4152 54.0083 57.6703C55.9515 58.4353 58.0133 58.8576 60.0596 59.2732C62.2479 59.7183 64.7361 60.1118 66.5401 58.7988C67.3762 58.187 67.9507 57.282 68.7916 56.676C70.7683 55.2401 73.528 55.8794 75.8274 56.732C82.1913 59.1048 88.0961 62.6886 93.1303 67.2455C95.7754 69.6435 98.3135 72.5997 98.5627 76.1629C98.6017 76.7082 98.5749 77.2687 98.3715 77.7695C97.6344 79.533 95.2377 79.6417 93.5208 80.4911C89.9748 82.2435 89.596 86.9907 88.5034 90.7903C86.1433 98.984 78.7713 104.687 71.2994 108.799C68.6217 110.276 65.1763 111.592 62.6801 109.825C61.0528 108.672 60.4536 106.583 59.8912 104.668C57.2625 95.6487 53.1202 86.8118 46.2698 80.3866C39.4193 73.9614 29.482 70.3192 20.3963 72.6894C16.9151 73.5954 13.3903 75.3498 9.88831 74.5108C8.72965 74.2338 7.64095 73.6739 6.45365 73.5282C2.70043 73.0728 0.795168 76.3978 -1.89525 78.1193C-4.74817 73.8567 4.94104 68.3158 7.67444 66.7056Z" fill="#FF530A"/>
      <path d="M36.0648 57.3889C40.5341 56.0198 45.6767 54.7311 49.6263 57.227C52.7711 59.2223 54.6984 63.4071 58.3995 63.8468C61.5866 64.2212 64.2156 61.5714 67.0702 60.0961C69.8463 58.6562 73.1615 58.3082 76.1726 59.1217C82.0422 60.7179 85.8948 66.1915 89.0487 71.3999C90.1097 73.1477 91.1769 74.998 91.3027 77.0478C91.3326 77.4636 91.3064 77.9062 91.0795 78.2599C90.4184 79.2853 88.8696 78.7135 87.6598 78.6354C85.4478 78.4936 83.6106 80.4898 82.9213 82.5958C82.2367 84.7076 82.3518 86.9816 81.9974 89.1732C81.6489 91.36 80.6327 93.6868 78.592 94.5588C76.6965 95.3697 74.4369 94.6603 72.852 93.345C71.2671 92.0298 70.2133 90.2076 69.1544 88.4386C64.891 81.3169 59.8574 74.3414 52.7865 69.9973C46.8395 66.3505 39.6357 64.7983 32.7203 65.6708C29.219 66.1136 25.8107 67.1552 22.3177 67.6792C20.6201 67.9352 18.6981 68.0142 17.4003 66.8927C13.4847 63.5366 20.841 62.036 22.5815 61.4999C27.0875 60.1396 31.5732 58.7667 36.0648 57.3889Z" fill="#FF530A"/>
      <g filter="url(#filter0_d_3053_253)">
        <path d="M41.8254 134.551C39.1548 138.028 39.6009 143.514 42.8 146.512C38.0812 149.144 35.7957 155.436 37.7315 160.485C38.4707 162.4 39.8543 164.195 41.8186 164.796C43.777 165.401 46.2718 164.38 46.7519 162.388C46.0201 164.881 47.7568 167.637 50.1347 168.68C52.5125 169.722 55.3136 169.368 57.7413 168.431C62.0775 166.77 65.6809 163.297 67.5045 159.031C67.1544 161.571 69.4401 163.914 71.9589 164.38C74.4777 164.846 77.0545 163.864 79.2452 162.545C82.5856 160.532 85.6046 157.168 85.3811 153.281C92.5686 155.62 100.909 154.031 106.743 149.215C109.043 147.317 110.885 143.535 108.576 141.652C110.677 141.102 112.789 140.553 114.89 140.003C118.388 139.094 122.203 137.952 124.166 134.922C126.124 131.886 124.339 126.605 120.74 126.893C123.794 126.585 126.902 126.104 129.624 124.67C132.335 123.235 134.623 120.67 134.985 117.621C135.346 114.572 133.291 111.217 130.255 110.777C133.163 110.873 136.071 110.968 138.984 111.07C142.748 111.194 146.984 111.143 149.626 108.462C151.164 106.909 151.871 104.671 151.925 102.483C152.085 95.3121 144.427 89.0832 137.435 90.7045C140.279 88.885 141.655 85.0482 140.61 81.838C139.566 78.6277 136.203 76.3328 132.826 76.5355C130.89 69.8132 122.913 65.5219 116.236 67.597C109.554 69.6769 105.427 77.7338 107.651 84.3656C102.615 84.4204 97.7217 87.4811 95.4832 91.9915C93.2447 96.502 93.7514 102.251 96.7484 106.297C92.7274 107.296 88.6723 108.989 86.0638 112.209C83.4553 115.429 82.7745 120.475 85.4304 123.657C81.0937 121.676 75.9947 120.658 71.5804 122.461C67.1661 124.265 64.0613 129.647 66.0042 134.005C65.8897 131.613 64.0502 129.538 61.837 128.624C60.0001 127.869 57.7727 127.721 55.8318 128.109C53.623 128.552 53.0409 130.041 51.4699 130.711C48.1244 132.161 44.5445 131.014 41.8254 134.551Z" fill="#FFE6DE"/>
        <path d="M59.2731 132.385C55.9588 132.948 53.7025 136.915 54.9145 140.056C53.7103 138.573 51.9413 137.556 50.049 137.268C49.0821 137.117 48.064 137.164 47.1642 137.545C44.9347 138.485 43.9854 141.402 44.7915 143.683C45.5977 145.965 47.7893 147.552 50.1266 148.178C47.1401 148.734 44.2209 150.38 42.7797 153.058C41.3386 155.736 41.7484 159.47 44.1563 161.319C46.5642 163.169 50.7717 162.183 51.6398 159.273C51.6157 160.703 52.6875 162.001 54.0093 162.535C55.331 163.069 56.8444 162.946 58.2103 162.513C60.19 161.887 62.0115 160.56 62.9144 158.689C63.8172 156.818 63.6681 154.396 62.2882 152.843C62.9418 155.71 61.5107 159.123 58.7176 160.068C55.9303 161.008 52.3935 158.514 52.8855 155.612C51.5221 157.364 49.7325 159.311 47.53 159.014C45.6501 158.765 44.3218 156.729 44.5452 154.841C44.7676 152.964 46.2799 151.392 48.0683 150.748C49.8508 150.109 51.8499 150.289 53.649 150.88C52.1953 149.818 50.708 148.716 49.7601 147.188C48.8064 145.665 48.5009 143.588 49.4968 142.09C50.3739 140.769 52.1098 140.168 53.6795 140.409C55.2491 140.651 56.6365 141.62 57.6458 142.842C56.0589 140.707 56.769 137.262 59.0584 135.92C61.4331 134.528 64.1359 135.762 65.7451 137.664C66.1598 134.561 62.1733 131.89 59.2731 132.385Z" fill="#FFE6DE"/>
        <path d="M83.8395 148.709C87.1805 151.12 91.6161 151.786 95.6414 150.91C98.2104 150.346 100.707 149.137 102.401 147.122C104.096 145.107 104.849 142.213 103.926 139.755C106.931 139.565 109.972 139.228 112.765 138.117C115.558 137.006 118.122 135.036 119.396 132.31C120.67 129.585 120.423 126.059 118.353 123.872C116.283 121.684 112.353 121.394 110.303 123.595C111.865 120.211 109.76 115.762 106.241 114.536C102.717 113.314 98.5047 115.244 96.8188 118.574C98.5081 115.544 103.305 115.295 105.774 117.741C108.238 120.18 108.375 124.355 106.696 127.385C109.364 125.672 112.881 123.944 115.486 125.759C117.673 127.282 117.775 130.702 116.308 132.921C114.841 135.141 112.263 136.358 109.72 137.155C107.155 137.951 104.193 138.37 101.909 136.956C103.655 140.908 101.865 145.939 98.2403 148.295C94.6151 150.651 89.6246 150.405 85.9621 148.103C82.6843 146.041 80.9652 142.82 79.6708 139.307C76.6361 140.872 82.1989 147.528 83.8395 148.709Z" fill="#FFE6DE"/>
        <path d="M98.4668 97.4096C98.459 94.7436 99.7776 92.2259 101.511 90.1983C102.99 88.4682 104.881 86.9758 107.111 86.5348C109.341 86.0938 111.929 86.9526 113.004 88.9537C112.509 86.327 112.863 83.4704 114.337 81.2356C115.811 79.0009 118.495 77.5094 121.154 77.8011C123.818 78.0987 126.215 80.3979 126.297 83.071C126.856 81.2689 129.421 81.0798 131.144 81.8506C133.385 82.8586 135.154 85.0011 135.403 87.4488C135.651 89.8965 134.191 92.4919 131.859 93.2717C134.624 92.3991 137.93 93.8307 139.187 96.4445C140.444 99.0583 139.5 102.529 137.098 104.149C134.697 105.77 131.119 105.326 129.169 103.177C131.209 103.597 133.448 103.795 135.272 102.779C137.096 101.763 138.105 99.1189 136.779 97.512C134.919 95.2588 131.134 97.0903 128.397 96.051C130.234 94.7305 131.9 93.0128 132.573 90.8517C133.246 88.6905 132.674 86.0403 130.795 84.772C128.92 83.5095 125.828 84.3293 125.27 86.5229C126.482 83.8877 124.319 80.4847 121.449 80.0441C118.579 79.6035 115.69 81.7243 114.777 84.4846C113.859 87.2391 114.681 90.369 116.404 92.7056C114.366 91.3041 112.08 90.0881 109.606 89.9374C106.44 89.7418 103.233 91.4784 101.661 94.2346C99.928 97.2705 101.645 99.7888 101.027 102.658C99.3297 101.621 98.4694 99.3457 98.4668 97.4096Z" fill="#FFE6DE"/>
        <path d="M71.3242 143.366C71.5439 142.079 72.5578 140.84 73.8583 140.755C72.7674 140.05 72.4552 138.605 72.3375 137.312C72.0794 134.402 72.4455 131.134 74.6016 129.168C76.1519 127.761 78.4807 127.297 80.4601 127.964C82.4386 128.641 83.9962 130.41 84.4333 132.457C86.1913 129.606 90.8561 129.221 93.0598 131.748C95.2635 134.275 94.2476 138.844 91.18 140.191C92.6026 138.998 93.101 136.814 92.3239 135.126C91.5469 133.437 89.5547 132.4 87.731 132.74C85.9074 133.081 84.4157 134.772 84.3239 136.629C83.9475 134.845 83.0875 133.127 81.6981 131.954C80.3088 130.781 78.3579 130.208 76.607 130.684C75.9683 130.859 75.354 131.171 74.8945 131.657C74.4292 132.149 74.143 132.787 73.9476 133.428C73.018 136.476 74.0405 140.027 76.4464 142.124C75.5895 141.382 73.1769 145.302 73.118 145.919C72.9879 147.279 74.0541 148.523 74.2135 149.772C72.2347 148.762 70.9555 145.54 71.3242 143.366Z" fill="#FFE6DE"/>
      </g>
      <path d="M-13.5985 98.666C-14.7356 100.799 -14.0764 103.72 -12.1325 105.15C-14.7104 105.976 -15.3363 109.381 -14.8838 112.052C-14.5272 114.156 -13.5991 116.395 -11.6554 117.267C-9.71175 118.139 -6.84907 116.579 -7.28785 114.494C-8.0156 115.711 -7.24819 117.388 -6.03176 118.127C-4.81532 118.865 -3.31925 118.922 -1.89555 118.951C0.170924 118.988 2.39812 118.97 4.07888 117.769C5.75965 116.567 6.36554 113.708 4.69023 112.497C6.7576 114.207 9.3629 115.394 12.0525 115.308C14.7314 115.221 17.4412 113.689 18.4774 111.209C19.5136 108.728 18.4657 105.443 15.9921 104.391C19.4682 104.997 23.1883 101.985 23.08 98.4621C22.9718 94.9392 18.7442 92.154 15.5551 93.6509C18.2701 90.9986 18.6497 86.2996 16.3954 83.2365C14.0026 79.9939 9.4679 79.0187 5.46833 79.5694C2.0559 80.0368 -1.34097 81.5192 -3.44163 84.2517C-5.53647 86.9794 -6.01376 91.0737 -4.00754 93.8718C-5.09563 92.352 -7.46077 95.8315 -7.92092 96.0449C-10.0609 97.0576 -12.1037 95.8755 -13.5985 98.666Z" fill="#FFE6DE"/>
      <path d="M-11.4037 100.896C-12.5408 103.029 -11.8816 105.951 -9.93764 107.381C-12.5156 108.207 -13.1415 111.611 -12.689 114.282C-12.3324 116.386 -11.4043 118.625 -9.4606 119.498C-7.51692 120.37 -4.65424 118.81 -5.09302 116.724C-5.82077 117.942 -5.05336 119.619 -3.83693 120.357C-2.62049 121.095 -1.12442 121.153 0.299279 121.182C2.36575 121.218 4.59294 121.201 6.27371 119.999C7.95448 118.798 8.56035 115.938 6.88504 114.727C8.95241 116.437 11.5577 117.625 14.2473 117.539C16.9368 117.453 19.636 115.92 20.6722 113.439C21.7084 110.959 20.6605 107.673 18.187 106.622C21.663 107.227 25.3831 104.215 25.2749 100.693C25.1666 97.1697 20.939 94.3844 17.7499 95.8813C20.465 93.229 20.8445 88.5301 18.5902 85.467C16.1974 82.2244 11.6627 81.2492 7.66316 81.7998C4.25072 82.2672 0.853843 83.7497 -1.24682 86.4822C-3.34166 89.2099 -3.81895 93.3042 -1.81273 96.1023C-2.90082 94.5825 -5.26595 98.0619 -5.72609 98.2753C-7.8709 99.2822 -9.91367 98.1002 -11.4037 100.896Z" fill="#FFE6DE"/>
      <path d="M10.7136 90.6116C11.7305 91.642 12.2642 93.0712 12.4595 94.5056C12.7137 96.3907 12.3842 98.4346 11.1469 99.8768C13.4923 98.5673 16.4719 98.4769 18.8907 99.652C19.777 100.085 20.6601 100.776 20.8083 101.75C20.9285 102.512 20.5653 103.282 20.0744 103.873C18.7143 105.534 16.2493 106.189 14.2413 105.428C15.618 106.622 16.6448 108.221 17.147 109.98C17.4447 111.017 17.5349 112.205 16.9247 113.09C16.296 114.001 15.0736 114.335 13.9761 114.203C12.8844 114.066 11.8787 113.536 10.9039 113.019C10.2453 112.672 9.5723 112.307 9.08487 111.74C8.59744 111.173 8.34317 110.355 8.63343 109.675C9.63768 110.726 10.7393 111.823 12.1647 112.115C13.5901 112.407 15.3548 111.45 15.3065 109.992C15.2847 109.266 14.8305 108.632 14.3633 108.078C13.3692 106.921 12.1946 105.913 10.8993 105.103C12.5029 105.101 14.1084 104.911 15.6722 104.541C16.276 104.395 16.8994 104.213 17.3682 103.796C17.837 103.38 18.0975 102.676 17.8236 102.119C17.5278 101.511 16.7699 101.305 16.1003 101.241C13.1381 100.925 10.1886 101.823 7.34746 102.71C8.29184 101.47 9.25469 100.205 9.82311 98.7474C11.5253 94.4055 8.45654 94.1389 6.79882 91.2294C5.19573 88.4216 9.05596 88.9355 10.7136 90.6116Z" fill="#FFE6DE"/>
      <defs>
        <filter id="filter0_d_3053_253" x="33.2209" y="65.1595" width="122.528" height="109.887" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dy="1.91081"/>
          <feGaussianBlur stdDeviation="1.91081"/>
          <feComposite in2="hardAlpha" operator="out"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3053_253"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3053_253" result="shape"/>
        </filter>
      </defs>
    </g>
  )
}

export const HeadBlock11 = () => {
  return (
    <g>
      <path d="M199.253 143.655C198.793 142.593 198.311 141.478 197.693 140.508C198.233 141.36 197.616 140.398 197.516 140.234C197.387 140.024 197.252 139.807 197.118 139.605C196.799 139.114 196.475 138.637 196.132 138.169C195.453 137.231 194.72 136.338 193.946 135.49C193.782 135.309 193.611 135.13 193.441 134.95C193.181 134.681 193.215 134.713 193.536 135.046C193.42 134.931 193.305 134.816 193.189 134.701C192.776 134.306 192.357 133.924 191.926 133.551C190.605 132.405 190.073 130.206 191.184 128.693C192.213 127.282 194.27 126.716 195.678 127.945C199.705 131.44 202.941 135.845 205.133 140.896C205.849 142.549 205.107 144.809 203.506 145.466C201.857 146.148 200.022 145.434 199.254 143.662L199.253 143.655Z" fill="black"/>
      <path d="M201.289 179.426C201.289 179.426 230.671 149.274 227.962 126.246L234.308 138.125L243.035 132.264C243.035 132.264 226.605 176.341 201.289 179.426Z" fill="#FF530A"/>
      <path d="M216.702 160.71C215.935 151.036 212.935 141.727 207.486 133.396C186.168 100.786 135.51 95.4821 94.3378 121.548C61.3776 142.414 50.0518 176.564 50.0002 206.773C49.8902 270.759 96.3795 305 151.988 305V249.446C158.632 246.906 165.194 243.642 171.529 239.63C197.859 222.961 213.938 197.501 216.527 172.782C216.953 168.723 217.018 164.688 216.698 160.71H216.702Z" fill="#EFB825"/>
      <path d="M117.533 171.228C117.66 171.302 117.787 171.367 117.918 171.438C118.079 171.522 118.244 171.61 118.406 171.691C118.557 171.769 118.712 171.844 118.859 171.918C131.227 177.902 146.055 173.782 153.5 162.664C154.033 161.869 154.528 161.037 154.982 160.167C161.85 147.043 156.608 130.926 143.274 124.171C129.938 117.413 113.559 122.571 106.691 135.695C106.238 136.564 105.836 137.447 105.485 138.34C100.673 150.615 105.701 164.696 117.529 171.228H117.533Z" fill="#FFE6DE"/>
      <path d="M117.983 172.425C118.096 172.492 118.217 172.553 118.337 172.618C118.485 172.695 118.632 172.773 118.784 172.848C118.921 172.919 119.062 172.986 119.2 173.054C130.484 178.513 144.013 174.755 150.806 164.608C154.202 155.255 148.413 143.697 137.049 137.94C125.686 132.18 112.758 134.257 106.997 142.415C102.604 153.615 107.193 166.461 117.986 172.421L117.983 172.425Z" fill="black"/>
      <path d="M111.225 222.625C120.81 222.625 128.58 214.979 128.58 205.547C128.58 196.115 120.81 188.469 111.225 188.469C101.64 188.469 93.8701 196.115 93.8701 205.547C93.8701 214.979 101.64 222.625 111.225 222.625Z" fill="#FF530A"/>
      <path d="M134.609 188.992C139.349 193.278 141.535 199.806 140.384 206.026C140.112 207.498 138.418 208.59 136.94 208.208C135.379 207.805 134.434 206.388 134.723 204.819C134.778 204.524 134.826 204.227 134.867 203.929C134.888 203.78 134.901 203.631 134.918 203.483C134.894 203.716 134.894 203.848 134.905 203.537C134.925 202.897 134.929 202.265 134.877 201.629C134.853 201.331 134.822 201.03 134.781 200.736C134.771 200.672 134.702 200.178 134.757 200.543C134.815 200.908 134.729 200.421 134.716 200.354C134.585 199.728 134.417 199.112 134.21 198.507C134.114 198.223 134.008 197.942 133.894 197.661C133.839 197.523 133.777 197.384 133.719 197.245C133.564 196.877 133.76 197.164 133.764 197.323C133.764 197.107 133.451 196.714 133.344 196.525C133.196 196.264 133.042 196.004 132.877 195.75C132.53 195.209 132.141 194.705 131.739 194.204C131.478 193.876 132.096 194.57 131.794 194.275C131.687 194.171 131.588 194.056 131.481 193.947C131.244 193.704 130.997 193.47 130.742 193.24C129.628 192.232 129.412 190.325 130.515 189.215C131.601 188.123 133.413 187.913 134.606 188.992H134.609Z" fill="black"/>
      <path d="M136.637 201.725C143.993 203.599 151.682 203.92 159.206 202.963C173.223 201.187 187.168 195.779 199.085 188.368C205.795 184.197 211.944 178.876 216.526 172.534C216.918 168.472 216.952 164.43 216.609 160.445C211.284 174.486 197.573 183.839 183.937 189.87C169.583 196.218 153.442 200.125 137.864 196.154C134.244 195.231 133.028 200.805 136.633 201.725H136.637Z" fill="black"/>
      <path d="M69.1068 47.5466C69.1068 47.5466 50.6776 46.0097 39.6195 50.3311C28.5663 54.6584 71.486 58.671 69.1068 47.5466Z" fill="#FF530A"/>
      <path d="M-6.4509 102.879C-6.4509 102.879 -27.3792 75.0939 20.0382 54.0533C20.0382 54.0533 36.2753 47.8944 56.2481 51.1233C76.2209 54.3522 59.0587 44.7799 75.2487 48.3121C91.4386 51.8443 124.558 81.2344 124.558 81.2344C124.558 81.2344 101.178 136.744 52.0604 147.921C52.0604 147.921 43.3006 139.479 42.5953 131.545C41.8899 123.61 39.3891 123.237 35.627 120.743C31.8649 118.249 34.2193 113.144 35.5293 110.604C36.8394 108.064 35.839 105.962 31.95 104.797C28.0619 103.621 29.5605 96.3045 29.5605 96.3045C29.5605 96.3045 27.2813 100.623 22.6703 94.4444C18.0642 88.2713 23.0807 88.751 18.0642 88.2713C13.0477 87.7917 21.9151 92.6401 9.36087 103.972C-3.19338 115.304 -6.4509 102.879 -6.4509 102.879Z" fill="#FF530A"/>
      <path opacity="0.53" d="M124.553 81.237C124.553 81.237 101.173 136.746 52.0552 147.923C52.0552 147.923 43.2954 139.482 42.5901 131.547C41.8848 123.613 39.3839 123.24 35.6219 120.745C31.8598 118.251 34.2141 113.146 35.5242 110.606C36.8342 108.066 35.8339 105.965 31.9448 104.799C28.0567 103.623 29.5553 96.307 29.5553 96.307C29.5553 96.307 27.2761 100.626 22.6652 94.447C18.059 88.2739 23.0755 88.7535 18.059 88.2739C13.0425 87.7942 21.91 92.6426 9.35572 103.975C-3.19852 115.307 -6.45607 102.881 -6.45607 102.881C-3.50278 104.692 0.579561 103.388 3.69909 101.889C6.26254 100.654 8.84078 99.1528 10.358 96.7452C12.071 94.0292 12.172 90.6175 13.3989 87.6567C14.6258 84.6958 18.091 82.0509 20.8781 83.6366C23.0752 84.887 23.5282 88.0568 25.6863 89.3786C28.2396 90.9419 31.6275 89.1047 34.5111 89.9059C36.6001 90.4918 38.076 92.3865 38.9425 94.3731C39.809 96.3597 40.2242 98.523 41.052 100.522C41.7154 102.108 42.6403 103.597 43.1208 105.246C43.8277 107.668 43.5675 110.388 44.8343 112.563C46.4596 115.363 50.3102 116.707 51.0166 119.863C51.5175 122.083 50.3074 124.756 51.7715 126.494C52.8789 127.812 54.8791 127.756 56.5888 127.598C57.1894 127.543 57.8015 127.478 58.324 127.174C60.4312 125.944 59.0185 122.714 59.4357 120.314C59.8863 117.789 62.5526 116.263 65.0748 115.797C67.6018 115.336 70.2444 115.572 72.6963 114.831C76.031 113.83 78.5846 111.184 80.829 108.519C87.1821 100.964 92.6791 92.1 101.483 87.6328C107.276 84.701 113.906 84.005 120.345 83.1834C121.604 83.025 124.482 82.4797 124.553 81.237Z" fill="#FF530A"/>
      <path d="M7.67444 66.7056C16.0171 61.7867 24.919 57.8062 34.1494 54.8759C37.5231 53.8042 41.104 52.8649 44.5757 53.5722C47.9422 54.2588 50.8088 56.4152 54.0083 57.6703C55.9515 58.4353 58.0133 58.8576 60.0596 59.2732C62.2479 59.7183 64.7361 60.1118 66.5401 58.7988C67.3762 58.187 67.9507 57.282 68.7916 56.676C70.7683 55.2401 73.528 55.8794 75.8274 56.732C82.1913 59.1048 88.0961 62.6886 93.1303 67.2455C95.7754 69.6435 98.3135 72.5997 98.5627 76.1629C98.6017 76.7082 98.5749 77.2687 98.3715 77.7695C97.6344 79.533 95.2377 79.6417 93.5208 80.4911C89.9748 82.2435 89.596 86.9907 88.5034 90.7903C86.1433 98.984 78.7713 104.687 71.2994 108.799C68.6217 110.276 65.1763 111.592 62.6801 109.825C61.0528 108.672 60.4536 106.583 59.8912 104.668C57.2625 95.6487 53.1202 86.8118 46.2698 80.3866C39.4193 73.9614 29.482 70.3192 20.3963 72.6894C16.9151 73.5954 13.3903 75.3498 9.88831 74.5108C8.72965 74.2338 7.64095 73.6739 6.45365 73.5282C2.70043 73.0728 0.795168 76.3978 -1.89525 78.1193C-4.74817 73.8567 4.94104 68.3158 7.67444 66.7056Z" fill="#FF530A"/>
      <path d="M36.0648 57.3889C40.5341 56.0198 45.6767 54.7311 49.6263 57.227C52.7711 59.2223 54.6984 63.4071 58.3995 63.8468C61.5866 64.2212 64.2156 61.5714 67.0702 60.0961C69.8463 58.6562 73.1615 58.3082 76.1726 59.1217C82.0422 60.7179 85.8948 66.1915 89.0487 71.3999C90.1097 73.1477 91.1769 74.998 91.3027 77.0478C91.3326 77.4636 91.3064 77.9062 91.0795 78.2599C90.4184 79.2853 88.8696 78.7135 87.6598 78.6354C85.4478 78.4936 83.6106 80.4898 82.9213 82.5958C82.2367 84.7076 82.3518 86.9816 81.9974 89.1732C81.6489 91.36 80.6327 93.6868 78.592 94.5588C76.6965 95.3697 74.4369 94.6603 72.852 93.345C71.2671 92.0298 70.2133 90.2076 69.1544 88.4386C64.891 81.3169 59.8574 74.3414 52.7865 69.9973C46.8395 66.3505 39.6357 64.7983 32.7203 65.6708C29.219 66.1136 25.8107 67.1552 22.3177 67.6792C20.6201 67.9352 18.6981 68.0142 17.4003 66.8927C13.4847 63.5366 20.841 62.036 22.5815 61.4999C27.0875 60.1396 31.5732 58.7667 36.0648 57.3889Z" fill="#FF530A"/>
      <g filter="url(#filter0_d_3053_834)">
        <path d="M41.8254 134.551C39.1548 138.028 39.6009 143.514 42.8 146.512C38.0812 149.144 35.7957 155.436 37.7315 160.485C38.4707 162.4 39.8543 164.195 41.8186 164.796C43.777 165.401 46.2718 164.38 46.7519 162.388C46.0201 164.881 47.7568 167.637 50.1347 168.68C52.5125 169.722 55.3136 169.368 57.7413 168.431C62.0775 166.77 65.6809 163.297 67.5045 159.031C67.1544 161.571 69.4401 163.914 71.9589 164.38C74.4777 164.846 77.0545 163.864 79.2452 162.545C82.5856 160.532 85.6046 157.168 85.3811 153.281C92.5686 155.62 100.909 154.031 106.743 149.215C109.043 147.317 110.885 143.535 108.576 141.652C110.677 141.102 112.789 140.553 114.89 140.003C118.388 139.094 122.203 137.952 124.166 134.922C126.124 131.886 124.339 126.605 120.74 126.893C123.794 126.585 126.902 126.104 129.624 124.67C132.335 123.235 134.623 120.67 134.985 117.621C135.346 114.572 133.291 111.217 130.255 110.777C133.163 110.873 136.071 110.968 138.984 111.07C142.748 111.194 146.984 111.143 149.626 108.462C151.164 106.909 151.871 104.671 151.925 102.483C152.085 95.3121 144.427 89.0832 137.435 90.7045C140.279 88.885 141.655 85.0482 140.61 81.838C139.566 78.6277 136.203 76.3328 132.826 76.5355C130.89 69.8132 122.913 65.5219 116.236 67.597C109.554 69.6769 105.427 77.7338 107.651 84.3656C102.615 84.4204 97.7217 87.4811 95.4832 91.9915C93.2447 96.502 93.7514 102.251 96.7484 106.297C92.7274 107.296 88.6723 108.989 86.0638 112.209C83.4553 115.429 82.7745 120.475 85.4304 123.657C81.0937 121.676 75.9947 120.658 71.5804 122.461C67.1661 124.265 64.0613 129.647 66.0042 134.005C65.8897 131.613 64.0502 129.538 61.837 128.624C60.0001 127.869 57.7727 127.721 55.8318 128.109C53.623 128.552 53.0409 130.041 51.4699 130.711C48.1244 132.161 44.5445 131.014 41.8254 134.551Z" fill="#FFE6DE"/>
        <path d="M59.2731 132.385C55.9588 132.948 53.7025 136.915 54.9145 140.056C53.7103 138.573 51.9413 137.556 50.049 137.268C49.0821 137.117 48.064 137.164 47.1642 137.545C44.9347 138.485 43.9854 141.402 44.7915 143.683C45.5977 145.965 47.7893 147.552 50.1266 148.178C47.1401 148.734 44.2209 150.38 42.7797 153.058C41.3386 155.736 41.7484 159.47 44.1563 161.319C46.5642 163.169 50.7717 162.183 51.6398 159.273C51.6157 160.703 52.6875 162.001 54.0093 162.535C55.331 163.069 56.8444 162.946 58.2103 162.513C60.19 161.887 62.0115 160.56 62.9144 158.689C63.8172 156.818 63.6681 154.396 62.2882 152.843C62.9418 155.71 61.5107 159.123 58.7176 160.068C55.9303 161.008 52.3935 158.514 52.8855 155.612C51.5221 157.364 49.7325 159.311 47.53 159.014C45.6501 158.765 44.3218 156.729 44.5452 154.841C44.7676 152.964 46.2799 151.392 48.0683 150.748C49.8508 150.109 51.8499 150.289 53.649 150.88C52.1953 149.818 50.708 148.716 49.7601 147.188C48.8064 145.665 48.5009 143.588 49.4968 142.09C50.3739 140.769 52.1098 140.168 53.6795 140.409C55.2491 140.651 56.6365 141.62 57.6458 142.842C56.0589 140.707 56.769 137.262 59.0584 135.92C61.4331 134.528 64.1359 135.762 65.7451 137.664C66.1598 134.561 62.1733 131.89 59.2731 132.385Z" fill="#FFE6DE"/>
        <path d="M83.8395 148.709C87.1805 151.12 91.6161 151.786 95.6414 150.91C98.2104 150.346 100.707 149.137 102.401 147.122C104.096 145.107 104.849 142.213 103.926 139.755C106.931 139.565 109.972 139.228 112.765 138.117C115.558 137.006 118.122 135.036 119.396 132.31C120.67 129.585 120.423 126.059 118.353 123.872C116.283 121.684 112.353 121.394 110.303 123.595C111.865 120.211 109.76 115.762 106.241 114.536C102.717 113.314 98.5047 115.244 96.8188 118.574C98.5081 115.544 103.305 115.295 105.774 117.741C108.238 120.18 108.375 124.355 106.696 127.385C109.364 125.672 112.881 123.944 115.486 125.759C117.673 127.282 117.775 130.702 116.308 132.921C114.841 135.141 112.263 136.358 109.72 137.155C107.155 137.951 104.193 138.37 101.909 136.956C103.655 140.908 101.865 145.939 98.2403 148.295C94.6151 150.651 89.6246 150.405 85.9621 148.103C82.6843 146.041 80.9652 142.82 79.6708 139.307C76.6361 140.872 82.1989 147.528 83.8395 148.709Z" fill="#FFE6DE"/>
        <path d="M98.4668 97.4096C98.459 94.7436 99.7776 92.2259 101.511 90.1983C102.99 88.4682 104.881 86.9758 107.111 86.5348C109.341 86.0938 111.929 86.9526 113.004 88.9537C112.509 86.327 112.863 83.4704 114.337 81.2356C115.811 79.0009 118.495 77.5094 121.154 77.8011C123.818 78.0987 126.215 80.3979 126.297 83.071C126.856 81.2689 129.421 81.0798 131.144 81.8506C133.385 82.8586 135.154 85.0011 135.403 87.4488C135.651 89.8965 134.191 92.4919 131.859 93.2717C134.624 92.3991 137.93 93.8307 139.187 96.4445C140.444 99.0583 139.5 102.529 137.098 104.149C134.697 105.77 131.119 105.326 129.169 103.177C131.209 103.597 133.448 103.795 135.272 102.779C137.096 101.763 138.105 99.1189 136.779 97.512C134.919 95.2588 131.134 97.0903 128.397 96.051C130.234 94.7305 131.9 93.0128 132.573 90.8517C133.246 88.6905 132.674 86.0403 130.795 84.772C128.92 83.5095 125.828 84.3293 125.27 86.5229C126.482 83.8877 124.319 80.4847 121.449 80.0441C118.579 79.6035 115.69 81.7243 114.777 84.4846C113.859 87.2391 114.681 90.369 116.404 92.7056C114.366 91.3041 112.08 90.0881 109.606 89.9374C106.44 89.7418 103.233 91.4784 101.661 94.2346C99.928 97.2705 101.645 99.7888 101.027 102.658C99.3297 101.621 98.4694 99.3457 98.4668 97.4096Z" fill="#FFE6DE"/>
        <path d="M71.3242 143.366C71.5439 142.079 72.5578 140.84 73.8583 140.755C72.7674 140.05 72.4552 138.605 72.3375 137.312C72.0794 134.402 72.4455 131.134 74.6016 129.168C76.1519 127.761 78.4807 127.297 80.4601 127.964C82.4386 128.641 83.9962 130.41 84.4333 132.457C86.1913 129.606 90.8561 129.221 93.0598 131.748C95.2635 134.275 94.2476 138.844 91.18 140.191C92.6026 138.998 93.101 136.814 92.3239 135.126C91.5469 133.437 89.5547 132.4 87.731 132.74C85.9074 133.081 84.4157 134.772 84.3239 136.629C83.9475 134.845 83.0875 133.127 81.6981 131.954C80.3088 130.781 78.3579 130.208 76.607 130.684C75.9683 130.859 75.354 131.171 74.8945 131.657C74.4292 132.149 74.143 132.787 73.9476 133.428C73.018 136.476 74.0405 140.027 76.4464 142.124C75.5895 141.382 73.1769 145.302 73.118 145.919C72.9879 147.279 74.0541 148.523 74.2135 149.772C72.2347 148.762 70.9555 145.54 71.3242 143.366Z" fill="#FFE6DE"/>
      </g>
      <path d="M-13.5985 98.666C-14.7356 100.799 -14.0764 103.72 -12.1325 105.15C-14.7104 105.976 -15.3363 109.381 -14.8838 112.052C-14.5272 114.156 -13.5991 116.395 -11.6554 117.267C-9.71175 118.139 -6.84907 116.579 -7.28785 114.494C-8.0156 115.711 -7.24819 117.388 -6.03176 118.127C-4.81532 118.865 -3.31925 118.922 -1.89555 118.951C0.170924 118.988 2.39812 118.97 4.07888 117.769C5.75965 116.567 6.36554 113.708 4.69023 112.497C6.7576 114.207 9.3629 115.394 12.0525 115.308C14.7314 115.221 17.4412 113.689 18.4774 111.209C19.5136 108.728 18.4657 105.443 15.9921 104.391C19.4682 104.997 23.1883 101.985 23.08 98.4621C22.9718 94.9392 18.7442 92.154 15.5551 93.6509C18.2701 90.9986 18.6497 86.2996 16.3954 83.2365C14.0026 79.9939 9.4679 79.0187 5.46833 79.5694C2.0559 80.0368 -1.34097 81.5192 -3.44163 84.2517C-5.53647 86.9794 -6.01376 91.0737 -4.00754 93.8718C-5.09563 92.352 -7.46077 95.8315 -7.92092 96.0449C-10.0609 97.0576 -12.1037 95.8755 -13.5985 98.666Z" fill="#FFE6DE"/>
      <path d="M-11.4037 100.896C-12.5408 103.029 -11.8816 105.951 -9.93764 107.381C-12.5156 108.207 -13.1415 111.611 -12.689 114.282C-12.3324 116.386 -11.4043 118.625 -9.4606 119.498C-7.51692 120.37 -4.65424 118.81 -5.09302 116.724C-5.82077 117.942 -5.05336 119.619 -3.83693 120.357C-2.62049 121.095 -1.12442 121.153 0.299279 121.182C2.36575 121.218 4.59294 121.201 6.27371 119.999C7.95448 118.798 8.56035 115.938 6.88504 114.727C8.95241 116.437 11.5577 117.625 14.2473 117.539C16.9368 117.453 19.636 115.92 20.6722 113.439C21.7084 110.959 20.6605 107.673 18.187 106.622C21.663 107.227 25.3831 104.215 25.2749 100.693C25.1666 97.1697 20.939 94.3844 17.7499 95.8813C20.465 93.229 20.8445 88.5301 18.5902 85.467C16.1974 82.2244 11.6627 81.2492 7.66316 81.7998C4.25072 82.2672 0.853843 83.7497 -1.24682 86.4822C-3.34166 89.2099 -3.81895 93.3042 -1.81273 96.1023C-2.90082 94.5825 -5.26595 98.0619 -5.72609 98.2753C-7.8709 99.2822 -9.91367 98.1002 -11.4037 100.896Z" fill="#FFE6DE"/>
      <path d="M10.7136 90.6116C11.7305 91.642 12.2642 93.0712 12.4595 94.5056C12.7137 96.3907 12.3842 98.4346 11.1469 99.8768C13.4923 98.5673 16.4719 98.4769 18.8907 99.652C19.777 100.085 20.6601 100.776 20.8083 101.75C20.9285 102.512 20.5653 103.282 20.0744 103.873C18.7143 105.534 16.2493 106.189 14.2413 105.428C15.618 106.622 16.6448 108.221 17.147 109.98C17.4447 111.017 17.5349 112.205 16.9247 113.09C16.296 114.001 15.0736 114.335 13.9761 114.203C12.8844 114.066 11.8787 113.536 10.9039 113.019C10.2453 112.672 9.5723 112.307 9.08487 111.74C8.59744 111.173 8.34317 110.355 8.63343 109.675C9.63768 110.726 10.7393 111.823 12.1647 112.115C13.5901 112.407 15.3548 111.45 15.3065 109.992C15.2847 109.266 14.8305 108.632 14.3633 108.078C13.3692 106.921 12.1946 105.913 10.8993 105.103C12.5029 105.101 14.1084 104.911 15.6722 104.541C16.276 104.395 16.8994 104.213 17.3682 103.796C17.837 103.38 18.0975 102.676 17.8236 102.119C17.5278 101.511 16.7699 101.305 16.1003 101.241C13.1381 100.925 10.1886 101.823 7.34746 102.71C8.29184 101.47 9.25469 100.205 9.82311 98.7474C11.5253 94.4055 8.45654 94.1389 6.79882 91.2294C5.19573 88.4216 9.05596 88.9355 10.7136 90.6116Z" fill="#FFE6DE"/>
      <defs>
        <filter id="filter0_d_3053_834" x="33.2209" y="65.1595" width="122.528" height="109.887" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dy="1.91081"/>
          <feGaussianBlur stdDeviation="1.91081"/>
          <feComposite in2="hardAlpha" operator="out"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3053_834"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3053_834" result="shape"/>
        </filter>
      </defs>
    </g>
  )
}

export const HeadBlock12 = () => {
  return (
    <g>
      <path d="M199.253 143.655C198.793 142.593 198.311 141.478 197.693 140.508C198.233 141.36 197.616 140.398 197.516 140.234C197.387 140.024 197.252 139.807 197.118 139.605C196.799 139.114 196.475 138.637 196.132 138.169C195.453 137.231 194.72 136.338 193.946 135.49C193.782 135.309 193.611 135.13 193.441 134.95C193.181 134.681 193.215 134.713 193.536 135.046C193.42 134.931 193.305 134.816 193.189 134.701C192.776 134.306 192.357 133.924 191.926 133.551C190.605 132.405 190.073 130.206 191.184 128.693C192.213 127.282 194.27 126.716 195.678 127.945C199.705 131.44 202.941 135.845 205.133 140.896C205.849 142.549 205.107 144.809 203.506 145.466C201.857 146.148 200.022 145.434 199.254 143.662L199.253 143.655Z" fill="black"/>
      <path d="M201.289 179.426C201.289 179.426 230.671 149.274 227.962 126.246L234.308 138.125L243.035 132.264C243.035 132.264 226.605 176.341 201.289 179.426Z" fill="#FF530A"/>
      <path d="M216.702 160.71C215.935 151.036 212.935 141.727 207.486 133.396C186.168 100.786 135.51 95.4821 94.3378 121.548C61.3776 142.414 50.0518 176.564 50.0002 206.773C49.8902 270.759 96.3795 305 151.988 305V249.446C158.632 246.906 165.194 243.642 171.529 239.63C197.859 222.961 213.938 197.501 216.527 172.782C216.953 168.723 217.018 164.688 216.698 160.71H216.702Z" fill="#EFB825"/>
      <path d="M117.533 171.228C117.66 171.302 117.787 171.367 117.918 171.438C118.079 171.522 118.244 171.61 118.406 171.691C118.557 171.769 118.712 171.844 118.859 171.918C131.227 177.902 146.055 173.782 153.5 162.664C154.033 161.869 154.528 161.037 154.982 160.167C161.85 147.043 156.608 130.926 143.274 124.171C129.938 117.413 113.559 122.571 106.691 135.695C106.238 136.564 105.836 137.447 105.485 138.34C100.673 150.615 105.701 164.696 117.529 171.228H117.533Z" fill="#FFE6DE"/>
      <path d="M117.983 172.425C118.096 172.492 118.217 172.553 118.337 172.618C118.485 172.695 118.632 172.773 118.784 172.848C118.921 172.919 119.062 172.986 119.2 173.054C130.484 178.513 144.013 174.755 150.806 164.608C154.202 155.255 148.413 143.697 137.049 137.94C125.686 132.18 112.758 134.257 106.997 142.415C102.604 153.615 107.193 166.461 117.986 172.421L117.983 172.425Z" fill="black"/>
      <path d="M111.225 222.625C120.81 222.625 128.58 214.979 128.58 205.547C128.58 196.115 120.81 188.469 111.225 188.469C101.64 188.469 93.8701 196.115 93.8701 205.547C93.8701 214.979 101.64 222.625 111.225 222.625Z" fill="#FF530A"/>
      <path d="M134.609 188.992C139.349 193.278 141.535 199.806 140.384 206.026C140.112 207.498 138.418 208.59 136.94 208.208C135.379 207.805 134.434 206.388 134.723 204.819C134.778 204.524 134.826 204.227 134.867 203.929C134.888 203.78 134.901 203.631 134.918 203.483C134.894 203.716 134.894 203.848 134.905 203.537C134.925 202.897 134.929 202.265 134.877 201.629C134.853 201.331 134.822 201.03 134.781 200.736C134.771 200.672 134.702 200.178 134.757 200.543C134.815 200.908 134.729 200.421 134.716 200.354C134.585 199.728 134.417 199.112 134.21 198.507C134.114 198.223 134.008 197.942 133.894 197.661C133.839 197.523 133.777 197.384 133.719 197.245C133.564 196.877 133.76 197.164 133.764 197.323C133.764 197.107 133.451 196.714 133.344 196.525C133.196 196.264 133.042 196.004 132.877 195.75C132.53 195.209 132.141 194.705 131.739 194.204C131.478 193.876 132.096 194.57 131.794 194.275C131.687 194.171 131.588 194.056 131.481 193.947C131.244 193.704 130.997 193.47 130.742 193.24C129.628 192.232 129.412 190.325 130.515 189.215C131.601 188.123 133.413 187.913 134.606 188.992H134.609Z" fill="black"/>
      <path d="M136.637 201.725C143.993 203.599 151.682 203.92 159.206 202.963C173.223 201.187 187.168 195.779 199.085 188.368C205.795 184.197 211.944 178.876 216.526 172.534C216.918 168.472 216.952 164.43 216.609 160.445C211.284 174.486 197.573 183.839 183.937 189.87C169.583 196.218 153.442 200.125 137.864 196.154C134.244 195.231 133.028 200.805 136.633 201.725H136.637Z" fill="black"/>
      <path d="M69.1068 47.5466C69.1068 47.5466 50.6776 46.0097 39.6195 50.3311C28.5663 54.6584 71.486 58.671 69.1068 47.5466Z" fill="#FF530A"/>
      <path d="M-6.45041 102.877C-6.45041 102.877 -27.3787 75.092 20.0387 54.0514C20.0387 54.0514 36.2758 47.8924 56.2486 51.1213C76.2214 54.3503 59.0592 44.7779 75.2492 48.3101C91.4391 51.8423 124.559 81.2325 124.559 81.2325C124.559 81.2325 101.179 136.742 52.0609 147.919C52.0609 147.919 43.3011 139.477 42.5958 131.543C41.8904 123.608 39.3896 123.235 35.6275 120.741C31.8654 118.247 34.2198 113.142 35.5298 110.602C36.8399 108.062 35.8395 105.96 31.9504 104.795C28.0624 103.619 29.5609 96.3025 29.5609 96.3025C29.5609 96.3025 27.2818 100.621 22.6708 94.4425C18.0647 88.2694 23.0811 88.749 18.0647 88.2694C13.0482 87.7897 21.9156 92.6381 9.36136 103.97C-3.19289 115.302 -6.45041 102.877 -6.45041 102.877Z" fill="#FF530A"/>
      <path opacity="0.53" d="M124.553 81.237C124.553 81.237 101.173 136.746 52.0552 147.923C52.0552 147.923 43.2954 139.482 42.5901 131.547C41.8848 123.613 39.3839 123.24 35.6219 120.745C31.8598 118.251 34.2141 113.146 35.5242 110.606C36.8342 108.066 35.8339 105.965 31.9448 104.799C28.0567 103.623 29.5553 96.307 29.5553 96.307C29.5553 96.307 27.2761 100.626 22.6652 94.447C18.059 88.2739 23.0755 88.7535 18.059 88.2739C13.0425 87.7942 21.91 92.6426 9.35572 103.975C-3.19852 115.307 -6.45607 102.881 -6.45607 102.881C-3.50278 104.692 0.579561 103.388 3.69909 101.889C6.26254 100.654 8.84078 99.1528 10.358 96.7452C12.071 94.0292 12.172 90.6175 13.3989 87.6567C14.6258 84.6958 18.091 82.0509 20.8781 83.6366C23.0752 84.887 23.5282 88.0568 25.6863 89.3786C28.2396 90.9419 31.6275 89.1047 34.5111 89.9059C36.6001 90.4918 38.076 92.3865 38.9425 94.3731C39.809 96.3597 40.2242 98.523 41.052 100.522C41.7154 102.108 42.6403 103.597 43.1208 105.246C43.8277 107.668 43.5675 110.388 44.8343 112.563C46.4596 115.363 50.3102 116.707 51.0166 119.863C51.5175 122.083 50.3074 124.756 51.7715 126.494C52.8789 127.812 54.8791 127.756 56.5888 127.598C57.1894 127.543 57.8015 127.478 58.324 127.174C60.4312 125.944 59.0185 122.714 59.4357 120.314C59.8863 117.789 62.5526 116.263 65.0748 115.797C67.6018 115.336 70.2444 115.572 72.6963 114.831C76.031 113.83 78.5846 111.184 80.829 108.519C87.1821 100.964 92.6791 92.1 101.483 87.6328C107.276 84.701 113.906 84.005 120.345 83.1834C121.604 83.025 124.482 82.4797 124.553 81.237Z" fill="#FF530A"/>
      <path d="M7.67444 66.7037C16.0171 61.7848 24.919 57.8042 34.1494 54.8739C37.5231 53.8022 41.104 52.863 44.5757 53.5703C47.9422 54.2568 50.8088 56.4132 54.0083 57.6683C55.9515 58.4333 58.0133 58.8557 60.0596 59.2712C62.2479 59.7164 64.7361 60.1098 66.5401 58.7969C67.3762 58.185 67.9507 57.2801 68.7916 56.674C70.7683 55.2382 73.528 55.8774 75.8274 56.7301C82.1913 59.1029 88.0961 62.6866 93.1303 67.2436C95.7754 69.6416 98.3135 72.5978 98.5627 76.1609C98.6017 76.7063 98.5749 77.2668 98.3715 77.7675C97.6344 79.5311 95.2377 79.6398 93.5208 80.4891C89.9748 82.2415 89.596 86.9888 88.5034 90.7883C86.1433 98.982 78.7713 104.686 71.2994 108.797C68.6217 110.274 65.1763 111.59 62.6801 109.823C61.0528 108.67 60.4536 106.581 59.8912 104.666C57.2625 95.6467 53.1202 86.8099 46.2698 80.3846C39.4193 73.9594 29.482 70.3172 20.3963 72.6875C16.9151 73.5934 13.3903 75.3478 9.88831 74.5089C8.72965 74.2318 7.64095 73.6719 6.45365 73.5262C2.70043 73.0708 0.795168 76.3958 -1.89525 78.1174C-4.74817 73.8548 4.94104 68.3139 7.67444 66.7037Z" fill="#FF530A"/>
      <path d="M36.0653 57.387C40.5346 56.0178 45.6772 54.7291 49.6268 57.225C52.7716 59.2203 54.6989 63.4051 58.3999 63.8448C61.5871 64.2193 64.2161 61.5694 67.0707 60.0942C69.8468 58.6543 73.162 58.3063 76.1731 59.1197C82.0427 60.716 85.8953 66.1896 89.0492 71.398C90.1102 73.1458 91.1774 74.996 91.3031 77.0459C91.3331 77.4616 91.3069 77.9042 91.08 78.2579C90.4189 79.2833 88.8701 78.7116 87.6603 78.6335C85.4483 78.4917 83.6111 80.4879 82.9217 82.5938C82.2372 84.7056 82.3522 86.9797 81.9979 89.1713C81.6494 91.3581 80.6332 93.6848 78.5925 94.5569C76.697 95.3677 74.4374 94.6583 72.8525 93.3431C71.2676 92.0278 70.2138 90.2057 69.1549 88.4366C64.8915 81.3149 59.8579 74.3395 52.787 69.9953C46.84 66.3485 39.6362 64.7964 32.7208 65.6689C29.2195 66.1117 25.8112 67.1532 22.3182 67.6773C20.6206 67.9333 18.6986 68.0122 17.4008 66.8907C13.4852 63.5347 20.8415 62.034 22.582 61.4979C27.0879 60.1376 31.5737 58.7647 36.0653 57.387Z" fill="#FF530A"/>
      <g filter="url(#filter0_d_3053_1124)">
        <path d="M41.8258 134.549C39.1553 138.026 39.6014 143.512 42.8004 146.51C38.0817 149.142 35.7962 155.434 37.732 160.483C38.4712 162.398 39.8548 164.193 41.8191 164.794C43.7775 165.399 46.2723 164.378 46.7523 162.386C46.0206 164.879 47.7573 167.635 50.1352 168.678C52.513 169.72 55.314 169.366 57.7417 168.429C62.078 166.768 65.6814 163.295 67.505 159.029C67.1548 161.569 69.4406 163.912 71.9594 164.378C74.4782 164.844 77.055 163.862 79.2457 162.543C82.5861 160.53 85.6051 157.166 85.3816 153.279C92.5691 155.618 100.91 154.029 106.744 149.213C109.043 147.315 110.885 143.533 108.577 141.65C110.678 141.1 112.79 140.551 114.891 140.002C118.388 139.092 122.203 137.95 124.166 134.92C126.124 131.884 124.339 126.603 120.74 126.891C123.794 126.583 126.902 126.102 129.624 124.668C132.335 123.233 134.624 120.668 134.985 117.619C135.346 114.57 133.291 111.215 130.255 110.775C133.164 110.871 136.072 110.966 138.985 111.068C142.748 111.192 146.984 111.141 149.627 108.46C151.164 106.907 151.872 104.669 151.925 102.481C152.085 95.3102 144.428 89.0813 137.435 90.7025C140.28 88.8831 141.655 85.0462 140.611 81.836C139.566 78.6258 136.203 76.3308 132.827 76.5335C130.89 69.8113 122.913 65.5199 116.237 67.595C109.555 69.675 105.427 77.7319 107.651 84.3636C102.616 84.4184 97.7222 87.4791 95.4837 91.9896C93.2452 96.5 93.7519 102.249 96.7489 106.295C92.7279 107.294 88.6728 108.987 86.0643 112.207C83.4558 115.427 82.7749 120.473 85.4309 123.655C81.0942 121.674 75.9951 120.656 71.5808 122.459C67.1666 124.263 64.0618 129.645 66.0047 134.003C65.8902 131.611 64.0507 129.536 61.8375 128.622C60.0006 127.867 57.7732 127.719 55.8323 128.107C53.6235 128.55 53.0414 130.039 51.4704 130.709C48.1249 132.159 44.545 131.012 41.8258 134.549Z" fill="#FFE6DE"/>
        <path d="M59.2736 132.383C55.9593 132.946 53.703 136.913 54.915 140.054C53.7108 138.571 51.9417 137.554 50.0495 137.266C49.0826 137.115 48.0645 137.162 47.1647 137.543C44.9352 138.483 43.9859 141.4 44.792 143.681C45.5982 145.963 47.7898 147.55 50.1271 148.176C47.1405 148.732 44.2214 150.378 42.7802 153.056C41.339 155.734 41.7489 159.468 44.1568 161.317C46.5647 163.167 50.7722 162.181 51.6403 159.271C51.6162 160.701 52.688 161.999 54.0097 162.533C55.3315 163.067 56.8449 162.944 58.2108 162.511C60.1905 161.885 62.012 160.558 62.9148 158.687C63.8177 156.816 63.6686 154.394 62.2887 152.841C62.9423 155.708 61.5112 159.121 58.7181 160.066C55.9308 161.006 52.3939 158.512 52.886 155.61C51.5226 157.362 49.733 159.309 47.5305 159.012C45.6506 158.763 44.3223 156.727 44.5457 154.839C44.7681 152.962 46.2804 151.39 48.0688 150.746C49.8513 150.107 51.8504 150.287 53.6494 150.878C52.1958 149.816 50.7085 148.714 49.7606 147.186C48.8068 145.663 48.5014 143.586 49.4973 142.088C50.3744 140.767 52.1103 140.166 53.68 140.407C55.2496 140.649 56.637 141.618 57.6463 142.841C56.0594 140.705 56.7695 137.26 59.0589 135.918C61.4336 134.526 64.1364 135.76 65.7456 137.662C66.1603 134.56 62.1738 131.889 59.2736 132.383Z" fill="#FFE6DE"/>
        <path d="M83.84 148.708C87.181 151.118 91.6166 151.784 95.6419 150.908C98.2109 150.344 100.708 149.135 102.402 147.12C104.096 145.105 104.85 142.211 103.927 139.753C106.932 139.563 109.972 139.226 112.765 138.115C115.558 137.004 118.122 135.034 119.396 132.308C120.671 129.583 120.423 126.057 118.353 123.87C116.283 121.682 112.353 121.392 110.304 123.593C111.866 120.209 109.76 115.76 106.242 114.534C102.717 113.312 98.5052 115.242 96.8193 118.572C98.5086 115.542 103.305 115.293 105.774 117.739C108.238 120.178 108.375 124.353 106.697 127.383C109.365 125.67 112.882 123.942 115.486 125.757C117.673 127.28 117.775 130.7 116.308 132.919C114.841 135.139 112.263 136.356 109.72 137.153C107.156 137.949 104.193 138.368 101.91 136.954C103.655 140.907 101.866 145.937 98.2408 148.293C94.6156 150.649 89.6251 150.403 85.9626 148.101C82.6848 146.039 80.9657 142.818 79.6713 139.305C76.6365 140.87 82.1993 147.526 83.84 148.708Z" fill="#FFE6DE"/>
        <path d="M98.4673 97.4076C98.4594 94.7416 99.7781 92.2239 101.511 90.1963C102.99 88.4662 104.881 86.9739 107.111 86.5328C109.341 86.0918 111.93 86.9507 113.004 88.9518C112.51 86.3251 112.864 83.4684 114.337 81.2337C115.811 78.999 118.496 77.5074 121.155 77.7992C123.818 78.0968 126.215 80.396 126.298 83.0691C126.856 81.267 129.421 81.0779 131.144 81.8486C133.386 82.8566 135.155 84.9992 135.403 87.4469C135.652 89.8946 134.192 92.4899 131.859 93.2697C134.624 92.3972 137.93 93.8287 139.187 96.4425C140.444 99.0564 139.501 102.527 137.099 104.147C134.697 105.768 131.12 105.324 129.169 103.175C131.21 103.595 133.449 103.793 135.273 102.777C137.097 101.761 138.105 99.117 136.779 97.51C134.919 95.2569 131.135 97.0884 128.397 96.049C130.234 94.7285 131.9 93.0108 132.573 90.8497C133.247 88.6886 132.674 86.0383 130.795 84.77C128.921 83.5076 125.829 84.3274 125.27 86.5209C126.482 83.8858 124.319 80.4828 121.45 80.0422C118.58 79.6015 115.69 81.7224 114.778 84.4826C113.86 87.2371 114.681 90.367 116.405 92.7036C114.367 91.3022 112.081 90.0861 109.607 89.9354C106.44 89.7399 103.233 91.4764 101.661 94.2327C99.9285 97.2685 101.645 99.7868 101.028 102.656C99.3302 101.619 98.4699 99.3438 98.4673 97.4076Z" fill="#FFE6DE"/>
        <path d="M71.3247 143.364C71.5443 142.077 72.5583 140.838 73.8588 140.753C72.7679 140.048 72.4556 138.603 72.3379 137.31C72.0799 134.4 72.446 131.132 74.6021 129.166C76.1523 127.759 78.4812 127.295 80.4606 127.962C82.439 128.639 83.9967 130.408 84.4338 132.455C86.1918 129.604 90.8566 129.219 93.0603 131.746C95.264 134.273 94.2481 138.842 91.1805 140.19C92.6031 138.996 93.1014 136.812 92.3244 135.124C91.5474 133.435 89.5552 132.398 87.7315 132.738C85.9078 133.079 84.4162 134.77 84.3244 136.627C83.948 134.843 83.088 133.125 81.6986 131.952C80.3093 130.779 78.3584 130.206 76.6075 130.682C75.9687 130.857 75.3544 131.169 74.895 131.656C74.4296 132.147 74.1435 132.785 73.9481 133.426C73.0185 136.474 74.041 140.025 76.4468 142.122C75.59 141.38 73.1774 145.3 73.1184 145.917C72.9884 147.277 74.0545 148.521 74.214 149.77C72.2352 148.76 70.9559 145.538 71.3247 143.364Z" fill="#FFE6DE"/>
      </g>
      <path d="M-13.5974 106.729C-13.7091 109.144 -11.8601 111.5 -9.49058 111.957C-11.4646 113.81 -10.569 117.153 -9.01408 119.371C-7.78913 121.119 -5.9899 122.743 -3.85997 122.697C-1.73004 122.651 0.18609 120.013 -1.10503 118.318C-1.23992 119.73 0.172806 120.915 1.5884 121.06C3.004 121.205 4.37988 120.615 5.67822 120.03C7.5605 119.176 9.56463 118.204 10.5672 116.398C11.5698 114.592 10.89 111.749 8.85702 111.374C11.4581 112.031 14.321 111.986 16.7134 110.754C19.0957 109.526 20.886 106.979 20.7575 104.294C20.629 101.609 18.2725 99.0909 15.587 99.2025C18.9868 98.258 21.0544 93.941 19.4448 90.8055C17.8352 87.6699 12.8215 86.9683 10.5833 89.689C11.8975 86.1282 10.2239 81.721 6.87316 79.9217C3.32045 78.0196 -1.194 79.0847 -4.57027 81.2985C-7.45196 83.185 -9.88401 85.9817 -10.6088 89.3513C-11.3304 92.714 -10.0045 96.617 -6.99169 98.2834C-8.62668 97.3776 -9.26984 101.535 -9.59388 101.926C-11.0922 103.759 -13.4446 103.568 -13.5974 106.729Z" fill="#FFE6DE"/>
      <path d="M-10.6579 107.802C-10.7697 110.216 -8.92068 112.572 -6.55113 113.029C-8.5252 114.882 -7.62953 118.225 -6.07464 120.444C-4.84968 122.191 -3.05044 123.815 -0.920515 123.769C1.20942 123.723 3.12554 121.085 1.83442 119.39C1.69953 120.802 3.11226 121.987 4.52786 122.132C5.94345 122.277 7.31933 121.687 8.61768 121.102C10.5 120.248 12.5041 119.277 13.5067 117.47C14.5092 115.664 13.8295 112.821 11.7965 112.446C14.3976 113.103 17.2604 113.058 19.6528 111.826C22.0452 110.594 23.8254 108.051 23.6969 105.366C23.5685 102.681 21.212 100.163 18.5265 100.275C21.9262 99.3303 23.9938 95.0133 22.3843 91.8777C20.7747 88.7422 15.7609 88.0406 13.5228 90.7612C14.8369 87.2004 13.1633 82.7933 9.8126 80.9939C6.25989 79.0919 1.74545 80.157 -1.63082 82.3707C-4.5125 84.2573 -6.94456 87.054 -7.66936 90.4235C-8.39097 93.7863 -7.06508 97.6893 -4.05224 99.3557C-5.68723 98.4499 -6.33039 102.608 -6.65443 102.998C-8.15963 104.828 -10.512 104.637 -10.6579 107.802Z" fill="#FFE6DE"/>
      <path d="M4.90565 89.0207C6.26632 89.5149 7.36168 90.5768 8.15366 91.7886C9.19217 93.3823 9.77163 95.3698 9.273 97.2034C10.8295 95.0142 13.4819 93.6539 16.171 93.6773C17.1575 93.6883 18.2515 93.9329 18.8033 94.7492C19.239 95.3861 19.2412 96.2372 19.0515 96.9819C18.5359 99.0659 16.5902 100.715 14.4502 100.89C16.2058 101.377 17.8198 102.381 19.0281 103.754C19.7418 104.563 20.3332 105.598 20.1621 106.659C19.9849 107.751 19.024 108.577 17.9761 108.929C16.9315 109.274 15.7954 109.227 14.6931 109.178C13.9493 109.147 13.1849 109.107 12.5014 108.804C11.8179 108.501 11.2372 107.871 11.2075 107.133C12.5655 107.65 14.0315 108.169 15.4441 107.821C16.8568 107.473 18.0399 105.851 17.3707 104.555C17.0395 103.908 16.3574 103.531 15.6976 103.231C14.3032 102.613 12.8098 102.206 11.2921 102.03C12.7396 101.34 14.1083 100.48 15.3618 99.4741C15.8445 99.083 16.3296 98.6514 16.5744 98.0741C16.8192 97.4969 16.7521 96.7488 16.2657 96.3632C15.7378 95.9415 14.9646 96.0801 14.3324 96.3096C11.5214 97.2959 9.24259 99.3725 7.0568 101.393C7.37784 99.8677 7.70482 98.3122 7.5926 96.7514C7.26687 92.0992 4.38062 93.1753 1.63473 91.2586C-1.01816 89.4104 2.68909 88.2181 4.90565 89.0207Z" fill="#FFE6DE"/>
      <defs>
        <filter id="filter0_d_3053_1124" x="33.2214" y="65.1576" width="122.528" height="109.887" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dy="1.91081"/>
          <feGaussianBlur stdDeviation="1.91081"/>
          <feComposite in2="hardAlpha" operator="out"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3053_1124"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3053_1124" result="shape"/>
        </filter>
      </defs>
    </g>
  )
}

export const HeadBlock13 = () => {
  return (
    <g>
      <path d="M199.253 143.655C198.793 142.593 198.311 141.478 197.693 140.508C198.233 141.36 197.616 140.398 197.516 140.234C197.387 140.024 197.252 139.807 197.118 139.605C196.799 139.114 196.475 138.637 196.132 138.169C195.453 137.231 194.72 136.338 193.946 135.49C193.782 135.309 193.611 135.13 193.441 134.95C193.181 134.681 193.215 134.713 193.536 135.046C193.42 134.931 193.305 134.816 193.189 134.701C192.776 134.306 192.357 133.924 191.926 133.551C190.605 132.405 190.073 130.206 191.184 128.693C192.213 127.282 194.27 126.716 195.678 127.945C199.705 131.44 202.941 135.845 205.133 140.896C205.849 142.549 205.107 144.809 203.506 145.466C201.857 146.148 200.022 145.434 199.254 143.662L199.253 143.655Z" fill="black"/>
      <path d="M201.289 179.426C201.289 179.426 230.671 149.274 227.962 126.246L234.308 138.125L243.035 132.264C243.035 132.264 226.605 176.341 201.289 179.426Z" fill="#FF530A"/>
      <path d="M216.702 160.71C215.935 151.036 212.935 141.727 207.486 133.396C186.168 100.786 135.51 95.4821 94.3378 121.548C61.3776 142.414 50.0518 176.564 50.0002 206.773C49.8902 270.759 96.3795 305 151.988 305V249.446C158.632 246.906 165.194 243.642 171.529 239.63C197.859 222.961 213.938 197.501 216.527 172.782C216.953 168.723 217.018 164.688 216.698 160.71H216.702Z" fill="#EFB825"/>
      <path d="M117.533 171.228C117.66 171.302 117.787 171.367 117.918 171.438C118.079 171.522 118.244 171.61 118.406 171.691C118.557 171.769 118.712 171.844 118.859 171.918C131.227 177.902 146.055 173.782 153.5 162.664C154.033 161.869 154.528 161.037 154.982 160.167C161.85 147.043 156.608 130.926 143.274 124.171C129.938 117.413 113.559 122.571 106.691 135.695C106.238 136.564 105.836 137.447 105.485 138.34C100.673 150.615 105.701 164.696 117.529 171.228H117.533Z" fill="#FFE6DE"/>
      <path d="M117.983 172.425C118.096 172.492 118.217 172.553 118.337 172.618C118.485 172.695 118.632 172.773 118.784 172.848C118.921 172.919 119.062 172.986 119.2 173.054C130.484 178.513 144.013 174.755 150.806 164.608C154.202 155.255 148.413 143.697 137.049 137.94C125.686 132.18 112.758 134.257 106.997 142.415C102.604 153.615 107.193 166.461 117.986 172.421L117.983 172.425Z" fill="black"/>
      <path d="M111.225 222.625C120.81 222.625 128.58 214.979 128.58 205.547C128.58 196.115 120.81 188.469 111.225 188.469C101.64 188.469 93.8701 196.115 93.8701 205.547C93.8701 214.979 101.64 222.625 111.225 222.625Z" fill="#FF530A"/>
      <path d="M134.609 188.992C139.349 193.278 141.535 199.806 140.384 206.026C140.112 207.498 138.418 208.59 136.94 208.208C135.379 207.805 134.434 206.388 134.723 204.819C134.778 204.524 134.826 204.227 134.867 203.929C134.888 203.78 134.901 203.631 134.918 203.483C134.894 203.716 134.894 203.848 134.905 203.537C134.925 202.897 134.929 202.265 134.877 201.629C134.853 201.331 134.822 201.03 134.781 200.736C134.771 200.672 134.702 200.178 134.757 200.543C134.815 200.908 134.729 200.421 134.716 200.354C134.585 199.728 134.417 199.112 134.21 198.507C134.114 198.223 134.008 197.942 133.894 197.661C133.839 197.523 133.777 197.384 133.719 197.245C133.564 196.877 133.76 197.164 133.764 197.323C133.764 197.107 133.451 196.714 133.344 196.525C133.196 196.264 133.042 196.004 132.877 195.75C132.53 195.209 132.141 194.705 131.739 194.204C131.478 193.876 132.096 194.57 131.794 194.275C131.687 194.171 131.588 194.056 131.481 193.947C131.244 193.704 130.997 193.47 130.742 193.24C129.628 192.232 129.412 190.325 130.515 189.215C131.601 188.123 133.413 187.913 134.606 188.992H134.609Z" fill="black"/>
      <path d="M136.637 201.725C143.993 203.599 151.682 203.92 159.206 202.963C173.223 201.187 187.168 195.779 199.085 188.368C205.795 184.197 211.944 178.876 216.526 172.534C216.918 168.472 216.952 164.43 216.609 160.445C211.284 174.486 197.573 183.839 183.937 189.87C169.583 196.218 153.442 200.125 137.864 196.154C134.244 195.231 133.028 200.805 136.633 201.725H136.637Z" fill="black"/>
      <path d="M69.1068 47.5466C69.1068 47.5466 50.6776 46.0097 39.6195 50.3311C28.5663 54.6584 71.486 58.671 69.1068 47.5466Z" fill="#FF530A"/>
      <path d="M-6.45041 102.877C-6.45041 102.877 -27.3787 75.092 20.0387 54.0514C20.0387 54.0514 36.2758 47.8924 56.2486 51.1213C76.2214 54.3503 59.0592 44.7779 75.2492 48.3101C91.4391 51.8423 124.559 81.2325 124.559 81.2325C124.559 81.2325 101.179 136.742 52.0609 147.919C52.0609 147.919 43.3011 139.477 42.5958 131.543C41.8904 123.608 39.3896 123.235 35.6275 120.741C31.8654 118.247 34.2198 113.142 35.5298 110.602C36.8399 108.062 35.8395 105.96 31.9504 104.795C28.0624 103.619 29.5609 96.3025 29.5609 96.3025C29.5609 96.3025 27.2818 100.621 22.6708 94.4425C18.0647 88.2694 23.0811 88.749 18.0647 88.2694C13.0482 87.7897 21.9156 92.6381 9.36136 103.97C-3.19289 115.302 -6.45041 102.877 -6.45041 102.877Z" fill="#FF530A"/>
      <path opacity="0.53" d="M124.553 81.237C124.553 81.237 101.173 136.746 52.0552 147.923C52.0552 147.923 43.2954 139.482 42.5901 131.547C41.8848 123.613 39.3839 123.24 35.6219 120.745C31.8598 118.251 34.2141 113.146 35.5242 110.606C36.8342 108.066 35.8339 105.965 31.9448 104.799C28.0567 103.623 29.5553 96.307 29.5553 96.307C29.5553 96.307 27.2761 100.626 22.6652 94.447C18.059 88.2739 23.0755 88.7535 18.059 88.2739C13.0425 87.7942 21.91 92.6426 9.35572 103.975C-3.19852 115.307 -6.45607 102.881 -6.45607 102.881C-3.50278 104.692 0.579561 103.388 3.69909 101.889C6.26254 100.654 8.84078 99.1528 10.358 96.7452C12.071 94.0292 12.172 90.6175 13.3989 87.6567C14.6258 84.6958 18.091 82.0509 20.8781 83.6366C23.0752 84.887 23.5282 88.0568 25.6863 89.3786C28.2396 90.9419 31.6275 89.1047 34.5111 89.9059C36.6001 90.4918 38.076 92.3865 38.9425 94.3731C39.809 96.3597 40.2242 98.523 41.052 100.522C41.7154 102.108 42.6403 103.597 43.1208 105.246C43.8277 107.668 43.5675 110.388 44.8343 112.563C46.4596 115.363 50.3102 116.707 51.0166 119.863C51.5175 122.083 50.3074 124.756 51.7715 126.494C52.8789 127.812 54.8791 127.756 56.5888 127.598C57.1894 127.543 57.8015 127.478 58.324 127.174C60.4312 125.944 59.0185 122.714 59.4357 120.314C59.8863 117.789 62.5526 116.263 65.0748 115.797C67.6018 115.336 70.2444 115.572 72.6963 114.831C76.031 113.83 78.5846 111.184 80.829 108.519C87.1821 100.964 92.6791 92.1 101.483 87.6328C107.276 84.701 113.906 84.005 120.345 83.1834C121.604 83.025 124.482 82.4797 124.553 81.237Z" fill="#FF530A"/>
      <path d="M7.67444 66.7037C16.0171 61.7848 24.919 57.8042 34.1494 54.8739C37.5231 53.8022 41.104 52.863 44.5757 53.5703C47.9422 54.2568 50.8088 56.4132 54.0083 57.6683C55.9515 58.4333 58.0133 58.8557 60.0596 59.2712C62.2479 59.7164 64.7361 60.1098 66.5401 58.7969C67.3762 58.185 67.9507 57.2801 68.7916 56.674C70.7683 55.2382 73.528 55.8774 75.8274 56.7301C82.1913 59.1029 88.0961 62.6866 93.1303 67.2436C95.7754 69.6416 98.3135 72.5978 98.5627 76.1609C98.6017 76.7063 98.5749 77.2668 98.3715 77.7675C97.6344 79.5311 95.2377 79.6398 93.5208 80.4891C89.9748 82.2415 89.596 86.9888 88.5034 90.7883C86.1433 98.982 78.7713 104.686 71.2994 108.797C68.6217 110.274 65.1763 111.59 62.6801 109.823C61.0528 108.67 60.4536 106.581 59.8912 104.666C57.2625 95.6467 53.1202 86.8099 46.2698 80.3846C39.4193 73.9594 29.482 70.3172 20.3963 72.6875C16.9151 73.5934 13.3903 75.3478 9.88831 74.5089C8.72965 74.2318 7.64095 73.6719 6.45365 73.5262C2.70043 73.0708 0.795168 76.3958 -1.89525 78.1174C-4.74817 73.8548 4.94104 68.3139 7.67444 66.7037Z" fill="#FF530A"/>
      <path d="M36.0653 57.387C40.5346 56.0178 45.6772 54.7291 49.6268 57.225C52.7716 59.2203 54.6989 63.4051 58.3999 63.8448C61.5871 64.2193 64.2161 61.5694 67.0707 60.0942C69.8468 58.6543 73.162 58.3063 76.1731 59.1197C82.0427 60.716 85.8953 66.1896 89.0492 71.398C90.1102 73.1458 91.1774 74.996 91.3031 77.0459C91.3331 77.4616 91.3069 77.9042 91.08 78.2579C90.4189 79.2833 88.8701 78.7116 87.6603 78.6335C85.4483 78.4917 83.6111 80.4879 82.9217 82.5938C82.2372 84.7056 82.3522 86.9797 81.9979 89.1713C81.6494 91.3581 80.6332 93.6848 78.5925 94.5569C76.697 95.3677 74.4374 94.6583 72.8525 93.3431C71.2676 92.0278 70.2138 90.2057 69.1549 88.4366C64.8915 81.3149 59.8579 74.3395 52.787 69.9953C46.84 66.3485 39.6362 64.7964 32.7208 65.6689C29.2195 66.1117 25.8112 67.1532 22.3182 67.6773C20.6206 67.9333 18.6986 68.0122 17.4008 66.8907C13.4852 63.5347 20.8415 62.034 22.582 61.4979C27.0879 60.1376 31.5737 58.7647 36.0653 57.387Z" fill="#FF530A"/>
      <g filter="url(#filter0_d_3053_1414)">
        <path d="M41.8258 134.549C39.1553 138.026 39.6014 143.512 42.8004 146.51C38.0817 149.142 35.7962 155.434 37.732 160.483C38.4712 162.398 39.8548 164.193 41.8191 164.794C43.7775 165.399 46.2723 164.378 46.7523 162.386C46.0206 164.879 47.7573 167.635 50.1352 168.678C52.513 169.72 55.314 169.366 57.7417 168.429C62.078 166.768 65.6814 163.295 67.505 159.029C67.1548 161.569 69.4406 163.912 71.9594 164.378C74.4782 164.844 77.055 163.862 79.2457 162.543C82.5861 160.53 85.6051 157.166 85.3816 153.279C92.5691 155.618 100.91 154.029 106.744 149.213C109.043 147.315 110.885 143.533 108.577 141.65C110.678 141.1 112.79 140.551 114.891 140.002C118.388 139.092 122.203 137.95 124.166 134.92C126.124 131.884 124.339 126.603 120.74 126.891C123.794 126.583 126.902 126.102 129.624 124.668C132.335 123.233 134.624 120.668 134.985 117.619C135.346 114.57 133.291 111.215 130.255 110.775C133.164 110.871 136.072 110.966 138.985 111.068C142.748 111.192 146.984 111.141 149.627 108.46C151.164 106.907 151.872 104.669 151.925 102.481C152.085 95.3102 144.428 89.0813 137.435 90.7025C140.28 88.8831 141.655 85.0462 140.611 81.836C139.566 78.6258 136.203 76.3308 132.827 76.5335C130.89 69.8113 122.913 65.5199 116.237 67.595C109.555 69.675 105.427 77.7319 107.651 84.3636C102.616 84.4184 97.7222 87.4791 95.4837 91.9896C93.2452 96.5 93.7519 102.249 96.7489 106.295C92.7279 107.294 88.6728 108.987 86.0643 112.207C83.4558 115.427 82.7749 120.473 85.4309 123.655C81.0942 121.674 75.9951 120.656 71.5808 122.459C67.1666 124.263 64.0618 129.645 66.0047 134.003C65.8902 131.611 64.0507 129.536 61.8375 128.622C60.0006 127.867 57.7732 127.719 55.8323 128.107C53.6235 128.55 53.0414 130.039 51.4704 130.709C48.1249 132.159 44.545 131.012 41.8258 134.549Z" fill="#FFE6DE"/>
        <path d="M59.2736 132.383C55.9593 132.946 53.703 136.913 54.915 140.054C53.7108 138.571 51.9417 137.554 50.0495 137.266C49.0826 137.115 48.0645 137.162 47.1647 137.543C44.9352 138.483 43.9859 141.4 44.792 143.681C45.5982 145.963 47.7898 147.55 50.1271 148.176C47.1405 148.732 44.2214 150.378 42.7802 153.056C41.339 155.734 41.7489 159.468 44.1568 161.317C46.5647 163.167 50.7722 162.181 51.6403 159.271C51.6162 160.701 52.688 161.999 54.0097 162.533C55.3315 163.067 56.8449 162.944 58.2108 162.511C60.1905 161.885 62.012 160.558 62.9148 158.687C63.8177 156.816 63.6686 154.394 62.2887 152.841C62.9423 155.708 61.5112 159.121 58.7181 160.066C55.9308 161.006 52.3939 158.512 52.886 155.61C51.5226 157.362 49.733 159.309 47.5305 159.012C45.6506 158.763 44.3223 156.727 44.5457 154.839C44.7681 152.962 46.2804 151.39 48.0688 150.746C49.8513 150.107 51.8504 150.287 53.6494 150.878C52.1958 149.816 50.7085 148.714 49.7606 147.186C48.8068 145.663 48.5014 143.586 49.4973 142.088C50.3744 140.767 52.1103 140.166 53.68 140.407C55.2496 140.649 56.637 141.618 57.6463 142.841C56.0594 140.705 56.7695 137.26 59.0589 135.918C61.4336 134.526 64.1364 135.76 65.7456 137.662C66.1603 134.56 62.1738 131.889 59.2736 132.383Z" fill="#FFE6DE"/>
        <path d="M83.84 148.708C87.181 151.118 91.6166 151.784 95.6419 150.908C98.2109 150.344 100.708 149.135 102.402 147.12C104.096 145.105 104.85 142.211 103.927 139.753C106.932 139.563 109.972 139.226 112.765 138.115C115.558 137.004 118.122 135.034 119.396 132.308C120.671 129.583 120.423 126.057 118.353 123.87C116.283 121.682 112.353 121.392 110.304 123.593C111.866 120.209 109.76 115.76 106.242 114.534C102.717 113.312 98.5052 115.242 96.8193 118.572C98.5086 115.542 103.305 115.293 105.774 117.739C108.238 120.178 108.375 124.353 106.697 127.383C109.365 125.67 112.882 123.942 115.486 125.757C117.673 127.28 117.775 130.7 116.308 132.919C114.841 135.139 112.263 136.356 109.72 137.153C107.156 137.949 104.193 138.368 101.91 136.954C103.655 140.907 101.866 145.937 98.2408 148.293C94.6156 150.649 89.6251 150.403 85.9626 148.101C82.6848 146.039 80.9657 142.818 79.6713 139.305C76.6365 140.87 82.1993 147.526 83.84 148.708Z" fill="#FFE6DE"/>
        <path d="M98.4673 97.4076C98.4594 94.7416 99.7781 92.2239 101.511 90.1963C102.99 88.4662 104.881 86.9739 107.111 86.5328C109.341 86.0918 111.93 86.9507 113.004 88.9518C112.51 86.3251 112.864 83.4684 114.337 81.2337C115.811 78.999 118.496 77.5074 121.155 77.7992C123.818 78.0968 126.215 80.396 126.298 83.0691C126.856 81.267 129.421 81.0779 131.144 81.8486C133.386 82.8566 135.155 84.9992 135.403 87.4469C135.652 89.8946 134.192 92.4899 131.859 93.2697C134.624 92.3972 137.93 93.8287 139.187 96.4425C140.444 99.0564 139.501 102.527 137.099 104.147C134.697 105.768 131.12 105.324 129.169 103.175C131.21 103.595 133.449 103.793 135.273 102.777C137.097 101.761 138.105 99.117 136.779 97.51C134.919 95.2569 131.135 97.0884 128.397 96.049C130.234 94.7285 131.9 93.0108 132.573 90.8497C133.247 88.6886 132.674 86.0383 130.795 84.77C128.921 83.5076 125.829 84.3274 125.27 86.5209C126.482 83.8858 124.319 80.4828 121.45 80.0422C118.58 79.6015 115.69 81.7224 114.778 84.4826C113.86 87.2371 114.681 90.367 116.405 92.7036C114.367 91.3022 112.081 90.0861 109.607 89.9354C106.44 89.7399 103.233 91.4764 101.661 94.2327C99.9285 97.2685 101.645 99.7868 101.028 102.656C99.3302 101.619 98.4699 99.3438 98.4673 97.4076Z" fill="#FFE6DE"/>
        <path d="M71.3247 143.364C71.5443 142.077 72.5583 140.838 73.8588 140.753C72.7679 140.048 72.4556 138.603 72.3379 137.31C72.0799 134.4 72.446 131.132 74.6021 129.166C76.1523 127.759 78.4812 127.295 80.4606 127.962C82.439 128.639 83.9967 130.408 84.4338 132.455C86.1918 129.604 90.8566 129.219 93.0603 131.746C95.264 134.273 94.2481 138.842 91.1805 140.19C92.6031 138.996 93.1014 136.812 92.3244 135.124C91.5474 133.435 89.5552 132.398 87.7315 132.738C85.9078 133.079 84.4162 134.77 84.3244 136.627C83.948 134.843 83.088 133.125 81.6986 131.952C80.3093 130.779 78.3584 130.206 76.6075 130.682C75.9687 130.857 75.3544 131.169 74.895 131.656C74.4296 132.147 74.1435 132.785 73.9481 133.426C73.0185 136.474 74.041 140.025 76.4468 142.122C75.59 141.38 73.1774 145.3 73.1184 145.917C72.9884 147.277 74.0545 148.521 74.214 149.77C72.2352 148.76 70.9559 145.538 71.3247 143.364Z" fill="#FFE6DE"/>
      </g>
      <path d="M-9.96085 114.234C-8.99174 116.448 -6.29065 117.741 -3.96357 117.102C-4.9131 119.637 -2.62927 122.238 -0.252729 123.539C1.61947 124.563 3.95196 125.222 5.84115 124.238C7.73033 123.253 8.28009 120.039 6.37179 119.091C6.87616 120.417 8.66771 120.854 10.0011 120.357C11.3345 119.86 12.3066 118.721 13.2117 117.622C14.5213 116.023 15.8878 114.264 15.9867 112.201C16.0855 110.137 14.2171 107.889 12.2282 108.453C14.8514 107.891 17.3982 106.582 18.9976 104.418C20.5896 102.262 21.0668 99.1858 19.7625 96.8353C18.4582 94.4849 15.2302 93.2709 12.8719 94.5603C15.5017 92.2079 15.4436 87.4216 12.6119 85.3232C9.7801 83.2247 4.97413 84.8162 4.17231 88.2467C3.77359 84.4721 0.321251 81.2619 -3.47983 81.1326C-7.5075 81.0006 -11.0834 83.955 -13.1301 87.435C-14.8782 90.4027 -15.8202 93.9872 -14.9777 97.3293C-14.1355 100.664 -11.2181 103.576 -7.77887 103.736C-9.64593 103.648 -8.38121 107.66 -8.49892 108.154C-9.03051 110.461 -11.2242 111.331 -9.96085 114.234Z" fill="#FFE6DE"/>
      <path d="M-6.8505 113.894C-5.8814 116.108 -3.1803 117.402 -0.853222 116.762C-1.80276 119.297 0.481081 121.899 2.85762 123.199C4.72982 124.223 7.0623 124.883 8.95149 123.898C10.8407 122.913 11.3904 119.699 9.48213 118.751C9.98651 120.077 11.7781 120.514 13.1114 120.017C14.4448 119.52 15.417 118.382 16.322 117.282C17.6316 115.683 18.9981 113.924 19.097 111.861C19.1959 109.797 17.3275 107.55 15.3386 108.114C17.9618 107.551 20.5085 106.242 22.1079 104.078C23.7073 101.914 24.1772 98.846 22.8729 96.4955C21.5685 94.145 18.3406 92.9311 15.9822 94.2205C18.6121 91.868 18.554 87.0818 15.7222 84.9833C12.8904 82.8848 8.08447 84.4763 7.28266 87.9068C6.88394 84.1322 3.4316 80.9221 -0.369481 80.7928C-4.39716 80.6608 -7.97302 83.6151 -10.0197 87.0952C-11.7679 90.0628 -12.7098 93.6474 -11.8674 96.9895C-11.0251 100.324 -8.10779 103.236 -4.66851 103.396C-6.53558 103.308 -5.27087 107.321 -5.38858 107.814C-5.92772 110.121 -8.12142 110.992 -6.8505 113.894Z" fill="#FFE6DE"/>
      <path d="M-1.21435 90.1618C0.224494 90.0023 1.67688 90.4693 2.92364 91.2051C4.56055 92.174 5.96028 93.6993 6.32529 95.5641C6.75125 92.912 8.52692 90.5176 10.9482 89.3477C11.8376 88.9207 12.9268 88.6555 13.7831 89.143C14.4558 89.5211 14.8347 90.2831 14.9944 91.0348C15.4551 93.1317 14.4409 95.4718 12.5996 96.5763C14.3894 96.2356 16.2813 96.4212 17.9727 97.1172C18.9707 97.526 19.9592 98.1918 20.276 99.2195C20.6009 100.277 20.105 101.443 19.3214 102.223C18.5377 102.995 17.498 103.455 16.4882 103.9C15.8076 104.202 15.1043 104.504 14.3574 104.535C13.6105 104.566 12.8109 104.259 12.4571 103.61C13.904 103.473 15.448 103.288 16.5604 102.351C17.6729 101.413 18.0152 99.4345 16.8412 98.5688C16.2579 98.1359 15.4794 98.0999 14.7549 98.1231C13.2309 98.1862 11.712 98.4833 10.2733 98.9977C11.2655 97.7379 12.1117 96.3603 12.79 94.9034C13.0496 94.339 13.2934 93.7372 13.2572 93.1112C13.221 92.4852 12.8296 91.8443 12.2227 91.7139C11.5626 91.5696 10.9308 92.0363 10.4656 92.5221C8.38216 94.6513 7.25866 97.5224 6.19358 100.302C5.80605 98.7922 5.41032 97.2527 4.61848 95.9031C2.26608 91.8762 0.154887 94.1193 -3.15587 93.6169C-6.35292 93.1348 -3.55713 90.4239 -1.21435 90.1618Z" fill="#FFE6DE"/>
      <defs>
        <filter id="filter0_d_3053_1414" x="33.2214" y="65.1576" width="122.528" height="109.887" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dy="1.91081"/>
          <feGaussianBlur stdDeviation="1.91081"/>
          <feComposite in2="hardAlpha" operator="out"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3053_1414"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3053_1414" result="shape"/>
        </filter>
      </defs>
    </g>
  )
}

export const HeadBlock14 = () => {
  return (
    <g>
      <path d="M199.253 143.655C198.793 142.593 198.311 141.478 197.693 140.508C198.233 141.36 197.616 140.398 197.516 140.234C197.387 140.024 197.252 139.807 197.118 139.605C196.799 139.114 196.475 138.637 196.132 138.169C195.453 137.231 194.72 136.338 193.946 135.49C193.782 135.309 193.611 135.13 193.441 134.95C193.181 134.681 193.215 134.713 193.536 135.046C193.42 134.931 193.305 134.816 193.189 134.701C192.776 134.306 192.357 133.924 191.926 133.551C190.605 132.405 190.073 130.206 191.184 128.693C192.213 127.282 194.27 126.716 195.678 127.945C199.705 131.44 202.941 135.845 205.133 140.896C205.849 142.549 205.107 144.809 203.506 145.466C201.857 146.148 200.022 145.434 199.254 143.662L199.253 143.655Z" fill="black"/>
      <path d="M201.289 179.426C201.289 179.426 230.671 149.274 227.962 126.246L234.308 138.125L243.035 132.264C243.035 132.264 226.605 176.341 201.289 179.426Z" fill="#FF530A"/>
      <path d="M216.702 160.71C215.935 151.036 212.935 141.727 207.486 133.396C186.168 100.786 135.51 95.4821 94.3378 121.548C61.3776 142.414 50.0518 176.564 50.0002 206.773C49.8902 270.759 96.3795 305 151.988 305V249.446C158.632 246.906 165.194 243.642 171.529 239.63C197.859 222.961 213.938 197.501 216.527 172.782C216.953 168.723 217.018 164.688 216.698 160.71H216.702Z" fill="#EFB825"/>
      <path d="M117.533 171.228C117.66 171.302 117.787 171.367 117.918 171.438C118.079 171.522 118.244 171.61 118.406 171.691C118.557 171.769 118.712 171.844 118.859 171.918C131.227 177.902 146.055 173.782 153.5 162.664C154.033 161.869 154.528 161.037 154.982 160.167C161.85 147.043 156.608 130.926 143.274 124.171C129.938 117.413 113.559 122.571 106.691 135.695C106.238 136.564 105.836 137.447 105.485 138.34C100.673 150.615 105.701 164.696 117.529 171.228H117.533Z" fill="#FFE6DE"/>
      <path d="M117.983 172.425C118.096 172.492 118.217 172.553 118.337 172.618C118.485 172.695 118.632 172.773 118.784 172.848C118.921 172.919 119.062 172.986 119.2 173.054C130.484 178.513 144.013 174.755 150.806 164.608C154.202 155.255 148.413 143.697 137.049 137.94C125.686 132.18 112.758 134.257 106.997 142.415C102.604 153.615 107.193 166.461 117.986 172.421L117.983 172.425Z" fill="black"/>
      <path d="M111.225 222.625C120.81 222.625 128.58 214.979 128.58 205.547C128.58 196.115 120.81 188.469 111.225 188.469C101.64 188.469 93.8701 196.115 93.8701 205.547C93.8701 214.979 101.64 222.625 111.225 222.625Z" fill="#FF530A"/>
      <path d="M134.609 188.992C139.349 193.278 141.535 199.806 140.384 206.026C140.112 207.498 138.418 208.59 136.94 208.208C135.379 207.805 134.434 206.388 134.723 204.819C134.778 204.524 134.826 204.227 134.867 203.929C134.888 203.78 134.901 203.631 134.918 203.483C134.894 203.716 134.894 203.848 134.905 203.537C134.925 202.897 134.929 202.265 134.877 201.629C134.853 201.331 134.822 201.03 134.781 200.736C134.771 200.672 134.702 200.178 134.757 200.543C134.815 200.908 134.729 200.421 134.716 200.354C134.585 199.728 134.417 199.112 134.21 198.507C134.114 198.223 134.008 197.942 133.894 197.661C133.839 197.523 133.777 197.384 133.719 197.245C133.564 196.877 133.76 197.164 133.764 197.323C133.764 197.107 133.451 196.714 133.344 196.525C133.196 196.264 133.042 196.004 132.877 195.75C132.53 195.209 132.141 194.705 131.739 194.204C131.478 193.876 132.096 194.57 131.794 194.275C131.687 194.171 131.588 194.056 131.481 193.947C131.244 193.704 130.997 193.47 130.742 193.24C129.628 192.232 129.412 190.325 130.515 189.215C131.601 188.123 133.413 187.913 134.606 188.992H134.609Z" fill="black"/>
      <path d="M136.637 201.725C143.993 203.599 151.682 203.92 159.206 202.963C173.223 201.187 187.168 195.779 199.085 188.368C205.795 184.197 211.944 178.876 216.526 172.534C216.918 168.472 216.952 164.43 216.609 160.445C211.284 174.486 197.573 183.839 183.937 189.87C169.583 196.218 153.442 200.125 137.864 196.154C134.244 195.231 133.028 200.805 136.633 201.725H136.637Z" fill="black"/>
      <path d="M69.1068 47.5466C69.1068 47.5466 50.6776 46.0097 39.6195 50.3311C28.5663 54.6584 71.486 58.671 69.1068 47.5466Z" fill="#FF530A"/>
      <path d="M-6.45041 102.877C-6.45041 102.877 -27.3787 75.092 20.0387 54.0514C20.0387 54.0514 36.2758 47.8924 56.2486 51.1213C76.2214 54.3503 59.0592 44.7779 75.2492 48.3101C91.4391 51.8423 124.559 81.2325 124.559 81.2325C124.559 81.2325 101.179 136.742 52.0609 147.919C52.0609 147.919 43.3011 139.477 42.5958 131.543C41.8904 123.608 39.3896 123.235 35.6275 120.741C31.8654 118.247 34.2198 113.142 35.5298 110.602C36.8399 108.062 35.8395 105.96 31.9504 104.795C28.0624 103.619 29.5609 96.3025 29.5609 96.3025C29.5609 96.3025 27.2818 100.621 22.6708 94.4425C18.0647 88.2694 23.0811 88.749 18.0647 88.2694C13.0482 87.7897 21.9156 92.6381 9.36136 103.97C-3.19289 115.302 -6.45041 102.877 -6.45041 102.877Z" fill="#FF530A"/>
      <path opacity="0.53" d="M124.554 81.237C124.554 81.237 101.174 136.746 52.0562 147.923C52.0562 147.923 43.2964 139.482 42.5911 131.547C41.8857 123.613 39.3849 123.24 35.6228 120.745C31.8608 118.251 34.2151 113.146 35.5251 110.606C36.8352 108.066 35.8349 105.965 31.9458 104.799C28.0577 103.623 29.5563 96.307 29.5563 96.307C29.5563 96.307 27.2771 100.626 22.6661 94.447C18.06 88.2739 23.0765 88.7535 18.06 88.2739C13.0435 87.7942 21.9109 92.6426 9.3567 103.975C-3.19755 115.307 -6.45509 102.881 -6.45509 102.881C-3.5018 104.692 0.580537 103.388 3.70006 101.889C6.26352 100.654 8.84176 99.1528 10.359 96.7452C12.072 94.0292 12.1729 90.6175 13.3999 87.6567C14.6268 84.6958 18.0919 82.0509 20.879 83.6366C23.0761 84.887 23.5292 88.0568 25.6873 89.3786C28.2406 90.9419 31.6285 89.1047 34.5121 89.9059C36.6011 90.4918 38.077 92.3865 38.9435 94.3731C39.81 96.3597 40.2252 98.523 41.053 100.522C41.7164 102.108 42.6413 103.597 43.1217 105.246C43.8286 107.668 43.5685 110.388 44.8353 112.563C46.4606 115.363 50.3112 116.707 51.0175 119.863C51.5185 122.083 50.3084 124.756 51.7725 126.494C52.8799 127.812 54.8801 127.756 56.5898 127.598C57.1903 127.543 57.8025 127.478 58.325 127.174C60.4321 125.944 59.0195 122.714 59.4367 120.314C59.8873 117.789 62.5536 116.263 65.0758 115.797C67.6028 115.336 70.2454 115.572 72.6973 114.831C76.032 113.83 78.5856 111.184 80.83 108.519C87.1831 100.964 92.6801 92.1 101.484 87.6328C107.277 84.701 113.907 84.005 120.346 83.1834C121.605 83.025 124.483 82.4797 124.554 81.237Z" fill="#FF530A"/>
      <path d="M7.67444 66.7037C16.0171 61.7848 24.919 57.8042 34.1494 54.8739C37.5231 53.8022 41.104 52.863 44.5757 53.5703C47.9422 54.2568 50.8088 56.4132 54.0083 57.6683C55.9515 58.4333 58.0133 58.8557 60.0596 59.2712C62.2479 59.7164 64.7361 60.1098 66.5401 58.7969C67.3762 58.185 67.9507 57.2801 68.7916 56.674C70.7683 55.2382 73.528 55.8774 75.8274 56.7301C82.1913 59.1029 88.0961 62.6866 93.1303 67.2436C95.7754 69.6416 98.3135 72.5978 98.5627 76.1609C98.6017 76.7063 98.5749 77.2668 98.3715 77.7675C97.6344 79.5311 95.2377 79.6398 93.5208 80.4891C89.9748 82.2415 89.596 86.9888 88.5034 90.7883C86.1433 98.982 78.7713 104.686 71.2994 108.797C68.6217 110.274 65.1763 111.59 62.6801 109.823C61.0528 108.67 60.4536 106.581 59.8912 104.666C57.2625 95.6467 53.1202 86.8099 46.2698 80.3846C39.4193 73.9594 29.482 70.3172 20.3963 72.6875C16.9151 73.5934 13.3903 75.3478 9.88831 74.5089C8.72965 74.2318 7.64095 73.6719 6.45365 73.5262C2.70043 73.0708 0.795168 76.3958 -1.89525 78.1174C-4.74817 73.8548 4.94104 68.3139 7.67444 66.7037Z" fill="#FF530A"/>
      <path d="M36.0653 57.387C40.5346 56.0178 45.6772 54.7291 49.6268 57.225C52.7716 59.2203 54.6989 63.4051 58.3999 63.8448C61.5871 64.2193 64.2161 61.5694 67.0707 60.0942C69.8468 58.6543 73.162 58.3063 76.1731 59.1197C82.0427 60.716 85.8953 66.1896 89.0492 71.398C90.1102 73.1458 91.1774 74.996 91.3031 77.0459C91.3331 77.4616 91.3069 77.9042 91.08 78.2579C90.4189 79.2833 88.8701 78.7116 87.6603 78.6335C85.4483 78.4917 83.6111 80.4879 82.9217 82.5938C82.2372 84.7056 82.3522 86.9797 81.9979 89.1713C81.6494 91.3581 80.6332 93.6848 78.5925 94.5569C76.697 95.3677 74.4374 94.6583 72.8525 93.3431C71.2676 92.0278 70.2138 90.2057 69.1549 88.4366C64.8915 81.3149 59.8579 74.3395 52.787 69.9953C46.84 66.3485 39.6362 64.7964 32.7208 65.6689C29.2195 66.1117 25.8112 67.1532 22.3182 67.6773C20.6206 67.9333 18.6986 68.0122 17.4008 66.8907C13.4852 63.5347 20.8415 62.034 22.582 61.4979C27.0879 60.1376 31.5737 58.7647 36.0653 57.387Z" fill="#FF530A"/>
      <g filter="url(#filter0_d_3053_1269)">
        <path d="M41.8258 134.549C39.1553 138.026 39.6014 143.512 42.8004 146.51C38.0817 149.142 35.7962 155.434 37.732 160.483C38.4712 162.398 39.8548 164.193 41.8191 164.794C43.7775 165.399 46.2723 164.378 46.7523 162.386C46.0206 164.879 47.7573 167.635 50.1352 168.678C52.513 169.72 55.314 169.366 57.7417 168.429C62.078 166.768 65.6814 163.295 67.505 159.029C67.1548 161.569 69.4406 163.912 71.9594 164.378C74.4782 164.844 77.055 163.862 79.2457 162.543C82.5861 160.53 85.6051 157.166 85.3816 153.279C92.5691 155.618 100.91 154.029 106.744 149.213C109.043 147.315 110.885 143.533 108.577 141.65C110.678 141.1 112.79 140.551 114.891 140.002C118.388 139.092 122.203 137.95 124.166 134.92C126.124 131.884 124.339 126.603 120.74 126.891C123.794 126.583 126.902 126.102 129.624 124.668C132.335 123.233 134.624 120.668 134.985 117.619C135.346 114.57 133.291 111.215 130.255 110.775C133.164 110.871 136.072 110.966 138.985 111.068C142.748 111.192 146.984 111.141 149.627 108.46C151.164 106.907 151.872 104.669 151.925 102.481C152.085 95.3102 144.428 89.0813 137.435 90.7025C140.28 88.8831 141.655 85.0462 140.611 81.836C139.566 78.6258 136.203 76.3308 132.827 76.5335C130.89 69.8113 122.913 65.5199 116.237 67.595C109.555 69.675 105.427 77.7319 107.651 84.3636C102.616 84.4184 97.7222 87.4791 95.4837 91.9896C93.2452 96.5 93.7519 102.249 96.7489 106.295C92.7279 107.294 88.6728 108.987 86.0643 112.207C83.4558 115.427 82.7749 120.473 85.4309 123.655C81.0942 121.674 75.9951 120.656 71.5808 122.459C67.1666 124.263 64.0618 129.645 66.0047 134.003C65.8902 131.611 64.0507 129.536 61.8375 128.622C60.0006 127.867 57.7732 127.719 55.8323 128.107C53.6235 128.55 53.0414 130.039 51.4704 130.709C48.1249 132.159 44.545 131.012 41.8258 134.549Z" fill="#FFE6DE"/>
        <path d="M59.2736 132.383C55.9593 132.946 53.703 136.913 54.915 140.054C53.7108 138.571 51.9417 137.554 50.0495 137.266C49.0826 137.115 48.0645 137.162 47.1647 137.543C44.9352 138.483 43.9859 141.4 44.792 143.681C45.5982 145.963 47.7898 147.55 50.1271 148.176C47.1405 148.732 44.2214 150.378 42.7802 153.056C41.339 155.734 41.7489 159.468 44.1568 161.317C46.5647 163.167 50.7722 162.181 51.6403 159.271C51.6162 160.701 52.688 161.999 54.0097 162.533C55.3315 163.067 56.8449 162.944 58.2108 162.511C60.1905 161.885 62.012 160.558 62.9148 158.687C63.8177 156.816 63.6686 154.394 62.2887 152.841C62.9423 155.708 61.5112 159.121 58.7181 160.066C55.9308 161.006 52.3939 158.512 52.886 155.61C51.5226 157.362 49.733 159.309 47.5305 159.012C45.6506 158.763 44.3223 156.727 44.5457 154.839C44.7681 152.962 46.2804 151.39 48.0688 150.746C49.8513 150.107 51.8504 150.287 53.6494 150.878C52.1958 149.816 50.7085 148.714 49.7606 147.186C48.8068 145.663 48.5014 143.586 49.4973 142.088C50.3744 140.767 52.1103 140.166 53.68 140.407C55.2496 140.649 56.637 141.618 57.6463 142.841C56.0594 140.705 56.7695 137.26 59.0589 135.918C61.4336 134.526 64.1364 135.76 65.7456 137.662C66.1603 134.56 62.1738 131.889 59.2736 132.383Z" fill="#FFE6DE"/>
        <path d="M83.84 148.708C87.181 151.118 91.6166 151.784 95.6419 150.908C98.2109 150.344 100.708 149.135 102.402 147.12C104.096 145.105 104.85 142.211 103.927 139.753C106.932 139.563 109.972 139.226 112.765 138.115C115.558 137.004 118.122 135.034 119.396 132.308C120.671 129.583 120.423 126.057 118.353 123.87C116.283 121.682 112.353 121.392 110.304 123.593C111.866 120.209 109.76 115.76 106.242 114.534C102.717 113.312 98.5052 115.242 96.8193 118.572C98.5086 115.542 103.305 115.293 105.774 117.739C108.238 120.178 108.375 124.353 106.697 127.383C109.365 125.67 112.882 123.942 115.486 125.757C117.673 127.28 117.775 130.7 116.308 132.919C114.841 135.139 112.263 136.356 109.72 137.153C107.156 137.949 104.193 138.368 101.91 136.954C103.655 140.907 101.866 145.937 98.2408 148.293C94.6156 150.649 89.6251 150.403 85.9626 148.101C82.6848 146.039 80.9657 142.818 79.6713 139.305C76.6365 140.87 82.1993 147.526 83.84 148.708Z" fill="#FFE6DE"/>
        <path d="M98.4673 97.4076C98.4594 94.7416 99.7781 92.2239 101.511 90.1963C102.99 88.4662 104.881 86.9739 107.111 86.5328C109.341 86.0918 111.93 86.9507 113.004 88.9518C112.51 86.3251 112.864 83.4684 114.337 81.2337C115.811 78.999 118.496 77.5074 121.155 77.7992C123.818 78.0968 126.215 80.396 126.298 83.0691C126.856 81.267 129.421 81.0779 131.144 81.8486C133.386 82.8566 135.155 84.9992 135.403 87.4469C135.652 89.8946 134.192 92.4899 131.859 93.2697C134.624 92.3972 137.93 93.8287 139.187 96.4425C140.444 99.0564 139.501 102.527 137.099 104.147C134.697 105.768 131.12 105.324 129.169 103.175C131.21 103.595 133.449 103.793 135.273 102.777C137.097 101.761 138.105 99.117 136.779 97.51C134.919 95.2569 131.135 97.0884 128.397 96.049C130.234 94.7285 131.9 93.0108 132.573 90.8497C133.247 88.6886 132.674 86.0383 130.795 84.77C128.921 83.5076 125.829 84.3274 125.27 86.5209C126.482 83.8858 124.319 80.4828 121.45 80.0422C118.58 79.6015 115.69 81.7224 114.778 84.4826C113.86 87.2371 114.681 90.367 116.405 92.7036C114.367 91.3022 112.081 90.0861 109.607 89.9354C106.44 89.7399 103.233 91.4764 101.661 94.2327C99.9285 97.2685 101.645 99.7868 101.028 102.656C99.3302 101.619 98.4699 99.3438 98.4673 97.4076Z" fill="#FFE6DE"/>
        <path d="M71.3247 143.364C71.5443 142.077 72.5583 140.838 73.8588 140.753C72.7679 140.048 72.4556 138.603 72.3379 137.31C72.0799 134.4 72.446 131.132 74.6021 129.166C76.1523 127.759 78.4812 127.295 80.4606 127.962C82.439 128.639 83.9967 130.408 84.4338 132.455C86.1918 129.604 90.8566 129.219 93.0603 131.746C95.264 134.273 94.2481 138.842 91.1805 140.19C92.6031 138.996 93.1014 136.812 92.3244 135.124C91.5474 133.435 89.5552 132.398 87.7315 132.738C85.9078 133.079 84.4162 134.77 84.3244 136.627C83.948 134.843 83.088 133.125 81.6986 131.952C80.3093 130.779 78.3584 130.206 76.6075 130.682C75.9687 130.857 75.3544 131.169 74.895 131.656C74.4296 132.147 74.1435 132.785 73.9481 133.426C73.0185 136.474 74.041 140.025 76.4468 142.122C75.59 141.38 73.1774 145.3 73.1184 145.917C72.9884 147.277 74.0545 148.521 74.214 149.77C72.2352 148.76 70.9559 145.538 71.3247 143.364Z" fill="#FFE6DE"/>
      </g>
      <path d="M-13.5974 106.729C-13.7091 109.144 -11.8601 111.5 -9.49058 111.957C-11.4646 113.81 -10.569 117.153 -9.01408 119.371C-7.78913 121.119 -5.9899 122.743 -3.85997 122.697C-1.73004 122.651 0.18609 120.013 -1.10503 118.318C-1.23992 119.73 0.172806 120.915 1.5884 121.06C3.004 121.205 4.37988 120.615 5.67822 120.03C7.5605 119.176 9.56463 118.204 10.5672 116.398C11.5698 114.592 10.89 111.749 8.85702 111.374C11.4581 112.031 14.321 111.986 16.7134 110.754C19.0957 109.526 20.886 106.979 20.7575 104.294C20.629 101.609 18.2725 99.0909 15.587 99.2025C18.9868 98.258 21.0544 93.941 19.4448 90.8055C17.8352 87.6699 12.8215 86.9683 10.5833 89.689C11.8975 86.1282 10.2239 81.721 6.87316 79.9217C3.32045 78.0196 -1.194 79.0847 -4.57027 81.2985C-7.45196 83.185 -9.88401 85.9817 -10.6088 89.3513C-11.3304 92.714 -10.0045 96.617 -6.99169 98.2834C-8.62668 97.3776 -9.26984 101.535 -9.59388 101.926C-11.0922 103.759 -13.4446 103.568 -13.5974 106.729Z" fill="#FFE6DE"/>
      <path d="M-10.6579 107.802C-10.7697 110.216 -8.92068 112.572 -6.55113 113.029C-8.5252 114.882 -7.62953 118.225 -6.07464 120.444C-4.84968 122.191 -3.05044 123.815 -0.920515 123.769C1.20942 123.723 3.12554 121.085 1.83442 119.39C1.69953 120.802 3.11226 121.987 4.52786 122.132C5.94345 122.277 7.31933 121.687 8.61768 121.102C10.5 120.248 12.5041 119.277 13.5067 117.47C14.5092 115.664 13.8295 112.821 11.7965 112.446C14.3976 113.103 17.2604 113.058 19.6528 111.826C22.0452 110.594 23.8254 108.051 23.6969 105.366C23.5685 102.681 21.212 100.163 18.5265 100.275C21.9262 99.3303 23.9938 95.0133 22.3843 91.8777C20.7747 88.7422 15.7609 88.0406 13.5228 90.7612C14.8369 87.2004 13.1633 82.7933 9.8126 80.9939C6.25989 79.0919 1.74545 80.157 -1.63082 82.3707C-4.5125 84.2573 -6.94456 87.054 -7.66936 90.4235C-8.39097 93.7863 -7.06508 97.6893 -4.05224 99.3557C-5.68723 98.4499 -6.33039 102.608 -6.65443 102.998C-8.15963 104.828 -10.512 104.637 -10.6579 107.802Z" fill="#FFE6DE"/>
      <path d="M4.90565 89.0207C6.26632 89.5149 7.36168 90.5768 8.15366 91.7886C9.19217 93.3823 9.77163 95.3698 9.273 97.2034C10.8295 95.0142 13.4819 93.6539 16.171 93.6773C17.1575 93.6883 18.2515 93.9329 18.8033 94.7492C19.239 95.3861 19.2412 96.2372 19.0515 96.9819C18.5359 99.0659 16.5902 100.715 14.4502 100.89C16.2058 101.377 17.8198 102.381 19.0281 103.754C19.7418 104.563 20.3332 105.598 20.1621 106.659C19.9849 107.751 19.024 108.577 17.9761 108.929C16.9315 109.274 15.7954 109.227 14.6931 109.178C13.9493 109.147 13.1849 109.107 12.5014 108.804C11.8179 108.501 11.2372 107.871 11.2075 107.133C12.5655 107.65 14.0315 108.169 15.4441 107.821C16.8568 107.473 18.0399 105.851 17.3707 104.555C17.0395 103.908 16.3574 103.531 15.6976 103.231C14.3032 102.613 12.8098 102.206 11.2921 102.03C12.7396 101.34 14.1083 100.48 15.3618 99.4741C15.8445 99.083 16.3296 98.6514 16.5744 98.0741C16.8192 97.4969 16.7521 96.7488 16.2657 96.3632C15.7378 95.9415 14.9646 96.0801 14.3324 96.3096C11.5214 97.2959 9.24259 99.3725 7.0568 101.393C7.37784 99.8677 7.70482 98.3122 7.5926 96.7514C7.26687 92.0992 4.38062 93.1753 1.63473 91.2586C-1.01816 89.4104 2.68909 88.2181 4.90565 89.0207Z" fill="#FFE6DE"/>
      <defs>
        <filter id="filter0_d_3053_1269" x="33.2214" y="65.1576" width="122.528" height="109.887" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dy="1.91081"/>
          <feGaussianBlur stdDeviation="1.91081"/>
          <feComposite in2="hardAlpha" operator="out"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3053_1269"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3053_1269" result="shape"/>
        </filter>
      </defs>
    </g>
  )
}

export const HeadBlock15 = () => {
  return (
    <g>
      <path d="M199.253 143.655C198.793 142.593 198.311 141.478 197.693 140.508C198.233 141.36 197.616 140.398 197.516 140.234C197.387 140.024 197.252 139.807 197.118 139.605C196.799 139.114 196.475 138.637 196.132 138.169C195.453 137.231 194.72 136.338 193.946 135.49C193.782 135.309 193.611 135.13 193.441 134.95C193.181 134.681 193.215 134.713 193.536 135.046C193.42 134.931 193.305 134.816 193.189 134.701C192.776 134.306 192.357 133.924 191.926 133.551C190.605 132.405 190.073 130.206 191.184 128.693C192.213 127.282 194.27 126.716 195.678 127.945C199.705 131.44 202.941 135.845 205.133 140.896C205.849 142.549 205.107 144.809 203.506 145.466C201.857 146.148 200.022 145.434 199.254 143.662L199.253 143.655Z" fill="black"/>
      <path d="M201.289 179.426C201.289 179.426 230.671 149.274 227.962 126.246L234.308 138.125L243.035 132.264C243.035 132.264 226.605 176.341 201.289 179.426Z" fill="#FF530A"/>
      <path d="M216.702 160.71C215.935 151.036 212.935 141.727 207.486 133.396C186.168 100.786 135.51 95.4821 94.3378 121.548C61.3776 142.414 50.0518 176.564 50.0002 206.773C49.8902 270.759 96.3795 305 151.988 305V249.446C158.632 246.906 165.194 243.642 171.529 239.63C197.859 222.961 213.938 197.501 216.527 172.782C216.953 168.723 217.018 164.688 216.698 160.71H216.702Z" fill="#EFB825"/>
      <path d="M117.533 171.228C117.66 171.302 117.787 171.367 117.918 171.438C118.079 171.522 118.244 171.61 118.406 171.691C118.557 171.769 118.712 171.844 118.859 171.918C131.227 177.902 146.055 173.782 153.5 162.664C154.033 161.869 154.528 161.037 154.982 160.167C161.85 147.043 156.608 130.926 143.274 124.171C129.938 117.413 113.559 122.571 106.691 135.695C106.238 136.564 105.836 137.447 105.485 138.34C100.673 150.615 105.701 164.696 117.529 171.228H117.533Z" fill="#FFE6DE"/>
      <path d="M117.983 172.425C118.096 172.492 118.217 172.553 118.337 172.618C118.485 172.695 118.632 172.773 118.784 172.848C118.921 172.919 119.062 172.986 119.2 173.054C130.484 178.513 144.013 174.755 150.806 164.608C154.202 155.255 148.413 143.697 137.049 137.94C125.686 132.18 112.758 134.257 106.997 142.415C102.604 153.615 107.193 166.461 117.986 172.421L117.983 172.425Z" fill="black"/>
      <path d="M111.225 222.625C120.81 222.625 128.58 214.979 128.58 205.547C128.58 196.115 120.81 188.469 111.225 188.469C101.64 188.469 93.8701 196.115 93.8701 205.547C93.8701 214.979 101.64 222.625 111.225 222.625Z" fill="#FF530A"/>
      <path d="M134.609 188.992C139.349 193.278 141.535 199.806 140.384 206.026C140.112 207.498 138.418 208.59 136.94 208.208C135.379 207.805 134.434 206.388 134.723 204.819C134.778 204.524 134.826 204.227 134.867 203.929C134.888 203.78 134.901 203.631 134.918 203.483C134.894 203.716 134.894 203.848 134.905 203.537C134.925 202.897 134.929 202.265 134.877 201.629C134.853 201.331 134.822 201.03 134.781 200.736C134.771 200.672 134.702 200.178 134.757 200.543C134.815 200.908 134.729 200.421 134.716 200.354C134.585 199.728 134.417 199.112 134.21 198.507C134.114 198.223 134.008 197.942 133.894 197.661C133.839 197.523 133.777 197.384 133.719 197.245C133.564 196.877 133.76 197.164 133.764 197.323C133.764 197.107 133.451 196.714 133.344 196.525C133.196 196.264 133.042 196.004 132.877 195.75C132.53 195.209 132.141 194.705 131.739 194.204C131.478 193.876 132.096 194.57 131.794 194.275C131.687 194.171 131.588 194.056 131.481 193.947C131.244 193.704 130.997 193.47 130.742 193.24C129.628 192.232 129.412 190.325 130.515 189.215C131.601 188.123 133.413 187.913 134.606 188.992H134.609Z" fill="black"/>
      <path d="M136.637 201.725C143.993 203.599 151.682 203.92 159.206 202.963C173.223 201.187 187.168 195.779 199.085 188.368C205.795 184.197 211.944 178.876 216.526 172.534C216.918 168.472 216.952 164.43 216.609 160.445C211.284 174.486 197.573 183.839 183.937 189.87C169.583 196.218 153.442 200.125 137.864 196.154C134.244 195.231 133.028 200.805 136.633 201.725H136.637Z" fill="black"/>
      <path d="M69.1068 47.5486C69.1068 47.5486 50.6776 46.0117 39.6195 50.3331C28.5663 54.6603 71.486 58.673 69.1068 47.5486Z" fill="#FF530A"/>
      <path d="M-6.45041 102.879C-6.45041 102.879 -27.3787 75.0939 20.0387 54.0533C20.0387 54.0533 36.2758 47.8944 56.2486 51.1233C76.2214 54.3522 59.0592 44.7799 75.2492 48.3121C91.4391 51.8443 124.559 81.2344 124.559 81.2344C124.559 81.2344 101.179 136.744 52.0609 147.921C52.0609 147.921 43.3011 139.479 42.5958 131.545C41.8904 123.61 39.3896 123.237 35.6275 120.743C31.8654 118.249 34.2198 113.144 35.5298 110.604C36.8399 108.064 35.8395 105.962 31.9504 104.797C28.0624 103.621 29.5609 96.3045 29.5609 96.3045C29.5609 96.3045 27.2818 100.623 22.6708 94.4444C18.0647 88.2713 23.0811 88.751 18.0647 88.2713C13.0482 87.7917 21.9156 92.6401 9.36136 103.972C-3.19289 115.304 -6.45041 102.879 -6.45041 102.879Z" fill="#FF530A"/>
      <path opacity="0.53" d="M124.553 81.2389C124.553 81.2389 101.173 136.748 52.0552 147.925C52.0552 147.925 43.2954 139.484 42.5901 131.549C41.8848 123.615 39.3839 123.241 35.6219 120.747C31.8598 118.253 34.2141 113.148 35.5242 110.608C36.8342 108.068 35.8339 105.967 31.9448 104.801C28.0567 103.625 29.5553 96.309 29.5553 96.309C29.5553 96.309 27.2761 100.628 22.6652 94.4489C18.059 88.2758 23.0755 88.7555 18.059 88.2758C13.0425 87.7962 21.91 92.6446 9.35572 103.977C-3.19852 115.309 -6.45607 102.883 -6.45607 102.883C-3.50278 104.694 0.579561 103.39 3.69909 101.891C6.26254 100.656 8.84078 99.1547 10.358 96.7472C12.071 94.0312 12.172 90.6195 13.3989 87.6586C14.6258 84.6978 18.091 82.0529 20.8781 83.6386C23.0752 84.889 23.5282 88.0587 25.6863 89.3805C28.2396 90.9439 31.6275 89.1066 34.5111 89.9079C36.6001 90.4938 38.076 92.3885 38.9425 94.3751C39.809 96.3617 40.2242 98.525 41.052 100.524C41.7154 102.11 42.6403 103.598 43.1208 105.248C43.8277 107.67 43.5675 110.39 44.8343 112.565C46.4596 115.365 50.3102 116.709 51.0166 119.865C51.5175 122.085 50.3074 124.758 51.7715 126.496C52.8789 127.814 54.8791 127.758 56.5888 127.6C57.1894 127.545 57.8015 127.48 58.324 127.176C60.4312 125.946 59.0185 122.716 59.4357 120.316C59.8863 117.791 62.5526 116.265 65.0748 115.799C67.6018 115.338 70.2444 115.574 72.6963 114.833C76.031 113.832 78.5846 111.186 80.829 108.521C87.1821 100.966 92.6791 92.102 101.483 87.6347C107.276 84.7029 113.906 84.007 120.345 83.1854C121.604 83.027 124.482 82.4817 124.553 81.2389Z" fill="#FF530A"/>
      <path d="M7.67444 66.7056C16.0171 61.7867 24.919 57.8062 34.1494 54.8759C37.5231 53.8042 41.104 52.8649 44.5757 53.5722C47.9422 54.2588 50.8088 56.4152 54.0083 57.6703C55.9515 58.4353 58.0133 58.8576 60.0596 59.2732C62.2479 59.7183 64.7361 60.1118 66.5401 58.7988C67.3762 58.187 67.9507 57.282 68.7916 56.676C70.7683 55.2401 73.528 55.8794 75.8274 56.732C82.1913 59.1048 88.0961 62.6886 93.1303 67.2455C95.7754 69.6435 98.3135 72.5997 98.5627 76.1629C98.6017 76.7082 98.5749 77.2687 98.3715 77.7695C97.6344 79.533 95.2377 79.6417 93.5208 80.4911C89.9748 82.2435 89.596 86.9907 88.5034 90.7903C86.1433 98.984 78.7713 104.687 71.2994 108.799C68.6217 110.276 65.1763 111.592 62.6801 109.825C61.0528 108.672 60.4536 106.583 59.8912 104.668C57.2625 95.6487 53.1202 86.8118 46.2698 80.3866C39.4193 73.9614 29.482 70.3192 20.3963 72.6894C16.9151 73.5954 13.3903 75.3498 9.88831 74.5108C8.72965 74.2338 7.64095 73.6739 6.45365 73.5282C2.70043 73.0728 0.795168 76.3978 -1.89525 78.1193C-4.74817 73.8567 4.94104 68.3158 7.67444 66.7056Z" fill="#FF530A"/>
      <path d="M36.0653 57.3889C40.5346 56.0198 45.6772 54.7311 49.6268 57.227C52.7716 59.2223 54.6989 63.4071 58.3999 63.8468C61.5871 64.2212 64.2161 61.5714 67.0707 60.0961C69.8468 58.6562 73.162 58.3082 76.1731 59.1217C82.0427 60.7179 85.8953 66.1915 89.0492 71.3999C90.1102 73.1477 91.1774 74.998 91.3031 77.0478C91.3331 77.4636 91.3069 77.9062 91.08 78.2599C90.4189 79.2853 88.8701 78.7135 87.6603 78.6354C85.4483 78.4936 83.6111 80.4898 82.9217 82.5958C82.2372 84.7076 82.3522 86.9816 81.9979 89.1732C81.6494 91.36 80.6332 93.6868 78.5925 94.5588C76.697 95.3697 74.4374 94.6603 72.8525 93.345C71.2676 92.0298 70.2138 90.2076 69.1549 88.4386C64.8915 81.3169 59.8579 74.3414 52.787 69.9973C46.84 66.3505 39.6362 64.7983 32.7208 65.6708C29.2195 66.1136 25.8112 67.1552 22.3182 67.6792C20.6206 67.9352 18.6986 68.0142 17.4008 66.8927C13.4852 63.5366 20.8415 62.036 22.582 61.4999C27.0879 60.1396 31.5737 58.7667 36.0653 57.3889Z" fill="#FF530A"/>
      <g filter="url(#filter0_d_3053_979)">
        <path d="M41.8258 134.551C39.1553 138.028 39.6014 143.514 42.8004 146.512C38.0817 149.144 35.7962 155.436 37.732 160.485C38.4712 162.4 39.8548 164.195 41.8191 164.796C43.7775 165.401 46.2723 164.38 46.7523 162.388C46.0206 164.881 47.7573 167.637 50.1352 168.68C52.513 169.722 55.314 169.368 57.7417 168.431C62.078 166.77 65.6814 163.297 67.505 159.031C67.1548 161.571 69.4406 163.914 71.9594 164.38C74.4782 164.846 77.055 163.864 79.2457 162.545C82.5861 160.532 85.6051 157.168 85.3816 153.281C92.5691 155.62 100.91 154.031 106.744 149.215C109.043 147.317 110.885 143.535 108.577 141.652C110.678 141.102 112.79 140.553 114.891 140.003C118.388 139.094 122.203 137.952 124.166 134.922C126.124 131.886 124.339 126.605 120.74 126.893C123.794 126.585 126.902 126.104 129.624 124.67C132.335 123.235 134.624 120.67 134.985 117.621C135.346 114.572 133.291 111.217 130.255 110.777C133.164 110.873 136.072 110.968 138.985 111.07C142.748 111.194 146.984 111.143 149.627 108.462C151.164 106.909 151.872 104.671 151.925 102.483C152.085 95.3121 144.428 89.0832 137.435 90.7045C140.28 88.885 141.655 85.0482 140.611 81.838C139.566 78.6277 136.203 76.3328 132.827 76.5355C130.89 69.8132 122.913 65.5219 116.237 67.597C109.555 69.6769 105.427 77.7338 107.651 84.3656C102.616 84.4204 97.7222 87.4811 95.4837 91.9915C93.2452 96.502 93.7519 102.251 96.7489 106.297C92.7279 107.296 88.6728 108.989 86.0643 112.209C83.4558 115.429 82.7749 120.475 85.4309 123.657C81.0942 121.676 75.9951 120.658 71.5808 122.461C67.1666 124.265 64.0618 129.647 66.0047 134.005C65.8902 131.613 64.0507 129.538 61.8375 128.624C60.0006 127.869 57.7732 127.721 55.8323 128.109C53.6235 128.552 53.0414 130.041 51.4704 130.711C48.1249 132.161 44.545 131.014 41.8258 134.551Z" fill="#FFE6DE"/>
        <path d="M59.2736 132.385C55.9593 132.948 53.703 136.915 54.915 140.056C53.7108 138.573 51.9417 137.556 50.0495 137.268C49.0826 137.117 48.0645 137.164 47.1647 137.545C44.9352 138.485 43.9859 141.402 44.792 143.683C45.5982 145.965 47.7898 147.552 50.1271 148.178C47.1405 148.734 44.2214 150.38 42.7802 153.058C41.339 155.736 41.7489 159.47 44.1568 161.319C46.5647 163.169 50.7722 162.183 51.6403 159.273C51.6162 160.703 52.688 162.001 54.0097 162.535C55.3315 163.069 56.8449 162.946 58.2108 162.513C60.1905 161.887 62.012 160.56 62.9148 158.689C63.8177 156.818 63.6686 154.396 62.2887 152.843C62.9423 155.71 61.5112 159.123 58.7181 160.068C55.9308 161.008 52.3939 158.514 52.886 155.612C51.5226 157.364 49.733 159.311 47.5305 159.014C45.6506 158.765 44.3223 156.729 44.5457 154.841C44.7681 152.964 46.2804 151.392 48.0688 150.748C49.8513 150.109 51.8504 150.289 53.6494 150.88C52.1958 149.818 50.7085 148.716 49.7606 147.188C48.8068 145.665 48.5014 143.588 49.4973 142.09C50.3744 140.769 52.1103 140.168 53.68 140.409C55.2496 140.651 56.637 141.62 57.6463 142.842C56.0594 140.707 56.7695 137.262 59.0589 135.92C61.4336 134.528 64.1364 135.762 65.7456 137.664C66.1603 134.561 62.1738 131.89 59.2736 132.385Z" fill="#FFE6DE"/>
        <path d="M83.84 148.709C87.181 151.12 91.6166 151.786 95.6419 150.91C98.2109 150.346 100.708 149.137 102.402 147.122C104.096 145.107 104.85 142.213 103.927 139.755C106.932 139.565 109.972 139.228 112.765 138.117C115.558 137.006 118.122 135.036 119.396 132.31C120.671 129.585 120.423 126.059 118.353 123.872C116.283 121.684 112.353 121.394 110.304 123.595C111.866 120.211 109.76 115.762 106.242 114.536C102.717 113.314 98.5052 115.244 96.8193 118.574C98.5086 115.544 103.305 115.295 105.774 117.741C108.238 120.18 108.375 124.355 106.697 127.385C109.365 125.672 112.882 123.944 115.486 125.759C117.673 127.282 117.775 130.702 116.308 132.921C114.841 135.141 112.263 136.358 109.72 137.155C107.156 137.951 104.193 138.37 101.91 136.956C103.655 140.908 101.866 145.939 98.2408 148.295C94.6156 150.651 89.6251 150.405 85.9626 148.103C82.6848 146.041 80.9657 142.82 79.6713 139.307C76.6365 140.872 82.1993 147.528 83.84 148.709Z" fill="#FFE6DE"/>
        <path d="M98.4673 97.4096C98.4594 94.7436 99.7781 92.2259 101.511 90.1983C102.99 88.4682 104.881 86.9758 107.111 86.5348C109.341 86.0938 111.93 86.9526 113.004 88.9537C112.51 86.327 112.864 83.4704 114.337 81.2356C115.811 79.0009 118.496 77.5094 121.155 77.8011C123.818 78.0987 126.215 80.3979 126.298 83.071C126.856 81.2689 129.421 81.0798 131.144 81.8506C133.386 82.8586 135.155 85.0011 135.403 87.4488C135.652 89.8965 134.192 92.4919 131.859 93.2717C134.624 92.3991 137.93 93.8307 139.187 96.4445C140.444 99.0583 139.501 102.529 137.099 104.149C134.697 105.77 131.12 105.326 129.169 103.177C131.21 103.597 133.449 103.795 135.273 102.779C137.097 101.763 138.105 99.1189 136.779 97.512C134.919 95.2588 131.135 97.0903 128.397 96.051C130.234 94.7305 131.9 93.0128 132.573 90.8517C133.247 88.6905 132.674 86.0403 130.795 84.772C128.921 83.5095 125.829 84.3293 125.27 86.5229C126.482 83.8877 124.319 80.4847 121.45 80.0441C118.58 79.6035 115.69 81.7243 114.778 84.4846C113.86 87.2391 114.681 90.369 116.405 92.7056C114.367 91.3041 112.081 90.0881 109.607 89.9374C106.44 89.7418 103.233 91.4784 101.661 94.2346C99.9285 97.2705 101.645 99.7888 101.028 102.658C99.3302 101.621 98.4699 99.3457 98.4673 97.4096Z" fill="#FFE6DE"/>
        <path d="M71.3247 143.366C71.5443 142.079 72.5583 140.84 73.8588 140.755C72.7679 140.05 72.4556 138.605 72.3379 137.312C72.0799 134.402 72.446 131.134 74.6021 129.168C76.1523 127.761 78.4812 127.297 80.4606 127.964C82.439 128.641 83.9967 130.41 84.4338 132.457C86.1918 129.606 90.8566 129.221 93.0603 131.748C95.264 134.275 94.2481 138.844 91.1805 140.191C92.6031 138.998 93.1014 136.814 92.3244 135.126C91.5474 133.437 89.5552 132.4 87.7315 132.74C85.9078 133.081 84.4162 134.772 84.3244 136.629C83.948 134.845 83.088 133.127 81.6986 131.954C80.3093 130.781 78.3584 130.208 76.6075 130.684C75.9687 130.859 75.3544 131.171 74.895 131.657C74.4296 132.149 74.1435 132.787 73.9481 133.428C73.0185 136.476 74.041 140.027 76.4468 142.124C75.59 141.382 73.1774 145.302 73.1184 145.919C72.9884 147.279 74.0545 148.523 74.214 149.772C72.2352 148.762 70.9559 145.54 71.3247 143.366Z" fill="#FFE6DE"/>
      </g>
      <path d="M-13.598 98.666C-14.7351 100.799 -14.0759 103.72 -12.132 105.15C-14.7099 105.976 -15.3358 109.381 -14.8833 112.052C-14.5268 114.156 -13.5986 116.395 -11.6549 117.267C-9.71126 118.139 -6.84858 116.579 -7.28736 114.494C-8.01511 115.711 -7.2477 117.388 -6.03127 118.127C-4.81483 118.865 -3.31876 118.922 -1.89506 118.951C0.171412 118.988 2.3986 118.97 4.07937 117.769C5.76014 116.567 6.36603 113.708 4.69072 112.497C6.75808 114.207 9.36339 115.394 12.0529 115.308C14.7319 115.221 17.4417 113.689 18.4779 111.209C19.5141 108.728 18.4661 105.443 15.9926 104.391C19.4687 104.997 23.1888 101.985 23.0805 98.4621C22.9723 94.9392 18.7447 92.154 15.5556 93.6509C18.2706 90.9986 18.6502 86.2996 16.3958 83.2365C14.0031 79.9939 9.46839 79.0187 5.46882 79.5694C2.05639 80.0368 -1.34048 81.5192 -3.44114 84.2517C-5.53598 86.9794 -6.01327 91.0737 -4.00705 93.8718C-5.09514 92.352 -7.46029 95.8315 -7.92043 96.0449C-10.0604 97.0576 -12.1032 95.8755 -13.598 98.666Z" fill="#FFE6DE"/>
      <path d="M-11.4037 100.896C-12.5408 103.029 -11.8816 105.951 -9.93764 107.381C-12.5156 108.207 -13.1415 111.611 -12.689 114.282C-12.3324 116.386 -11.4043 118.625 -9.4606 119.498C-7.51692 120.37 -4.65424 118.81 -5.09302 116.724C-5.82077 117.942 -5.05336 119.619 -3.83693 120.357C-2.62049 121.095 -1.12442 121.153 0.299279 121.182C2.36575 121.218 4.59294 121.201 6.27371 119.999C7.95448 118.798 8.56035 115.938 6.88504 114.727C8.95241 116.437 11.5577 117.625 14.2473 117.539C16.9368 117.453 19.636 115.92 20.6722 113.439C21.7084 110.959 20.6605 107.673 18.187 106.622C21.663 107.227 25.3831 104.215 25.2749 100.693C25.1666 97.1697 20.939 94.3844 17.7499 95.8813C20.465 93.229 20.8445 88.5301 18.5902 85.467C16.1974 82.2244 11.6627 81.2492 7.66316 81.7998C4.25072 82.2672 0.853843 83.7497 -1.24682 86.4822C-3.34166 89.2099 -3.81895 93.3042 -1.81273 96.1023C-2.90082 94.5825 -5.26595 98.0619 -5.72609 98.2753C-7.8709 99.2822 -9.91367 98.1002 -11.4037 100.896Z" fill="#FFE6DE"/>
      <path d="M10.7141 90.6116C11.731 91.642 12.2647 93.0712 12.46 94.5056C12.7142 96.3907 12.3847 98.4346 11.1474 99.8768C13.4928 98.5673 16.4723 98.4769 18.8912 99.652C19.7775 100.085 20.6606 100.776 20.8088 101.75C20.929 102.512 20.5658 103.282 20.0749 103.873C18.7148 105.534 16.2498 106.189 14.2418 105.428C15.6185 106.622 16.6453 108.221 17.1475 109.98C17.4452 111.017 17.5354 112.205 16.9252 113.09C16.2965 114.001 15.0741 114.335 13.9766 114.203C12.8849 114.066 11.8792 113.536 10.9044 113.019C10.2458 112.672 9.57279 112.307 9.08536 111.74C8.59793 111.173 8.34365 110.355 8.63392 109.675C9.63817 110.726 10.7398 111.823 12.1652 112.115C13.5906 112.407 15.3553 111.45 15.307 109.992C15.2852 109.266 14.831 108.632 14.3638 108.078C13.3697 106.921 12.1951 105.913 10.8997 105.103C12.5034 105.101 14.1089 104.911 15.6727 104.541C16.2765 104.395 16.8999 104.213 17.3687 103.796C17.8375 103.38 18.0979 102.676 17.8241 102.119C17.5282 101.511 16.7704 101.305 16.1008 101.241C13.1386 100.925 10.1891 101.823 7.34795 102.71C8.29232 101.47 9.25518 100.205 9.82359 98.7474C11.5258 94.4055 8.45703 94.1389 6.79931 91.2294C5.19622 88.4216 9.05644 88.9355 10.7141 90.6116Z" fill="#FFE6DE"/>
      <defs>
        <filter id="filter0_d_3053_979" x="33.2214" y="65.1595" width="122.528" height="109.887" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dy="1.91081"/>
          <feGaussianBlur stdDeviation="1.91081"/>
          <feComposite in2="hardAlpha" operator="out"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3053_979"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3053_979" result="shape"/>
        </filter>
      </defs>
    </g>
  )
}
