import { useEffect } from 'react'
import { gsap } from 'gsap'

export const Block2 = () => {
  useEffect(() => {
    gsap.fromTo(
      '#box',
      { scale: 1 },
      {
        scale: 0.8,
        duration: 0.5,
        repeat: -1,
        yoyo: true,
        ease: 'power1.inOut'
      }
    )
  }, [])

  return (
    <g>
      <path d="M0.5 0C55.7276 0 100.5 44.7724 100.5 100H0.5V0Z" fill="#FF530A"/>
      <path d="M10.3571 66.176L17.6851 60.352H21.2051V84H16.5651V66.624L12.7891 69.536L10.3571 66.176ZM23.7321 66.176L31.0601 60.352H34.5801V84H29.9401V66.624L26.1641 69.536L23.7321 66.176ZM41.2031 84.512C40.4565 84.512 39.8271 84.2667 39.3151 83.776C38.8031 83.264 38.5471 82.6347 38.5471 81.888C38.5471 81.1627 38.8031 80.5547 39.3151 80.064C39.8271 79.5733 40.4565 79.328 41.2031 79.328C41.9071 79.328 42.5045 79.5733 42.9951 80.064C43.5071 80.5547 43.7631 81.1627 43.7631 81.888C43.7631 82.6347 43.5071 83.264 42.9951 83.776C42.5045 84.2667 41.9071 84.512 41.2031 84.512ZM43.5134 66.176L50.8414 60.352H54.3614V84H49.7214V66.624L45.9454 69.536L43.5134 66.176ZM69.3684 75.2L65.2084 79.648H74.0084V84H58.5844V80.064L65.9444 72.352C66.8404 71.4133 67.3417 70.88 67.4484 70.752C67.7257 70.432 68.0457 69.9307 68.4084 69.248C68.771 68.5867 68.9524 67.9787 68.9524 67.424C68.9524 66.528 68.643 65.8027 68.0244 65.248C67.4057 64.672 66.627 64.384 65.6884 64.384C64.0244 64.384 62.2537 65.2587 60.3764 67.008L57.7844 63.616C60.2377 61.1627 63.0004 59.936 66.0724 59.936C68.3337 59.936 70.179 60.6187 71.6084 61.984C73.059 63.328 73.7844 65.056 73.7844 67.168C73.7844 69.4507 72.3124 72.128 69.3684 75.2Z" fill="#FFE6DE"/>
      <path id="box" d="M19.5 30.0006V38.0006H15.5C14.7044 38.0006 13.9413 37.6845 13.3787 37.1219C12.8161 36.5593 12.5 35.7962 12.5 35.0006V31.0006C12.5 30.7354 12.6054 30.481 12.7929 30.2935C12.9804 30.1059 13.2348 30.0006 13.5 30.0006H19.5ZM27.5 30.0006C27.7652 30.0006 28.0196 30.1059 28.2071 30.2935C28.3946 30.481 28.5 30.7354 28.5 31.0006V35.0006C28.5 35.7962 28.1839 36.5593 27.6213 37.1219C27.0587 37.6845 26.2956 38.0006 25.5 38.0006H21.5V30.0006H27.5ZM25 18.0006C25.5905 18.0005 26.1715 18.1498 26.6888 18.4346C27.206 18.7194 27.6429 19.1304 27.9586 19.6295C28.2743 20.1285 28.4586 20.6993 28.4943 21.2887C28.53 21.8782 28.4161 22.4671 28.163 23.0006H28.5C29.0304 23.0006 29.5391 23.2113 29.9142 23.5864C30.2893 23.9614 30.5 24.4702 30.5 25.0006V26.0006C30.5 26.531 30.2893 27.0397 29.9142 27.4148C29.5391 27.7899 29.0304 28.0006 28.5 28.0006H21.5V23.0006H19.5V28.0006H12.5C11.9696 28.0006 11.4609 27.7899 11.0858 27.4148C10.7107 27.0397 10.5 26.531 10.5 26.0006V25.0006C10.5 24.4702 10.7107 23.9614 11.0858 23.5864C11.4609 23.2113 11.9696 23.0006 12.5 23.0006H12.837C12.6144 22.5319 12.4993 22.0194 12.5 21.5006C12.5 19.5676 14.067 18.0006 15.983 18.0006C17.738 17.9706 19.295 19.0926 20.364 20.9346L20.5 21.1776C21.533 19.2636 23.06 18.0636 24.791 18.0026L25 18.0006ZM16 20.0006C15.6022 20.0006 15.2206 20.1586 14.9393 20.4399C14.658 20.7212 14.5 21.1028 14.5 21.5006C14.5 21.8984 14.658 22.2799 14.9393 22.5612C15.2206 22.8426 15.6022 23.0006 16 23.0006H19.143C18.402 21.0956 17.194 19.9806 16 20.0006ZM24.983 20.0006C23.803 19.9806 22.598 21.0966 21.857 23.0006H25C25.3978 22.9983 25.7785 22.8381 26.0582 22.5552C26.3379 22.2723 26.4938 21.8899 26.4915 21.4921C26.4892 21.0943 26.329 20.7136 26.0462 20.4339C25.7633 20.1542 25.3808 19.9983 24.983 20.0006Z" fill="#FFE6DE"/>
    </g>
  )
}
