import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Layout } from 'components/layout/layout'
import { Drawer } from 'components/drawer/drawer'
import { Description } from 'components/description/description'
import { LogoDrawer } from 'components/logoDrawer/logoDrawer'
import { useActivateMutation } from 'store/sessionApi'
import smallSnakeImage from 'assets/images/december19/smallSnake.svg'
import logoDrawer from 'assets/images/december19/logoDrawer.png'
import { Snake } from './snake/snake'

export const December19 = () => {
  const [isDrawer, setIsDrawer] = useState(false)
  const [activate, { isLoading }] = useActivateMutation()
  const navigate = useNavigate()

  const goNext = async () => {
    const res = await activate({ cell: 20 })

    if (res) {
      navigate('/december-19/complete')
    }
  }

  return (
    <>
      <Layout
        title='Привіти! Сьогодні трохи не в настрої 😥'
        subTitle='Четвер, 19 грудня'
        description={(
          <Description>
            Але час від часу почуватися не ок — абсолютно нормально. Головне — дбати про себе, тішити смачненьким та за потреби звертатися за <span>допомогою до спеціалістів</span>.
          </Description>
        )}
        snake={<Snake onComplete={() => setIsDrawer(true)} />}
      />

      <Drawer
        isOpen={isDrawer}
        title='Раджу онлайн-платформу психотерапії '
        topDescription='У них лише перевірені та досвідчені терапевти, яких підбирають під тебе. Все онлайн і максимально професійно.'
        mtTop='48px'
        description={(
          <>
            А від мене лови кешбек <span>10%</span> на весь чек у «М’ясомаркет», «Döner Маркет» та фірмових магазинах «Наша Ряба».
          </>
        )}
        onSubmit={goNext}
        buttonLabel='Погодуй мене і отримай кешбек'
        isDisabled={isLoading}
      >
        <LogoDrawer
          type='secondary'
          title='Подбай про менталочку '
          subTitle='Промокод EAT'
          description='Активуй промокод, щоб отримати знижку 50% на перший сеанс із психотерапевтом. Скористатися промокодом можна один раз.'
          logo={smallSnakeImage}
          logo1={logoDrawer}
          link='https://pleso.me/ua?utm_source=affiliate&utm_medium=mhp&utm_campaign=advent_calendar'
        />
      </Drawer>
    </>
  )
}
