import { Box } from '@mui/material'
import { Button } from 'components/uikit/button/button'
import { ReactComponent as SnakeImage } from 'assets/images/december01/snakeSmall.svg'
import { Container, Body, Title } from './styles'

export const ModalMusic = ({ isOpen, onClose, onSubmit }) => {
  return (
    <Container open={isOpen} onClose={onClose}>
      <Body>
        <Title>Не забудь потім погодувати мене!</Title>

        <Box width='100%' mb='32px'>
          <Button onClick={onSubmit}>Добренько</Button>
        </Box>

        <SnakeImage />
      </Body>
    </Container>
  )
}
