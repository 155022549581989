import { useEffect } from 'react'
import { gsap } from 'gsap'

export const Block2 = () => {
  useEffect(() => {
    gsap.fromTo(
      '#box',
      { scale: 1 },
      {
        scale: 0.8,
        duration: 0.5,
        repeat: -1,
        yoyo: true,
        ease: 'power1.inOut'
      }
    )
  }, [])

  return (
    <g>
      <path d="M0 0C55.2276 0 100 44.7724 100 100H0V0Z" fill="#FF530A"/>
      <path d="M13.1852 66.176L20.5133 60.352H24.0333V84H19.3933V66.624L15.6173 69.536L13.1852 66.176ZM42.5603 74.56H45.1203V78.496H42.5603V84H38.1443V78.496H27.7443L26.8163 75.456L39.1683 60.352H42.5603V74.56ZM38.1443 67.2L32.1923 74.56H38.1443V67.2ZM50.375 84.512C49.6283 84.512 48.999 84.2667 48.487 83.776C47.975 83.264 47.719 82.6347 47.719 81.888C47.719 81.1627 47.975 80.5547 48.487 80.064C48.999 79.5733 49.6283 79.328 50.375 79.328C51.079 79.328 51.6763 79.5733 52.167 80.064C52.679 80.5547 52.935 81.1627 52.935 81.888C52.935 82.6347 52.679 83.264 52.167 83.776C51.6763 84.2667 51.079 84.512 50.375 84.512ZM52.6852 66.176L60.0133 60.352H63.5333V84H58.8933V66.624L55.1173 69.536L52.6852 66.176ZM78.5403 75.2L74.3802 79.648H83.1803V84H67.7563V80.064L75.1163 72.352C76.0123 71.4133 76.5136 70.88 76.6203 70.752C76.8976 70.432 77.2176 69.9307 77.5803 69.248C77.9429 68.5867 78.1243 67.9787 78.1243 67.424C78.1243 66.528 77.8149 65.8027 77.1963 65.248C76.5776 64.672 75.7989 64.384 74.8603 64.384C73.1963 64.384 71.4256 65.2587 69.5483 67.008L66.9563 63.616C69.4096 61.1627 72.1723 59.936 75.2443 59.936C77.5056 59.936 79.3509 60.6187 80.7803 61.984C82.2309 63.328 82.9563 65.056 82.9563 67.168C82.9563 69.4507 81.4843 72.128 78.5403 75.2Z" fill="#FFE6DE"/>
      <path id="box" d="M19 30.0006V38.0006H15C14.2044 38.0006 13.4413 37.6845 12.8787 37.1219C12.3161 36.5593 12 35.7962 12 35.0006V31.0006C12 30.7354 12.1054 30.481 12.2929 30.2935C12.4804 30.1059 12.7348 30.0006 13 30.0006H19ZM27 30.0006C27.2652 30.0006 27.5196 30.1059 27.7071 30.2935C27.8946 30.481 28 30.7354 28 31.0006V35.0006C28 35.7962 27.6839 36.5593 27.1213 37.1219C26.5587 37.6845 25.7956 38.0006 25 38.0006H21V30.0006H27ZM24.5 18.0006C25.0905 18.0005 25.6715 18.1498 26.1888 18.4346C26.706 18.7194 27.1429 19.1304 27.4586 19.6295C27.7743 20.1285 27.9586 20.6993 27.9943 21.2887C28.03 21.8782 27.9161 22.4671 27.663 23.0006H28C28.5304 23.0006 29.0391 23.2113 29.4142 23.5864C29.7893 23.9614 30 24.4702 30 25.0006V26.0006C30 26.531 29.7893 27.0397 29.4142 27.4148C29.0391 27.7899 28.5304 28.0006 28 28.0006H21V23.0006H19V28.0006H12C11.4696 28.0006 10.9609 27.7899 10.5858 27.4148C10.2107 27.0397 10 26.531 10 26.0006V25.0006C10 24.4702 10.2107 23.9614 10.5858 23.5864C10.9609 23.2113 11.4696 23.0006 12 23.0006H12.337C12.1144 22.5319 11.9993 22.0194 12 21.5006C12 19.5676 13.567 18.0006 15.483 18.0006C17.238 17.9706 18.795 19.0926 19.864 20.9346L20 21.1776C21.033 19.2636 22.56 18.0636 24.291 18.0026L24.5 18.0006ZM15.5 20.0006C15.1022 20.0006 14.7206 20.1586 14.4393 20.4399C14.158 20.7212 14 21.1028 14 21.5006C14 21.8984 14.158 22.2799 14.4393 22.5612C14.7206 22.8426 15.1022 23.0006 15.5 23.0006H18.643C17.902 21.0956 16.694 19.9806 15.5 20.0006ZM24.483 20.0006C23.303 19.9806 22.098 21.0966 21.357 23.0006H24.5C24.8978 22.9983 25.2785 22.8381 25.5582 22.5552C25.8379 22.2723 25.9938 21.8899 25.9915 21.4921C25.9892 21.0943 25.829 20.7136 25.5462 20.4339C25.2633 20.1542 24.8808 19.9983 24.483 20.0006Z" fill="#FFE6DE"/>
    </g>
  )
}
