import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Box } from '@mui/material'
import { Layout } from 'components/layout/layout'
import { Drawer } from 'components/drawer/drawer'
import { Description } from 'components/description/description'
import { useActivateMutation } from 'store/sessionApi'
import logoDrawer from 'assets/images/december21/logoDrawer.png'
import { Snake } from './snake/snake'
import { LogoDrawerStyled } from './styles'

export const December22 = () => {
  const [isDrawer, setIsDrawer] = useState(false)
  const [activate, { isLoading }] = useActivateMutation()
  const navigate = useNavigate()

  const goNext = async () => {
    const res = await activate({ cell: 23 })

    if (res) {
      navigate('/december-22/complete')
    }
  }

  return (
    <>
      <Layout
        title='Смачної неділі! Сьогодні пропоную влаштувати репетицію Нового року.'
        subTitle='Неділя, 22 грудня'
        description={(
          <Description>
            План такий. Приходиш до кулінарії «М’ясомаркет», береш смакоту з новорічного меню (холодець — мій топчик) і решту дня проводиш на дивані зі смаколиками.
          </Description>
        )}
        snake={(
          <Box mt='48px'>
            <Snake onComplete={() => setIsDrawer(true)} />
          </Box>
        )}
        typePhone='snowflakes'
      />

      <Drawer
        isOpen={isDrawer}
        title='Ну і як же без подарунків!'
        topDescription={<>Лови <span>20%</span> кешбеку на всі страви з кулінарії.</>}
        buttonLabel='Погодуй мене і отримай кешбек'
        onSubmit={goNext}
        isDisabled={isLoading}
      >
        <LogoDrawerStyled logo={logoDrawer} />
      </Drawer>
    </>
  )
}
