import { styled, Box, Typography } from '@mui/material'

export const Container = styled(Box)(({ theme }) => ({
  backgroundColor: theme.colors.darkTeal,
  marginTop: '48px',
  padding: '92.82px 20px',
  borderRadius: '48px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}))

export const SecondaryContainer = styled(Container)(({ theme }) => ({
  backgroundColor: theme.colors.white,
  flexDirection: 'column',
  padding: '0',
  position: 'relative',
  overflow: 'hidden'
}))

export const PrimaryContainer = styled(SecondaryContainer)(({ theme }) => ({
  backgroundColor: theme.colors.darkTeal
}))

export const RightContainer = styled(SecondaryContainer)(({ theme }) => ({
  backgroundColor: theme.colors.darkTeal,
  marginTop: '48px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '48px',
  padding: '32px 21px',
  position: 'relative'
}))

export const Title = styled(Typography)(({ theme }) => ({
  fontFamily: 'Axiforma',
  fontWeight: '700',
  fontSize: '24px',
  lineHeight: '31.2px',
  color: theme.colors.darkTeal,
  padding: '32px 21px 0 21px'
}))

export const Promocode = styled(Typography)(({ theme }) => ({
  fontFamily: 'Axiforma',
  fontWeight: '500',
  fontSize: '16px',
  lineHeight: '24px',
  color: theme.colors.darkTeal,
  marginTop: '20px'
}))

export const Description = styled(Typography)(({ theme }) => ({
  marginTop: '20px',
  fontWeight: '400',
  fontSize: '13px',
  lineHeight: '16.9px',
  color: theme.colors.darkTeal,
  marginBottom: '20px',
  padding: '0 21px'
}))

export const RightDescription = styled(Description)(({ theme }) => ({
  color: `${theme.colors.veryPalePink}CC`,
  marginBottom: '24px',
  padding: 0
}))

export const Image1 = styled(Box)(() => ({
  position: 'absolute',
  bottom: '-10px',
  left: '-7px'
}))

export const Image2 = styled(Box)(() => ({
  // position: 'absolute',
  // bottom: '32px'
}))

export const TitlePrimary = styled(Title)(({ theme }) => ({
  color: theme.colors.veryPalePink,
  marginBottom: '32px',

  span: {
    color: theme.colors.goldenYellow
  }
}))

export const TitleRight = styled(Typography)(({ theme }) => ({
  color: theme.colors.veryPalePink,
  fontWeight: '700',
  fontSize: '24px',
  lineHeight: '31.2px',
  marginBottom: '24px'
}))

export const SubTitle = styled(Typography)(({ theme }) => ({
  color: theme.colors.goldenYellow,
  fontWeight: '700',
  fontSize: '16px',
  lineHeight: '20.8px',
  marginBottom: '4px'
}))

export const ActiveCodeLabel = styled('a')(({ theme }) => ({
  marginTop: '24px',
  color: theme.colors.goldenYellow,
  fontWeight: '600',
  fontSize: '16px',
  lineHeight: '24px',
  cursor: 'pointer',
  zIndex: 100
}))

export const ActiveCodeLabel2 = styled(ActiveCodeLabel)(({ theme }) => ({
  marginTop: '20px',
  color: theme.colors.orange,
  marginBottom: '32px'
}))

export const RightImageContainer = styled('div')(() => ({
  position: 'absolute',
  bottom: '-15px',
  right: 0,

  img: {
    width: '99.59px',
    height: '99.26px'
  }
}))

export const LeftImageContainer = styled('div')(() => ({
  position: 'absolute',
  bottom: '-15px',
  left: 0,

  img: {
    width: '99.59px',
    height: '99.26px'
  }
}))
