import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isPlaying: false,
  days: []
}

const playbackSlice = createSlice({
  name: 'playback',
  initialState,
  reducers: {
    play: (state) => {
      state.isPlaying = true
    },
    pause: (state) => {
      state.isPlaying = false
    },
    togglePlayPause: (state) => {
      state.isPlaying = !state.isPlaying
    },
    changeDays: (state, { payload }) => {
      state.days = payload
    }
  }
})

export const { play, pause, togglePlayPause, changeDays } = playbackSlice.actions
export default playbackSlice.reducer
