import { useEffect, useRef } from 'react'
import { gsap } from 'gsap'
import { useSelector } from 'react-redux'
import { changeOpacity } from 'utils/basic'
import OpenBoxMusic from 'assets/music/openBox.mp3'
import { HeadBlock1 } from './headBlock/headBlock1'
import { Block1 } from './block1/block1'
import { Block2 } from './block2/block2'
import { Block3 } from './block3/block3'
import { TailBlock } from './tailBlock/tailBlock'
import { Container } from './styles'

export const Snake = () => {
  const { days } = useSelector(({ playback }) => playback)

  const blockRefs = useRef([])
  const tailBlockRef = useRef(null)
  const audioRef = useRef(null)

  useEffect(() => {
    if (days[0]?.is_skipped) changeOpacity(blockRefs.current[2])
  }, [days])

  useEffect(() => {
    audioRef.current = new Audio(OpenBoxMusic)

    gsap.fromTo(
      blockRefs.current[0],
      { x: '150vw', y: 100 },
      { x: 100, y: 100, duration: 0.4, ease: 'linear', onComplete: startBlock2Animation }
    )

    gsap.set(blockRefs.current[1], { x: '150vw', y: 100, transformOrigin: '100% 100%' })
    gsap.set(blockRefs.current[2], { x: 200, y: '150vh' })
    gsap.set(tailBlockRef.current, { x: '-150vw', y: 240 })
  }, [])

  const startBlock2Animation = () => {
    gsap.to(blockRefs.current[1], { x: 200, y: 100, duration: 0.4, ease: 'linear', onComplete: startBlock3Animation } )
  }

  const startBlock3Animation = () => {
    gsap.to(blockRefs.current[2], { x: 200, y: 200, duration: 0.4, ease: 'linear', onComplete: startTailBlockAnimation } )
  }

  const startTailBlockAnimation = () => {
    gsap.to(tailBlockRef.current, { x: 95, y: 210, duration: 0.4, ease: 'linear' } )
  }

  return (
    <Container>
      <svg width="398" height="382" viewBox="0 0 398 382" overflow='visible' fill='none'>
        <HeadBlock1 />

        <g ref={(el) => (blockRefs.current[0] = el)}>
          <Block1 />
        </g>

        <g ref={(el) => (blockRefs.current[1] = el)}>
          <Block2 />
        </g>

        <g ref={(el) => (blockRefs.current[2] = el)}>
          <Block3 />
        </g>

        <g ref={tailBlockRef}>
          <TailBlock />
        </g>
      </svg>
    </Container>
  )
}
