import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Layout } from 'components/layout/layout'
import { Drawer } from 'components/drawer/drawer'
import { LogoDrawer } from 'components/logoDrawer/logoDrawer'
import { useActivateMutation } from 'store/sessionApi'
import { ReactComponent as SurpriseImage } from 'assets/images/conditions/surprise.svg'
import { ReactComponent as CardImage } from 'assets/images/conditions/card.svg'
import logoDrawerImage from 'assets/images/december09/logoDrawer.png'
import { Card, DescriptionStyled } from './styles'

export const December09Conditions = () => {
  const [isDrawer, setIsDrawer] = useState(false)
  const [activate, { isLoading }] = useActivateMutation()
  const navigate = useNavigate()

  const goNext = async () => {
    const res = await activate({ cell: 10 })

    if (res) {
      navigate('/december-09/complete')
    }
  }

  return (
    <>
      <Layout
        title='Отже'
        subTitle={isDrawer ? 'Понеділок, 9 грудня' : null}
        description={(
          <>
            <Card>
              <SurpriseImage />

              <DescriptionStyled>
                Щодня заходь у застосунок і відкривай нову комірку. Щойно тицьнеш — побачиш, який кешбек я приготував на сьогодні.
              </DescriptionStyled>
            </Card>

            <Card>
              <CardImage />

              <DescriptionStyled>
                Щоб ним скористатися, завітай до магазину та просто відскануй свою картку лояльності. На ній уже буде активовано пропозицію. От і все!
              </DescriptionStyled>
            </Card>
          </>
        )}
        buttonLabel='Зловивти кешбек'
        onSubmit={() => setIsDrawer(true)}
      />

      <Drawer
        isOpen={isDrawer}
        title={<>Сьогодні лови кешбек <span>10%</span> на весь чек, що діє у</>}
        buttonLabel='Погодуй мене і отримай кешбек'
        onSubmit={goNext}
        isDisabled={isLoading}
      >
        <LogoDrawer logo={logoDrawerImage} />
      </Drawer>
    </>
  )
}
