import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Layout } from 'components/layout/layout'
import { Drawer } from 'components/drawer/drawer'
import { LogoDrawer } from 'components/logoDrawer/logoDrawer'
import { useActivateMutation } from 'store/sessionApi'
import { Description } from 'components/description/description'
import logoDrawer from 'assets/images/december26/logoDrawer.png'
import { Snake } from './snake/snake'

export const December26 = () => {
  const [isDrawer, setIsDrawer] = useState(false)
  const [activate, { isLoading }] = useActivateMutation()
  const navigate = useNavigate()

  const goNext = async () => {
    const res = await activate({ cell: 27 })

    if (res) {
      navigate('/december-26/complete')
    }
  }

  return (
    <>
      <Layout
        title='Привіти! Якщо святкові приготування тебе трохи втомили, заходь за смакотою від «Легко».'
        subTitle='Четвер, 26 грудня'
        description={(
          <Description>
            По-перше, вона сьогодні з кешбеком <span>25%</span>. По-друге, страви майже готові, просто поклади на пательню або в духовку. А поки все готується, відпочинь.<br /><br />
            Тицяй на комірку і забирай нагороду.
          </Description>
        )}
        snake={<Snake onComplete={() => setIsDrawer(true)} />}
      />

      <Drawer
        isOpen={isDrawer}
        title={<>Чекаю в гості з кешбеком <span>25%</span>.</>}
        buttonLabel='Погодуй мене і отримай кешбек'
        onSubmit={goNext}
        isDisabled={isLoading}
      >
        <LogoDrawer logo={logoDrawer} />
      </Drawer>
    </>
  )
}
