import { useNavigate } from 'react-router-dom'
import { Box } from '@mui/material'
import { Layout } from 'components/layout/layout'
import { Description } from 'components/description/description'
import { Button } from 'components/uikit/button/button'
import { ReactComponent as SnakeImage } from 'assets/images/december09/snake.svg'
import { SnakeContainer } from './styles'

export const December09 = () => {
  const navigate = useNavigate()

  const goNext = () => navigate('/december-09/conditions')

  return (
    <Layout
      title='Привіти! Сподіваюсь, у нас із тобою все топчик.'
      subTitle='Понеділок, 9 грудня'
      description={<Description>Але про всяк випадок нагадаю, як тут усе працює.</Description>}
      snake={(
        <SnakeContainer>
          <SnakeImage />

          <Box mt='48px'>
            <Button onClick={goNext}>Умови акції</Button>
          </Box>
        </SnakeContainer>
      )}
      typePhone='snowflakes'
    />
  )
}
