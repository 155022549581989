import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const sessionApi = createApi({
  reducerPath: 'userApi',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://api.zmiye.top/api',
    prepareHeaders: (headers) => {
      const persistedData = localStorage.getItem('persist:calendar')

      if (persistedData) {
        try {
          const parsedData = JSON.parse(persistedData)
          const token = JSON.parse(parsedData.token || '')

          if (token) {
            headers.set('Authorization', `Bearer ${token}`)
          }
        } catch (error) {
          console.error('Failed to parse token from localStorage:', error)
        }
      }

      headers.set('Content-Type', 'application/json')

      return headers
    }


  }),
  endpoints: builder => ({
    auth: builder.mutation({
      query: ({ identifier }) => ({
        url: '/auth',
        method: 'POST',
        body: {
          identifier
        }
      })
    }),
    getSnake: builder.query({
      query: () => '/snake'
    }),
    activate: builder.mutation({
      query: ({ cell }) => ({
        url: '/snake/activate',
        method: 'POST',
        body: {
          cell_number: cell
        }
      })
    })
  })
})

export const { useAuthMutation, useGetSnakeQuery, useActivateMutation } = sessionApi
