import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Layout } from 'components/layout/layout'
import { Drawer } from 'components/drawer/drawer'
import { Description } from 'components/description/description'
import { useActivateMutation } from 'store/sessionApi'
import logoDrawer from 'assets/images/december23/logoDrawer.png'
import { Snake } from './snake/snake'
import { LogoDrawerStyled } from './styles'

export const December23 = () => {
  const [isDrawer, setIsDrawer] = useState(false)
  const [activate, { isLoading }] = useActivateMutation()
  const navigate = useNavigate()

  const goNext = async () => {
    const res = await activate({ cell: 24 })

    if (res) {
      navigate('/december-23/complete')
    }
  }

  return (
    <>
      <Layout
        title='Мої шануваннячка! За два дні Різдво, тож варто подумати про щось особливе до столу.'
        subTitle='Понеділок, 23 грудня'
        description={(
          <Description>
            Раджу бігти у «М’ясомаркет», поки не розібрали м’ясце від Skott Smeat. Це фермерська яловичина, на яку сьогодні діє кешбек <span>30%</span>. Смакуватиме топчик!<br /><br />
            Тицяй на комірку і забирай нагороду.
          </Description>
        )}
        snake={<Snake onComplete={() => setIsDrawer(true)} />}
      />

      <Drawer
        isOpen={isDrawer}
        title={<>Кешбек <span>30%</span> на м’ясце від Skott Smeat у</>}
        buttonLabel='Погодуй мене і отримай кешбек'
        onSubmit={goNext}
        isDisabled={isLoading}
      >
        <LogoDrawerStyled logo={logoDrawer} />
      </Drawer>
    </>
  )
}
