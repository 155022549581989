export const HeadBlock10 = () => {
  return (
    <g>
      <path d="M34.5 84.9998C16.9999 -2.50026 108 -18.1126 131.5 36.3874" stroke="#FFE6DE" strokeWidth="8"/>
      <path d="M180 95.0912C180 95.0912 214.849 71.4703 216.831 48.3684L220.655 61.2817L230.384 57.2963C230.384 57.2963 205.418 97.1647 180 95.0912Z" fill="#FF530A"/>
      <path d="M197.702 78.5977C196.935 68.9238 193.935 59.6152 188.486 51.2841C167.168 18.6736 116.51 13.3698 75.3378 39.4353C42.3776 60.3018 31.0518 94.4514 31.0002 124.66C30.8902 188.647 77.3795 222.888 132.988 222.888V167.334C139.632 164.794 146.194 161.529 152.529 157.518C178.859 140.849 194.938 115.389 197.527 90.6698C197.953 86.6108 198.018 82.5755 197.698 78.5977H197.702Z" fill="#EFB825"/>
      <path d="M93.225 140.514C102.81 140.514 110.58 132.868 110.58 123.436C110.58 114.004 102.81 106.358 93.225 106.358C83.6402 106.358 75.8701 114.004 75.8701 123.436C75.8701 132.868 83.6402 140.514 93.225 140.514Z" fill="#FF530A"/>
      <path d="M116.609 106.879C121.349 111.164 123.535 117.693 122.384 123.913C122.112 125.384 120.418 126.477 118.94 126.095C117.379 125.692 116.434 124.275 116.723 122.705C116.778 122.411 116.826 122.113 116.867 121.816C116.888 121.667 116.901 121.518 116.918 121.369C116.894 121.603 116.894 121.735 116.905 121.423C116.925 120.784 116.929 120.152 116.877 119.516C116.853 119.218 116.822 118.917 116.781 118.623C116.771 118.558 116.702 118.065 116.757 118.43C116.815 118.795 116.729 118.308 116.716 118.24C116.585 117.615 116.417 116.999 116.21 116.394C116.114 116.11 116.008 115.829 115.894 115.548C115.839 115.409 115.777 115.271 115.719 115.132C115.564 114.763 115.76 115.051 115.764 115.21C115.764 114.993 115.451 114.601 115.344 114.412C115.196 114.151 115.042 113.891 114.877 113.637C114.53 113.096 114.141 112.592 113.739 112.091C113.478 111.763 114.096 112.456 113.794 112.162C113.687 112.057 113.588 111.942 113.481 111.834C113.244 111.591 112.997 111.357 112.742 111.127C111.628 110.119 111.412 108.211 112.515 107.102C113.601 106.009 115.413 105.8 116.606 106.879H116.609Z" fill="black"/>
      <path d="M118.637 119.614C125.993 121.488 133.682 121.809 141.206 120.852C155.223 119.076 169.168 113.667 181.085 106.256C187.795 102.086 193.944 96.7651 198.526 90.423C198.918 86.3606 198.952 82.3186 198.609 78.334C193.284 92.3747 179.573 101.727 165.937 107.758C151.583 114.107 135.442 118.014 119.864 114.043C116.244 113.119 115.028 118.694 118.633 119.614H118.637Z" fill="black"/>
      <path d="M181.253 61.5426C180.793 60.4804 180.311 59.3652 179.693 58.3961C180.233 59.2475 179.616 58.2852 179.516 58.1212C179.387 57.9113 179.252 57.695 179.118 57.4924C178.799 57.0017 178.475 56.525 178.132 56.0565C177.453 55.1189 176.72 54.2261 175.946 53.3772C175.782 53.1972 175.611 53.0175 175.441 52.8379C175.181 52.5687 175.215 52.6005 175.536 52.9339C175.42 52.8188 175.305 52.7037 175.189 52.5887C174.776 52.1934 174.357 51.8122 173.926 51.4386C172.605 50.2925 172.073 48.0936 173.184 46.5809C174.213 45.1696 176.27 44.6034 177.678 45.8323C181.705 49.3276 184.941 53.7323 187.133 58.7836C187.849 60.4365 187.107 62.6963 185.506 63.354C183.857 64.0357 182.022 63.3217 181.254 61.5494L181.253 61.5426Z" fill="black"/>
      <path d="M92.2121 73.1809C96.4562 76.9868 103.782 78.2293 108.931 78.0541C118.52 77.7365 134.621 68.6415 135.092 66.1985C135.886 62.0814 130.117 63.7966 124.808 66.5639C113.96 72.2181 103.165 73.8375 93.2526 68.7582C90.9532 67.7294 88.9996 71.0717 92.2121 73.1809Z" fill="black"/>
      <ellipse cx="37" cy="99.5" rx="37" ry="42.5" fill="#FF530A"/>
      <ellipse cx="37" cy="99.5" rx="22" ry="24.5" fill="#FFE6DE"/>
    </g>
  )
}
