import { Drawer, styled, Typography } from '@mui/material'

export const Container = styled(Drawer)(({ theme }) => ({
  '& .MuiBackdrop-root': {
    backgroundColor: 'rgba(0, 0, 0, 0)'
  },

  '& .MuiPaper-root': {
    backgroundColor: theme.colors.veryPalePink,
    padding: '48px 24px',
    borderRadius: '48px 48px 0 0',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    textAlign: 'center',
    height: 'calc(100% - 220px)'
  }
}))

export const Title = styled(Typography)(({ theme }) => ({
  fontFamily: 'Axiforma',
  fontWeight: '700',
  fontSize: '32px',
  lineHeight: '38.4px',
  color: theme.colors.darkTeal,

  span: {
    color: theme.colors.orange
  }
}))

export const Description = styled(Typography)(({ theme, top }) => ({
  fontFamily: 'Axiforma',
  fontWeight: '500',
  fontSize: '16px',
  lineHeight: '24px',
  color: '#003429CC',
  marginTop: top || '48px',

  span: {
    color: theme.colors.orange
  }
}))
