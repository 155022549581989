import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Box } from '@mui/material'
import { Layout } from 'components/layout/layout'
import { Drawer } from 'components/drawer/drawer'
import { LogoDrawer } from 'components/logoDrawer/logoDrawer'
import { useActivateMutation } from 'store/sessionApi'
import logoDrawer from 'assets/images/december25/logoDrawer.png'
import { Snake } from './snake/snake'

export const December25 = () => {
  const [isDrawer, setIsDrawer] = useState(false)
  const [activate, { isLoading }] = useActivateMutation()
  const navigate = useNavigate()

  const goNext = async () => {
    const res = await activate({ cell: 26 })

    if (res) {
      navigate('/december-25/complete')
    }
  }

  return (
    <>
      <Layout
        title='З Різдвом! Бажаю тобі затишного настрою, теплих обіймів та смачної вечері.'
        subTitle='Середа, 25 грудня'
        snake={(
          <Box mt='68px'>
            <Snake onComplete={() => setIsDrawer(true)} />
          </Box>
        )}
        typePhone='snowflakes'
      />

      <Drawer
        isOpen={isDrawer}
        title='А якщо кутя вже не лізе, заїжджай на донер до «Döner Маркет».'
        topDescription={<>Сьогодні святковий кешбек <span>20%</span>.</>}
        buttonLabel='Погодуй мене і отримай кешбек'
        onSubmit={goNext}
        isDisabled={isLoading}
      >
        <LogoDrawer logo={logoDrawer} />
      </Drawer>
    </>
  )
}
