import { styled, Modal, Box, Typography } from '@mui/material'

export const Container = styled(Modal)(() => ({
  backgroundColor: '#00342980',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  'div:focus-visible': {
    outline: 'none'
  }
}))

export const Body = styled(Box)(({ theme }) => ({
  backgroundColor: theme.colors.veryPalePink,
  padding: '32px 32px 0 32px',
  borderRadius: '48px',
  textAlign: 'center',
  width: '100%',
  maxWidth: '300px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center'
}))

export const Title = styled(Typography)(({ theme }) => ({
  fontFamily: 'Axiforma',
  fontWeight: '700',
  fontSize: '32px',
  lineHeight: '41.6px',
  color: theme.colors.darkTeal,
  marginBottom: '32px'
}))
