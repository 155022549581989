export const TailBlock1 = () => {
  return (
    <path d="M70.5 70.6442C109.161 70.6442 140.5 39.3051 140.5 0.644165H70.5C31.8404 0.644165 0.5 31.9845 0.5 70.6442C0.5 89.9752 16.169 105.644 35.5 105.644C54.831 105.644 70.5 89.9752 70.5 70.6442Z" fill="#EFB825"/>
  )
}

export const TailBlock2 = () => {
  return (
    <path d="M67.5 70.6437C106.161 70.6437 137.5 39.3047 137.5 0.643722H78.5495C41.1157 -4.198 6.39787 21.6179 0.465802 59.1398C-2.52272 78.0429 10.218 95.799 29.0002 99.081C48.0939 102.1 64.4813 89.7376 67.5 70.6437Z" fill="#EFB825"/>
  )
}

export const TailBlock3 = () => {
  return (
    <path d="M70.5 70.6445C109.161 70.6445 140.5 39.3055 140.5 0.644531H70.5C31.8404 0.644531 0.5 31.9849 0.5 70.6445C0.5 89.9756 16.169 105.645 35.5 105.645C54.831 105.645 70.5 89.9756 70.5 70.6445Z" fill="#EFB825"/>
  )
}
