import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Layout } from 'components/layout/layout'
import { Drawer } from 'components/drawer/drawer'
import { Description } from 'components/description/description'
import { useActivateMutation } from 'store/sessionApi'
import logoDrawer from 'assets/images/december16/logoDrawer.png'
import { Snake } from './snake/snake'
import { LogoDrawerStyled } from './styles'

export const December16 = () => {
  const [isDrawer, setIsDrawer] = useState(false)
  const [activate, { isLoading }] = useActivateMutation()
  const navigate = useNavigate()

  const goNext = async () => {
    const res = await activate({ cell: 17 })

    if (res) {
      navigate('/december-16/complete')
    }
  }

  return (
    <>
      <Layout
        title='2024-й випробовував нас на міцність, але треба тримати хвіст бубликом.'
        subTitle='Понеділок, 16 грудня'
        description={(
          <Description>
            А ще — знаходити маленькі радощі кожного дня та завжди мати в морозилці «Легко».  Щоб, навіть коли сил нема, смачна вечеря все одно була.
          </Description>
        )}
        snake={<Snake onComplete={() => setIsDrawer(true)} />}
      />

      <Drawer
        isOpen={isDrawer}
        title={<>Лови <span>25%</span> кешбеку на заморожену смакоту від «Легко» та хутчіше до</>}
        buttonLabel='Погодуй мене і отримай кешбек'
        onSubmit={goNext}
        isDisabled={isLoading}
      >
        <LogoDrawerStyled logo={logoDrawer} />
      </Drawer>
    </>
  )
}
