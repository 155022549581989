import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Layout } from 'components/layout/layout'
import { Description } from 'components/description/description'
import { Drawer } from 'components/drawer/drawer'
import { Snake } from './snake/snake'
import { LogoDrawer } from 'components/logoDrawer/logoDrawer'
import { useActivateMutation } from 'store/sessionApi'
import logoDrawerImage from 'assets/images/december02/logoDrawer.png'
import smallSnake from 'assets/images/december02/smallSnake.svg'

export const December02 = () => {
  const [isDrawer, setIsDrawer] = useState(false)
  const [activate, { isLoading }] = useActivateMutation()
  const navigate = useNavigate()

  const goNext = async () => {
    const res = await activate({ cell: 3 })

    if (res) {
      navigate('/december-02/complete')
    }
  }

  return (
    <>
      <Layout
        title='Моє шануваннячко! Звертаюся до тебе з терміновим посланням.'
        subTitle='Понеділок, 2 грудня'
        description={(
          <Description>
            Миколай просив нагадати, що, за новим стилем, він збирається до дітлахів 6 грудня.<br /><br />
            Тож хутчіше замовляй подарунки, якщо маєш маленького топчика.
          </Description>
        )}
        snake={<Snake onComplete={() => setIsDrawer(true)} />}
      />

      <Drawer
        isOpen={isDrawer}
        title={<>Лови <span>15%</span> кешбеку на смакоту від «Бащинський» у «М’ясомаркет» та фірмових магазинах «Наша Ряба».</>}
        topDescription='А ще сюрприз від Видавництва Старого Лева, у яких є цікаве для всієї родини.'
        description='Промокод діє з 2.12.2024 р. до 31.01.2025 р. та може бути використаний багаторазово. Пропозиція діє лише для учасників Спільноти Старого Лева.'
        buttonLabel='Погодуй мене і отримай кешбек'
        onSubmit={goNext}
        isDisabled={isLoading}
      >
        <LogoDrawer
          type='right'
          title='Книга — топчик подарунок'
          description='Активуй промокод під час замовлення на starylev.com.ua та отримай знижку 20% на весь асортимент.'
          logo={logoDrawerImage}
          logo1={smallSnake}
          subTitle='OLDLEV'
          link='https://starylev.com.ua/'
        />
      </Drawer>
    </>
  )
}
