import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { RouterProvider } from 'react-router-dom'
import { CssBaseline, ThemeProvider } from '@mui/material'
import { router } from 'router/router'
import { theme } from 'theme/theme'
import { persistor, store } from 'store/store'
import 'assets/fonts/axiforma/fontsAxiforma.css'
import 'assets/fonts/gilroy/fontsGilroy.css'

export const App = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={theme}>
          <CssBaseline />

          <RouterProvider router={router} />
        </ThemeProvider>
      </PersistGate>
    </Provider>
  )
}
