import { styled, Box } from '@mui/material'

export const Container = styled(Box)(() => ({
  position: 'relative',
  paddingLeft: '25px'
}))

export const SaluteContainer = styled(Box)(() => ({
  position: 'absolute',
  top: 0,
  right: '20px'
}))
