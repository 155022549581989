import { styled, Typography } from '@mui/material'

export const Container = styled(Typography)(({ theme }) => ({
  fontFamily: 'Axiforma',
  fontWeight: '500',
  fontSize: '16px',
  lineHeight: '24px',
  color: `${theme.colors.veryPalePink}CC`,
  marginBottom: '48px',

  span: {
    fontWeight: '600',
    fontSize: '16px',
    color: theme.colors.goldenYellow
  }
}))
