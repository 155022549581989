import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Layout } from 'components/layout/layout'
import { Drawer } from 'components/drawer/drawer'
import { LogoDrawer } from 'components/logoDrawer/logoDrawer'
import { useActivateMutation } from 'store/sessionApi'
import logoDrawer from 'assets/images/december13/logoDrawer.png'
import { Snake } from './snake/snake'

export const December13 = () => {
  const [isDrawer, setIsDrawer] = useState(false)
  const [activate, { isLoading }] = useActivateMutation()
  const navigate = useNavigate()

  const goNext = async () => {
    const res = await activate({ cell: 14 })

    if (res) {
      navigate('/december-13/complete')
    }
  }

  return (
    <>
      <Layout
        title='П’ятниця — чудовий день для зустрічі з друзями, яких давно не бачив.'
        subTitle='П’ятниця, 13 грудня'
        snake={<Snake onComplete={() => setIsDrawer(true)} />}
      />

      <Drawer
        isOpen={isDrawer}
        title={<>Бери піцу у «М’ясомаркет» з кешбеком <span>25%</span> і гоу.</>}
        buttonLabel='Погодуй мене і отримай кешбек'
        onSubmit={goNext}
        isDisabled={isLoading}
      >
        <LogoDrawer logo={logoDrawer} />
      </Drawer>
    </>
  )
}
