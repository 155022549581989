import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Layout } from 'components/layout/layout'
import { Description } from 'components/description/description'
import { Drawer } from 'components/drawer/drawer'
import { LogoDrawer } from 'components/logoDrawer/logoDrawer'
import { useActivateMutation } from 'store/sessionApi'
import logoDrawerImage from 'assets/images/december03/logoDrawer.png'
import { Snake } from './snake/snake'

export const December03 = () => {
  const [isDrawer, setIsDrawer] = useState(false)
  const [activate, { isLoading }] = useActivateMutation()
  const navigate = useNavigate()

  const goNext = async () => {
    const res = await activate({ cell: 4 })

    if (res) {
      navigate('/december-03/complete')
    }
  }

  return (
    <>
      <Layout
        title={<>Привіти! Сьогодні до тебе з суперкешбеком <span>20%</span>.</>}
        subTitle='Вівторок, 3 грудня'
        description={<Description>Тицяй на комірку і забирай нагороду.</Description>}
        snake={<Snake onComplete={() => setIsDrawer(true)} />}
      />

      <Drawer
        isOpen={isDrawer}
        title={<>Cуперкешбек <span>20%</span> на весь чек.</>}
        buttonLabel='Погодуй мене і отримай кешбек'
        onSubmit={goNext}
        description='Заходь у гості!'
        isDisabled={isLoading}
      >
        <LogoDrawer logo={logoDrawerImage} />
      </Drawer>
    </>
  )
}
