import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Box } from '@mui/material'
import { Layout } from 'components/layout/layout'
import { Description } from 'components/description/description'
import { Drawer } from 'components/drawer/drawer'
import { LogoDrawer } from 'components/logoDrawer/logoDrawer'
import { useActivateMutation } from 'store/sessionApi'
import { ReactComponent as ChickenImage } from 'assets/images/december12/chicken.svg'
import { ReactComponent as Num1Image } from 'assets/images/december12/num1.svg'
import { ReactComponent as Num2Image } from 'assets/images/december12/num2.svg'
import { ReactComponent as Num3Image } from 'assets/images/december12/num3.svg'
import { ReactComponent as Num4Image } from 'assets/images/december12/num4.svg'
import logoDrawer from 'assets/images/december12/logoDrawer.png'
import { Snake } from './snake/snake'
import { Title, Row, Circle, Label } from './styles'

export const December12 = () => {
  const [isDrawer, setIsDrawer] = useState(false)
  const [activate, { isLoading }] = useActivateMutation()
  const navigate = useNavigate()

  const goNext = async () => {
    const res = await activate({ cell: 13 })

    if (res) {
      navigate('/december-12/complete')
    }
  }

  return (
    <>
      <Layout
        title='Привіт, кулінаре! Як щодо курячих гомілок?'
        subTitle='Четвер, 12 грудня'
        description={(
          <Description>
            Купуй сьогодні з казковим кешбеком <span>25%</span> у «М’ясомаркет» або фірмових магазинах «Наша Ряба».<br /><br />
            А після рекомендую приготувати ароматні гомілки з мандаринами.<br /><br />
            Тицяй на комірку і забирай нагороду.
          </Description>
        )}
        snake={<Snake onComplete={() => setIsDrawer(true)} />}
        typePhone='shin'
      />

      <Drawer
        isOpen={isDrawer}
        headIcon={<ChickenImage />}
        title='Aроматні гомілки з мандаринами'
        buttonLabel='Погодуй мене і отримай кешбек'
        onSubmit={goNext}
        isDisabled={isLoading}
      >
        <Box mt='48px'>
          <Title>Інгредієнти</Title>

          <Row>
            <Circle />
            <Label>Курячі гомілки без шкірки «Наша Ряба» — 1 паковання</Label>
          </Row>

          <Row>
            <Circle />
            <Label>Мандарини — 2 шт.</Label>
          </Row>

          <Row>
            <Circle />
            <Label>Мед — 20 г</Label>
          </Row>

          <Row>
            <Circle />
            <Label>Мандарини — 2 шт.</Label>
          </Row>

          <Row>
            <Circle />
            <Label>Часник — 4 зубчики</Label>
          </Row>

          <Row>
            <Circle />
            <Label>Мускатний горіх — 10 г</Label>
          </Row>

          <Row>
            <Circle />
            <Label>Суміш перців горошком — 10 г</Label>
          </Row>

          <Row>
            <Circle />
            <Label>Оливкова олія — 10 г</Label>
          </Row>

          <Row>
            <Circle />
            <Label>Розмарин — 2 гілочки</Label>
          </Row>

          <Row>
            <Circle />
            <Label>Сіль до смаку</Label>
          </Row>
        </Box>

        <Box mt='48px'>
          <Title>Як готувати</Title>

          <Row>
            <Num1Image />
            <Label>Очищуємо мандарини, цедру нарізаємо на мілкій терці. </Label>
          </Row>

          <Row>
            <Num2Image />
            <Label>Робимо маринад: змішуємо мед, олію, сіль, цедру та спеції. </Label>
          </Row>

          <Row>
            <Num3Image />
            <Label>Натираємо курочку маринадом, зверху викладаємо мандарини й часник та залишаємо на кілька годин.</Label>
          </Row>

          <Row>
            <Num4Image />
            <Label>Запікаємо в духовці 30 хв за температури 165 °С.  </Label>
          </Row>
        </Box>

        <LogoDrawer
          type='primary'
          title={<>Погодуй мене — подарую <span>25% кешбеку</span> на курячі гомілки.</>}
          logo={logoDrawer}
        />
      </Drawer>
    </>
  )
}
