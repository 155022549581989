import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Box } from '@mui/material'
import { Layout } from 'components/layout/layout'
import { Description } from 'components/description/description'
import { Drawer } from 'components/drawer/drawer'
import { Button } from 'components/uikit/button/button'
import { ModalMusic } from 'components/modalMusic/modalMusic'
import { useActivateMutation } from 'store/sessionApi'
import { ReactComponent as MusicImage } from 'assets/images/december01/music.svg'
import { Snake } from './snake/snake'

export const December01 = () => {
  const [isDrawer, setIsDrawer] = useState(false)
  const [isModal, setIsModal] = useState(false)
  const [activate, { isLoading }] = useActivateMutation()
  const navigate = useNavigate()

  const onChangeIsModal = () => setIsModal(!isModal)

  const goNext = async () => {
    const res = await activate({ cell: 2 })

    if (res) {
      navigate('/december-01/complete')
    }
  }

  const openPlaylist = () => {
    onChangeIsModal()
  }

  return (
    <>
      <Layout
        title='Привіт, кулінаре! Вітаю з початком ммм’ясембера.'
        subTitle='Неділя, 1 грудня'
        description={(
          <Description>
            Сьогодні дарую тобі цілих <span>20%</span> кешбеку на весь чек.<br /><br />

            Забігай за смачненьким до «М’ясомаркет», «Döner Маркет» або фірмових магазинів «Наша Ряба», і гайда готувати вечерю разом із родиною. <br /><br />

            Тицяй на комірку і забирай нагороду.
          </Description>
        )}
        snake={<Snake onComplete={() => setIsDrawer(true)} />}
        typePhone='snowflakes'
      />

      <ModalMusic isOpen={isModal} onClose={onChangeIsModal} onSubmit={onChangeIsModal} />

      <Drawer
        isOpen={isDrawer}
        title='Для атмосфери — вмикай різдвяний плейлист.'
        buttonLabel='Погодуй мене і отримай кешбек'
        headIcon={<MusicImage />}
        onSubmit={goNext}
        isDisabled={isLoading}
      >
        <Box sx={{ marginTop: '72px' }}>
          <a href="https://music.youtube.com/playlist?list=PLg4sm8Gq028s7ddP7Bzz6beVY_h10u8zm">
            <Button type='large' onClick={openPlaylist}>Різдвяний плейлист</Button>
          </a>
        </Box>
      </Drawer>
    </>
  )
}
