import { useRef, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import mainPhoneMusic from 'assets/music/mainPhone.mp3'
import christmasPhoneMusic from 'assets/music/christmasPhone.mp3'
import sportPhoneMusic from 'assets/music/sportPhone.mp3'
import sadPhoneMusic from 'assets/music/sadPhone.mp3'
import finalPhoneMusic from 'assets/music/finalPhone.mp3'

export const Music = () => {
  const { isPlaying } = useSelector(({ playback }) => playback)
  const location = useLocation()
  const audioRef = useRef(null)

  useEffect(() => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.play()
      } else {
        audioRef.current.pause()
      }
    }
  }, [isPlaying])

  const getSrcMusic = () => {
    if (
      location.pathname.includes('december-06') || location.pathname.includes('december-18') ||
      location.pathname.includes('december-22') || location.pathname.includes('december-25') ||
      location.pathname.includes('december-30')
    ) return christmasPhoneMusic
    if (location.pathname.includes('december-17')) return sportPhoneMusic
    if (location.pathname.includes('december-19')) return sadPhoneMusic
    if (location.pathname.includes('december-31')) return finalPhoneMusic

    return mainPhoneMusic
  }

  return (
    <audio ref={audioRef} loop>
      <source src={getSrcMusic()} type="audio/mpeg" />
    </audio>
  )
}
