import { ButtonStyled, LargeButtonStyled } from './styles'

export const Button = ({ children, onClick, type = 'primary', isDisabled, isShadow, ...props }) => {
  const buttons = {
    'primary': ButtonStyled,
    'large': LargeButtonStyled
  }

  const Container = buttons[type] || buttons.primary

  return (
    <Container disabled={isDisabled} {...props} onClick={onClick} isshadow={isShadow ? 1 : 0}>
      {children}
    </Container>
  )
}
