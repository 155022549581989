import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Layout } from 'components/layout/layout'
import { Drawer } from 'components/drawer/drawer'
import { Description } from 'components/description/description'
import { LogoDrawer } from 'components/logoDrawer/logoDrawer'
import { useActivateMutation } from 'store/sessionApi'
import logoDrawer from 'assets/images/december17/logoDrawer.png'
import smallSnakeImage from 'assets/images/december17/smallSnake.svg'
import { Snake } from './snake/snake'

export const December17 = () => {
  const [isDrawer, setIsDrawer] = useState(false)
  const [activate, { isLoading }] = useActivateMutation()
  const navigate = useNavigate()

  const goNext = async () => {
    const res = await activate({ cell: 18 })

    if (res) {
      navigate('/december-17/complete')
    }
  }

  return (
    <>
      <Layout
        title='Привіти! Багато хто на початку року починає нове життя.'
        subTitle='Вівторок, 17 грудня'
        description={(
          <Description>
            Я от теж пообіцяв дружині зайнятися пілатесом. Хоча до цього був лише майстром зі спортивного поїдання млинців.
          </Description>
        )}
        snake={<Snake onComplete={() => setIsDrawer(true)} />}
      />

      <Drawer
        isOpen={isDrawer}
        title={<>До речі, сьогодні діє кешбек <span>15%</span> на всі шедеври з відділу кулінарії у «М’ясомаркет».</>}
        topDescription='А на активне життя нехай тебе надихне подарунок від моїх друзів, магазину туристичного спорядження Gorgany!'
        description='Промокод дійсний до 31.12.2024 року. Знижка не діє на подарункові сертифікати та прокат.'
        buttonLabel='Погодуй мене і отримай кешбек'
        onSubmit={goNext}
        isDisabled={isLoading}
      >
        <LogoDrawer
          type='left'
          logo={logoDrawer}
          logo1={smallSnakeImage}
          title='Гайда на шопінг в Gorgany '
          subTitle='zmiietopchyk'
          description='Активуй промокод на gorgany.com або в офлайн-магазинах та отримуй знижку 10% на весь асортимент, акційне теж. '
          link='https://www.gorgany.com/?gad_source=1&gbraid=0AAAAACfWV8flrhUKIDsTfoE2fEMLJoadp&gclid=Cj0KCQiAire5BhCNARIsAM53K1hTg_J8rRAlxQw_5MT4n7yPnFNX1ugFDwqmd0R9XlNNOtBVyzwThWgaAonIEALw_wcB'
        />
      </Drawer>
    </>
  )
}
