// import { useEffect } from 'react'
// import { useNavigate } from 'react-router-dom'
// import { useSelector } from 'react-redux'
import { Button } from 'components/uikit/button/button'
import { Voice } from 'components/voice/voice'
import { ReactComponent as LogoImage } from 'assets/images/logo.svg'
import { SnowflakesBackground } from './snowflakesBackground/snowflakesBackground'
import { ShinBackground } from './shinBackground/shinBackground'
import { PopcornBackground } from './popcornBackground/popcornBackground'
import { MusicBackground } from './musicBackground/musicBackground'
import { PizzaBackground } from './pizzaBackground/pizzaBackground'
import { Container, TopSection, Head, Title, SubTitle } from './styles'

export const Layout = ({ title, description, snake, buttonLabel, onSubmit, subTitle, typePhone }) => {
  // const { days } = useSelector(({ playback }) => playback)
  // const navigate = useNavigate()

  // useEffect(() => {
  //   // console.log('days', days)

  //   const currentDate = new Date().toISOString().split('T')[0]

  //   if (currentDate === '2024-11-29') navigate(days?.[0]?.is_active ? '/' : '/november-29/complete')
  //   if (currentDate === '2024-11-30') navigate('/november-30')
  //   if (currentDate === '2024-12-01') navigate(days?.[2]?.is_active ? '/december-01' : '/december-01/complete')
  //   if (currentDate === '2024-12-02') navigate(days?.[3]?.is_active ? '/december-02' : '/december-02/complete')
  //   if (currentDate === '2024-12-03') navigate(days?.[4]?.is_active ? '/december-03' : '/december-03/complete')
  //   if (currentDate === '2024-12-04') navigate(days?.[5]?.is_active ? '/december-04' : '/december-04/complete')
  //   if (currentDate === '2024-12-05') navigate(days?.[6]?.is_active ? '/december-05' : '/december-05/complete')
  //   if (currentDate === '2024-12-06') navigate(days?.[7]?.is_active ? '/december-06' : '/december-06/complete')
  //   if (currentDate === '2024-12-07') navigate(days?.[8]?.is_active ? '/december-07' : '/december-07/complete')
  //   if (currentDate === '2024-12-08') navigate(days?.[9]?.is_active ? '/december-08' : '/december-08/complete')
  //   if (currentDate === '2024-12-09') navigate(days?.[10]?.is_active ? '/december-09' : '/december-09/complete')
  //   if (currentDate === '2024-12-10') navigate(days?.[11]?.is_active ? '/december-10' : '/december-10/complete')
  //   if (currentDate === '2024-12-11') navigate(days?.[12]?.is_active ? '/december-11' : '/december-11/complete')
  //   if (currentDate === '2024-12-12') navigate(days?.[13]?.is_active ? '/december-12' : '/december-12/complete')
  //   if (currentDate === '2024-12-13') navigate(days?.[14]?.is_active ? '/december-13' : '/december-13/complete')
  //   if (currentDate === '2024-12-14') navigate(days?.[15]?.is_active ? '/december-14' : '/december-14/complete')
  //   if (currentDate === '2024-12-15') navigate(days?.[16]?.is_active ? '/december-15' : '/december-15/complete')
  //   if (currentDate === '2024-12-16') navigate(days?.[17]?.is_active ? '/december-16' : '/december-16/complete')
  //   if (currentDate === '2024-12-17') navigate(days?.[18]?.is_active ? '/december-17' : '/december-17/complete')
  //   if (currentDate === '2024-12-18') navigate(days?.[19]?.is_active ? '/december-18' : '/december-18/complete')
  //   if (currentDate === '2024-12-19') navigate(days?.[20]?.is_active ? '/december-19' : '/december-19/complete')
  //   if (currentDate === '2024-12-20') navigate(days?.[21]?.is_active ? '/december-20' : '/december-20/complete')
  //   if (currentDate === '2024-12-21') navigate(days?.[22]?.is_active ? '/december-21' : '/december-21/complete')
  //   if (currentDate === '2024-12-22') navigate(days?.[23]?.is_active ? '/december-22' : '/december-22/complete')
  //   if (currentDate === '2024-12-23') navigate(days?.[24]?.is_active ? '/december-23' : '/december-23/complete')
  //   if (currentDate === '2024-12-24') navigate(days?.[25]?.is_active ? '/december-24' : '/december-24/complete')
  //   if (currentDate === '2024-12-25') navigate(days?.[26]?.is_active ? '/december-25' : '/december-25/complete')
  //   if (currentDate === '2024-12-26') navigate(days?.[27]?.is_active ? '/december-26' : '/december-26/complete')
  //   if (currentDate === '2024-12-27') navigate(days?.[28]?.is_active ? '/december-27' : '/december-27/complete')
  //   if (currentDate === '2024-12-28') navigate(days?.[29]?.is_active ? '/december-28' : '/december-28/complete')
  //   if (currentDate === '2024-12-29') navigate(days?.[30]?.is_active ? '/december-29' : '/december-29/complete')
  //   if (currentDate === '2024-12-30') navigate(days?.[31]?.is_active ? '/december-30' : '/december-30/complete')
  //   if (currentDate === '2024-12-31') navigate(days?.[32]?.is_active ? '/december-31' : '/december-31/complete')
  // }, [days])

  return (
    <Container>
      {typePhone === 'snowflakes' ? <SnowflakesBackground /> : null}
      {typePhone === 'shin' ? <ShinBackground /> : null}
      {typePhone === 'popcorn' ? <PopcornBackground /> : null}
      {typePhone === 'music' ? <MusicBackground /> : null}
      {typePhone === 'pizza' ? <PizzaBackground /> : null}

      <TopSection>
        <Head>
          <LogoImage />

          {subTitle ? <SubTitle>{subTitle}</SubTitle> : null}

          <Voice />
        </Head>

        {title ? <Title>{title}</Title> : null}

        {description ? description : null}

        {snake ? snake : null}
      </TopSection>

      {buttonLabel && <Button onClick={onSubmit}>{buttonLabel}</Button>}
    </Container>
  )
}
