import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Outlet, useLocation } from 'react-router-dom'
import { Music } from 'components/music/music'
import { useSelector, useDispatch } from 'react-redux'
import { useAuthMutation, useGetSnakeQuery } from 'store/sessionApi'
import { changeToken } from 'store/sessionSlice'
import { changeDays } from 'store/playbackSlice'

export const RouterLayout = () => {
  const [auth] = useAuthMutation()
  const { token } = useSelector(({ session }) => session)
  const { days } = useSelector(({ playback }) => playback)
  const { data } = useGetSnakeQuery()
  const location = useLocation()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  // console.log('data', data)

  useEffect(() => {
    const postAuth = async () => {
      const identifier = location?.search?.split('=')?.[1]

      if (identifier) {
        const res = await auth({ identifier: decodeURIComponent(identifier) })

        if (res?.data?.access_token) {
          dispatch(changeToken(res?.data?.access_token))
          window.location.href = '/'
        } else {
          dispatch(changeToken(null))
        }
      }
    }

    if (!token) {
      postAuth()
    }
  }, [])

  useEffect(() => {
    if (data?.length) {
      dispatch(changeDays(data || []))

      const currentDate = new Date().toISOString().split('T')[0]

      if (currentDate === '2024-11-29') navigate(data?.[0]?.is_active ? '/' : '/november-29/complete')
      if (currentDate === '2024-11-30') navigate('/november-30')
      if (currentDate === '2024-12-01') navigate(data?.[1]?.is_active ? '/december-01' : '/december-01/complete')
      if (currentDate === '2024-12-02') navigate(data?.[2]?.is_active ? '/december-02' : '/december-02/complete')
      if (currentDate === '2024-12-03') navigate(data?.[3]?.is_active ? '/december-03' : '/december-03/complete')
      if (currentDate === '2024-12-04') navigate(data?.[4]?.is_active ? '/december-04' : '/december-04/complete')
      if (currentDate === '2024-12-05') navigate(data?.[5]?.is_active ? '/december-05' : '/december-05/complete')
      if (currentDate === '2024-12-06') navigate(data?.[6]?.is_active ? '/december-06' : '/december-06/complete')
      if (currentDate === '2024-12-07') navigate(data?.[7]?.is_active ? '/december-07' : '/december-07/complete')
      if (currentDate === '2024-12-08') navigate(data?.[8]?.is_active ? '/december-08' : '/december-08/complete')
      if (currentDate === '2024-12-09') navigate(data?.[9]?.is_active ? '/december-09' : '/december-09/complete')
      if (currentDate === '2024-12-10') navigate(data?.[10]?.is_active ? '/december-10' : '/december-10/complete')
      if (currentDate === '2024-12-11') navigate(data?.[11]?.is_active ? '/december-11' : '/december-11/complete')
      if (currentDate === '2024-12-12') navigate(data?.[12]?.is_active ? '/december-12' : '/december-12/complete')
      if (currentDate === '2024-12-13') navigate(data?.[13]?.is_active ? '/december-13' : '/december-13/complete')
      if (currentDate === '2024-12-14') navigate(data?.[14]?.is_active ? '/december-14' : '/december-14/complete')
      if (currentDate === '2024-12-15') navigate(data?.[15]?.is_active ? '/december-15' : '/december-15/complete')
      if (currentDate === '2024-12-16') navigate(data?.[16]?.is_active ? '/december-16' : '/december-16/complete')
      if (currentDate === '2024-12-17') navigate(data?.[17]?.is_active ? '/december-17' : '/december-17/complete')
      if (currentDate === '2024-12-18') navigate(data?.[18]?.is_active ? '/december-18' : '/december-18/complete')
      if (currentDate === '2024-12-19') navigate(data?.[19]?.is_active ? '/december-19' : '/december-19/complete')
      if (currentDate === '2024-12-20') navigate(data?.[1]?.is_active ? '/december-20' : '/december-20/complete')
      if (currentDate === '2024-12-21') navigate(data?.[2]?.is_active ? '/december-21' : '/december-21/complete')
      if (currentDate === '2024-12-22') navigate(data?.[3]?.is_active ? '/december-22' : '/december-22/complete')
      if (currentDate === '2024-12-23') navigate(data?.[4]?.is_active ? '/december-23' : '/december-23/complete')
      if (currentDate === '2024-12-24') navigate(data?.[5]?.is_active ? '/december-24' : '/december-24/complete')
      if (currentDate === '2024-12-25') navigate(data?.[6]?.is_active ? '/december-25' : '/december-25/complete')
      if (currentDate === '2024-12-26') navigate(data?.[27]?.is_active ? '/december-26' : '/december-26/complete')
      if (currentDate === '2024-12-27') navigate(data?.[28]?.is_active ? '/december-27' : '/december-27/complete')
      if (currentDate === '2024-12-28') navigate(data?.[29]?.is_active ? '/december-28' : '/december-28/complete')
      if (currentDate === '2024-12-29') navigate(data?.[30]?.is_active ? '/december-29' : '/december-29/complete')
      if (currentDate === '2024-12-30') navigate(data?.[31]?.is_active ? '/december-30' : '/december-30/complete')
      if (currentDate === '2024-12-31') navigate(data?.[32]?.is_active ? '/december-31' : '/december-31/complete')
    }
  }, [data])

  return (token && days?.length) ? (
    <>
      <Music />

      <Outlet />
    </>
  ) : null
}
