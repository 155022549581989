import { Layout } from 'components/layout/layout'
import { Description } from 'components/description/description'
import { Snake } from './snake/snake'

export const December18Complete = () => {
  return (
    <Layout
      title='Оце смакота!'
      subTitle='Середа, 18 грудня'
      description={(
        <Description>
          Твоя нагорода вже в додатку!< br/>
          Завтра буде нова, повертайся!
        </Description>
      )}
      snake={<Snake />}
    />
  )
}
