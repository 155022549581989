import { useEffect } from 'react'
import { gsap } from 'gsap'

export const Block2 = () => {
  useEffect(() => {
    gsap.fromTo(
      '#box',
      { scale: 1 },
      {
        scale: 0.8,
        duration: 0.5,
        repeat: -1,
        yoyo: true,
        ease: 'power1.inOut'
      }
    )
  }, [])

  return (
    <g>
      <path d="M0 0C55.2276 0 100 44.7724 100 100H0V0Z" fill="#FF530A"/>
      <path d="M8.13837 66.176L15.4664 60.352H18.9864V84H14.3464V66.624L10.5704 69.536L8.13837 66.176ZM31.3054 68.768C33.5454 68.768 35.4334 69.472 36.9694 70.88C38.5054 72.288 39.2734 74.1227 39.2734 76.384C39.2734 78.7093 38.4627 80.6187 36.8414 82.112C35.1987 83.6267 33.0654 84.384 30.4414 84.384C27.5614 84.384 24.916 83.5093 22.5054 81.76L24.3934 77.984C26.3987 79.3493 28.34 80.032 30.2174 80.032C31.5187 80.032 32.5534 79.6907 33.3214 79.008C34.1107 78.304 34.5054 77.4293 34.5054 76.384C34.5054 75.36 34.1214 74.5173 33.3534 73.856C32.6067 73.1733 31.6574 72.832 30.5054 72.832C29.012 72.832 27.7107 73.3547 26.6014 74.4L23.8494 72.192L25.1294 60.352H37.6414V64.704H28.6494L28.1374 69.344C29.1187 68.96 30.1747 68.768 31.3054 68.768ZM44.4219 84.512C43.6752 84.512 43.0459 84.2667 42.5339 83.776C42.0219 83.264 41.7659 82.6347 41.7659 81.888C41.7659 81.1627 42.0219 80.5547 42.5339 80.064C43.0459 79.5733 43.6752 79.328 44.4219 79.328C45.1259 79.328 45.7232 79.5733 46.2139 80.064C46.7259 80.5547 46.9819 81.1627 46.9819 81.888C46.9819 82.6347 46.7259 83.264 46.2139 83.776C45.7232 84.2667 45.1259 84.512 44.4219 84.512ZM46.7321 66.176L54.0601 60.352H57.5801V84H52.9401V66.624L49.1641 69.536L46.7321 66.176ZM72.5871 75.2L68.4271 79.648H77.2271V84H61.8031V80.064L69.1631 72.352C70.0591 71.4133 70.5605 70.88 70.6671 70.752C70.9445 70.432 71.2645 69.9307 71.6271 69.248C71.9898 68.5867 72.1711 67.9787 72.1711 67.424C72.1711 66.528 71.8618 65.8027 71.2431 65.248C70.6245 64.672 69.8458 64.384 68.9071 64.384C67.2431 64.384 65.4725 65.2587 63.5951 67.008L61.0031 63.616C63.4565 61.1627 66.2191 59.936 69.2911 59.936C71.5525 59.936 73.3978 60.6187 74.8271 61.984C76.2778 63.328 77.0031 65.056 77.0031 67.168C77.0031 69.4507 75.5311 72.128 72.5871 75.2Z" fill="#FFE6DE"/>
      <path id="box" d="M19 30.0006V38.0006H15C14.2044 38.0006 13.4413 37.6845 12.8787 37.1219C12.3161 36.5593 12 35.7962 12 35.0006V31.0006C12 30.7354 12.1054 30.481 12.2929 30.2935C12.4804 30.1059 12.7348 30.0006 13 30.0006H19ZM27 30.0006C27.2652 30.0006 27.5196 30.1059 27.7071 30.2935C27.8946 30.481 28 30.7354 28 31.0006V35.0006C28 35.7962 27.6839 36.5593 27.1213 37.1219C26.5587 37.6845 25.7956 38.0006 25 38.0006H21V30.0006H27ZM24.5 18.0006C25.0905 18.0005 25.6715 18.1498 26.1888 18.4346C26.706 18.7194 27.1429 19.1304 27.4586 19.6295C27.7743 20.1285 27.9586 20.6993 27.9943 21.2887C28.03 21.8782 27.9161 22.4671 27.663 23.0006H28C28.5304 23.0006 29.0391 23.2113 29.4142 23.5864C29.7893 23.9614 30 24.4702 30 25.0006V26.0006C30 26.531 29.7893 27.0397 29.4142 27.4148C29.0391 27.7899 28.5304 28.0006 28 28.0006H21V23.0006H19V28.0006H12C11.4696 28.0006 10.9609 27.7899 10.5858 27.4148C10.2107 27.0397 10 26.531 10 26.0006V25.0006C10 24.4702 10.2107 23.9614 10.5858 23.5864C10.9609 23.2113 11.4696 23.0006 12 23.0006H12.337C12.1144 22.5319 11.9993 22.0194 12 21.5006C12 19.5676 13.567 18.0006 15.483 18.0006C17.238 17.9706 18.795 19.0926 19.864 20.9346L20 21.1776C21.033 19.2636 22.56 18.0636 24.291 18.0026L24.5 18.0006ZM15.5 20.0006C15.1022 20.0006 14.7206 20.1586 14.4393 20.4399C14.158 20.7212 14 21.1028 14 21.5006C14 21.8984 14.158 22.2799 14.4393 22.5612C14.7206 22.8426 15.1022 23.0006 15.5 23.0006H18.643C17.902 21.0956 16.694 19.9806 15.5 20.0006ZM24.483 20.0006C23.303 19.9806 22.098 21.0966 21.357 23.0006H24.5C24.8978 22.9983 25.2785 22.8381 25.5582 22.5552C25.8379 22.2723 25.9938 21.8899 25.9915 21.4921C25.9892 21.0943 25.829 20.7136 25.5462 20.4339C25.2633 20.1542 24.8808 19.9983 24.483 20.0006Z" fill="#FFE6DE"/>
    </g>
  )
}
