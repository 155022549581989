import React, { useEffect, useRef } from 'react'
import gsap from 'gsap'
import { ReactComponent as Item1 } from './1.svg'
import { ReactComponent as Item2 } from './2.svg'
import { ReactComponent as Item3 } from './3.svg'
import { ReactComponent as Item4 } from './4.svg'
import { ReactComponent as Item5 } from './5.svg'
import { ReactComponent as Item6 } from './6.svg'
import { ReactComponent as Item7 } from './7.svg'
import { Container } from './styles'

export const PopcornBackground = () => {
  const itemsRefs = useRef([])

  useEffect(() => {
    const positions = [
      { x: 0, y: 0 },
      { x: '90vw', y: 30 },
      { x: '97vw', y: 100 },
      { x: 50, y: 230 },
      { x: '89vw', y: 300 },
      { x: 70, y: 450 },
      { x: '92vw', y: 430 },
      { x: '88vw', y: 600 },
      { x: 50, y: 650 },
      { x: 150, y: 680 },
      { x: 10, y: 750 },
      { x: 120, y: 850 },
      { x: 180, y: 950 },
      { x: '80vw', y: 900 },
      { x: '95vw', y: 1000 },
      // --------------
      { x: 50, y: -100 },
      { x: 20, y: -200 },
      { x: '60vw', y: -110 },
      { x: '95vw', y: -150 },
      { x: '87vw', y: -200 },
      { x: '40vw', y: -230 },
      { x: '20vw', y: -290 },
      // --------------
      { x: 50, y: -350 },
      { x: 20, y: -450 },
      { x: '60vw', y: -360 },
      { x: '95vw', y: -400 },
      { x: '87vw', y: -450 },
      { x: '40vw', y: -480 },
      { x: '20vw', y: -540 }
    ]

    positions.forEach((pos, index) => gsap.set(itemsRefs.current[index], { x: pos.x, y: pos.y }))

    const animateSnowflake = (item, index, startY) => {
      const distance = window.innerHeight - startY
      const duration = distance / 24

      gsap.to(item, {
        y: '100vh',
        x: `+=${Math.random() * 400 - 200}`,
        duration: duration,
        ease: 'linear',
        onComplete: () => {
          gsap.set(item, { x: positions[index].x, y: -100 })
          animateSnowflake(item, index, 0)
        }
      })
    }

    itemsRefs.current.forEach((item, index) => {
      const startY = positions[index].y
      animateSnowflake(item, index, startY)
    })

    return () => itemsRefs.current.forEach(item => gsap.killTweensOf(item))
  }, [])

  return (
    <Container>
      <Item1 ref={(el) => (itemsRefs.current[0] = el)} style={{ position: 'absolute' }} />
      <Item2 ref={(el) => (itemsRefs.current[1] = el)} style={{ position: 'absolute' }} />
      <Item3 ref={(el) => (itemsRefs.current[2] = el)} style={{ position: 'absolute' }} />
      <Item4 ref={(el) => (itemsRefs.current[3] = el)} style={{ position: 'absolute' }} />
      <Item5 ref={(el) => (itemsRefs.current[4] = el)} style={{ position: 'absolute' }} />
      <Item6 ref={(el) => (itemsRefs.current[5] = el)} style={{ position: 'absolute' }} />
      <Item1 ref={(el) => (itemsRefs.current[6] = el)} style={{ position: 'absolute' }} />
      <Item7 ref={(el) => (itemsRefs.current[7] = el)} style={{ position: 'absolute' }} />
      <Item2 ref={(el) => (itemsRefs.current[8] = el)} style={{ position: 'absolute' }} />
      <Item2 ref={(el) => (itemsRefs.current[9] = el)} style={{ position: 'absolute' }} />
      <Item2 ref={(el) => (itemsRefs.current[10] = el)} style={{ position: 'absolute' }} />
      <Item5 ref={(el) => (itemsRefs.current[11] = el)} style={{ position: 'absolute' }} />
      <Item2 ref={(el) => (itemsRefs.current[12] = el)} style={{ position: 'absolute' }} />
      <Item7 ref={(el) => (itemsRefs.current[13] = el)} style={{ position: 'absolute' }} />
      <Item1 ref={(el) => (itemsRefs.current[14] = el)} style={{ position: 'absolute' }} />

      <Item4 ref={(el) => (itemsRefs.current[15] = el)} style={{ position: 'absolute' }} />
      <Item2 ref={(el) => (itemsRefs.current[16] = el)} style={{ position: 'absolute' }} />
      <Item4 ref={(el) => (itemsRefs.current[17] = el)} style={{ position: 'absolute' }} />
      <Item1 ref={(el) => (itemsRefs.current[18] = el)} style={{ position: 'absolute' }} />
      <Item4 ref={(el) => (itemsRefs.current[19] = el)} style={{ position: 'absolute' }} />
      <Item4 ref={(el) => (itemsRefs.current[20] = el)} style={{ position: 'absolute' }} />
      <Item2 ref={(el) => (itemsRefs.current[21] = el)} style={{ position: 'absolute' }} />

      <Item4 ref={(el) => (itemsRefs.current[22] = el)} style={{ position: 'absolute' }} />
      <Item2 ref={(el) => (itemsRefs.current[23] = el)} style={{ position: 'absolute' }} />
      <Item4 ref={(el) => (itemsRefs.current[24] = el)} style={{ position: 'absolute' }} />
      <Item1 ref={(el) => (itemsRefs.current[25] = el)} style={{ position: 'absolute' }} />
      <Item4 ref={(el) => (itemsRefs.current[26] = el)} style={{ position: 'absolute' }} />
      <Item4 ref={(el) => (itemsRefs.current[27] = el)} style={{ position: 'absolute' }} />
      <Item2 ref={(el) => (itemsRefs.current[28] = el)} style={{ position: 'absolute' }} />
    </Container>
  )
}
