import { Box } from '@mui/material'
import {
  Container,
  SecondaryContainer,
  PrimaryContainer,
  RightContainer,
  Title,
  TitlePrimary,
  TitleRight,
  SubTitle,
  Promocode,
  Description,
  RightDescription,
  Image1,
  Image2,
  ActiveCodeLabel,
  ActiveCodeLabel2,
  RightImageContainer,
  LeftImageContainer
} from './styles'

export const LogoDrawer = ({ logo, logo1, type, title, subTitle, promocode, description, mtLogo, link, ...props }) => {
  console.log('type', type)
  const containers = {
    secondary: () => (
      <SecondaryContainer>
        <Title>{title}</Title>
        <Promocode>{promocode}</Promocode>
        <Description>{description}</Description>

        <Image1>
          <img src={logo} alt='logo-drawer' />
        </Image1>

        <Image2>
          <img src={logo1} alt='logo-drawer' />
        </Image2>

        <ActiveCodeLabel2 href={link}>Активувати код</ActiveCodeLabel2>
      </SecondaryContainer>
    ),
    primary: () => (
      <PrimaryContainer>
        <TitlePrimary>{title}</TitlePrimary>

        <Box mt={mtLogo || 0} display='flex'>
          <img src={logo} alt='logo-drawer' />
        </Box>
      </PrimaryContainer>
    ),
    right: () => (
      <RightContainer>
        <TitleRight>{title}</TitleRight>
        <SubTitle>{subTitle}</SubTitle>
        <RightDescription>{description}</RightDescription>

        <img src={logo} alt='logo-drawer' />

        <ActiveCodeLabel href={link}>Активувати код</ActiveCodeLabel>

        <RightImageContainer>
          <img src={logo1} alt='logo1' />
        </RightImageContainer>
      </RightContainer>
    ),
    left: () => (
      <RightContainer>
        <TitleRight>{title}</TitleRight>
        <SubTitle>{subTitle}</SubTitle>
        <RightDescription>{description}</RightDescription>

        <img src={logo} alt='logo-drawer' />

        <ActiveCodeLabel href={link}>Активувати код</ActiveCodeLabel>

        <LeftImageContainer>
          <img src={logo1} alt='logo1' />
        </LeftImageContainer>
      </RightContainer>
    ),
    default: () => (
      <Container {...props}>
        <img src={logo} alt='logo-drawer' />
      </Container>
    )
  }

  return containers[type]?.() || containers.default()
}
