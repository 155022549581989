import { styled, Box, Typography, Container as ContainerMaterial } from '@mui/material'

export const Container = styled(ContainerMaterial)(({ theme }) => ({
  padding: '24px 24px 48px 24px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: theme.colors.darkTeal,
  overflowY: 'auto',
  overflowX: 'hidden'
}))

export const TopSection = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  marginBottom: '48px',
  width: '100%',
  zIndex: 2
}))

export const Head = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%'
}))

export const Title = styled(Typography)(({ theme }) => ({
  marginTop: '48px',
  fontFamily: 'Axiforma',
  fontWeight: '700',
  fontSize: '32px',
  lineHeight: '38.4px',
  color: theme.colors.veryPalePink,
  marginBottom: '24px',

  span: {
    color: theme.colors.goldenYellow
  }
}))

export const SubTitle = styled(Typography)(({ theme }) => ({
  marginTop: '12px',
  fontFamily: 'Axiforma',
  fontWeight: '700',
  fontSize: '16px',
  lineHeight: '20.8px',
  color: theme.colors.goldenYellow
}))
