export const HeadBlock1 = () => {
  return (
    <g>
      <path d="M149.753 38.6529C149.294 37.5908 148.811 36.4756 148.194 35.5064C148.734 36.3579 148.116 35.3956 148.016 35.2316C147.888 35.0217 147.753 34.8054 147.618 34.6027C147.299 34.112 146.975 33.6354 146.633 33.1668C145.954 32.2293 145.221 31.3365 144.447 30.4876C144.282 30.3075 144.112 30.1279 143.941 29.9483C143.682 29.679 143.716 29.7109 144.036 30.0442C143.921 29.9292 143.805 29.8141 143.689 29.699C143.276 29.3037 142.857 28.9225 142.426 28.549C141.106 27.4028 140.574 25.2039 141.685 23.6913C142.714 22.28 144.77 21.7137 146.178 22.9427C150.206 26.438 153.442 30.8427 155.634 35.894C156.349 37.5469 155.607 39.8066 154.006 40.4644C152.357 41.146 150.522 40.432 149.754 38.6597L149.753 38.6529Z" fill="black"/>
      <path d="M167.202 55.71C166.435 46.0361 163.435 36.7275 157.986 28.3964C136.668 -4.21412 86.0096 -9.51786 44.8377 16.5476C11.8776 37.4141 0.551724 71.5637 0.500164 101.773C0.390171 165.759 46.8795 200 102.488 200V144.446C109.132 141.906 115.694 138.642 122.029 134.63C148.359 117.961 164.438 92.5013 167.026 67.7821C167.453 63.7231 167.518 59.6878 167.198 55.71H167.202Z" fill="#EFB825"/>
      <path d="M68.0326 66.228C68.1598 66.3024 68.2869 66.3667 68.4176 66.4377C68.5791 66.5223 68.7441 66.6103 68.9056 66.6914C69.0569 66.7692 69.2116 66.8436 69.3594 66.9181C81.7267 72.9017 96.5552 68.7818 104 57.6636C104.533 56.8687 105.028 56.0366 105.482 55.1673C112.35 42.0433 107.108 25.9257 93.7744 19.1709C80.4377 12.4127 64.0591 17.571 57.1914 30.695C56.7376 31.5643 56.3355 32.4472 55.9849 33.3401C51.1727 45.6152 56.2014 59.6965 68.0291 66.228H68.0326Z" fill="#FFE6DE"/>
      <path d="M68.4829 67.4247C68.5963 67.4924 68.7166 67.5532 68.8369 67.6175C68.9847 67.6953 69.1325 67.7731 69.2838 67.8475C69.4213 67.9186 69.5622 67.9862 69.6997 68.0539C80.9843 73.5132 94.5135 69.7552 101.306 59.6078C104.702 50.2552 98.9132 38.6973 87.5495 32.9403C76.1858 27.1799 63.2582 29.2568 57.4973 37.4153C53.1044 48.6147 57.6932 61.4614 68.4863 67.4213L68.4829 67.4247Z" fill="black"/>
      <path d="M61.7251 117.625C71.31 117.625 79.08 109.979 79.08 100.547C79.08 91.1149 71.31 83.4688 61.7251 83.4688C52.1403 83.4688 44.3703 91.1149 44.3703 100.547C44.3703 109.979 52.1403 117.625 61.7251 117.625Z" fill="#FF530A"/>
      <path d="M85.1091 83.992C89.8491 88.2776 92.0352 94.8058 90.8837 101.026C90.6122 102.498 88.9176 103.59 87.4395 103.208C85.879 102.805 84.9338 101.388 85.2225 99.8187C85.2775 99.5244 85.3256 99.2267 85.3669 98.9291C85.3875 98.7802 85.4012 98.6314 85.4184 98.4826C85.3944 98.716 85.3944 98.8479 85.4047 98.5367C85.4253 97.8974 85.4287 97.2649 85.3772 96.629C85.3531 96.3313 85.3222 96.0303 85.2809 95.736C85.2706 95.6717 85.2019 95.1779 85.2569 95.5432C85.3153 95.9085 85.2294 95.4214 85.2156 95.3538C85.085 94.728 84.9166 94.1124 84.7103 93.5069C84.6141 93.2228 84.5075 92.9421 84.3941 92.6613C84.3391 92.5226 84.2772 92.3839 84.2188 92.2453C84.0641 91.8766 84.2601 92.1641 84.2635 92.3231C84.2635 92.1066 83.9507 91.7142 83.8441 91.5248C83.6963 91.2643 83.5417 91.0039 83.3767 90.7502C83.0295 90.209 82.6411 89.705 82.2389 89.2044C81.9777 88.8763 82.5964 89.5697 82.2939 89.2754C82.1874 89.1706 82.0877 89.0556 81.9811 88.9473C81.744 88.7038 81.4965 88.4704 81.2421 88.2404C80.1284 87.2324 79.9119 85.3247 81.0153 84.2152C82.1014 83.1227 83.9129 82.913 85.1056 83.992H85.1091Z" fill="black"/>
      <path d="M167.391 59.457C159.868 57.5898 153.851 57.6101 146.156 58.5639C131.822 60.3334 117.562 65.7226 105.375 73.1071C98.5137 77.2628 92.2252 82.5644 87.5397 88.8839C87.139 92.9317 87.1038 96.9594 87.4553 100.93C92.9001 86.9392 106.922 77.6201 120.866 71.6107C135.544 65.2845 151.284 61.4729 167.214 65.4297C167.214 65.4297 167.391 63.9663 167.391 59.457Z" fill="black"/>
    </g>
  )
}
