import { useEffect } from 'react'
import { gsap } from 'gsap'

export const Block2 = () => {
  useEffect(() => {
    gsap.fromTo(
      '#box',
      { scale: 1 },
      {
        scale: 0.8,
        duration: 0.5,
        repeat: -1,
        yoyo: true,
        ease: 'power1.inOut'
      }
    )
  }, [])

  return (
    <g>
      <path d="M0 0C55.2276 0 100 44.7724 100 100H0V0Z" fill="#FF530A"/>
      <path d="M8.26337 66.176L15.5914 60.352H19.1114V84H14.4714V66.624L10.6954 69.536L8.26337 66.176ZM34.1184 75.2L29.9584 79.648H38.7584V84H23.3344V80.064L30.6944 72.352C31.5904 71.4133 32.0917 70.88 32.1984 70.752C32.4757 70.432 32.7957 69.9307 33.1584 69.248C33.521 68.5867 33.7024 67.9787 33.7024 67.424C33.7024 66.528 33.393 65.8027 32.7744 65.248C32.1557 64.672 31.377 64.384 30.4384 64.384C28.7744 64.384 27.0037 65.2587 25.1264 67.008L22.5344 63.616C24.9877 61.1627 27.7504 59.936 30.8224 59.936C33.0837 59.936 34.929 60.6187 36.3584 61.984C37.809 63.328 38.5344 65.056 38.5344 67.168C38.5344 69.4507 37.0624 72.128 34.1184 75.2ZM44.2969 84.512C43.5502 84.512 42.9209 84.2667 42.4089 83.776C41.8969 83.264 41.6409 82.6347 41.6409 81.888C41.6409 81.1627 41.8969 80.5547 42.4089 80.064C42.9209 79.5733 43.5502 79.328 44.2969 79.328C45.0009 79.328 45.5982 79.5733 46.0889 80.064C46.6009 80.5547 46.8569 81.1627 46.8569 81.888C46.8569 82.6347 46.6009 83.264 46.0889 83.776C45.5982 84.2667 45.0009 84.512 44.2969 84.512ZM46.6071 66.176L53.9351 60.352H57.4551V84H52.8151V66.624L49.0391 69.536L46.6071 66.176ZM72.4621 75.2L68.3021 79.648H77.1021V84H61.6781V80.064L69.0381 72.352C69.9341 71.4133 70.4355 70.88 70.5421 70.752C70.8195 70.432 71.1395 69.9307 71.5021 69.248C71.8648 68.5867 72.0461 67.9787 72.0461 67.424C72.0461 66.528 71.7368 65.8027 71.1181 65.248C70.4995 64.672 69.7208 64.384 68.7821 64.384C67.1181 64.384 65.3475 65.2587 63.4701 67.008L60.8781 63.616C63.3315 61.1627 66.0941 59.936 69.1661 59.936C71.4275 59.936 73.2728 60.6187 74.7021 61.984C76.1528 63.328 76.8781 65.056 76.8781 67.168C76.8781 69.4507 75.4061 72.128 72.4621 75.2Z" fill="#FFE6DE"/>
      <path id="box" d="M19 30.0006V38.0006H15C14.2044 38.0006 13.4413 37.6845 12.8787 37.1219C12.3161 36.5593 12 35.7962 12 35.0006V31.0006C12 30.7354 12.1054 30.481 12.2929 30.2935C12.4804 30.1059 12.7348 30.0006 13 30.0006H19ZM27 30.0006C27.2652 30.0006 27.5196 30.1059 27.7071 30.2935C27.8946 30.481 28 30.7354 28 31.0006V35.0006C28 35.7962 27.6839 36.5593 27.1213 37.1219C26.5587 37.6845 25.7956 38.0006 25 38.0006H21V30.0006H27ZM24.5 18.0006C25.0905 18.0005 25.6715 18.1498 26.1888 18.4346C26.706 18.7194 27.1429 19.1304 27.4586 19.6295C27.7743 20.1285 27.9586 20.6993 27.9943 21.2887C28.03 21.8782 27.9161 22.4671 27.663 23.0006H28C28.5304 23.0006 29.0391 23.2113 29.4142 23.5864C29.7893 23.9614 30 24.4702 30 25.0006V26.0006C30 26.531 29.7893 27.0397 29.4142 27.4148C29.0391 27.7899 28.5304 28.0006 28 28.0006H21V23.0006H19V28.0006H12C11.4696 28.0006 10.9609 27.7899 10.5858 27.4148C10.2107 27.0397 10 26.531 10 26.0006V25.0006C10 24.4702 10.2107 23.9614 10.5858 23.5864C10.9609 23.2113 11.4696 23.0006 12 23.0006H12.337C12.1144 22.5319 11.9993 22.0194 12 21.5006C12 19.5676 13.567 18.0006 15.483 18.0006C17.238 17.9706 18.795 19.0926 19.864 20.9346L20 21.1776C21.033 19.2636 22.56 18.0636 24.291 18.0026L24.5 18.0006ZM15.5 20.0006C15.1022 20.0006 14.7206 20.1586 14.4393 20.4399C14.158 20.7212 14 21.1028 14 21.5006C14 21.8984 14.158 22.2799 14.4393 22.5612C14.7206 22.8426 15.1022 23.0006 15.5 23.0006H18.643C17.902 21.0956 16.694 19.9806 15.5 20.0006ZM24.483 20.0006C23.303 19.9806 22.098 21.0966 21.357 23.0006H24.5C24.8978 22.9983 25.2785 22.8381 25.5582 22.5552C25.8379 22.2723 25.9938 21.8899 25.9915 21.4921C25.9892 21.0943 25.829 20.7136 25.5462 20.4339C25.2633 20.1542 24.8808 19.9983 24.483 20.0006Z" fill="#FFE6DE"/>
    </g>
  )
}
