import { createTheme } from '@mui/material/styles'

const colors = {
  white: '#ffffff',
  black: '#000000',
  veryPalePink: '#FFE6DE',
  darkTeal: '#003429',
  veryLightYellow: '#FFFFEE',
  goldenYellow: '#EFB825',
  orange: '#FF530A',
  darkCyan: '#18AB88'
}

export const theme = createTheme({
  colors,
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        '*': {
          margin: 0,
          padding: 0,
          boxSizing: 'content-box'
        },
        'html, body, #root': {
          touchAction: 'manipulation',
          height: '100%',
          overflowY: 'hidden'
        },
        '#root': {
          margin: '0 auto',
          display: 'flex',
          overflowX: 'hidden',
          position: 'relative'
        },
        a: {
          textDecoration: 'none'
        },
        li: {
          listStyle: 'none'
        }
      }
    }
  }
})
