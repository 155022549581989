export const HeadBlock1 = () => {
  return (
    <g>
      <path d="M151.289 74.425C151.289 74.425 180.671 44.2736 177.962 21.2456L184.308 33.1249L193.035 27.263C193.035 27.263 176.605 71.3402 151.289 74.425Z" fill="#FF530A"/>
      <path d="M166.702 55.71C165.935 46.0361 162.935 36.7275 157.486 28.3964C136.168 -4.21412 85.5096 -9.51786 44.3378 16.5476C11.3776 37.4141 0.0517541 71.5637 0.000194783 101.773C-0.109798 165.759 46.3795 200 101.988 200V144.446C108.632 141.906 115.194 138.642 121.529 134.63C147.859 117.961 163.938 92.5013 166.527 67.7821C166.953 63.7231 167.018 59.6878 166.698 55.71H166.702Z" fill="#EFB825"/>
      <path d="M67.5326 66.2295C67.6598 66.3039 67.787 66.3682 67.9176 66.4392C68.0791 66.5238 68.2441 66.6117 68.4057 66.6929C68.5569 66.7707 68.7116 66.8451 68.8594 66.9195C81.2268 72.9031 96.0552 68.7833 103.5 57.665C104.033 56.8702 104.528 56.0381 104.982 55.1688C111.85 42.0447 106.608 25.9272 93.2745 19.1724C79.9378 12.4142 63.5591 17.5725 56.6914 30.6965C56.2377 31.5658 55.8355 32.4486 55.4849 33.3416C50.6727 45.6166 55.7015 59.6979 67.5292 66.2295H67.5326Z" fill="#FFE6DE"/>
      <path d="M67.9829 67.4237C68.0963 67.4914 68.2166 67.5523 68.337 67.6165C68.4848 67.6943 68.6326 67.7721 68.7838 67.8466C68.9213 67.9176 69.0622 67.9852 69.1997 68.0529C80.4843 73.5122 94.0135 69.7543 100.806 59.6068C104.202 50.2543 98.4132 38.6963 87.0495 32.9393C75.6859 27.1789 62.7582 29.2558 56.9973 37.4143C52.6045 48.6137 57.1933 61.4604 67.9863 67.4204L67.9829 67.4237Z" fill="black"/>
      <path d="M61.2252 117.627C70.81 117.627 78.58 109.98 78.58 100.548C78.58 91.1164 70.81 83.4702 61.2252 83.4702C51.6403 83.4702 43.8703 91.1164 43.8703 100.548C43.8703 109.98 51.6403 117.627 61.2252 117.627Z" fill="#FF530A"/>
      <path d="M84.6091 83.991C89.3491 88.2766 91.5352 94.8048 90.3837 101.025C90.1122 102.497 88.4176 103.589 86.9395 103.207C85.379 102.804 84.4338 101.387 84.7225 99.8177C84.7775 99.5234 84.8256 99.2257 84.8669 98.9281C84.8875 98.7793 84.9012 98.6304 84.9184 98.4816C84.8944 98.715 84.8944 98.8469 84.9047 98.5357C84.9253 97.8964 84.9287 97.2639 84.8772 96.628C84.8531 96.3303 84.8222 96.0293 84.7809 95.735C84.7706 95.6707 84.7019 95.1769 84.7569 95.5422C84.8153 95.9075 84.7294 95.4204 84.7156 95.3528C84.585 94.727 84.4166 94.1114 84.2103 93.506C84.1141 93.2218 84.0075 92.9411 83.8941 92.6603C83.8391 92.5217 83.7772 92.383 83.7188 92.2443C83.5641 91.8756 83.7601 92.1631 83.7635 92.3221C83.7635 92.1056 83.4507 91.7132 83.3441 91.5238C83.1963 91.2634 83.0417 91.0029 82.8767 90.7492C82.5295 90.208 82.1411 89.704 81.7389 89.2034C81.4777 88.8753 82.0964 89.5687 81.7939 89.2745C81.6874 89.1696 81.5877 89.0546 81.4811 88.9464C81.244 88.7028 80.9965 88.4694 80.7421 88.2394C79.6284 87.2314 79.4119 85.3237 80.5153 84.2143C81.6014 83.1217 83.4129 82.912 84.6056 83.991H84.6091Z" fill="black"/>
      <path d="M86.637 96.7261C93.9928 98.6 101.682 98.9214 109.206 97.9641C123.224 96.1883 137.169 90.7797 149.086 83.3687C155.795 79.1981 161.945 73.8774 166.527 67.5353C166.918 63.4729 166.953 59.4309 166.609 55.4463C161.285 69.487 147.573 78.8396 133.938 84.8705C119.583 91.2194 103.442 95.1262 87.8642 91.1552C84.2447 90.2318 83.0279 95.8061 86.6336 96.7261H86.637Z" fill="black"/>
      <path d="M149.254 38.6549C148.794 37.5928 148.311 36.4775 147.694 35.5084C148.234 36.3598 147.616 35.3975 147.516 35.2335C147.388 35.0236 147.253 34.8073 147.118 34.6047C146.799 34.114 146.475 33.6373 146.133 33.1688C145.454 32.2312 144.721 31.3384 143.947 30.4895C143.782 30.3095 143.612 30.1298 143.441 29.9502C143.182 29.681 143.216 29.7128 143.536 30.0462C143.421 29.9311 143.305 29.816 143.189 29.701C142.776 29.3057 142.357 28.9245 141.926 28.5509C140.606 27.4048 140.074 25.2059 141.185 23.6932C142.214 22.2819 144.27 21.7157 145.678 22.9446C149.706 26.4399 152.942 30.8446 155.134 35.8959C155.849 37.5488 155.107 39.8086 153.506 40.4663C151.857 41.148 150.022 40.434 149.254 38.6617L149.254 38.6549Z" fill="black"/>
    </g>
  )
}
