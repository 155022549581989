import { useEffect, useRef } from 'react'
import { gsap } from 'gsap'
import { HeadBlock } from './headBlock/headBlock'
import { Block1 } from './block1/block1'
import { Block2 } from './block2/block2'
import { Block3 } from './block3/block3'
import { TailBlock } from './tailBlock/tailBlock'

export const Snake = () => {
  const block1Ref = useRef(null)
  const block2Ref = useRef(null)
  const block3Ref = useRef(null)
  const tailBlockRef = useRef(null)

  useEffect(() => {
    gsap.set(block1Ref.current, { x: 100, y: 100, onComplete: startBlock2Animation })
    gsap.set(block2Ref.current, { x: '150vw', y: 100, transformOrigin: '100% 100%' })
    gsap.set(block3Ref.current, { y: '150vh', x: 200 })
    gsap.set(tailBlockRef.current, { x: '-150vw', y: 217.64 })
  }, [])

  const startBlock2Animation = () => {
    gsap.to(block2Ref.current, { x: 200, y: 100, duration: 0.4, ease: 'linear', onComplete: startBlock3Animation } )
  }

  const startBlock3Animation = () => {
    gsap.to(block3Ref.current, { x: 200, y: 200, duration: 0.4, ease: 'linear', onComplete: startTailBlockAnimation } )
  }

  const startTailBlockAnimation = () => {
    gsap.to(tailBlockRef.current, { x: 95, y: 217.64, duration: 0.4, ease: 'linear' } )
  }

  return (
    <svg width="301" height="305" viewBox="0 0 351 305" overflow='visible'>
      <HeadBlock />

      <g ref={block1Ref}>
        <Block1 />
      </g>

      <g ref={block2Ref}>
        <Block2 />
      </g>

      <g ref={block3Ref}>
        <Block3 />
      </g>

      <g ref={tailBlockRef}>
        <TailBlock />
      </g>
    </svg>
  )
}
