export const HeadBlock1 = () => {
  return (
    <g>
      <path d="M166.702 55.71C165.935 46.0361 162.935 36.7275 157.486 28.3964C136.168 -4.21412 85.5096 -9.51786 44.3378 16.5476C11.3776 37.4141 0.0517541 71.5637 0.000194783 101.773C-0.109798 165.759 46.3795 200 101.988 200V144.446C108.632 141.906 115.194 138.642 121.529 134.63C147.859 117.961 163.938 92.5013 166.527 67.7821C166.953 63.7231 167.018 59.6878 166.698 55.71H166.702Z" fill="#EFB825"/>
      <path d="M67.5326 66.23C67.6598 66.3044 67.787 66.3687 67.9176 66.4397C68.0791 66.5243 68.2441 66.6122 68.4057 66.6934C68.5569 66.7712 68.7116 66.8456 68.8594 66.92C81.2268 72.9036 96.0552 68.7838 103.5 57.6655C104.033 56.8707 104.528 56.0386 104.982 55.1693C111.85 42.0452 106.608 25.9277 93.2745 19.1729C79.9378 12.4147 63.5591 17.5729 56.6914 30.697C56.2377 31.5663 55.8355 32.4491 55.4849 33.3421C50.6727 45.6171 55.7015 59.6984 67.5292 66.23H67.5326Z" fill="#FFE6DE"/>
      <path d="M67.9828 67.4228C68.0963 67.4904 68.2166 67.5513 68.3369 67.6156C68.4847 67.6934 68.6325 67.7712 68.7837 67.8456C68.9212 67.9166 69.0622 67.9843 69.1996 68.0519C80.4843 73.5112 94.0134 69.7533 100.806 59.6058C104.202 50.2533 98.4132 38.6953 87.0495 32.9383C75.6858 27.178 62.7582 29.2548 56.9973 37.4134C52.6044 48.6128 57.1932 61.4594 67.9863 67.4194L67.9828 67.4228Z" fill="black"/>
      <path d="M61.2252 117.627C70.8101 117.627 78.5801 109.981 78.5801 100.549C78.5801 91.1169 70.8101 83.4707 61.2252 83.4707C51.6404 83.4707 43.8704 91.1169 43.8704 100.549C43.8704 109.981 51.6404 117.627 61.2252 117.627Z" fill="#FF530A"/>
      <path d="M84.6091 83.9901C89.3491 88.2757 91.5352 94.8039 90.3837 101.024C90.1122 102.496 88.4176 103.588 86.9396 103.206C85.3791 102.803 84.4338 101.386 84.7225 99.8167C84.7775 99.5224 84.8256 99.2248 84.8669 98.9271C84.8875 98.7783 84.9013 98.6294 84.9185 98.4806C84.8944 98.714 84.8944 98.8459 84.9047 98.5347C84.9253 97.8954 84.9288 97.2629 84.8772 96.627C84.8531 96.3294 84.8222 96.0283 84.781 95.734C84.7707 95.6698 84.7019 95.1759 84.7569 95.5412C84.8153 95.9065 84.7294 95.4195 84.7157 95.3518C84.585 94.7261 84.4166 94.1104 84.2104 93.505C84.1141 93.2209 84.0076 92.9401 83.8941 92.6594C83.8392 92.5207 83.7773 92.382 83.7188 92.2433C83.5642 91.8746 83.7601 92.1621 83.7635 92.3211C83.7635 92.1046 83.4507 91.7123 83.3442 91.5228C83.1964 91.2624 83.0417 91.0019 82.8767 90.7483C82.5295 90.2071 82.1411 89.7031 81.739 89.2025C81.4777 88.8744 82.0964 89.5678 81.794 89.2735C81.6874 89.1686 81.5877 89.0536 81.4812 88.9454C81.244 88.7018 80.9965 88.4685 80.7421 88.2385C79.6285 87.2305 79.4119 85.3228 80.5153 84.2133C81.6015 83.1208 83.4129 82.911 84.6057 83.9901H84.6091Z" fill="black"/>
      <path d="M86.6368 96.7251C93.9926 98.599 101.682 98.9204 109.206 97.9631C123.223 96.1873 137.168 90.7787 149.085 83.3677C155.795 79.1971 161.944 73.8765 166.526 67.5343C166.918 63.4719 166.952 59.4299 166.609 55.4453C161.284 69.486 147.573 78.8386 133.937 84.8695C119.583 91.2185 103.442 95.1252 87.8639 91.1542C84.2445 90.2308 83.0276 95.8051 86.6334 96.7251H86.6368Z" fill="black"/>
      <path d="M149.253 38.6549C148.793 37.5928 148.311 36.4775 147.693 35.5084C148.233 36.3598 147.616 35.3975 147.516 35.2335C147.387 35.0236 147.252 34.8073 147.118 34.6047C146.799 34.114 146.475 33.6373 146.132 33.1688C145.453 32.2312 144.72 31.3384 143.946 30.4895C143.782 30.3095 143.611 30.1298 143.441 29.9502C143.181 29.681 143.215 29.7128 143.536 30.0462C143.42 29.9311 143.305 29.816 143.189 29.701C142.776 29.3057 142.357 28.9245 141.926 28.5509C140.605 27.4048 140.073 25.2059 141.184 23.6932C142.213 22.2819 144.27 21.7157 145.678 22.9446C149.705 26.4399 152.941 30.8446 155.133 35.8959C155.849 37.5488 155.107 39.8086 153.506 40.4663C151.857 41.148 150.022 40.434 149.254 38.6617L149.253 38.6549Z" fill="black"/>
    </g>
  )
}

export const HeadBlock2 = () => {
  return (
    <g>
      <path d="M166.702 55.71C165.935 46.0361 162.935 36.7275 157.486 28.3964C136.168 -4.21412 85.5096 -9.51786 44.3378 16.5476C11.3776 37.4141 0.0517541 71.5637 0.000194783 101.773C-0.109798 165.759 46.3795 200 101.988 200V144.446C108.632 141.906 115.194 138.642 121.529 134.63C147.859 117.961 163.938 92.5013 166.527 67.7821C166.953 63.7231 167.018 59.6878 166.698 55.71H166.702Z" fill="#EFB825"/>
      <path d="M61.225 117.625C70.8098 117.625 78.5799 109.979 78.5799 100.547C78.5799 91.1149 70.8098 83.4688 61.225 83.4688C51.6402 83.4688 43.8701 91.1149 43.8701 100.547C43.8701 109.979 51.6402 117.625 61.225 117.625Z" fill="#FF530A"/>
      <path d="M149.28 37.1158C148.821 36.0537 148.338 34.9384 147.721 33.9693C148.261 34.8208 147.643 33.8584 147.543 33.6944C147.415 33.4845 147.28 33.2683 147.145 33.0656C146.826 32.5749 146.502 32.0983 146.16 31.6297C145.481 30.6922 144.748 29.7994 143.974 28.9505C143.809 28.7704 143.639 28.5908 143.468 28.4111C143.209 28.1419 143.243 28.1738 143.563 28.5071C143.448 28.392 143.332 28.277 143.216 28.1619C142.803 27.7666 142.384 27.3854 141.953 27.0119C140.633 25.8657 140.101 23.6668 141.212 22.1541C142.241 20.7428 144.297 20.1766 145.705 21.4056C149.733 24.9008 152.969 29.3056 155.161 34.3569C155.876 36.0097 155.134 38.2695 153.533 38.9273C151.884 39.6089 150.049 38.8949 149.281 37.1226L149.28 37.1158Z" fill="black"/>
      <path d="M132.526 71.4609C136.073 62.648 145.673 53.7624 164.466 48.7558C165.471 51.8137 167.609 64.9461 162.789 80.2111C153.518 85.0578 130.981 75.3003 132.526 71.4609Z" fill="black" stroke="black" strokeWidth="2" strokeLinecap="round"/>
      <path d="M159.125 58L156 50.8L161 49L159.125 58Z" fill="white"/>
      <path d="M166 56L163 48.6144L165 48L166 56Z" fill="white"/>
      <path d="M134.346 74.0374C132.445 72.1335 132.988 71.3316 133.088 69.4639C137.344 71.026 147.559 74.141 150.27 75.8878C152.981 77.6346 152.554 80.0152 149.558 79.8555C141.191 79.0088 135.93 75.624 134.346 74.0374Z" fill="#7C0000"/>
      <path d="M67.8598 66.8761C67.9869 66.9503 68.1141 67.0144 68.2447 67.0853C68.4063 67.1696 68.5713 67.2574 68.7328 67.3383C68.8841 67.4159 69.0387 67.4902 69.1865 67.5644C81.5539 73.533 96.3824 69.4235 103.828 58.3331C104.36 57.5402 104.855 56.7102 105.309 55.8431C112.177 42.7519 106.935 26.6748 93.6016 19.9369C80.2649 13.1956 63.8862 18.341 57.0185 31.4321C56.5648 32.2993 56.1627 33.1799 55.8121 34.0706C50.9999 46.3149 56.0286 60.3609 67.8563 66.8761H67.8598Z" fill="#FFE6DE"/>
      <path d="M68.6852 67.6646C68.7987 67.732 68.9191 67.7928 69.0394 67.8569C69.1873 67.9345 69.3352 68.0121 69.4865 68.0864C69.624 68.1572 69.765 68.2247 69.9026 68.2922C81.1922 73.7388 94.7144 69.9896 101.493 59.8659C104.876 50.5352 99.0745 39.0043 87.7055 33.2608C76.3365 27.5139 63.4136 29.5859 57.6639 37.7253C53.2858 48.8985 57.8899 61.7152 68.6886 67.6612L68.6852 67.6646Z" fill="black"/>
    </g>
  )
}

export const HeadBlock3 = () => {
  return (
    <g>
      <path d="M166.702 55.71C165.935 46.0361 162.935 36.7275 157.486 28.3964C136.168 -4.21412 85.5096 -9.51786 44.3378 16.5476C11.3776 37.4141 0.0517541 71.5637 0.000194783 101.773C-0.109798 165.759 46.3795 200 101.988 200V144.446C108.632 141.906 115.194 138.642 121.529 134.63C147.859 117.961 163.938 92.5013 166.527 67.7821C166.953 63.7231 167.018 59.6878 166.698 55.71H166.702Z" fill="#EFB825"/>
      <path d="M61.225 117.625C70.8098 117.625 78.5799 109.979 78.5799 100.547C78.5799 91.1149 70.8098 83.4688 61.225 83.4688C51.6402 83.4688 43.8701 91.1149 43.8701 100.547C43.8701 109.979 51.6402 117.625 61.225 117.625Z" fill="#FF530A"/>
      <path d="M149.28 37.1158C148.821 36.0537 148.338 34.9384 147.721 33.9693C148.261 34.8208 147.643 33.8584 147.543 33.6944C147.415 33.4845 147.28 33.2683 147.145 33.0656C146.826 32.5749 146.502 32.0983 146.16 31.6297C145.481 30.6922 144.748 29.7994 143.974 28.9505C143.809 28.7704 143.639 28.5908 143.468 28.4111C143.209 28.1419 143.243 28.1738 143.563 28.5071C143.448 28.392 143.332 28.277 143.216 28.1619C142.803 27.7666 142.384 27.3854 141.953 27.0119C140.633 25.8657 140.101 23.6668 141.212 22.1541C142.241 20.7428 144.297 20.1766 145.705 21.4056C149.733 24.9008 152.969 29.3056 155.161 34.3569C155.876 36.0097 155.134 38.2695 153.533 38.9273C151.884 39.6089 150.049 38.8949 149.281 37.1226L149.28 37.1158Z" fill="black"/>
      <path d="M132.787 67.8095C136.115 65.6869 145.455 62.8644 164.016 59.7577C165.083 60.2409 167.498 62.5629 163.05 66.0895C153.946 68.0862 131.337 68.7343 132.787 67.8095Z" fill="black" stroke="black" strokeWidth="2" strokeLinecap="round"/>
      <path d="M158.916 62.1708L155.65 61.119L160.578 60.2011L158.916 62.1708Z" fill="white"/>
      <path d="M165.702 61.0015L162.557 59.8993L164.53 59.5527L165.702 61.0015Z" fill="white"/>
      <path d="M134.652 68.1061C132.721 67.9497 133.243 67.7316 133.3 67.3556C137.564 67.1784 147.783 66.6293 150.515 66.6633C153.247 66.6973 152.876 67.2105 149.896 67.5187C141.564 68.3013 136.262 68.2363 134.652 68.1061Z" fill="#7C0000"/>
      <path d="M67.8598 66.8761C67.9869 66.9503 68.1141 67.0144 68.2447 67.0853C68.4063 67.1696 68.5713 67.2574 68.7328 67.3383C68.8841 67.4159 69.0387 67.4902 69.1865 67.5644C81.5539 73.533 96.3824 69.4235 103.828 58.3331C104.36 57.5402 104.855 56.7102 105.309 55.8431C112.177 42.7519 106.935 26.6748 93.6016 19.9369C80.2649 13.1956 63.8862 18.341 57.0185 31.4321C56.5648 32.2993 56.1627 33.1799 55.8121 34.0706C50.9999 46.3149 56.0286 60.3609 67.8563 66.8761H67.8598Z" fill="#FFE6DE"/>
      <path d="M68.6852 67.6646C68.7987 67.732 68.9191 67.7928 69.0394 67.8569C69.1873 67.9345 69.3352 68.0121 69.4865 68.0864C69.624 68.1572 69.765 68.2247 69.9026 68.2922C81.1922 73.7388 94.7144 69.9896 101.493 59.8659C104.876 50.5352 99.0745 39.0043 87.7055 33.2608C76.3365 27.5139 63.4136 29.5859 57.6639 37.7253C53.2858 48.8985 57.8899 61.7152 68.6886 67.6612L68.6852 67.6646Z" fill="black"/>
    </g>
  )
}

export const HeadBlock4 = () => {
  return (
    <g>
      <path d="M166.702 55.71C165.935 46.0361 162.935 36.7275 157.486 28.3964C136.168 -4.21412 85.5096 -9.51786 44.3378 16.5476C11.3776 37.4141 0.0517541 71.5637 0.000194783 101.773C-0.109798 165.759 46.3795 200 101.988 200V144.446C108.632 141.906 115.194 138.642 121.529 134.63C147.859 117.961 163.938 92.5013 166.527 67.7821C166.953 63.7231 167.018 59.6878 166.698 55.71H166.702Z" fill="#EFB825"/>
      <path d="M61.225 117.625C70.8098 117.625 78.5799 109.979 78.5799 100.547C78.5799 91.1149 70.8098 83.4688 61.225 83.4688C51.6402 83.4688 43.8701 91.1149 43.8701 100.547C43.8701 109.979 51.6402 117.625 61.225 117.625Z" fill="#FF530A"/>
      <path d="M149.28 37.1158C148.821 36.0537 148.338 34.9384 147.721 33.9693C148.261 34.8208 147.643 33.8584 147.543 33.6944C147.415 33.4845 147.28 33.2683 147.145 33.0656C146.826 32.5749 146.502 32.0983 146.16 31.6297C145.481 30.6922 144.748 29.7994 143.974 28.9505C143.809 28.7704 143.639 28.5908 143.468 28.4111C143.209 28.1419 143.243 28.1738 143.563 28.5071C143.448 28.392 143.332 28.277 143.216 28.1619C142.803 27.7666 142.384 27.3854 141.953 27.0119C140.633 25.8657 140.101 23.6668 141.212 22.1541C142.241 20.7428 144.297 20.1766 145.705 21.4056C149.733 24.9008 152.969 29.3056 155.161 34.3569C155.876 36.0097 155.134 38.2695 153.533 38.9273C151.884 39.6089 150.049 38.8949 149.281 37.1226L149.28 37.1158Z" fill="black"/>
      <path d="M132.526 71.4609C136.073 62.648 145.673 53.7624 164.466 48.7558C165.471 51.8137 167.609 64.9461 162.789 80.2111C153.518 85.0578 130.981 75.3003 132.526 71.4609Z" fill="black" stroke="black" strokeWidth="2" strokeLinecap="round"/>
      <path d="M159.125 58L156 50.8L161 49L159.125 58Z" fill="white"/>
      <path d="M166 56L163 48.6144L165 48L166 56Z" fill="white"/>
      <path d="M134.346 74.0374C132.445 72.1335 132.988 71.3316 133.088 69.4639C137.344 71.026 147.559 74.141 150.27 75.8878C152.981 77.6346 152.554 80.0152 149.558 79.8555C141.191 79.0088 135.93 75.624 134.346 74.0374Z" fill="#7C0000"/>
      <path d="M67.8598 66.8761C67.9869 66.9503 68.1141 67.0144 68.2447 67.0853C68.4063 67.1696 68.5713 67.2574 68.7328 67.3383C68.8841 67.4159 69.0387 67.4902 69.1865 67.5644C81.5539 73.533 96.3824 69.4235 103.828 58.3331C104.36 57.5402 104.855 56.7102 105.309 55.8431C112.177 42.7519 106.935 26.6748 93.6016 19.9369C80.2649 13.1956 63.8862 18.341 57.0185 31.4321C56.5648 32.2993 56.1627 33.1799 55.8121 34.0706C50.9999 46.3149 56.0286 60.3609 67.8563 66.8761H67.8598Z" fill="#FFE6DE"/>
      <path d="M68.6852 67.6646C68.7987 67.732 68.9191 67.7928 69.0394 67.8569C69.1873 67.9345 69.3352 68.0121 69.4865 68.0864C69.624 68.1572 69.765 68.2247 69.9026 68.2922C81.1922 73.7388 94.7144 69.9896 101.493 59.8659C104.876 50.5352 99.0745 39.0043 87.7055 33.2608C76.3365 27.5139 63.4136 29.5859 57.6639 37.7253C53.2858 48.8985 57.8899 61.7152 68.6886 67.6612L68.6852 67.6646Z" fill="black"/>

    </g>
  )
}
