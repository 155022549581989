import { useEffect, useRef, useState } from 'react'
import { gsap } from 'gsap'
import OpenBoxMusic from 'assets/music/openBox.mp3'
import SaluteImage from 'assets/images/salute.gif'
import { HeadBlock1 } from './headBlock/headBlocks'
import { Block1 } from './block1/block1'
import { Block2 } from './block2/block2'
import { Block3 } from './block3/block3'
import { Block4 } from './block4/block4'
import { Block5 } from './block5/block5'
import { Block6 } from './block6/block6'
import { Block7 } from './block7/block7'
import { Block8 } from './block8/block8'
import { Block9 } from './block9/block9'
import { Block10 } from './block10/block10'
import { TailBlock } from './tailBlock/tailBlock'
import { Container, SaluteContainer } from './styles'

export const Snake = ({ onComplete }) => {
  const [showSalute, setShowSalute] = useState(false)
  const blockRefs = useRef([])
  const tailBlockRef = useRef(null)
  const audioRef = useRef(null)

  useEffect(() => {
    audioRef.current = new Audio(OpenBoxMusic)

    gsap.fromTo(
      blockRefs.current[0],
      { x: '150vw', y: 65 },
      { x: 49, y: 65, duration: 0.3, ease: 'linear', onComplete: startBlock2Animation }
    )

    gsap.set(blockRefs.current[1], { x: '150vw', y: 166, transformOrigin: '100% 100%' })
    gsap.set(blockRefs.current[2], { x: 251, y: '150vh' })
    gsap.set(blockRefs.current[3], { x: '-150vw', y: 266 })
    gsap.set(blockRefs.current[4], { x: 151, y: '150vh' })
    gsap.set(blockRefs.current[5], { x: '150vw', y: 366 })
    gsap.set(blockRefs.current[6], { x: 251, y: '150vh' })
    gsap.set(blockRefs.current[7], { x: '-150vw', y: 466 })
    gsap.set(blockRefs.current[8], { x: '-150vw', y: 466 })
    gsap.set(blockRefs.current[9], { x: 51, y: '150vh' })

    gsap.set(tailBlockRef.current, { x: '150vw', y: 566 })
  }, [])

  const startBlock2Animation = () => {
    gsap.to(blockRefs.current[1], { x: 251, y: 166, duration: 0.3, ease: 'linear', onComplete: startBlock3Animation } )
  }

  const startBlock3Animation = () => {
    gsap.to(blockRefs.current[2], { x: 251, y: 266, duration: 0.3, ease: 'linear', onComplete: startBlock4Animation } )
  }

  const startBlock4Animation = () => {
    gsap.to(blockRefs.current[3], { x: 151, y: 266, duration: 0.3, ease: 'linear', onComplete: startBlock5Animation } )
  }

  const startBlock5Animation = () => {
    gsap.to(blockRefs.current[4], { x: 151, y: 366, duration: 0.3, ease: 'linear', onComplete: startBlock6Animation } )
  }

  const startBlock6Animation = () => {
    gsap.to(blockRefs.current[5], { x: 251, y: 366, duration: 0.3, ease: 'linear', onComplete: startBlock7Animation } )
  }

  const startBlock7Animation = () => {
    gsap.to(blockRefs.current[6], { x: 251, y: 466, duration: 0.3, ease: 'linear', onComplete: startBlock8Animation } )
  }

  const startBlock8Animation = () => {
    gsap.to(blockRefs.current[7], { x: 151, y: 466, duration: 0.3, ease: 'linear', onComplete: startBlock9Animation } )
  }

  const startBlock9Animation = () => {
    gsap.to(blockRefs.current[8], { x: 51, y: 466, duration: 0.3, ease: 'linear', onComplete: startBlock10Animation } )
  }

  const startBlock10Animation = () => {
    gsap.to(blockRefs.current[9], { x: 51, y: 566, duration: 0.3, ease: 'linear', onComplete: startTailBlockAnimation } )
  }

  const startTailBlockAnimation = () => {
    gsap.to(tailBlockRef.current, { x: 151, y: 575, duration: 0.3, ease: 'linear' } )
  }

  const handleBlock2Click = () => {
    const rotationDuration = 3
    audioRef.current.play()

    gsap.to(blockRefs.current[1], { rotation: '+=90', duration: rotationDuration, ease: 'elastic.out(1, 0.3)' })

    setTimeout(() => {
      setShowSalute(true)

      setTimeout(() => {
        setShowSalute(false)
        onComplete()
      }, 2000)
    }, rotationDuration * 1000 * 0.1)
  }

  return (
    <Container>
      <svg width="351" height="723" viewBox="0 0 351 723" overflow='visible' fill='none'>
        <HeadBlock1 />

        <g ref={(el) => (blockRefs.current[0] = el)}>
          <Block1 />
        </g>

        <g ref={(el) => (blockRefs.current[1] = el)} style={{ cursor: 'pointer' }} onClick={handleBlock2Click}>
          <Block2 />
        </g>

        <g ref={(el) => (blockRefs.current[2] = el)}>
          <Block3 />
        </g>

        <g ref={(el) => (blockRefs.current[3] = el)}>
          <Block4 />
        </g>

        <g ref={(el) => (blockRefs.current[4] = el)}>
          <Block5 />
        </g>

        <g ref={(el) => (blockRefs.current[5] = el)}>
          <Block6 />
        </g>

        <g ref={(el) => (blockRefs.current[6] = el)}>
          <Block7 />
        </g>

        <g ref={(el) => (blockRefs.current[7] = el)}>
          <Block8 />
        </g>

        <g ref={(el) => (blockRefs.current[8] = el)}>
          <Block9 />
        </g>

        <g ref={(el) => (blockRefs.current[9] = el)}>
          <Block10 />
        </g>

        <g ref={tailBlockRef}>
          <TailBlock />
        </g>
      </svg>

      {showSalute && (
        <SaluteContainer>
          <img src={SaluteImage} alt="salute" width={180} height={318} />
        </SaluteContainer>
      )}
    </Container>
  )
}
