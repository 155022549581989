export const HeadBlock1 = ({ refEye }) => {
  return (
    <g>
      <path d="M151.289 74.4255C151.289 74.4255 180.671 44.274 177.962 21.2461L184.308 33.1254L193.035 27.2635C193.035 27.2635 176.605 71.3407 151.289 74.4255Z" fill="#FF530A"/>
      <path d="M166.702 55.71C165.935 46.0361 162.935 36.7275 157.486 28.3964C136.168 -4.21412 85.5096 -9.51786 44.3378 16.5476C11.3776 37.4141 0.0517541 71.5637 0.000194783 101.773C-0.109798 165.759 46.3795 200 101.988 200V144.446C108.632 141.906 115.194 138.642 121.529 134.63C147.859 117.961 163.938 92.5013 166.527 67.7821C166.953 63.7231 167.018 59.6878 166.698 55.71H166.702Z" fill="#EFB825"/>
      <path d="M67.5326 66.23C67.6598 66.3044 67.787 66.3687 67.9176 66.4397C68.0791 66.5243 68.2441 66.6122 68.4057 66.6934C68.5569 66.7712 68.7116 66.8456 68.8594 66.92C81.2268 72.9036 96.0552 68.7838 103.5 57.6655C104.033 56.8707 104.528 56.0386 104.982 55.1693C111.85 42.0452 106.608 25.9277 93.2745 19.1729C79.9378 12.4147 63.5591 17.5729 56.6914 30.697C56.2377 31.5663 55.8355 32.4491 55.4849 33.3421C50.6727 45.6171 55.7015 59.6984 67.5292 66.23H67.5326Z" fill="#FFE6DE"/>
      <path ref={refEye} d="M67.9828 67.4228C68.0963 67.4904 68.2166 67.5513 68.3369 67.6156C68.4847 67.6934 68.6325 67.7712 68.7837 67.8456C68.9212 67.9166 69.0622 67.9843 69.1996 68.0519C80.4843 73.5112 94.0134 69.7533 100.806 59.6058C104.202 50.2533 98.4132 38.6953 87.0495 32.9383C75.6858 27.178 62.7582 29.2548 56.9973 37.4134C52.6044 48.6128 57.1932 61.4594 67.9863 67.4194L67.9828 67.4228Z" fill="black"/>
      <path d="M61.2252 117.627C70.8101 117.627 78.5801 109.981 78.5801 100.549C78.5801 91.1169 70.8101 83.4707 61.2252 83.4707C51.6404 83.4707 43.8704 91.1169 43.8704 100.549C43.8704 109.981 51.6404 117.627 61.2252 117.627Z" fill="#FF530A"/>
      <path d="M84.6091 83.9901C89.3491 88.2757 91.5352 94.8039 90.3837 101.024C90.1122 102.496 88.4176 103.588 86.9396 103.206C85.3791 102.803 84.4338 101.386 84.7225 99.8167C84.7775 99.5224 84.8256 99.2248 84.8669 98.9271C84.8875 98.7783 84.9013 98.6294 84.9185 98.4806C84.8944 98.714 84.8944 98.8459 84.9047 98.5347C84.9253 97.8954 84.9288 97.2629 84.8772 96.627C84.8531 96.3294 84.8222 96.0283 84.781 95.734C84.7707 95.6698 84.7019 95.1759 84.7569 95.5412C84.8153 95.9065 84.7294 95.4195 84.7157 95.3518C84.585 94.7261 84.4166 94.1104 84.2104 93.505C84.1141 93.2209 84.0076 92.9401 83.8941 92.6594C83.8392 92.5207 83.7773 92.382 83.7188 92.2433C83.5642 91.8746 83.7601 92.1621 83.7635 92.3211C83.7635 92.1046 83.4507 91.7123 83.3442 91.5228C83.1964 91.2624 83.0417 91.0019 82.8767 90.7483C82.5295 90.2071 82.1411 89.7031 81.739 89.2025C81.4777 88.8744 82.0964 89.5678 81.794 89.2735C81.6874 89.1686 81.5877 89.0536 81.4812 88.9454C81.244 88.7018 80.9965 88.4685 80.7421 88.2385C79.6285 87.2305 79.4119 85.3228 80.5153 84.2133C81.6015 83.1208 83.4129 82.911 84.6057 83.9901H84.6091Z" fill="black"/>
      <path d="M86.6368 96.7251C93.9926 98.599 101.682 98.9204 109.206 97.9631C123.223 96.1873 137.168 90.7787 149.085 83.3677C155.795 79.1971 161.944 73.8765 166.526 67.5343C166.918 63.4719 166.952 59.4299 166.609 55.4453C161.284 69.486 147.573 78.8386 133.937 84.8695C119.583 91.2185 103.442 95.1252 87.8639 91.1542C84.2445 90.2308 83.0276 95.8051 86.6334 96.7251H86.6368Z" fill="black"/>
      <path d="M149.253 38.6549C148.793 37.5928 148.311 36.4775 147.693 35.5084C148.233 36.3598 147.616 35.3975 147.516 35.2335C147.387 35.0236 147.252 34.8073 147.118 34.6047C146.799 34.114 146.475 33.6373 146.132 33.1688C145.453 32.2312 144.72 31.3384 143.946 30.4895C143.782 30.3095 143.611 30.1298 143.441 29.9502C143.181 29.681 143.215 29.7128 143.536 30.0462C143.42 29.9311 143.305 29.816 143.189 29.701C142.776 29.3057 142.357 28.9245 141.926 28.5509C140.605 27.4048 140.073 25.2059 141.184 23.6932C142.213 22.2819 144.27 21.7157 145.678 22.9446C149.705 26.4399 152.941 30.8446 155.133 35.8959C155.849 37.5488 155.107 39.8086 153.506 40.4663C151.857 41.148 150.022 40.434 149.254 38.6617L149.253 38.6549Z" fill="black"/>
    </g>
  )
}
