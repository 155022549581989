import { Box } from '@mui/material'
import { Layout } from 'components/layout/layout'
import { Description } from 'components/description/description'
import { Button } from 'components/uikit/button/button'
import { Snake } from './snake/snake'

export const December01Complete = () => {
  const openPlaylist = () => {
    window.open('https://music.youtube.com/playlist?list=PLg4sm8Gq028s7ddP7Bzz6beVY_h10u8zm', '_blank').focus()
  }

  return (
    <Layout
      title='Дякую, я вже трішки більший!'
      subTitle='Неділя, 1 грудня'
      description={(
        <Description>
          Твій плейлист знизу! <br />
          Завтра буде нова нагорода, повертайся!
        </Description>
      )}
      snake={(
        <Box width='100%'>
          <Box sx={{ marginBottom: '48px' }}>
            <a href="https://music.youtube.com/playlist?list=PLg4sm8Gq028s7ddP7Bzz6beVY_h10u8zm">
              <Button type='large' onClick={openPlaylist}>Різдвяний плейлист</Button>
            </a>
          </Box>

          <Snake />
        </Box>
      )}
    />
  )
}
