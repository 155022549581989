import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Box } from '@mui/material'
import { Layout } from 'components/layout/layout'
import { Drawer } from 'components/drawer/drawer'
import { Description } from 'components/description/description'
import { LogoDrawer } from 'components/logoDrawer/logoDrawer'
import { useActivateMutation } from 'store/sessionApi'
import { ReactComponent as ChickenImage } from 'assets/images/december20/chicken.svg'
import { ReactComponent as Num1Image } from 'assets/images/december20/num1.svg'
import { ReactComponent as Num2Image } from 'assets/images/december20/num2.svg'
import { ReactComponent as Num3Image } from 'assets/images/december20/num3.svg'
import { ReactComponent as Num4Image } from 'assets/images/december20/num4.svg'
import { ReactComponent as Num5Image } from 'assets/images/december20/num5.svg'
import smallSnakeImage from 'assets/images/december20/smallSnake.svg'
import { Snake } from './snake/snake'
import { Title, Row, Label } from './styles'

export const December20 = () => {
  const [isDrawer, setIsDrawer] = useState(false)
  const [activate, { isLoading }] = useActivateMutation()
  const navigate = useNavigate()

  const goNext = async () => {
    const res = await activate({ cell: 21 })

    if (res) {
      navigate('/december-20/complete')
    }
  }

  return (
    <>
      <Layout
        title='Смачненькі були! Ковбаска від «Бащинський» — мій краш.'
        subTitle='П’ятниця, 20 грудня'
        description={(
          <Description>
            Пропоную тобі теж спробувати. Бо сьогодні діє кешбек <span>20%</span> на смакоту від «Бащинський» у «М’ясомаркет» та фірмових магазинах «Наша Ряба».<br /><br />
            Тицяй на комірку і забирай нагороду.
          </Description>
        )}
        snake={<Snake onComplete={() => setIsDrawer(true)} />}
        typePhone='shin'
      />

      <Drawer
        isOpen={isDrawer}
        title='Гарячий бутер від «Бащинський»'
        buttonLabel='Погодуй мене і отримай кешбек'
        onSubmit={goNext}
        headIcon={<ChickenImage />}
        isDisabled={isLoading}
      >
        <Title>Як готувати</Title>

        <Box mt='24px'>
          <Row>
            <Num1Image />
            <Label>Отже, наріж скибочками хлібчик (я люблю білий), сир, варену ковбаску від «Бащинський» та помідор.</Label>
          </Row>

          <Row>
            <Num2Image />
            <Label>Збий два яйця з сіллю та перчиком або спеціями до смаку. </Label>
          </Row>

          <Row>
            <Num3Image />
            <Label>Зроби бутер, склавши всі інгредієнти між двома скибочками хлібчика, і занур його в суміш з яєць.</Label>
          </Row>

          <Row>
            <Num4Image />
            <Label>Поклади на пательню та смаж до золотої скоринки (близько 2 хвилин).</Label>
          </Row>

          <Row>
            <Num5Image />
            <Label>А тепер кусь!</Label>
          </Row>
        </Box>

        <LogoDrawer
          type='primary'
          logo={smallSnakeImage}
          title={<>Погодуй мене — подарую <span>20%</span> на смакоту від «Бащинський».</>}
        />
      </Drawer>
    </>
  )
}
