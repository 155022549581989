import { createBrowserRouter } from 'react-router-dom'
import { RouterLayout } from 'components/routerLayout/routerLayout'
import { Home } from 'pages/home/home'
import { Conditions } from 'pages/conditions/conditions'
import { November29 } from 'pages/november29/november29'
import { November29Complete } from 'pages/november29Complete/november29Complete'
import { November30 } from 'pages/november30/november30'
import { December01 } from 'pages/december01/december01'
import { December01Complete } from 'pages/december01Complete/december01Complete'
import { December02 } from 'pages/december02/december02'
import { December02Complete } from 'pages/december02Complete/december02Complete'
import { December03 } from 'pages/december03/december03'
import { December03Complete } from 'pages/december03Complete/december03Complete'
import { December04 } from 'pages/december04/december04'
import { December04Complete } from 'pages/december04Complete/december04Complete'
import { December05 } from 'pages/december05/december05'
import { December05Complete } from 'pages/december05Complete/december05Complete'
import { December06 } from 'pages/december06/december06'
import { December06Complete } from 'pages/december06Complete/december06Complete'
import { December07 } from 'pages/december07/december07'
import { December07Complete } from 'pages/december07Complete/december07Complete'
import { December08 } from 'pages/december08/december08'
import { December08Complete } from 'pages/december08Complete/december08Complete'
import { December09 } from 'pages/december09/december09'
import { December09Conditions } from 'pages/december09Conditions/december09Conditions'
import { December09Complete } from 'pages/december09Complete/december09Complete'
import { December10 } from 'pages/december10/december10'
import { December10Complete } from 'pages/december10Complete/december10Complete'
import { December11 } from 'pages/december11/december11'
import { December11Complete } from 'pages/december11Complete/december11Complete'
import { December12 } from 'pages/december12/december12'
import { December12Complete } from 'pages/december12Complete/december12Complete'
import { December13 } from 'pages/december13/december13'
import { December13Complete } from 'pages/december13Complete/december13Complete'
import { December14 } from 'pages/december14/december14'
import { December14Complete } from 'pages/december14Complete/december14Complete'
import { December15 } from 'pages/december15/december15'
import { December15Complete } from 'pages/december15Complete/december15Complete'
import { December16 } from 'pages/december16/december16'
import { December16Complete } from 'pages/december16Complete/december16Complete'
import { December17 } from 'pages/december17/december17'
import { December17Complete } from 'pages/december17Complete/december17Complete'
import { December18 } from 'pages/december18/december18'
import { December18Complete } from 'pages/december18Complete/december18Complete'
import { December19 } from 'pages/december19/december19'
import { December19Complete } from 'pages/december19Complete/december19Complete'
import { December20 } from 'pages/december20/december20'
import { December20Complete } from 'pages/december20Complete/december20Complete'
import { December21 } from 'pages/december21/december21'
import { December21Complete } from 'pages/december21Complete/december21Complete'
import { December22 } from 'pages/december22/december22'
import { December22Complete } from 'pages/december22Complete/december22Complete'
import { December23 } from 'pages/december23/december23'
import { December23Complete } from 'pages/december23Complete/december23Complete'
import { December24 } from 'pages/december24/december24'
import { December24Complete } from 'pages/december24Complete/december24Complete'
import { December25 } from 'pages/december25/december25'
import { December25Complete } from 'pages/december25Complete/december25Complete'
import { December26 } from 'pages/december26/december26'
import { December26Complete } from 'pages/december26Complete/december26Complete'
import { December27 } from 'pages/december27/december27'
import { December27Complete } from 'pages/december27Complete/december27Complete'
import { December28 } from 'pages/december28/december28'
import { December28Complete } from 'pages/december28Complete/december28Complete'
import { December29 } from 'pages/december29/december29'
import { December29Complete } from 'pages/december29Complete/december29Complete'
import { December30 } from 'pages/december30/december30'
import { December30Complete } from 'pages/december30Complete/december30Complete'
import { December31 } from 'pages/december31/december31'
import { December31Complete } from 'pages/december31Complete/december31Complete'

export const router = createBrowserRouter([
  {
    path: '/',
    element: <RouterLayout />,
    children: [
      { path: '/', element: <Home /> },
      { path: '/conditions', element: <Conditions /> },
      { path: '/november-29', element: <November29 /> },
      { path: '/november-29/complete', element: <November29Complete /> },
      { path: '/november-30', element: <November30 /> },
      { path: '/december-01', element: <December01 /> },
      { path: '/december-01/complete', element: <December01Complete /> },
      { path: '/december-02', element: <December02 /> },
      { path: '/december-02/complete', element: <December02Complete /> },
      { path: '/december-03', element: <December03 /> },
      { path: '/december-03/complete', element: <December03Complete /> },
      { path: '/december-04', element: <December04 /> },
      { path: '/december-04/complete', element: <December04Complete /> },
      { path: '/december-05', element: <December05 /> },
      { path: '/december-05/complete', element: <December05Complete /> },
      { path: '/december-06', element: <December06 /> },
      { path: '/december-06/complete', element: <December06Complete /> },
      { path: '/december-07', element: <December07 /> },
      { path: '/december-07/complete', element: <December07Complete /> },
      { path: '/december-08', element: <December08 /> },
      { path: '/december-08/complete', element: <December08Complete /> },
      { path: '/december-09', element: <December09 /> },
      { path: '/december-09/conditions', element: <December09Conditions /> },
      { path: '/december-09/complete', element: <December09Complete /> },
      { path: '/december-10', element: <December10 /> },
      { path: '/december-10/complete', element: <December10Complete /> },
      { path: '/december-11', element: <December11 /> },
      { path: '/december-11/complete', element: <December11Complete /> },
      { path: '/december-12', element: <December12 /> },
      { path: '/december-12/complete', element: <December12Complete /> },
      { path: '/december-13', element: <December13 /> },
      { path: '/december-13/complete', element: <December13Complete /> },
      { path: '/december-14', element: <December14 /> },
      { path: '/december-14/complete', element: <December14Complete /> },
      { path: '/december-15', element: <December15 /> },
      { path: '/december-15/complete', element: <December15Complete /> },
      { path: '/december-16', element: <December16 /> },
      { path: '/december-16/complete', element: <December16Complete /> },
      { path: '/december-17', element: <December17 /> },
      { path: '/december-17/complete', element: <December17Complete /> },
      { path: '/december-18', element: <December18 /> },
      { path: '/december-18/complete', element: <December18Complete /> },
      { path: '/december-19', element: <December19 /> },
      { path: '/december-19/complete', element: <December19Complete /> },
      { path: '/december-20', element: <December20 /> },
      { path: '/december-20/complete', element: <December20Complete /> },
      { path: '/december-21', element: <December21 /> },
      { path: '/december-21/complete', element: <December21Complete /> },
      { path: '/december-22', element: <December22 /> },
      { path: '/december-22/complete', element: <December22Complete /> },
      { path: '/december-23', element: <December23 /> },
      { path: '/december-23/complete', element: <December23Complete /> },
      { path: '/december-24', element: <December24 /> },
      { path: '/december-24/complete', element: <December24Complete /> },
      { path: '/december-25', element: <December25 /> },
      { path: '/december-25/complete', element: <December25Complete /> },
      { path: '/december-26', element: <December26 /> },
      { path: '/december-26/complete', element: <December26Complete /> },
      { path: '/december-27', element: <December27 /> },
      { path: '/december-27/complete', element: <December27Complete /> },
      { path: '/december-28', element: <December28 /> },
      { path: '/december-28/complete', element: <December28Complete /> },
      { path: '/december-29', element: <December29 /> },
      { path: '/december-29/complete', element: <December29Complete /> },
      { path: '/december-30', element: <December30 /> },
      { path: '/december-30/complete', element: <December30Complete /> },
      { path: '/december-31', element: <December31 /> },
      { path: '/december-31/complete', element: <December31Complete /> }
    ]
  }
])
