import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import sessionReducer from './sessionSlice'
import playbackReducer from './playbackSlice'
import { sessionApi } from './sessionApi'

const persistConfig = {
  key: 'calendar',
  storage,
  blacklist: ['register', 'playback']
}

const persistedReducer = persistReducer(persistConfig, sessionReducer)

export const store = configureStore({
  reducer: {
    session: persistedReducer,
    playback: playbackReducer,
    [sessionApi.reducerPath]: sessionApi.reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      }
    }).concat(sessionApi.middleware)
})

export const persistor = persistStore(store)

setupListeners(store.dispatch)
