export const BUTTONS = [
  { label: 'Банош', key: 1 },
  { label: 'Сардельки від «Бащинський»', key: 2 },
  { label: 'Жаб’ячі лапки ', key: 3 }
]

export const ANSWERS = {
  2: {
    title: 'Юху, відповідь топчик!',
    description: <>Лови <span>25%</span> кешбеку на смакоту від «Бащинський» у «М’ясомаркет», та фірмових магазинах «Наша Ряба».</>
  },
  1: {
    title: 'Упс, комусь треба підкріпитися.',
    description: <>Лови <span>25%</span> кешбеку на смакоту від «Бащинський» у «М’ясомаркет», та фірмових магазинах «Наша Ряба».</>
  },
  3: {
    title: 'Упс, комусь треба підкріпитися.',
    description: <>Лови <span>25%</span> кешбеку на смакоту від «Бащинський» у «М’ясомаркет», та фірмових магазинах «Наша Ряба».</>
  }
}
