import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Layout } from 'components/layout/layout'
import { Drawer } from 'components/drawer/drawer'
import { LogoDrawer } from 'components/logoDrawer/logoDrawer'
import { useActivateMutation } from 'store/sessionApi'
import { Description } from 'components/description/description'
import logoDrawer from 'assets/images/december31/logoDrawer.png'
import { Snake } from './snake/snake'

export const December31 = () => {
  const [isDrawer, setIsDrawer] = useState(false)
  const [activate, { isLoading }] = useActivateMutation()
  const navigate = useNavigate()

  const goNext = async () => {
    const res = await activate({ cell: 32 })

    if (res) {
      navigate('/december-31/complete')
    }
  }

  return (
    <>
      <Layout
        title='Привіти! Сьогодні останній день не лише року, а й нашої пригоди 😥'
        subTitle='Вівторок, 31 грудня'
        description={(
          <Description>
            Бажаю, щоб новий рік був до тебе добрим, щедрим та неймовірно смачним. Завжди пам’ятай, що ти — топчик!
          </Description>
        )}
        snake={<Snake onComplete={() => setIsDrawer(true)} />}
      />

      <Drawer
        isOpen={isDrawer}
        title={<>І заходь на прощання за «Апетитною». Адже на неї діє кешбек <span>20%</span>.</>}
        buttonLabel='Погодуй мене і отримай кешбек'
        onSubmit={goNext}
        isDisabled={isLoading}
      >
        <LogoDrawer logo={logoDrawer} />
      </Drawer>
    </>
  )
}
