import { styled, Box, Typography } from '@mui/material'

export const SnakeContainer = styled(Box)(() => ({
  position: 'relative',
  display: 'inline-block'
}))

export const LightContainer = styled(Box)(() => ({
  position: 'absolute',
  top: '-30px',
  right: '-45px',
  cursor: 'pointer'
}))

export const Title = styled(Typography)(({ theme }) => ({
  fontFamily: 'Axiforma',
  fontWeight: '700',
  fontSize: '24px',
  lineHeight: '31.2px',
  color: theme.colors.darkTeal,
  textAlign: 'left',
  marginTop: '48px'
}))

export const Row = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'flex-start',
  textAlign: 'left',
  marginTop: '16px',

  svg: {
    minWidth: '24px',
    minHeight: '24px'
  }
}))

export const Label = styled(Typography)(({ theme }) => ({
  fontFamily: 'Axiforma',
  fontWeight: '500',
  fontSize: '16px',
  lineHeight: '24px',
  color: `${theme.colors.darkTeal}CC`,
  marginLeft: '8px'
}))
