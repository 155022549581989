import { styled, Box } from '@mui/material'

export const Container = styled(Box)(() => ({
  position: 'relative'
}))

export const SaluteContainer = styled(Box)(() => ({
  position: 'absolute',
  bottom: '34.64px',
  right: '-20px'
}))
