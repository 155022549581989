import { styled, Box } from '@mui/material'
import { Button } from 'components/uikit/button/button'

export const ButtonStyled = styled(Button)(({ theme, isactive }) => ({
  backgroundColor: theme.colors[isactive ? 'darkCyan' : 'darkTeal'],
  marginBottom: '12px'
}))

export const SnakeContainer = styled(Box)(() => ({
  position: 'fixed',
  margin: '0 auto',
  left: 0,
  right: 0,
  bottom: -200,
  zIndex: -1,

  svg: {
    marginBottom: '-6px'
  }
}))

export const ConfRightContainer = styled(Box)(() => ({
  position: 'absolute',
  right: -60,
  top: -120,
  transform: 'rotate(90deg)'
}))

export const ConfLeftContainer = styled(Box)(() => ({
  position: 'absolute',
  left: -60,
  top: -120,
  transform: 'rotate(-90deg)'
}))
