import { useEffect, useRef } from 'react'
import { gsap } from 'gsap'
import { HeadBlock1 } from './headBlock/headBlocks'
import { Block1 } from './block1/block1'
import { Block2 } from './block2/block2'
import { Block3 } from './block3/block3'
import { Block4 } from './block4/block4'
import { Block5 } from './block5/block5'
import { Block6 } from './block6/block6'
import { Block7 } from './block7/block7'
import { Block8 } from './block8/block8'
import { Block9 } from './block9/block9'
import { Block10 } from './block10/block10'
import { Block11 } from './block11/block11'
import { Block12 } from './block12/block12'
import { Block13 } from './block13/block13'
import { Block14 } from './block14/block14'
import { Block15 } from './block15/block15'
import { Block16 } from './block16/block16'
import { Block17 } from './block17/block17'
import { Block18 } from './block18/block18'
import { TailBlock } from './tailBlock/tailBlock'
import { Container } from './styles'

export const Snake = () => {
  const blockRefs = useRef([])
  const tailBlockRef = useRef(null)
  const tongueRef = useRef(null)

  useEffect(() => {
    gsap.set(tongueRef.current, { x: 0, y: 0, transformOrigin: '100% 100%' })
    gsap.fromTo(
      tongueRef.current,
      { x: 0, y: 0 },
      { x: 1.25, y: 11, rotation: 19.25, duration: 0.15, ease: 'linear', repeat: -1, yoyo: true }
    )

    gsap.fromTo(
      blockRefs.current[0],
      { x: '150vw', y: 0 },
      { x: 0, y: 0, duration: 0.3, ease: 'linear', onComplete: startBlock2Animation }
    )

    gsap.set(blockRefs.current[1], { x: '150vw', y: 100, transformOrigin: '100% 100%' })
    gsap.set(blockRefs.current[2], { x: 200, y: '150vh' })
    gsap.set(blockRefs.current[3], { x: '-150vw', y: 200 })
    gsap.set(blockRefs.current[4], { x: 100, y: '150vh' })
    gsap.set(blockRefs.current[5], { x: '150vw', y: 300 })
    gsap.set(blockRefs.current[6], { x: 200, y: '150vh' })
    gsap.set(blockRefs.current[7], { x: '-150vw', y: 400 })
    gsap.set(blockRefs.current[8], { x: '-150vw', y: 400 })
    gsap.set(blockRefs.current[9], { x: 0, y: '150vh' })
    gsap.set(blockRefs.current[10], { x: '150vw', y: 500 })
    gsap.set(blockRefs.current[11], { x: 100, y: '150vh' })
    gsap.set(blockRefs.current[12], { x: '-150vw', y: 600 })
    gsap.set(blockRefs.current[13], { x: 0, y: '150vh' })
    gsap.set(blockRefs.current[14], { x: '150vw', y: 700 })
    gsap.set(blockRefs.current[15], { x: '150vw', y: 700 })
    gsap.set(blockRefs.current[16], { x: 200, y: '150vh' })
    gsap.set(blockRefs.current[17], { x: '-150vw', y: 800 })

    gsap.set(tailBlockRef.current, { x: 100, y: '150vh' })
  }, [])

  const startBlock2Animation = () => {
    gsap.to(blockRefs.current[1], { x: 200, y: 100, duration: 0.3, ease: 'linear', onComplete: startBlock3Animation } )
  }

  const startBlock3Animation = () => {
    gsap.to(blockRefs.current[2], { x: 200, y: 200, duration: 0.3, ease: 'linear', onComplete: startBlock4Animation } )
  }

  const startBlock4Animation = () => {
    gsap.to(blockRefs.current[3], { x: 100, y: 200, duration: 0.3, ease: 'linear', onComplete: startBlock5Animation } )
  }

  const startBlock5Animation = () => {
    gsap.to(blockRefs.current[4], { x: 100, y: 300, duration: 0.3, ease: 'linear', onComplete: startBlock6Animation } )
  }

  const startBlock6Animation = () => {
    gsap.to(blockRefs.current[5], { x: 200, y: 300, duration: 0.3, ease: 'linear', onComplete: startBlock7Animation } )
  }

  const startBlock7Animation = () => {
    gsap.to(blockRefs.current[6], { x: 200, y: 400, duration: 0.3, ease: 'linear', onComplete: startBlock8Animation } )
  }

  const startBlock8Animation = () => {
    gsap.to(blockRefs.current[7], { x: 100, y: 400, duration: 0.3, ease: 'linear', onComplete: startBlock9Animation } )
  }

  const startBlock9Animation = () => {
    gsap.to(blockRefs.current[8], { x: 0, y: 400, duration: 0.3, ease: 'linear', onComplete: startBlock10Animation } )
  }

  const startBlock10Animation = () => {
    gsap.to(blockRefs.current[9], { x: 0, y: 500, duration: 0.3, ease: 'linear', onComplete: startBlock11Animation } )
  }

  const startBlock11Animation = () => {
    gsap.to(blockRefs.current[10], { x: 100, y: 500, duration: 0.3, ease: 'linear', onComplete: startBlock12Animation } )
  }

  const startBlock12Animation = () => {
    gsap.to(blockRefs.current[11], { x: 100, y: 600, duration: 0.3, ease: 'linear', onComplete: startBlock13Animation } )
  }

  const startBlock13Animation = () => {
    gsap.to(blockRefs.current[12], { x: 0, y: 600, duration: 0.3, ease: 'linear', onComplete: startBlock14Animation } )
  }

  const startBlock14Animation = () => {
    gsap.to(blockRefs.current[13], { x: 0, y: 700, duration: 0.3, ease: 'linear', onComplete: startBlock15Animation } )
  }

  const startBlock15Animation = () => {
    gsap.to(blockRefs.current[14], { x: 100, y: 700, duration: 0.3, ease: 'linear', onComplete: startBlock16Animation } )
  }

  const startBlock16Animation = () => {
    gsap.to(blockRefs.current[15], { x: 200, y: 700, duration: 0.3, ease: 'linear', onComplete: startBlock17Animation } )
  }

  const startBlock17Animation = () => {
    gsap.to(blockRefs.current[16], { x: 200, y: 800, duration: 0.3, ease: 'linear', onComplete: startBlock18Animation } )
  }

  const startBlock18Animation = () => {
    gsap.to(blockRefs.current[17], { x: 100, y: 800, duration: 0.3, ease: 'linear', onComplete: startTailBlockAnimation } )
  }

  const startTailBlockAnimation = () => {
    gsap.to(tailBlockRef.current, { x: 100, y: 900, duration: 0.3, ease: 'linear' } )
  }

  return (
    <Container>
      <svg width="302" height="1005" viewBox="0 0 302 1005" overflow='visible' fill='none'>
        <HeadBlock1 tongueRef={tongueRef} />

        <g ref={(el) => (blockRefs.current[0] = el)}>
          <Block1 />
        </g>

        <g ref={(el) => (blockRefs.current[1] = el)}>
          <Block2 />
        </g>

        <g ref={(el) => (blockRefs.current[2] = el)}>
          <Block3 />
        </g>

        <g ref={(el) => (blockRefs.current[3] = el)}>
          <Block4 />
        </g>

        <g ref={(el) => (blockRefs.current[4] = el)}>
          <Block5 />
        </g>

        <g ref={(el) => (blockRefs.current[5] = el)}>
          <Block6 />
        </g>

        <g ref={(el) => (blockRefs.current[6] = el)}>
          <Block7 />
        </g>

        <g ref={(el) => (blockRefs.current[7] = el)}>
          <Block8 />
        </g>

        <g ref={(el) => (blockRefs.current[8] = el)}>
          <Block9 />
        </g>

        <g ref={(el) => (blockRefs.current[9] = el)}>
          <Block10 />
        </g>

        <g ref={(el) => (blockRefs.current[10] = el)}>
          <Block11 />
        </g>

        <g ref={(el) => (blockRefs.current[11] = el)}>
          <Block12 />
        </g>

        <g ref={(el) => (blockRefs.current[12] = el)}>
          <Block13 />
        </g>

        <g ref={(el) => (blockRefs.current[13] = el)}>
          <Block14 />
        </g>

        <g ref={(el) => (blockRefs.current[14] = el)}>
          <Block15 />
        </g>

        <g ref={(el) => (blockRefs.current[15] = el)}>
          <Block16 />
        </g>

        <g ref={(el) => (blockRefs.current[16] = el)}>
          <Block17 />
        </g>

        <g ref={(el) => (blockRefs.current[17] = el)}>
          <Block18 />
        </g>

        <g ref={tailBlockRef}>
          <TailBlock />
        </g>
      </svg>
    </Container>
  )
}
