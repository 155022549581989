import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Layout } from 'components/layout/layout'
import { Description } from 'components/description/description'
import { Drawer } from 'components/drawer/drawer'
import { LogoDrawer } from 'components/logoDrawer/logoDrawer'
import { useActivateMutation } from 'store/sessionApi'
import logoDrawerImage from 'assets/images/december05/logoDrawer.png'
import { Snake } from './snake/snake'

export const December05 = () => {
  const [isDrawer, setIsDrawer] = useState(false)
  const [activate, { isLoading }] = useActivateMutation()
  const navigate = useNavigate()

  const goNext = async () => {
    const res = await activate({ cell: 6 })

    if (res) {
      navigate('/december-05/complete')
    }
  }

  return (
    <>
      <Layout
        title='Вітання! Миколай уже погодував оленів та збирається в дорогу.'
        subTitle='Четвер, 5 грудня'
        description={(
          <Description>
            A мені нетерпець подарувати тобі кешбек <span>15%</span> на весь чек. Бо дорослі теж заслуговують на подарунки, ну.<br /><br />
            Тицяй на комірку і забирай нагороду.
          </Description>
        )}
        snake={<Snake onComplete={() => setIsDrawer(true)} />}
      />

      <Drawer
        isOpen={isDrawer}
        title='До зустрічі сьогодні!'
        buttonLabel='Погодуй мене і отримай кешбек'
        onSubmit={goNext}
        isDisabled={isLoading}
      >
        <LogoDrawer logo={logoDrawerImage} />
      </Drawer>
    </>
  )
}
