export const changeOpacity = groupElement => {
  const targetPath1 = groupElement.querySelectorAll('.back-ref')
  const targetPath2 = groupElement.querySelectorAll('.text-ref')

  targetPath1.forEach((path) => {
    path.setAttribute('opacity', '0.1')
    path.setAttribute('fill', '#EFB825')
  })

  targetPath2.forEach((path) => {
    path.setAttribute('fill', 'white')
    path.setAttribute('opacity', '0.4')
  })
}