import { useState, useRef, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Box } from '@mui/material'
import { Layout } from 'components/layout/layout'
import { Drawer } from 'components/drawer/drawer'
import { useActivateMutation } from 'store/sessionApi'
import { ReactComponent as SnakeImage } from 'assets/images/december24/snake.svg'
import answerTrueMusic from 'assets/music/answerTrue.mp3'
import answerFalseMusic from 'assets/music/answerFalse.mp3'
import ConfRightImage from './confRight.gif'
import { BUTTONS, ANSWERS } from './constants'
import { ButtonStyled, SnakeContainer, ConfRightContainer, ConfLeftContainer } from './styles'

export const December24 = () => {
  const [active, setActive] = useState(null)
  const [isCheck, setIsCheck] = useState(false)
  const [isDrawer, setIsDrawer] = useState(false)
  const [activate, { isLoading }] = useActivateMutation()
  const audio1Ref = useRef(null)
  const audio2Ref = useRef(null)
  const navigate = useNavigate()

  useEffect(() => {
    audio1Ref.current = new Audio(answerTrueMusic)
    audio2Ref.current = new Audio(answerFalseMusic)
  }, [])

  const goNext = async () => {
    const res = await activate({ cell: 25 })

    if (res) {
      navigate('/december-24/complete')
    }
  }

  const renderButtons = () => (
    <Box mt='48px'>
      {BUTTONS.map(({ label, key }, index) => (
        <ButtonStyled key={key} type='large' onClick={() => setActive(key)} isactive={active === key ? 1 : 0}>
          {(isCheck && 3 === active && index === 2) && (
            <ConfLeftContainer>
              <img src={ConfRightImage} alt="salute" width={180} height={318} />
            </ConfLeftContainer>
          )}

          {label}

          {(isCheck && 3 === active && index === 2) && (
            <ConfRightContainer>
              <img src={ConfRightImage} alt="salute" width={180} height={318} />
            </ConfRightContainer>
          )}
        </ButtonStyled>
      ))}
    </Box>
  )

  return (
    <>
      <Layout subTitle='Вівторок, 24 грудня' />

      <Drawer
        isOpen
        title='Квіз!'
        topDescription='Що я стоптав на сніданок? '
        buttonLabel='Підтвердити'
        onSubmit={() => {
          setIsCheck(true)

          if (active === 3) {
            audio1Ref.current.play()
          } else {
            audio2Ref.current.play()
          }

          setTimeout(() => setIsDrawer(true), 1000)
        }}
        isDisabled={!active}
      >
        {renderButtons()}
      </Drawer>

      {isDrawer && (
        <Drawer
          isOpen
          title={ANSWERS[active].title}
          topDescription={ANSWERS[active].description}
          buttonLabel='Погодуй мене і отримай кешбек'
          onSubmit={goNext}
          isDisabled={!active || isLoading}
          isShadow
        >
          <SnakeContainer>
            <SnakeImage />
          </SnakeContainer>
        </Drawer>
      )}
    </>
  )
}
