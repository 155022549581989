import { useEffect } from 'react'
import { gsap } from 'gsap'

export const Block2 = () => {
  useEffect(() => {
    gsap.fromTo(
      '#box',
      { scale: 1 },
      {
        scale: 0.8,
        duration: 0.5,
        repeat: -1,
        yoyo: true,
        ease: 'power1.inOut'
      }
    )
  }, [])

  return (
    <g>
      <path d="M0 0C55.2276 0 100 44.7724 100 100H0V0Z" fill="#FF530A"/>
      <path d="M8.23212 66.176L15.5601 60.352H19.0801V84H14.4401V66.624L10.6641 69.536L8.23212 66.176ZM22.3751 64.704V60.352H37.7991V63.616L29.3511 84H24.3911L32.5191 64.704H22.3751ZM40.3281 84.512C39.5815 84.512 38.9521 84.2667 38.4401 83.776C37.9281 83.264 37.6721 82.6347 37.6721 81.888C37.6721 81.1627 37.9281 80.5547 38.4401 80.064C38.9521 79.5733 39.5815 79.328 40.3281 79.328C41.0321 79.328 41.6295 79.5733 42.1201 80.064C42.6321 80.5547 42.8881 81.1627 42.8881 81.888C42.8881 82.6347 42.6321 83.264 42.1201 83.776C41.6295 84.2667 41.0321 84.512 40.3281 84.512ZM42.6384 66.176L49.9664 60.352H53.4864V84H48.8464V66.624L45.0704 69.536L42.6384 66.176ZM68.4934 75.2L64.3334 79.648H73.1334V84H57.7094V80.064L65.0694 72.352C65.9654 71.4133 66.4667 70.88 66.5734 70.752C66.8507 70.432 67.1707 69.9307 67.5334 69.248C67.896 68.5867 68.0774 67.9787 68.0774 67.424C68.0774 66.528 67.768 65.8027 67.1494 65.248C66.5307 64.672 65.752 64.384 64.8134 64.384C63.1494 64.384 61.3787 65.2587 59.5014 67.008L56.9094 63.616C59.3627 61.1627 62.1254 59.936 65.1974 59.936C67.4587 59.936 69.304 60.6187 70.7334 61.984C72.184 63.328 72.9094 65.056 72.9094 67.168C72.9094 69.4507 71.4374 72.128 68.4934 75.2Z" fill="#FFE6DE"/>
      <path id="box" d="M19 30.0006V38.0006H15C14.2044 38.0006 13.4413 37.6845 12.8787 37.1219C12.3161 36.5593 12 35.7962 12 35.0006V31.0006C12 30.7354 12.1054 30.481 12.2929 30.2935C12.4804 30.1059 12.7348 30.0006 13 30.0006H19ZM27 30.0006C27.2652 30.0006 27.5196 30.1059 27.7071 30.2935C27.8946 30.481 28 30.7354 28 31.0006V35.0006C28 35.7962 27.6839 36.5593 27.1213 37.1219C26.5587 37.6845 25.7956 38.0006 25 38.0006H21V30.0006H27ZM24.5 18.0006C25.0905 18.0005 25.6715 18.1498 26.1888 18.4346C26.706 18.7194 27.1429 19.1304 27.4586 19.6295C27.7743 20.1285 27.9586 20.6993 27.9943 21.2887C28.03 21.8782 27.9161 22.4671 27.663 23.0006H28C28.5304 23.0006 29.0391 23.2113 29.4142 23.5864C29.7893 23.9614 30 24.4702 30 25.0006V26.0006C30 26.531 29.7893 27.0397 29.4142 27.4148C29.0391 27.7899 28.5304 28.0006 28 28.0006H21V23.0006H19V28.0006H12C11.4696 28.0006 10.9609 27.7899 10.5858 27.4148C10.2107 27.0397 10 26.531 10 26.0006V25.0006C10 24.4702 10.2107 23.9614 10.5858 23.5864C10.9609 23.2113 11.4696 23.0006 12 23.0006H12.337C12.1144 22.5319 11.9993 22.0194 12 21.5006C12 19.5676 13.567 18.0006 15.483 18.0006C17.238 17.9706 18.795 19.0926 19.864 20.9346L20 21.1776C21.033 19.2636 22.56 18.0636 24.291 18.0026L24.5 18.0006ZM15.5 20.0006C15.1022 20.0006 14.7206 20.1586 14.4393 20.4399C14.158 20.7212 14 21.1028 14 21.5006C14 21.8984 14.158 22.2799 14.4393 22.5612C14.7206 22.8426 15.1022 23.0006 15.5 23.0006H18.643C17.902 21.0956 16.694 19.9806 15.5 20.0006ZM24.483 20.0006C23.303 19.9806 22.098 21.0966 21.357 23.0006H24.5C24.8978 22.9983 25.2785 22.8381 25.5582 22.5552C25.8379 22.2723 25.9938 21.8899 25.9915 21.4921C25.9892 21.0943 25.829 20.7136 25.5462 20.4339C25.2633 20.1542 24.8808 19.9983 24.483 20.0006Z" fill="#FFE6DE"/>
    </g>
  )
}
