import { styled, Box } from '@mui/material'

export const SnakeContainer = styled(Box)(() => ({
  position: 'relative',
  display: 'inline-block'
}))

export const Row = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  marginTop: '16px',

  '&:first-child': {
    marginTop: '0'
  }
}))

export const Circle = styled(Box)(({ theme }) => ({
  width: '8px',
  height: '8px',
  border: `2px solid ${theme.colors.orange}`,
  borderRadius: '100%',
  marginLeft: '8px',
  marginRight: '10px'
}))
