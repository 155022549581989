import { useEffect } from 'react'
import { gsap } from 'gsap'

export const Block2 = () => {
  useEffect(() => {
    gsap.fromTo(
      '#box',
      { scale: 1 },
      {
        scale: 0.8,
        duration: 0.5,
        repeat: -1,
        yoyo: true,
        ease: 'power1.inOut'
      }
    )
  }, [])

  return (
    <g>
      <path d="M1.52588e-05 0C55.2276 0 100 44.7724 100 100H1.52588e-05V0Z" fill="#FF530A"/>
      <path d="M20.6184 75.2L16.4584 79.648H25.2584V84H9.83438V80.064L17.1944 72.352C18.0904 71.4133 18.5917 70.88 18.6984 70.752C18.9757 70.432 19.2957 69.9307 19.6584 69.248C20.021 68.5867 20.2024 67.9787 20.2024 67.424C20.2024 66.528 19.893 65.8027 19.2744 65.248C18.6557 64.672 17.877 64.384 16.9384 64.384C15.2744 64.384 13.5037 65.2587 11.6264 67.008L9.03438 63.616C11.4877 61.1627 14.2504 59.936 17.3224 59.936C19.5837 59.936 21.429 60.6187 22.8584 61.984C24.309 63.328 25.0344 65.056 25.0344 67.168C25.0344 69.4507 23.5624 72.128 20.6184 75.2ZM27.4689 64.704V60.352H42.8929V63.616L34.4449 84H29.4849L37.6129 64.704H27.4689ZM45.4219 84.512C44.6752 84.512 44.0459 84.2667 43.5339 83.776C43.0219 83.264 42.7659 82.6347 42.7659 81.888C42.7659 81.1627 43.0219 80.5547 43.5339 80.064C44.0459 79.5733 44.6752 79.328 45.4219 79.328C46.1259 79.328 46.7232 79.5733 47.2139 80.064C47.7259 80.5547 47.9819 81.1627 47.9819 81.888C47.9819 82.6347 47.7259 83.264 47.2139 83.776C46.7232 84.2667 46.1259 84.512 45.4219 84.512ZM47.7321 66.176L55.0601 60.352H58.5801V84H53.9401V66.624L50.1641 69.536L47.7321 66.176ZM73.5871 75.2L69.4271 79.648H78.2271V84H62.8031V80.064L70.1631 72.352C71.0591 71.4133 71.5605 70.88 71.6671 70.752C71.9445 70.432 72.2645 69.9307 72.6271 69.248C72.9898 68.5867 73.1711 67.9787 73.1711 67.424C73.1711 66.528 72.8618 65.8027 72.2431 65.248C71.6245 64.672 70.8458 64.384 69.9071 64.384C68.2431 64.384 66.4725 65.2587 64.5951 67.008L62.0031 63.616C64.4565 61.1627 67.2191 59.936 70.2911 59.936C72.5525 59.936 74.3978 60.6187 75.8271 61.984C77.2778 63.328 78.0031 65.056 78.0031 67.168C78.0031 69.4507 76.5311 72.128 73.5871 75.2Z" fill="#FFE6DE"/>
      <path id="box" d="M19 30.0006V38.0006H15C14.2044 38.0006 13.4413 37.6845 12.8787 37.1219C12.3161 36.5593 12 35.7962 12 35.0006V31.0006C12 30.7354 12.1054 30.481 12.2929 30.2935C12.4804 30.1059 12.7348 30.0006 13 30.0006H19ZM27 30.0006C27.2652 30.0006 27.5196 30.1059 27.7071 30.2935C27.8946 30.481 28 30.7354 28 31.0006V35.0006C28 35.7962 27.6839 36.5593 27.1213 37.1219C26.5587 37.6845 25.7956 38.0006 25 38.0006H21V30.0006H27ZM24.5 18.0006C25.0905 18.0005 25.6715 18.1498 26.1888 18.4346C26.706 18.7194 27.1429 19.1304 27.4586 19.6295C27.7743 20.1285 27.9586 20.6993 27.9943 21.2887C28.03 21.8782 27.9161 22.4671 27.663 23.0006H28C28.5304 23.0006 29.0391 23.2113 29.4142 23.5864C29.7893 23.9614 30 24.4702 30 25.0006V26.0006C30 26.531 29.7893 27.0397 29.4142 27.4148C29.0391 27.7899 28.5304 28.0006 28 28.0006H21V23.0006H19V28.0006H12C11.4696 28.0006 10.9609 27.7899 10.5858 27.4148C10.2107 27.0397 10 26.531 10 26.0006V25.0006C10 24.4702 10.2107 23.9614 10.5858 23.5864C10.9609 23.2113 11.4696 23.0006 12 23.0006H12.337C12.1144 22.5319 11.9993 22.0194 12 21.5006C12 19.5676 13.567 18.0006 15.483 18.0006C17.238 17.9706 18.795 19.0926 19.864 20.9346L20 21.1776C21.033 19.2636 22.56 18.0636 24.291 18.0026L24.5 18.0006ZM15.5 20.0006C15.1022 20.0006 14.7206 20.1586 14.4393 20.4399C14.158 20.7212 14 21.1028 14 21.5006C14 21.8984 14.158 22.2799 14.4393 22.5612C14.7206 22.8426 15.1022 23.0006 15.5 23.0006H18.643C17.902 21.0956 16.694 19.9806 15.5 20.0006ZM24.483 20.0006C23.303 19.9806 22.098 21.0966 21.357 23.0006H24.5C24.8978 22.9983 25.2785 22.8381 25.5582 22.5552C25.8379 22.2723 25.9938 21.8899 25.9915 21.4921C25.9892 21.0943 25.829 20.7136 25.5462 20.4339C25.2633 20.1542 24.8808 19.9983 24.483 20.0006Z" fill="#FFE6DE"/>
    </g>
  )
}
