import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Box } from '@mui/material'
import { Layout } from 'components/layout/layout'
import { Drawer } from 'components/drawer/drawer'
import { useActivateMutation } from 'store/sessionApi'
import { LogoDrawer } from 'components/logoDrawer/logoDrawer'
import logoDrawer from 'assets/images/december21/logoDrawer.png'
import { Snake } from './snake/snake'

export const December21 = () => {
  const [isDrawer, setIsDrawer] = useState(false)
  const [activate, { isLoading }] = useActivateMutation()
  const navigate = useNavigate()

  const goNext = async () => {
    const res = await activate({ cell: 22 })

    if (res) {
      navigate('/december-21/complete')
    }
  }

  return (
    <>
      <Layout
        title='Йо! Сьогодні субота, а отже, можна не виходити з дому.'
        subTitle='Субота, 21 грудня'
        snake={(
          <Box mt='24px'>
            <Snake onComplete={() => setIsDrawer(true)} />
          </Box>
        )}
      />

      <Drawer
        isOpen={isDrawer}
        title={<>Ну добре, хіба що на пів годинки, бо у «М’ясомаркет» <span>20%</span> кешбеку на піцу.</>}
        description='А потім знов під ковдрочку. '
        buttonLabel='Погодуй мене і отримай кешбек'
        onSubmit={goNext}
        isDisabled={isLoading}
      >
        <LogoDrawer logo={logoDrawer} />
      </Drawer>
    </>
  )
}
