import { useEffect, useRef } from 'react'
import { gsap } from 'gsap'
import { useSelector } from 'react-redux'
import { changeOpacity } from 'utils/basic'
import { HeadBlock } from './headBlock/headBlock'
import { Block1 } from './block1/block1'
import { Block2 } from './block2/block2'
import { Block3 } from './block3/block3'
import { Block4 } from './block4/block4'
import { TailBlock } from './tailBlock/tailBlock'

export const Snake = () => {
  const { days } = useSelector(({ playback }) => playback)
  const blockRefs = useRef([])
  const tailBlockRef = useRef(null)

  useEffect(() => {
    if (days[0]?.is_skipped) changeOpacity(blockRefs.current[1])
    if (days[1]?.is_skipped) changeOpacity(blockRefs.current[2])
  }, [days])

  useEffect(() => {
    gsap.fromTo(
      blockRefs.current[0],
      { x: '150vw', y: 0 },
      { x: 0, y: 0, duration: 0.4, ease: 'linear', onComplete: startBlock2Animation }
    )

    gsap.set(blockRefs.current[1], { x: '150vw', y: 100 })
    gsap.set(blockRefs.current[2], { x: 200, y: '150vh' })
    gsap.set(blockRefs.current[3], { x: '-150vw', y: 199.9 })
    gsap.set(tailBlockRef.current, { x: 200, y: '150vh' })
  }, [])

  const startBlock2Animation = () => {
    gsap.to(blockRefs.current[1], { x: 200, y: 100, duration: 0.4, ease: 'linear', onComplete: startBlock3Animation } )
  }

  const startBlock3Animation = () => {
    gsap.to(blockRefs.current[2], { x: 200, y: 200, duration: 0.4, ease: 'linear', onComplete: startBlock4Animation } )
  }

  const startBlock4Animation = () => {
    gsap.to(blockRefs.current[3], { x: 101, y: 199.9, duration: 0.4, ease: 'linear', onComplete: startTailBlockAnimation } )
  }

  const startTailBlockAnimation = () => {
    gsap.to(tailBlockRef.current, { x: 101, y: 299, duration: 0.4, ease: 'linear' } )
  }

  return (
    <svg width="302" height="405" viewBox="0 0 302 405" overflow='visible'>
      <HeadBlock />

      <g ref={(el) => (blockRefs.current[0] = el)}>
        <Block1 />
      </g>

      <g ref={(el) => (blockRefs.current[1] = el)}>
        <Block2 />
      </g>

      <g ref={(el) => (blockRefs.current[2] = el)}>
        <Block3 />
      </g>

      <g ref={(el) => (blockRefs.current[3] = el)}>
        <Block4 />
      </g>

      <g ref={tailBlockRef}>
        <TailBlock />
      </g>
    </svg>
  )
}
