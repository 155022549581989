import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Layout } from 'components/layout/layout'
import { Drawer } from 'components/drawer/drawer'
import { Description } from 'components/description/description'
import { LogoDrawer } from 'components/logoDrawer/logoDrawer'
import { useActivateMutation } from 'store/sessionApi'
import logoDrawerImage from 'assets/images/december27/logoDrawer.png'
import smallSnakeImage from 'assets/images/december27/smallSnake.svg'
import { Snake } from './snake/snake'

export const December27 = () => {
  const [isDrawer, setIsDrawer] = useState(false)
  const [activate, { isLoading }] = useActivateMutation()
  const navigate = useNavigate()

  const goNext = async () => {
    const res = await activate({ cell: 28 })

    if (res) {
      navigate('/december-27/complete')
    }
  }

  return (
    <>
      <Layout
        title='А що в нас тут? Так остання ж п’ятниця року!'
        subTitle='П’ятниця, 27 грудня'
        description={(
          <Description>
            Пропоную відзначити це соковитою піцою. Заходь в гості у «М’ясомаркет» та купуй із кешбеком <span>20%</span>.<br /><br />
            Тицяй на комірку і забирай нагороду.
          </Description>
        )}
        snake={<Snake onComplete={() => setIsDrawer(true)} />}
        typePhone='pizza'
      />

      <Drawer
        isOpen={isDrawer}
        title='А якщо ти досі обираєш подарунки під ялинку, лови сюрприз від моїх друзів ORNER.'
        topDescription='Від їхньої краси в мене тріпотить хвостик.'
        buttonLabel='Погодуй мене і отримай кешбек'
        onSubmit={goNext}
        isDisabled={isLoading}
      >
        <LogoDrawer
          type='secondary'
          title='100 грн у подарунок'
          promocode='ORNERNEWYEAR'
          description='Купон на 100 грн діє на замовлення від 500 грн, здійснені до 31.03.2025 року.'
          logo={smallSnakeImage}
          logo1={logoDrawerImage}
          link='https://orner.com.ua/ua'
        />
      </Drawer>
    </>
  )
}
