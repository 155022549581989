import { Layout } from 'components/layout/layout'
import { Description } from 'components/description/description'
import { Snake } from './snake/snake'

export const December14Complete = () => {
  return (
    <Layout
      title='Дякую, я вже трішки більший!'
      subTitle='Субота, 14 грудня'
      description={(
        <Description>
          Твоя нагорода вже в додатку!<br />
          Завтра буде нова, повертайся!
        </Description>
      )}
      snake={<Snake />}
    />
  )
}
