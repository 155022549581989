import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Box } from '@mui/material'
import { Layout } from 'components/layout/layout'
import { Drawer } from 'components/drawer/drawer'
import { useActivateMutation } from 'store/sessionApi'
import { ReactComponent as CubeImage } from 'assets/images/december30/cube.svg'
import random1Image from './random1.png'
import random2Image from './random2.png'
import random3Image from './random3.png'
import random4Image from './random4.png'
import random5Image from './random5.png'
import random6Image from './random6.png'
import random7Image from './random7.png'
import random8Image from './random8.png'
import random9Image from './random9.png'
import random10Image from './random10.png'
import random11Image from './random11.png'
import { Snake } from './snake/snake'

export const December30 = () => {
  const [isDrawer, setIsDrawer] = useState(false)
  const [currentImage, setCurrentImage] = useState(random1Image)
  const [isCycling, setIsCycling] = useState(true)
  const [activate, { isLoading }] = useActivateMutation()
  const navigate = useNavigate()

  const images = [
    random1Image,
    random2Image,
    random3Image,
    random4Image,
    random5Image,
    random6Image,
    random7Image,
    random8Image,
    random9Image,
    random10Image,
    random11Image
  ]

  useEffect(() => {
    let interval
    if (isCycling) {
      interval = setInterval(() => {
        setCurrentImage((prev) => {
          const currentIndex = images.indexOf(prev)
          const nextIndex = (currentIndex + 1) % images.length
          return images[nextIndex]
        })
      }, 500)
    }

    return () => clearInterval(interval)
  }, [isCycling, images])

  const handleStop = () => {
    if (isCycling) {
      setIsCycling(false)
    } else {
      goNext()
    }
  }

  const goNext = async () => {
    const res = await activate({ cell: 31 })

    if (res) {
      navigate('/december-30/complete')
    }
  }

  return (
    <>
      <Layout
        title='Вітаннячка! Дізнайся, що на тебе чекає в новому році.'
        subTitle='Понеділок, 30 грудня'
        snake={(
          <Box mt='24px'>
            <Snake onComplete={() => setIsDrawer(true)} />
          </Box>
        )}
      />

      <Drawer
        isOpen={isDrawer}
        title={<>Тицяй, щоб побачити передбачення.</>}
        headIcon={<CubeImage />}
        buttonLabel={isCycling ? 'Стоп' : 'Погодуй мене і отримай кешбек'}
        onSubmit={handleStop}
        isDisabled={isLoading}
      >
        <Box mt='48px'>
          <img src={currentImage} alt='random' width={355} height={244} />
        </Box>
      </Drawer>
    </>
  )
}
