import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  token: null
}

const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    changeToken: (state, { payload }) => {
      state.token = payload
    }
  }
})

export const { changeToken } = sessionSlice.actions
export default sessionSlice.reducer
