import { Box } from '@mui/material'
import { Button } from 'components/uikit/button/button'
import { Container, Title, Description } from './styles'

export const Drawer = ({
  isOpen,
  onClose,
  title,
  children,
  buttonLabel,
  onSubmit,
  headIcon,
  description,
  topDescription,
  isDisabled,
  isShadow,
  mtTop
}) => {
  return (
    <Container anchor='bottom' open={isOpen} onClose={onClose} transitionDuration={500}>
      <Box sx={{ marginBottom: '48px' }}>
        {headIcon ? (
          <Box sx={{ marginBottom: '24px' }}>
            {headIcon}
          </Box>
        ) : null}

        {title ? <Title>{title}</Title> : null}

        {topDescription ? <Description top={mtTop || '24px'}>{topDescription}</Description> : null}

        {children}

        {description ? <Description>{description}</Description> : null}
      </Box>

      <Button onClick={onSubmit} isDisabled={isDisabled} isShadow={isShadow}>{buttonLabel}</Button>
    </Container>
  )
}