import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Layout } from 'components/layout/layout'
import { Description } from 'components/description/description'
import { Drawer } from 'components/drawer/drawer'
import { useActivateMutation } from 'store/sessionApi'
import logoDrawerImage from 'assets/images/december04/logoDrawer.png'
import { Snake } from './snake/snake'
import { LogoDrawerStyled } from './styles'

export const December04 = () => {
  const [isDrawer, setIsDrawer] = useState(false)
  const [activate, { isLoading }] = useActivateMutation()
  const navigate = useNavigate()

  const goNext = async () => {
    const res = await activate({ cell: 5 })

    if (res) {
      navigate('/december-04/complete')
    }
  }

  return (
    <>
      <Layout
        title='Смачної середи!'
        subTitle='Середа, 4 грудня'
        description={(
          <Description>
            Впевнений, що вона в тебе буде такою, адже сьогодні <span>15%</span> кешбеку на «Легко». <br /><br />
            Тицяй на комірку і забирай нагороду.
          </Description>
        )}
        snake={<Snake onComplete={() => setIsDrawer(true)} />}
        typePhone='shin'
      />

      <Drawer
        isOpen={isDrawer}
        title='За що я обожнюю «Легко»?'
        buttonLabel='Погодуй мене і отримай кешбек'
        onSubmit={goNext}
        topDescription={(
          <>
            Бо страви смачнезні та майже готові! Розігрів пательню або духовку, 15 хвилин — і можна топтати. Чекаю у гості!
          </>
        )}
        isDisabled={isLoading}
      >
        <LogoDrawerStyled logo={logoDrawerImage} />
      </Drawer>
    </>
  )
}
