import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Layout } from 'components/layout/layout'
import { Description } from 'components/description/description'
import { Drawer } from 'components/drawer/drawer'
import { LogoDrawer } from 'components/logoDrawer/logoDrawer'
import { useActivateMutation } from 'store/sessionApi'
import smallSnakeImage from 'assets/images/december11/smallSnake.svg'
import megogoImage from 'assets/images/december11/megogo.svg'
import { Snake } from './snake/snake'

export const December11 = () => {
  const [isDrawer, setIsDrawer] = useState(false)
  const [activate, { isLoading }] = useActivateMutation()
  const navigate = useNavigate()

  const goNext = async () => {
    const res = await activate({ cell: 12 })

    if (res) {
      navigate('/december-11/complete')
    }
  }

  return (
    <>
      <Layout
        title='Вітаннячка! Сьогодні поділюся з тобою найлегшим рецептом у світі.'
        subTitle='Середа, 11 грудня'
        description={(
          <Description>
            Отож. Після роботи заходиш в гості до «М’ясомаркет» або фірмових магазинів «Наша Ряба». Купуєш заморожену смакоту від «Легко» з кешбеком <span>15%</span>. Вдома кидаєш на пательню, а собі хвилини на улюблене.<br /><br />
            Тицяй на комірку і забирай нагороду.
          </Description>
        )}
        snake={<Snake onComplete={() => setIsDrawer(true)} />}
      />

      <Drawer
        isOpen={isDrawer}
        topDescription='У передплаті не лише аудіокнижки, а ще й 170+ каналів!'
        title='Поки страва готується, насолодись аудіокнижкою. Бібліотека на MEGOGO — топчик!'
        buttonLabel='Погодуй мене і отримай кешбек'
        description='Скористатись пропозицією можна 1 раз за період проведення акції, вказавши номер телефону для активації. Акція діє з 01.12.2024 р. до 31.12.2024 р. на території України для користувачів програми лояльності ЇМО!. '
        onSubmit={goNext}
        mtTop='48px'
        isDisabled={isLoading}
      >
        <LogoDrawer
          type='secondary'
          title='Не проґав подарунок від MEGOGO'
          promocode='2MEXPU4A'
          description='Активуй промокод до 13 січня 2025р. на megogo.net/code або в застосунку MEGOGO на Smart TV (крім Android TV та Apple TV) та отримай 14 днів передплати «Національне ТБ».'
          logo={smallSnakeImage}
          logo1={megogoImage}
          link='https://megogo.net/ua/code'
        />
      </Drawer>
    </>
  )
}
