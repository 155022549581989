export const HeadBlock1 = () => {
  return (
    <g>
      <path d="M34.5 84.9998C16.9999 -2.50026 108 -18.1126 131.5 36.3874" stroke="#FFE6DE" strokeWidth="8"/>
      <path d="M182.289 97.3127C182.289 97.3127 211.671 67.1613 208.962 44.1333L215.308 56.0126L224.035 50.1507C224.035 50.1507 207.605 94.2279 182.289 97.3127Z" fill="#FF530A"/>
      <path d="M197.702 78.5977C196.935 68.9238 193.935 59.6152 188.486 51.2841C167.168 18.6736 116.51 13.3698 75.3378 39.4353C42.3776 60.3018 31.0518 94.4514 31.0002 124.66C30.8902 188.647 77.3795 222.888 132.988 222.888V167.334C139.632 164.794 146.194 161.529 152.529 157.518C178.859 140.849 194.938 115.389 197.527 90.6698C197.953 86.6108 198.018 82.5755 197.698 78.5977H197.702Z" fill="#EFB825"/>
      <path d="M98.5326 89.1172C98.6598 89.1916 98.787 89.2559 98.9176 89.3269C99.0791 89.4115 99.2441 89.4994 99.4057 89.5806C99.5569 89.6584 99.7116 89.7328 99.8594 89.8072C112.227 95.7908 127.055 91.671 134.5 80.5527C135.033 79.7579 135.528 78.9258 135.982 78.0565C142.85 64.9324 137.608 48.8149 124.274 42.0601C110.938 35.3019 94.5591 40.4602 87.6914 53.5842C87.2377 54.4535 86.8355 55.3363 86.4849 56.2293C81.6727 68.5043 86.7015 82.5856 98.5292 89.1172H98.5326Z" fill="#FFE6DE"/>
      <path d="M98.9828 90.3114C99.0963 90.3791 99.2166 90.44 99.3369 90.5042C99.4847 90.582 99.6325 90.6598 99.7837 90.7342C99.9212 90.8053 100.062 90.8729 100.2 90.9406C111.484 96.3999 125.013 92.642 131.806 82.4945C135.202 73.1419 129.413 61.584 118.049 55.827C106.686 50.0666 93.7582 52.1435 87.9973 60.302C83.6044 71.5014 88.1932 84.3481 98.9863 90.3081L98.9828 90.3114Z" fill="black"/>
      <path d="M92.2252 140.514C101.81 140.514 109.58 132.868 109.58 123.436C109.58 114.004 101.81 106.358 92.2252 106.358C82.6404 106.358 74.8704 114.004 74.8704 123.436C74.8704 132.868 82.6404 140.514 92.2252 140.514Z" fill="#FF530A"/>
      <path d="M115.609 106.879C120.349 111.164 122.535 117.693 121.384 123.913C121.112 125.384 119.418 126.477 117.94 126.095C116.379 125.692 115.434 124.275 115.723 122.705C115.778 122.411 115.826 122.113 115.867 121.816C115.888 121.667 115.901 121.518 115.918 121.369C115.894 121.603 115.894 121.735 115.905 121.423C115.925 120.784 115.929 120.152 115.877 119.516C115.853 119.218 115.822 118.917 115.781 118.623C115.771 118.558 115.702 118.065 115.757 118.43C115.815 118.795 115.729 118.308 115.716 118.24C115.585 117.615 115.417 116.999 115.21 116.394C115.114 116.11 115.008 115.829 114.894 115.548C114.839 115.409 114.777 115.271 114.719 115.132C114.564 114.763 114.76 115.051 114.764 115.21C114.764 114.993 114.451 114.601 114.344 114.412C114.196 114.151 114.042 113.891 113.877 113.637C113.53 113.096 113.141 112.592 112.739 112.091C112.478 111.763 113.096 112.456 112.794 112.162C112.687 112.057 112.588 111.942 112.481 111.834C112.244 111.591 111.997 111.357 111.742 111.127C110.628 110.119 110.412 108.211 111.515 107.102C112.601 106.009 114.413 105.8 115.606 106.879H115.609Z" fill="black"/>
      <path d="M117.637 119.614C124.993 121.488 132.682 121.809 140.206 120.852C154.223 119.076 168.168 113.667 180.085 106.256C186.795 102.086 192.944 96.7651 197.526 90.423C197.918 86.3606 197.952 82.3186 197.609 78.334C192.284 92.3747 178.573 101.727 164.937 107.758C150.583 114.107 134.442 118.014 118.864 114.043C115.244 113.119 114.028 118.694 117.633 119.614H117.637Z" fill="black"/>
      <path d="M180.253 61.5426C179.793 60.4804 179.311 59.3652 178.693 58.3961C179.233 59.2475 178.616 58.2852 178.516 58.1212C178.387 57.9113 178.252 57.695 178.118 57.4924C177.799 57.0017 177.475 56.525 177.132 56.0565C176.453 55.1189 175.72 54.2261 174.946 53.3772C174.782 53.1972 174.611 53.0175 174.441 52.8379C174.181 52.5687 174.215 52.6005 174.536 52.9339C174.42 52.8188 174.305 52.7037 174.189 52.5887C173.776 52.1934 173.357 51.8122 172.926 51.4386C171.605 50.2925 171.073 48.0936 172.184 46.5809C173.213 45.1696 175.27 44.6034 176.678 45.8323C180.705 49.3276 183.941 53.7323 186.133 58.7836C186.849 60.4365 186.107 62.6963 184.506 63.354C182.857 64.0357 181.022 63.3217 180.254 61.5494L180.253 61.5426Z" fill="black"/>
      <ellipse cx="35" cy="103.5" rx="35" ry="40.5" fill="#FF530A"/>
      <ellipse cx="35" cy="103.5" rx="20" ry="22.5" fill="#FFE6DE"/>
    </g>
  )
}
