import { styled, Box } from '@mui/material'
import { Description } from 'components/description/description'

export const Card = styled(Box)(() => ({
  marginTop: '24px',

  '&:last-child': {
    marginTop: '48px'
  },

  svg: {
    marginBottom: '24px'
  }
}))

export const DescriptionStyled = styled(Description)(({ theme }) => ({
  marginBottom: 0,
  color: `${theme.colors.veryLightYellow}CC`
}))
