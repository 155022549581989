import { styled, Button } from '@mui/material'

export const ButtonStyled = styled(Button)(({ theme, isshadow }) => ({
  backgroundColor: theme.colors.orange,
  minWidth: 'auto',
  fontFamily: 'Axiforma',
  maxWidth: '352px',
  width: '100%',
  borderRadius: '999999px',
  padding: '21.5px',
  textTransform: 'none',
  fontWeight: '700',
  fontSize: '16px',
  lineHeight: '20.8px',
  boxShadow: isshadow ? '0px 4px 10px 0px #00000026' : 'none',
  color: theme.colors.white,
  zIndex: 2,

  '&.Mui-disabled': {
    opacity: 0.5,
    color: theme.colors.white
  }
}))

export const LargeButtonStyled = styled(Button)(({ theme, isshadow }) => ({
  backgroundColor: theme.colors.darkCyan,
  minWidth: 'auto',
  fontFamily: 'Axiforma',
  maxWidth: '352px',
  width: '100%',
  borderRadius: '999999px',
  padding: '39.5px',
  textTransform: 'none',
  fontWeight: '700',
  fontSize: '16px',
  lineHeight: '20.8px',
  color: theme.colors.veryPalePink,
  boxShadow: isshadow ? '0px 4px 10px 0px #00000026' : 'none',
  zIndex: 2
}))
