import { useNavigate } from 'react-router-dom'
import { Layout } from 'components/layout/layout'
import { ReactComponent as SurpriseImage } from 'assets/images/conditions/surprise.svg'
import { ReactComponent as CardImage } from 'assets/images/conditions/card.svg'
import { Card, DescriptionStyled } from './styles'

export const Conditions = () => {
  const navigate = useNavigate()

  const goNext = () => navigate('/november-29')

  return (
    <Layout
      title='Умови прості'
      description={(
        <>
          <Card>
            <SurpriseImage />

            <DescriptionStyled>
              Щодня відкривай нову комірку та дізнавайся, який кешбек можеш отримати сьогодні й у яких точках продажу він діє.
            </DescriptionStyled>
          </Card>

          <Card>
            <CardImage />

            <DescriptionStyled>
              Коли будеш на місці, просто відскануй картку лояльності: на ній уже буде активовано пропозицію.
            </DescriptionStyled>
          </Card>
        </>
      )}
      buttonLabel='Далі'
      onSubmit={goNext}
      typePhone='snowflakes'
    />
  )
}
