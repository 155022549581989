import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Layout } from 'components/layout/layout'
import { Description } from 'components/description/description'
import { Drawer } from 'components/drawer/drawer'
import { useActivateMutation } from 'store/sessionApi'
import { LogoDrawer } from 'components/logoDrawer/logoDrawer'
import logoDrawer from 'assets/images/december06/logoDrawer.png'
import smallSnakeImage from 'assets/images/december06/smallSnake.svg'
import { Snake } from './snake/snake'

export const December06 = () => {
  const [isDrawer, setIsDrawer] = useState(false)
  const [activate, { isLoading }] = useActivateMutation()
  const navigate = useNavigate()

  const goNext = async () => {
    const res = await activate({ cell: 7 })

    if (res) {
      navigate('/december-06/complete')
    }
  }

  return (
    <>
      <Layout
        title='Привіти, з Миколайчиком!'
        subTitle='П’ятниця, 6 грудня'
        description={(
          <Description>
            Лови кешбек <span>20%</span> на піцу в «М’ясомаркет» за гарну поведінку та чарівну усмішку.<br /><br />
            Тицяй на комірку і забирай нагороду.
          </Description>
        )}
        snake={<Snake onComplete={() => setIsDrawer(true)} />}
        typePhone='snowflakes'
      />

      <Drawer
        isOpen={isDrawer}
        title={<>А якщо Миколайчик нічого не залишив тобі під подушкою, лови сюрприз від моїх друзів Kilogames, видавництва настільних ігор.</>}
        topDescription='Настолки — ідеальний подарунок до свят!'
        buttonLabel='Погодуй мене і отримай кешбек'
        onSubmit={goNext}
        isDisabled={isLoading}
      >
        {/* <LogoDrawerStyled logo={logoDrawerImage} /> */}

        <LogoDrawer
          type='secondary'
          title='250 грн у подарунок'
          promocode='kilotop'
          description='Промокод на 250 грн діє на замовлення від 700 грн, здійснені до 31.12.2024 р. включно, та не підсумовується з іншими знижками, окрім знижок за програмою лояльності.'
          logo={smallSnakeImage}
          logo1={logoDrawer}
          link='https://kilogames.com.ua'
        />
      </Drawer>
    </>
  )
}
