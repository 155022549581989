import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Box } from '@mui/material'
import { Layout } from 'components/layout/layout'
import { Description } from 'components/description/description'
import { Drawer } from 'components/drawer/drawer'
import { LogoDrawer } from 'components/logoDrawer/logoDrawer'
import { useActivateMutation } from 'store/sessionApi'
import { ReactComponent as MovieImage } from 'assets/images/december08/movie.svg'
import smallSnakeImage from 'assets/images/december08/smallSnake.svg'
import megogoImage from 'assets/images/december08/megogo.svg'
import { Snake } from './snake/snake'
import { Row, Circle } from './styles'

export const December08 = () => {
  const [isDrawer, setIsDrawer] = useState(false)
  const [activate, { isLoading }] = useActivateMutation()
  const navigate = useNavigate()

  const goNext = async () => {
    const res = await activate({ cell: 9 })

    if (res) {
      navigate('/december-08/complete')
    }
  }

  return (
    <>
      <Layout
        title='Смачненькі були! А що тут у нас, неділя?'
        subTitle='Неділя, 8 грудня'
        description={(
          <Description>
            Пропоную влаштувати піжамну вечірку зі смаколиками та різдвяним кіно. Забігай за готовими стравами до кулінарії у «М’ясомаркет». Сьогодні на них кешбек <span>20%</span>. <br /><br />
            Тицяй на комірку і забирай нагороду.
          </Description>
        )}
        snake={<Snake onComplete={() => setIsDrawer(true)} />}
        typePhone='popcorn'
      />

      <Drawer
        isOpen={isDrawer}
        headIcon={<MovieImage />}
        title='А ось мій топчик різдвяних фільмів:'
        topDescription={(
          <Box mt='48px'>
            <Row>
              <Circle /> «Нянька на Різдво»
            </Row>

            <Row>
              <Circle /> «Мій друг Санта Клаус»
            </Row>

            <Row>
              <Circle /> «Перше Різдво»
            </Row>

            <Row>
              <Circle /> «Різдво в замку»
            </Row>
          </Box>
        )}
        description='Скористатися пропозицією можна тільки 1 раз за період акції. Акція діє з 01.12.2024 до 31.12.2024 р. на території України для користувачів програми лояльності ЇМО!'
        buttonLabel='Погодуй мене і отримай кешбек'
        onSubmit={goNext}
        isDisabled={isLoading}
      >
        <LogoDrawer
          type='secondary'
          title='Дивись безкоштовно на MEGOGO'
          promocode='2MEXPU4A'
          description='Активуй промокод до 13 січня 2025р. на megogo.net/code або в застосунку MEGOGO на Smart TV (крім Android TV та Apple TV) та отримай 14 днів передплати «Національне ТБ».'
          logo={smallSnakeImage}
          logo1={megogoImage}
          link='https://megogo.net/ua/code'
        />
      </Drawer>
    </>
  )
}
