import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Layout } from 'components/layout/layout'
import { Drawer } from 'components/drawer/drawer'
import { Description } from 'components/description/description'
import { LogoDrawer } from 'components/logoDrawer/logoDrawer'
import { useActivateMutation } from 'store/sessionApi'
import logoDrawer from 'assets/images/december29/logoDrawer.png'
import { Snake } from './snake/snake'

export const December29 = () => {
  const [isDrawer, setIsDrawer] = useState(false)
  const [activate, { isLoading }] = useActivateMutation()
  const navigate = useNavigate()

  const goNext = async () => {
    const res = await activate({ cell: 30 })

    if (res) {
      navigate('/december-29/complete')
    }
  }

  return (
    <>
      <Layout
        title='Вітаннячка!'
        subTitle='Неділя, 29 грудня'
        description={<Description>Пропоную посмажити крилець та запросити друзів на смачну вечерю.</Description>}
        snake={<Snake onComplete={() => setIsDrawer(true)} />}
      />

      <Drawer
        isOpen={isDrawer}
        title={<>Якщо за, лови кешбек <span>25%</span> на смакоту від «Наша Ряба Апетитна».</>}
        buttonLabel='Погодуй мене і отримай кешбек'
        onSubmit={goNext}
        isDisabled={isLoading}
      >
        <LogoDrawer logo={logoDrawer} />
      </Drawer>
    </>
  )
}
