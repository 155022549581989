import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Box } from '@mui/material'
import { Layout } from 'components/layout/layout'
import { Drawer } from 'components/drawer/drawer'
import { useActivateMutation } from 'store/sessionApi'
import logoDrawer from 'assets/images/december15/logoDrawer.png'
import { Snake } from './snake/snake'
import { LogoDrawerStyled } from './styles'

export const December15 = () => {
  const [isDrawer, setIsDrawer] = useState(false)
  const [activate, { isLoading }] = useActivateMutation()
  const navigate = useNavigate()

  const goNext = async () => {
    const res = await activate({ cell: 16 })

    if (res) {
      navigate('/december-15/complete')
    }
  }

  return (
    <>
      <Layout
        title='Ку. Сьогодні medium грудня, а тому готуємо medium rare стейк.'
        subTitle='Неділя, 15 грудня'
        snake={(
          <Box mt='24px'>
            <Snake onComplete={() => setIsDrawer(true)} />
          </Box>
        )}
      />

      <Drawer
        isOpen={isDrawer}
        title={<>У «М’ясомаркет» ще й кешбек <span>25%</span> на Skott Smeat.</>}
        topDescription={<> А якщо немає настрою заморочуватися — заїжджай у «Döner Маркет» та смакуй із кешбеком <span>20%</span>. Ну просто кешбек-комбо якесь.</>}
        buttonLabel='Погодуй мене і отримай кешбек'
        onSubmit={goNext}
        isDisabled={isLoading}
      >
        <LogoDrawerStyled logo={logoDrawer} />
      </Drawer>
    </>
  )
}
